import { Table, Card, Tag, Button } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatMoney } from '../../helpers/formatMoney'
import {
    deleteServiceInvoiceAction,
    getTodayServiceInvoicesAction,
} from '../../redux/servicesDuck'

const ServicesList = () => {
    const columns = [
        {
            title: 'Tipo de servicio',
            dataIndex: 'service',
            key: 'service',
        },
        {
            title: 'Lote',
            dataIndex: 'lot',
            key: 'lot',
            render: (lot) =>
                lot ? (
                    `Sección ${lot.seccion} Manzana ${lot.manzana} Lote ${lot.lote}`
                ) : (
                    <Tag color="#f0b046">Sin lote asignado</Tag>
                ),
        },
        {
            title: 'No. de Servicio',
            dataIndex: 'measurerNumber',
            key: 'measurerNumber',
        },
        {
            title: 'Año',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Bimestre / Mes',
            dataIndex: 'twomonths',
            key: 'twomonths',
            render: (val, record) => (val ? val : record.month),
        },
        {
            title: 'Cantidad',
            dataIndex: 'amount',
            key: 'amount',
            render: (val) => formatMoney(val),
        },

        {
            title: 'Acciones',
            dataIndex: '_id',
            key: '_id',
            render: (_, record) => (
                <Button onClick={() => deleteInvoice(record._id)} type="danger">
                    Eliminar
                </Button>
            ),
        },
    ]
    const dispatch = useDispatch()
    const { todayServices, fetching } = useSelector(({ services }) => services)
    React.useEffect(() => {
        dispatch(getTodayServiceInvoicesAction())
    }, [])

    const deleteInvoice = (id) => {
        if (
            !window.confirm(
                '¿Estas segura de eliminar la factura?, esta acción no es reversible.',
            )
        ) {
            return
        }
        dispatch(deleteServiceInvoiceAction(id))
    }

    return (
        <Card
            title="Servicios capturados hoy"
            style={{ width: '60%', marginTop: 40 }}
        >
            <Table loading={fetching} columns={columns} dataSource={todayServices} />
        </Card>
    )
}

export default ServicesList
