import React from 'react'

import { Row, Col } from 'antd'

import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { SideLayout, InvoiceType } from '../Common'

import systems from '../../assets/images/invoices/systems.png'
import promotion from '../../assets/images/invoices/promotion.png'
import promotion_penalty from '../../assets/images/invoices/promotion_penalty.png'
import lomas from '../../assets/images/invoices/lomas.png'

import {
  INVOICES_ADD,
  INVOICES_CREATE,
  PENALTY_GENERATE,
} from '../../constants/routes'

function InvoicesGenerate() {
  const history = useHistory()
  const role = useSelector(({ user }) => user.role.toLowerCase())

  if (role === 'admin' || role === 'manager')
    return (
      <SideLayout backButton={false} type={role}>
        <h2 className="title">Generar facturas</h2>
        <h3 className="title-description">
          Genera o agrega las facturas correspondientes.
        </h3>

        <Row>
          <Col span={8}>
            <InvoiceType
              downlable
              title="Sistemas"
              description="Carga el archivo .xls correspondiente a las lecturas de agua del mes. No olvides utilizar la plantilla del archivo con estructura establecida."
              asset={<img src={systems} />}
              buttonTitle="Agregar Facturas"
              onButtonClick={() => history.push(INVOICES_ADD)}
            />
          </Col>
          <Col span={8}>
            <InvoiceType
              title="Fomento (mantenimeinto)"
              description="Genera las facturas de mantenimiento correspondientes al año en curso. Te pediremos actualizar el costo por metro antes de calcularla.  "
              asset={<img src={promotion} />}
              buttonTitle="Generar Facturas"
              onButtonClick={() => history.push(INVOICES_CREATE)}
            />
          </Col>
          <Col span={8}>
            {/* <InvoiceType
            title="Fomento (multas)"
            description="Genera la multa al residente en caso de percances dentro de Lomas de Cocoyoc."
            asset={<img src={promotion_penalty} />}
            buttonTitle="Generar Multa"
            onButtonClick={() => history.push(PENALTY_GENERATE)}
          /> */}
            <InvoiceType
              title="Lomas"
              description="Registra los recibos de luz y teléfono de los clientes que se encuetran al corriente en sus pagos."
              asset={<img src={lomas} />}
              buttonTitle="Agregar Facturas"
              onButtonClick={() =>
                history.push('/cajero/facturas/agregar/servicios')
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}></Col>
        </Row>
      </SideLayout>
    )

  return (
    <SideLayout backButton={false} type={role}>
      <h2 className="title">Generar facturas</h2>
      <h3 className="title-description">
        Genera o agrega las facturas correspondientes.
      </h3>

      <Row>
        <Col span={8}>
          <InvoiceType
            title="Fomento (multas)"
            description="Genera la multa al residente en caso de percances dentro de Lomas de Cocoyoc."
            asset={<img src={promotion_penalty} />}
            buttonTitle="Generar Multa"
            onButtonClick={() => history.push(PENALTY_GENERATE)}
          />
        </Col>
        <Col span={8}>
          <InvoiceType
            title="Lomas"
            description="Registra los recibos de luz y teléfono de los clientes que se encuetran al corriente en sus pagos."
            asset={<img src={lomas} />}
            buttonTitle="Agregar Facturas"
            onButtonClick={() => history.push(INVOICES_ADD)}
          />
        </Col>
      </Row>
    </SideLayout>
  )
}

export default InvoicesGenerate
