import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Modal, Button, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsersAction, deleteUserAction } from '../../../redux/adminDuck'
import { USERS_EDIT } from '../../../constants/routes';
import moment from 'moment'
import styles from './UsersTable.module.css';


function UsersTable() {
  const dispatch = useDispatch()
  const {users, fetching} = useSelector(({ admin }) => admin)
  const [handleModal, setHandleModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});

  const columns = [
    { title: 'Id', dataIndex: 'id', key: 'id', render: val => val.slice(0, 5) },
    { title: 'Usuario', dataIndex: 'displayName', key: 'displayName' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Teléfono', dataIndex: 'tel', key: 'tel' },
    { title: 'Rol', dataIndex: 'role', key: 'role' },
    {
      title: 'Fecha registro', dataIndex: 'createdAt', key: 'createdAt',
      render: val => moment(val).format('ll')
    },
    {
      title: 'Acciones',
      dataIndex: 'acciones',
      key: 'acciones',
      render: (text, user) => (
        <div className={styles.table__actions}>
          <Link to={USERS_EDIT.replace(':userId', user.id)} className="text-coal">
            <FontAwesomeIcon icon={faPencilAlt} />
          </Link>
          <Link onClick={() => setUserToDelete(user)} to="#" className="text-coal">
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllUsersAction())
  }, [])

  const dismissModalDelete = () => setUserToDelete({});

  const handleDelete = () => {
    dispatch(deleteUserAction(userToDelete.id))
      .then((response) => {
        dismissModalDelete();
        notification.success({
          message: 'Usuario eliminado'
        })
      })
      .catch(() => notification.error({ description: 'Ocúrrio un error al eliminar el usuario' }))
  };

  return (
    <>
      <Table columns={columns} dataSource={users} />
      <Modal
        title="Eliminar usuario"
        centered
        visible={!!userToDelete.id}
        onCancel={dismissModalDelete}
        className={styles.modal__print}
        footer={[
          <Button
            key="back"
            onClick={dismissModalDelete}
            className={styles.modal__btn}
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleDelete}
            className={styles.modal__btn}
            loading={fetching}
          >
            Eliminar
          </Button>,
        ]}
      >
        <p className="textColor">
          ¿Estas seguro de eliminar a este usuario? Se eliminará automáticamente
          su cuenta y no podrá acceder al sisitema.
        </p>
      </Modal>
    </>
  );
}

export default UsersTable;
