import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Row, Col, Divider, Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { SideLayout, InvoiceDetailTickets } from '../Common/index'
import { formatMoney } from '../../helpers/formatMoney'
import { useDispatch, useSelector } from 'react-redux'
import { getFomentInvoicesAction } from '../../redux/invoicesDuck'
import { Link } from 'react-router-dom'

const line = require('../../assets/svgs/Line_21.svg')

function InvoicesDetail({
  match: {
    params: { invoice: invoiceId },
  },
}) {
  const dispatch = useDispatch()
  const { fomentInvoices, fetching } = useSelector(({ invoices }) => invoices)
  const [invoice = {}] = fomentInvoices
  const receipt = {} //?????
  const [total, setTotal] = useState(0)
  const {
    pathname,
    // state
  } = useLocation()

  useEffect(() => {
    dispatch(getFomentInvoicesAction({ q: `_id=${invoiceId}` }))
  }, [])

  useEffect(() => {
    // format totals
    if (!Object.keys(invoice).length) return
    if (invoice.paid) {
      setTotal(invoice.receipts.reduce((acc, inv) => acc + inv.totalPaid, 0))
    } else {
      setTotal(invoice.before1500 + invoice.after1500 + invoice.clusterAmount)
    }
  }, [invoice])

  const routeType = pathname.split('/')[1]

  console.log('el invoice: ', invoice)

  return (
    <SideLayout type={routeType}>
      <span>Detalle</span>
      <h2 className="title">

        {receipt
          ? `Factura ${invoice.folio || ''}`
          : `Factura ${invoice.folio}`}
      </h2>
      <Row gutter={[24, 0]} style={{ position: 'relative' }}>
        <Col span={12}>
          <Card loading={fetching} style={{ maxWidth: 600 }} title={'Monto'}>
            <h1 style={{ fontWeight: 'bold' }}>{formatMoney(total, true)}</h1>
          </Card>
        </Col>
        <img
          style={{
            position: 'absolute',
            top: 50,
            right: 'calc(50% + 80px)',
            height: 600,
          }}
          src={line}
        />
        <Col span={12}>
          <Card loading={fetching}>
            <h3 className="subtitle-strong-18">
              Recibos <FontAwesomeIcon icon={faReceipt} />{' '}
              {/* <Tag style={{ float: "right" }} color={invoice.paid ? "#95BF48" : "orange"}>{invoice.paid ? "Pagada" : "Vencida"}</Tag> */}
            </h3>
            <Divider />
            {invoice.receipts?.map((rec) => (
              <><Link style={{ color: 'var(--blueAnt)' }} to={`/admin/recibos/${rec.id}`}>{rec.folio}</Link><br /></>
            ))}
          </Card>
        </Col>
      </Row>
      {/* {receipt && (
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <InvoicesReciept receipt={receipt} type={type} columns={getColumns()} />
            <InvoicesRecieptActions />
          </Col>
        </Row>
      )} */}
      <br />
      {invoice && <InvoiceDetailTickets fetching={fetching} {...invoice} />}
    </SideLayout>
  )
}

export default InvoicesDetail
