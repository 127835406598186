import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col, Form, Input, Button, Select } from 'antd'

import { Card, Divider } from '../index'
import { findLotAction } from '../../../redux/fomento.duck'
import { setPdfData } from '../../../redux/reports.duck'
import { Rules } from '../../../helpers'

import cardStyles from '../Card/Card.module.css'
import sectionNames from './sectionNames'
import { Link } from 'react-router-dom'

function CashierForm({ type, activeTag, onTagChange }) {
  const lastInput = React.useRef()
  const firstInput = React.useRef()
  const promotionCodes = [
    { code: 'MANT21', tag: 'Servicios Públicos Urbanos' },
    { code: 'TAG001', tag: 'Tag Residencial' },
    { code: 'MULT002', tag: 'Multas Residenciales' },
    { code: 'EXT003', tag: 'Productos extra' },
  ]
  const systemsCodes = [
    { code: 'AG123', tag: 'Agua' },
    { code: 'OTRO', tag: 'Otro' },
  ]

  const [form] = Form.useForm()
  const { Option } = Select
  const [serviceOptions] = useState(
    (type === 'systems' && systemsCodes) ||
    (type === 'promotion' && promotionCodes),
  )
  const dispatch = useDispatch()
  const { handleClickPDF } = useSelector(({ reports }) => reports)
  const { lot } = useSelector(({ fomento }) => fomento)
  const [formState, setFormState] = useState({})

  const collectData = () => {
    dispatch(
      setPdfData({
        seccion: formState.seccion,
        lomas: formState.lomas,
        manzana: formState.manzana,
        lote: formState.lote,
        residentName: formState.residentName,
        serviceType: formState.serviceType,
        medidor: formState.medidor,
      }),
    )
  }

  useEffect(() => {
    collectData()
  }, [handleClickPDF])

  const renderSelectOptions = (option) => {
    return (
      <Option key={option.code} value={option.code}>
        {option.code}
      </Option>
    )
  }

  const handleSelectCode = async (value) => {
    // activeTag && activeTag(value === 'TAG001')
    onTagChange(value)
    let { tag } = serviceOptions.find((option) => option.code === value)
    await form.setFieldsValue({ tag })
    setFormState({
      ...formState,
      serviceType: tag,
    })
  }

  // const handleChange = ({ target: { name, value } }) => {
  //   setFormState({ ...formState, [name]: value });
  // };

  const handleKeyPress = (e) => {
    const { key } = e
    if (key === 'Enter' && lastInput.current.state.focused) {
      dispatch(findLotAction(formState)).then((values) => {
        if (!values) return
        setFormState({
          ...values,
        })
        form.setFieldsValue({ ...values })
        form.setFieldsValue({ name: values.residentName })
        form.setFieldsValue({ number: values.residents[0]?.number })
        firstInput.current.focus()
        firstInput.current.select()
      })
      form.setFieldsValue({ id: null, name: null })
    } else if (key === 'Enter') {
      const form = e.target.form
      let index = Array.prototype.indexOf.call(form, e.target)
      let next = index + 1
      // if (!form.elements[next]) {
      //   findLot()
      //   next = 0
      // }
      // if(form.elements[next])
      if (index === 0) next++
      form.elements[next].focus()
      form.elements[next].select()
      e.preventDefault()
    }
  }

  const handleOnChangeInput = (value) => {
    setFormState({
      ...formState,
      ...value,
    })
    if (value.seccion) {
      form.setFieldsValue({ sectionName: sectionNames[value.seccion] })
    }

  }

  return (
    <Card onKeyPress={handleKeyPress}>
      <h4 className={cardStyles.card__title}>Información del lote</h4>
      <Form
        // onKeyDown={(value) => console.log(value)}
        form={form}
        layout="vertical"
        initialValues={{
          seccion: '',
          lote: '',
          lomas: '',
          manzana: '',
          id: '',
          name: '',
          medidor: '',
          codes: serviceOptions[0]?.code,
          tag: serviceOptions[0]?.tag,
        }}
        requiredMark={false}
      >
        <Row gutter={[15, 0]}>
          <Col span={4}>
            <Form.Item name="seccion" label="Sección:" rules={[Rules.basic]}>
              <Input
                ref={firstInput}
                type="text"
                min={0}
                rules={[Rules.basic]}
                placeholder="000"
                onChange={({ target }) =>
                  handleOnChangeInput({ seccion: target.value?.toUpperCase() })
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="sectionName" label={' '} rules={[Rules.basic]}>
              <Input
                type="text"
                disabled
                placeholder="Nombre de la sección"
                onChange={({ target }) =>
                  handleOnChangeInput({ lomas: target.value?.toUpperCase() })
                }
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="manzana" label="Manzana:" rules={[Rules.basic]}>
              <Input
                type="text"
                min={0}
                rules={[Rules.basic]}
                placeholder="00"
                onChange={({ target }) =>
                  handleOnChangeInput({ manzana: target.value?.toUpperCase() })
                }
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="lote" label="Lote:" rules={[Rules.basic]}>
              <Input
                ref={lastInput}
                type="text"
                min={0}
                rules={[Rules.basic]}
                placeholder="00"
                onChange={({ target }) =>
                  handleOnChangeInput({ lote: target.value?.toUpperCase() })
                }
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label={' '}>
              {lot.id ? (
                <Link
                  to={`/admin/lotes/detalle/${lot.id}`}
                  className={cardStyles.card__btn__link}
                >
                  Ver detalle
                </Link>
              ) : null}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 0]}>
          <Col span={4}>
            <Form.Item name="number" label="Id:" rules={[Rules.basic]}>
              <Input
                disabled
                type="number"
                min={0}
                rules={[Rules.basic]}
                placeholder="000"
              // onChange={({ target }) =>
              //   handleOnChangeInput({ id: target.value })
              // }
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              name="name"
              label="Nombre del residente:"
              rules={[Rules.basic]}
            >
              <Input
                disabled
                onChange={({ target }) =>
                  handleOnChangeInput({ residentName: target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <h4 className={cardStyles.card__title}>Información del servicio</h4>
        <Row gutter={[15, 0]}>
          <Col span={7}>
            <Form.Item label="Código:" name="codes" rules={[Rules.basic]}>
              <Select onChange={handleSelectCode}>
                {serviceOptions.map(renderSelectOptions)}
              </Select>
            </Form.Item>
          </Col>
          {type === 'systems' && (
            <>
              <Col span={7}>
                <Form.Item label={' '} name="tag" rules={[Rules.basic]}>
                  <Input
                    disabled
                    onChange={({ target }) =>
                      handleOnChangeInput({ serviceType: target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Medidor" name="medidor" rules={[Rules.basic]}>
                  <Input
                    placeholder="00000000"
                    onChange={({ target }) =>
                      handleOnChangeInput({ medidor: target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </>
          )}
          {type === 'promotion' && (
            <Col span={13}>
              <Form.Item label={' '} name="tag" rules={[Rules.basic]}>
                <Input
                  disabled
                  onChange={({ target }) =>
                    handleOnChangeInput({ serviceType: target.value })
                  }
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  )
}

export default CashierForm

CashierForm.propTypes = {
  type: PropTypes.oneOf(['promotion', 'services', 'systems']),
}
