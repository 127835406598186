import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Tag, Pagination, Button } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'

import {
  Seeker,
  Card,
  InvoicesRegisterForm,
  TableCustomFooter,
} from '../../Common'
import {
  INVOICES_DETAIL_HASH,
  INVOICES_DETAIL_TAG,
  ADMIN_INVOICE_DETAIL,
  ADMIM_INVOICE_RECEIPT,
} from '../../../constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import {
  findWaterInvoiceWithNumberAction,
  getWaterInvoicesPaginatedAction,
  removeMultipleWaterInvoicesAction,
} from '../../../redux/adminDuck'
import { formatMoney } from '../../../helpers/formatMoney'

const data = [
  {
    key: '1',
    factura: '1234567',
    residente: 'Jose Luis Enriquez',
    lote: 'Sec. 1 Lt. 4 Mz. 6',
    codigo: 'MANT21',
    anio: '2020',
    bim: 'B1',
    fecha: '12/dic/1990',
    recibo: 'AGU-123',
    importe: '$0.00',
    estatus: 'Pagado',
  },
  {
    key: '2',
    factura: '1234567',
    residente: 'Jose Luis Enriquez',
    lote: 'Sec. 1 Lt. 4 Mz. 6',
    codigo: 'MANT21',
    anio: '2020',
    bim: 'B1',
    fecha: '12/dic/1990',
    recibo: 'AGU-123',
    importe: '$0.00',
    estatus: 'Pagado',
  },
]

function SystemsInvoices() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const type = pathname.split('/')[1]
  const [handleLink, setHandleLink] = useState(false)
  const { waterInvoices = [], limit, page, total, fetching } = useSelector(
    ({ admin }) => admin,
  )
  const [show, setShow] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  React.useEffect(() => {
    dispatch(getWaterInvoicesPaginatedAction())
  }, [])

  React.useEffect(() => {
    if (!filtered) {
      dispatch(getWaterInvoicesPaginatedAction())
      return
    }
    const paginated = {
      q: 'measurer:null',
    }
    dispatch(getWaterInvoicesPaginatedAction(paginated))
  }, [filtered])

  const invoice = type === 'admin' ? ADMIN_INVOICE_DETAIL : INVOICES_DETAIL_HASH
  const receipt = type === 'admin' ? ADMIM_INVOICE_RECEIPT : INVOICES_DETAIL_TAG

  const [visibility, setVisibility] = useState(false)
  const columns = [
    {
      title: 'Folio',
      dataIndex: 'id',
      key: 'id',
      render: val => val.slice(val.length - 5,)
    },

    {
      title: 'Lote',
      dataIndex: 'measurer',
      key: 'measurer',
      render: (measurer) =>
        measurer
          ? `Seccion ${measurer.lot?.seccion}, Manzana ${measurer.lot?.manzana}, Lote ${measurer.lot?.lote}`
          : null,
    },
    {
      title: 'Residente',
      dataIndex: 'measurer',
      key: 'measurer',
      render: (measurer) =>
        measurer
          ? `${measurer.lot?.residents[0].firstName}, ${measurer.lot?.residents[0].lastName}`
          : null,
    },
    {
      title: 'Número de medidor',
      dataIndex: 'measurerNumber',
      key: 'measurerNumber',
    },
    {
      title: 'Año',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: '#Recibo',
      dataIndex: 'receipts',
      key: 'receipts',
      render: (array) =>
        array?.length ? array.map(rec =>
        (
          <Link
            onMouseEnter={() => setHandleLink(true)}
            onMouseLeave={() => setHandleLink(false)}
            to={{
              pathname: receipt
                .replace(':receipt', rec._id)
                .replace(':type', 'promotion'),
              state: { receipt: rec },
            }}
            className="link-blue"
          >
            {rec.folio}
          </Link>
        )
        ) : <Tag>Sin recibos</Tag>,
    },
    {
      title: 'Importe',
      dataIndex: 'amount',
      key: 'amount',
      render: (val) => formatMoney(val),
    },
    {
      title: 'Recargo',
      dataIndex: 'surcharge',
      key: 'surcharge',
      render: (val) => formatMoney(val),
    },
    {
      title: 'Estado',
      dataIndex: 'measurerNumber',
      key: 'measurerNumber',
      render: (val, record) =>
        !val ? (
          <Tag color="red">El medidor no existe</Tag>
        ) : record.paid ? (
          <Tag color="#95bf48">Pagada</Tag>
        ) : (
          <Tag color="#f0b046">Pendiente</Tag>
        ),
    },
  ]

  const handleNew = () => setVisibility(!visibility)
  const onCancelModal = () => setVisibility(false)

  const changePage = (nextPage) => {
    const paginated = {
      p: nextPage,
      l: limit,
    }
    dispatch(getWaterInvoicesPaginatedAction(paginated))
  }

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys: ', selectedRowKeys)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.paid,
    }),
  }

  const removeSelectedInvoices = () => {
    if (
      !window.confirm(
        `¿Estas seguro de eliminar ${selectedRowKeys.length} facturas?`,
      )
    ) {
      return
    }

    dispatch(removeMultipleWaterInvoicesAction(selectedRowKeys))
    setFiltered(false)
    setSelectedRowKeys([])
  }

  const handleSearch = ({ target: { value } }) => {
    if (value.length < 1 || value === '')
      return dispatch(getWaterInvoicesPaginatedAction())
    else if (value.length < 6) return
    dispatch(findWaterInvoiceWithNumberAction(value))
  }

  return (
    <>
      <div style={{ display: 'flex', gap: 10 }}>
        <Seeker
          onSearch={handleSearch}
          noUpload
          noFilter
          onUploadButtonClick={() => setShow((show) => !show)}
        // btnAdd={{ action: handleNew }}
        />
        <Button
          loading={fetching}
          onClick={() => setFiltered((f) => !f)}
          type={filtered ? 'primary' : 'secondary'}
        >
          Facturas con error
        </Button>
        {!!selectedRowKeys.length && (
          <Button
            loading={fetching}
            onClick={removeSelectedInvoices}
            type={'danger'}
          >
            Eliminar {selectedRowKeys.length} factura
            {selectedRowKeys.length > 1 ? 's' : null}{' '}
          </Button>
        )}
      </div>
      <Card>
        <Table
          loading={fetching}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={waterInvoices.map((inv) => ({ ...inv, key: inv.id }))} // to show actual invoices
          onRow={(record) => ({
            onClick:
              !handleLink &&
              (() =>
                history.push(`/admin/facturas/detalle/${record._id}/agua`)),
          })}
          pagination={false}
        />

        {/* <TableCustomFooter
          total={data.length}
          totalAmount="$1,140,000.00"
          type="systems"
        /> */}
        {!filtered && (
          <Pagination
            showSizeChanger={false}
            pageSize={limit || 50}
            current={page}
            onChange={changePage}
            total={total}
            style={{ marginTop: 32 }}
          />
        )}
      </Card>
      <InvoicesRegisterForm visible={visibility} onCancel={onCancelModal} />
    </>
  )
}

export default SystemsInvoices
