import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Form, Select } from 'antd';

import { Rules } from '../../../helpers';
import { INVOICES_REGISTER_TAG } from '../../../constants/routes';

function InvoicesRegisterForm({ visible, onCancel }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const { Option } = Select;
  const [invoiceType, setInvoiceType] = useState({
    type: '',
    charge: '',
    action: '',
  });

  const onCreate = () => {
    let params = `${invoiceType.type}${invoiceType.charge}${invoiceType.action}`;
    history.push(`${INVOICES_REGISTER_TAG}${params}`);
  };

  const handleInvoiceType = () => {
    let type = form.getFieldValue('type');
		if (type === 'luz') {
			setInvoiceType({ ...invoiceType, action: '', type });
		} else {
			setInvoiceType({ ...invoiceType, type });
		}
  };

  const handleInvoiceCharge = () => {
    let charge = form.getFieldValue('charge');
    setInvoiceType({ ...invoiceType, charge: `-${charge}` });
  };

  const handleInvoiceAction = () => {
    let action = form.getFieldValue('actions');
    setInvoiceType({ ...invoiceType, action: `-${action}` });
  };

  return (
    <Modal
      visible={visible}
      title="Agregar facturas"
      okText="Continuar"
      cancelText="Cancelar"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        requiredMark={false}
      >
        <Form.Item name="type" label="Tipos de factura:" rules={[Rules.basic]}>
          <Select
            placeholder="Selecciona el tipo de factura"
            onChange={handleInvoiceType}
          >
            <Option value="agua">Agua</Option>
            <Option value="luz">Luz</Option>
            <Option value="telefono">Teléfono</Option>
          </Select>
        </Form.Item>
        <Form.Item name="charge" label="Forma de carga:" rules={[Rules.basic]}>
          <Select
            placeholder="Selecciona la forma de carga"
            onChange={handleInvoiceCharge}
          >
            <Option value="manual">Manual</Option>
            <Option value="auto">Automatizada</Option>
          </Select>
        </Form.Item>
				{invoiceType.type !== 'luz' && (<>
        <Form.Item
          name="actions"
          label="Acciones:"
          rules={invoiceType.type !== 'luz' && [Rules.basic]}
        >
          <Select
            placeholder="Selecciona la acción a realizar"
            disabled={invoiceType.type === 'luz'}
						onChange={handleInvoiceAction}
          >
						<Option value="add">Agregar facturas nuevas</Option>
        		<Option value="update">Actualizar existentes</Option>
          </Select>
        </Form.Item>
				</>)}
      </Form>
    </Modal>
  );
}

export default InvoicesRegisterForm;
