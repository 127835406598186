import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Seeker, Card, TableCustomFooter } from '../../Common'
import {
  INVOICES_DETAIL_HASH,
  INVOICES_DETAIL_TAG,
  ADMIN_INVOICE_DETAIL,
  ADMIM_INVOICE_RECEIPT,
} from '../../../constants/routes'
import { formatMoney } from '../../../helpers/formatMoney'
import { clearInvoiceAction, findInvoiceByLotAction, getFomentInvoicesAction } from '../../../redux/invoicesDuck'

function PromotionInvoices() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { fetching, found, fomentInvoices, totalDebt, limit, page, total } = useSelector(({ invoices }) => invoices)
  const { pathname } = useLocation()
  const type = pathname.split('/')[1]
  const [handleLink, setHandleLink] = useState(false)

  const invoive = type === 'admin' ? ADMIN_INVOICE_DETAIL : INVOICES_DETAIL_HASH
  const receipt = type === 'admin' ? ADMIM_INVOICE_RECEIPT : INVOICES_DETAIL_TAG

  const changePage = (nextPage) => {
    const paginated = {
      p: nextPage,
      l: limit,
    }
    dispatch(getFomentInvoicesAction(paginated))
  }

  const pagination = { limit, page, changePage, totalPages: total }


  const columns = [
    {
      title: 'Factura',
      dataIndex: 'folio',
      key: 'folio',
    },
    {
      title: 'Residente',
      dataIndex: 'lot',
      key: 'lot',
      render: (lot) =>
        lot
          ? `${lot.residents[0]?.firstName} ${lot.residents[0]?.lastName} ${lot.residents[0]?.surName}`
          : null,
    },
    {
      title: 'Lote',
      dataIndex: 'lot',
      key: 'lot',
      render: (lot) =>
        lot ? `Sec. ${lot.seccion} Mz. ${lot.manzana} Lt. ${lot.lote} ` : null,
    },
    {
      title: 'Código',
      dataIndex: 'serviceCode', // add this value, is missing rightnow
      key: 'serviceCode',
    },
    {
      title: 'Año',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Fecha pago',
      dataIndex: 'updatedAt',
      key: 'updatedAt', // depends on receips
    },
    {
      title: '#Recibo',
      dataIndex: 'receipts',
      key: 'receipts',
      render: (array) =>
        array?.length ? array.map(rec =>
        (
          <Link
            onMouseEnter={() => setHandleLink(true)}
            onMouseLeave={() => setHandleLink(false)}
            to={{
              pathname: receipt
                .replace(':receipt', rec._id)
                .replace(':type', 'promotion'),
              state: { receipt: rec },
            }}
            className="link-blue"
          >
            {rec.folio}
          </Link>
        )
        ) : <Tag>Sin recibos</Tag>,
    },
    {
      title: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      align: 'right',
      render: (_, { before1500, after1500, clusterAmount }) =>
        formatMoney(
          Number(before1500) + Number(after1500) + Number(clusterAmount),
        ),
    },
    {
      title: 'Estatus', // this needs more logic from other fields change it.
      dataIndex: 'paid',
      key: 'paid',
      render: (value) =>
        value ? (
          <Tag color="#95bf48">Pagado</Tag>
        ) : (
          <Tag color="#F0B046">Pendiente</Tag>
        ),
    },
  ]

  const handleSearch = ({ target: { value } }) => {
    const arr = value.split(' ')
    if (arr.length < 3 || arr[2] === '') { return dispatch(clearInvoiceAction()) }
    const query = {
      seccion: arr[0],
      manzana: arr[1],
      lote: arr[2]
    }
    dispatch(findInvoiceByLotAction(query))
  }

  return (
    <>
      <Seeker
        onSearch={handleSearch}
        noUpload
        noFilter
      //onUploadButtonClick={() => setShow((show) => !show)}
      // btnAdd={{ action: handleNew }}
      />
      <Card>
        <Table
          loading={fetching}
          columns={columns}
          dataSource={found ? found.items : fomentInvoices}
          onRow={(record) => ({
            onClick:
              !handleLink &&
              (() =>
                history.push(invoive.replace(':invoice', record.id), {
                  invoice: record,
                })),
          })}
          pagination={false}
        />
        {/* //  CHECK PAGINATION IS WORKING!!!! WITH PAGE CHANGE */}
        <TableCustomFooter // check this and update it
          pagination={found ? {} : pagination}
          total={0}
          totalAmount={found ? formatMoney(found.totalDebt, true) : formatMoney(totalDebt, true)} // needs to generate total debt from all invoices
          type="promotion"
        />
      </Card>
    </>
  )
}

export default PromotionInvoices
