import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Table } from 'antd'

import {
  CASHIER_SYSTEMS,
  CASHIER_PROMOTION,
  ADMIN_SISTEMS,
  ADMIN_PROMOTION,
} from '../../constants/routes'
import { Divider, SideLayout, Card } from '../Common'
import Total from '../Common/Total'
import { useSelector } from 'react-redux'
import cashierStyles from './Cashier.module.css'
import { formatMoney } from '../../helpers/formatMoney'

const CASHIER_LOMAS = '/caja/lomas'

const columns = [
  {
    title: 'Factura',
    dataIndex: 'invoices',
    key: 'invoices',
    render: (invoices) =>
      invoices.map((inv) => (
        <Link
          to={`/admin/facturas/detalle/${inv.id}`}
          style={{ color: '#2D9CDB' }}
        >
          {inv.folio}
        </Link>
      )),
  },
  {
    title: 'Residente',
    dataIndex: 'lot',
    key: 'lot',
    render: (lot) =>
      lot?.residents?.length
        ? lot?.residents[0]?.firstName + ' ' + lot?.residents[0]?.lastName
        : '',
  },
  {
    title: 'Lote',
    dataIndex: 'lot',
    key: 'lot',
    render: (lot) =>
      lot ? `Sec ${lot.seccion}, Mz ${lot.manzana}, Lt ${lot.lote}` : '',
  },
  {
    title: 'Forma de pago',
    dataIndex: 'paymentForm',
    key: 'paymentForm',
    render: (paymentForm) =>
      paymentForm.reduce((acc, pf) => acc + `${pf.tipo}, `, ''),
  },
  {
    title: '#Recibo',
    dataIndex: 'folio',
    key: 'folio',
    render: (val, rec) => (
      <Link to={`/admin/recibos/${rec.id}`} style={{ color: '#2D9CDB' }}>
        {val}
      </Link>
    ),
  },
  {
    title: 'Importe',
    dataIndex: 'totalPaid',
    key: 'totalPaid',
    render: (val) => <b>{formatMoney(val)}</b>,
  },
]

function Cashier() {
  const history = useHistory()
  const location = useLocation()
  const type = location.pathname.split('/')[1]
  const { todayReceipts } = useSelector(({ user: { stats } }) => stats)

  return (
    <SideLayout type={type} backButton={false}>
      <h2 className="title">Caja</h2>
      <h3 className="title-description">
        Manten el control de tus cobros del día.
      </h3>
      <Total />
      <Divider />
      <div className={cashierStyles.cashier__grid}>
        <div className={cashierStyles.cashier__grid__actions}>
          <Card>
            <div className="subtitle-strong">Sistemas</div>
            <div>Cobros de agua.</div>
            <div className={cashierStyles.buttons__container}>
              <Button
                type="primary"
                onClick={() =>
                  history.push(
                    type === 'admin' ? ADMIN_SISTEMS : CASHIER_SYSTEMS,
                  )
                }
              >
                Cobrar
              </Button>
              <Link className={cashierStyles.link} to="/admin/facturas">
                Historial
              </Link>
            </div>
          </Card>
          <Card>
            <div className="subtitle-strong">Fomento</div>
            <div>Cobros de mantenimiento y servicios internos.</div>
            <div className={cashierStyles.buttons__container}>
              <Button
                type="primary"
                onClick={() =>
                  history.push(
                    type === 'admin' ? ADMIN_PROMOTION : CASHIER_PROMOTION,
                  )
                }
              >
                Cobrar
              </Button>
              <Link className={cashierStyles.link} to="/admin/facturas">
                Historial
              </Link>
            </div>
          </Card>
          <Card>
            <div className="subtitle-strong">Lomas</div>
            <div>Recepción de despósitos para pago de servicios.</div>
            <div className={cashierStyles.buttons__container}>
              <Button
                type="primary"
                onClick={() => history.push(CASHIER_LOMAS)}
              >
                Cobrar
              </Button>
              <Link className={cashierStyles.link} to="/admin/facturas">
                Historial
              </Link>
            </div>
          </Card>
        </div>
        <div className={cashierStyles.cashier__grid__table}>
          <Table
            pagination={false}
            columns={columns}
            dataSource={todayReceipts}
          />
        </div>
      </div>
    </SideLayout>
  )
}

export default Cashier
