import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, message } from 'antd';

import { Card } from '../index';
import Rules from '../../../helpers/rules';

import styles from './ResidentUpdate.module.css';
import cardStyles from '../Card/Card.module.css';

function ResidentUpdate({ setIsEditing }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);

  const handleEditing = () => {
    setIsEditing(false);
  };

  const handleSubmit = () => {
    setloading(true);
    setTimeout(() => {
      message.success('Residente actualizado.');
      setloading(false);
      setIsEditing(false);
    }, 2000);
  };

  return (
    <Row>
      <Col lg={12} md={24} sm={24} xs={24}>
        <Card>
          <h4 className={cardStyles.card__title}>Información del residente</h4>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleSubmit}
          >
            <Row gutter={[15, 0]}>
              <Col span={8}>
                <Form.Item label="Nombre" name="name" rules={[Rules.basic]}>
                  <Input placeholder="Nombre" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Apellido Paterno"
                  name="pname"
                  rules={[Rules.basic]}
                >
                  <Input placeholder="Apellido Paterno" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Apellido Materno"
                  name="mname"
                  rules={[Rules.basic]}
                >
                  <Input placeholder="Apellido Materno" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Email" name="email" rules={[Rules.basic]}>
              <Input type="email" placeholder="Email" />
            </Form.Item>
            <Form.Item label="Teléfono" name="phone" rules={[Rules.basic]}>
              <Input placeholder="Teléfono" />
            </Form.Item>
            <Row
              justify="center"
              gutter={[50, 0]}
              className={styles.footer__form}
            >
              <Col>
                <Button
                  className={`${styles.btn__footer} ${styles.text__default}`}
                  type="default"
                  onClick={handleEditing}
                  htmlType="button"
                >
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  className={styles.btn__footer}
                  type="primary" 

                  htmlType="submit"
                  loading={loading}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default ResidentUpdate;
