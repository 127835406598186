import React, { useEffect } from 'react';
import { Row, Modal, Button } from 'antd';
import { useState, useRef } from 'react';
import {
  // useDispatch,
  useSelector
} from 'react-redux';

import componentToPdf from '../../../tools/componentToPdf';
import FomentReceipt from '../../FomentReceipt';
// import { collectDataReport } from '../../../redux/reports.duck';
import printFilesImage from '../../../assets/svgs/PrintFiles.svg';
import styles from './CashierPrint.module.css';

function CashierPrint({ onFinish }) {
  // const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const componentRef = useRef();
  const { print: PDF } = useSelector(({ fomento }) => fomento);

  const handleModalVisivility = (visivility) => {
    // dispatch(collectDataReport());
    setModalVisible(visivility);
    setTimeout(() => {
      createPDF(componentRef.current)
    }, 1500);
    setTimeout(() => {
      setModalVisible(false);
      onFinish()
    }, 5000);
  };

  const createPDF = (html) => {
    componentToPdf.createPdf(html, `FACTURA_${PDF.folio}`);
    // onFinish()
  };

  useEffect(() => {
    handleModalVisivility(true)
  }, [])

  return (
    <>
      <Row justify="center">
        <Button
          style={{ display: "none" }}
          onClick={() => handleModalVisivility(true)}
          type="primary"
          className={styles.btn__print}
        >
          Imprimir
        </Button>
      </Row>
      <Modal
        mask={false}
        title="Descargando factura"
        centered
        visible={modalVisible}
        onOk={() => handleModalVisivility(false)}
        onCancel={() => handleModalVisivility(false)}
        className={styles.modal__print}
        footer={[
          <Button key="back" onClick={() => handleModalVisivility(false)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading
          />,
        ]}
      >
        <div style={{ position: 'fixed', zIndex: -10000, bottom: '-300vh' }}>
          <FomentReceipt currentRef={componentRef} />
        </div>
        <p className="textColor">
          Espera un momento, se está generando la factura.
        </p>
        <Row justify="center">
          <img src={printFilesImage} alt="print files" />
        </Row>
      </Modal>
    </>
  );
}

export default CashierPrint;
