import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setIsAdminAction } from '../../../redux/user.duck';
import { Divider } from '../index';
import Notification from '../Notifications';
import BackButton from '../BackButton';

import styles from './StatusBar.module.css';

const StatusBar = ({ backButton }) => {
  const dispatch = useDispatch();
  const { displayName, isAdmin, photoURL } = useSelector(({ user }) => user);
  const { pathname } = useLocation();
  const role = pathname.split('/')[1] === 'admin';
  const [showDrawerNotifications, setShowDrawerNotifications] = useState(false);

  useEffect(() => {
    dispatch(setIsAdminAction(role));
  }, [isAdmin, role, dispatch]);

  return (
    <div className={styles.statusbar}>
      {backButton ? <BackButton /> : <div />}
      <div className={styles.statusbar__right}>
        <Notification
          visible={showDrawerNotifications}
          onClose={() => setShowDrawerNotifications(false)}
        />
        <FontAwesomeIcon
          icon={faBell}
          className={styles['statusbar__center-content']}
          style={{ cursor: 'pointer' }}
          onClick={() => setShowDrawerNotifications(true)}
        />
        <Divider type="vertical" />
        <div
          className={`${styles['statusbar__center-content']} ${styles.statusbar__username}`}
        >
          {displayName}
        </div>
        <Avatar
          src={photoURL ? photoURL : null}
          icon={
            <FontAwesomeIcon
              icon={faUser}
              className={styles['statusbar__center-content']}
            />
          }
        />
      </div>
    </div>
  );
};

export default StatusBar;
