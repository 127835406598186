import React, { useState } from 'react'
import {
    Select,
    Card,
    Input,
    Divider,
    Table,
    Button,
    InputNumber,
    Spin,
} from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { formatMoney } from '../../helpers/formatMoney'
import { useHistory } from 'react-router-dom'
import {
    clearFomentInfoAction,
    removeDebtFromReceiptAction,
} from '../../redux/fomento.duck'
import PrintingModal from '../Systems/PrintingModal'
import { doOneMontlhyPaymentAction } from '../../redux/agreementsDuck'
// import PrintingModal from './PrintingModal'


const GenerateCovenantReceipt = ({ onCancel, lot = {}, agreement, fetching, }) => {
    console.log(agreement)
    // constants
    const { Option } = Select
    const paymentMethod = [
        { code: 'EFEC', tag: 'Efectivo' },
        { code: 'TRANS', tag: 'Transferencia bancaria' },
        { code: 'CHEQ', tag: 'Cheque' },
        { code: 'TDDC', tag: 'Tarjeta de crédito' },
    ]
    const initialForm = {
        amount: 0,
        paymentMethod: 'EFEC',
        secondPaymentMethod: 'TDDC',
        reference: '',
        notes: '',
        model: '',
        vehicleYear: '',
        brand: '',
        plates: '',
    }
    //
    const dispatch = useDispatch()
    const history = useHistory()
    // const { lot, fetching } = useSelector(({ fomento }) => fomento)
    const [form, setForm] = useState(initialForm)
    const [errors, setErrors] = useState({})

    const [printing, setPrinting] = useState(false)
    const { displayName, id: userId } = useSelector(({ user }) => user)

    React.useEffect(() => {
        if (agreement) {
            setForm({
                ...form,
                amount: agreement.monthlyPaymentAmount
            })
        }

    }, [agreement])

    // methods
    const onChange = ({ target: { name, value } }) => {
        setForm({ ...form, [name]: value })
    }

    const removeInvoiceOfAgreement = (record) => {
        dispatch(removeDebtFromReceiptAction(record.id))
    }

    // Validation
    const isValidForm = () => {
        if (!lot.id) return
        const err = {}
        if (!form.amount || form.amount < 1) err.amount = 'Introduce una cantidad'
        if (!form.amount || typeof form.amount !== 'number' || form.amount < 1) {
            err.amount = 'Ingresa una cantidad'
        }
        if (!form.paymentMethod) err.paymentMethod = 'Selecciona una forma de pago'
        if (!displayName) {
            alert('Inicia sesión nuevamente porfavor')
            history.push('/signin')
        }


        setErrors(err)
        return !!!Object.keys(err).length
    }

    // Backend commute
    const saveReceipt = () => {
        if (!isValidForm()) return
        const pForms = [
            {
                tipo: form.paymentMethod,
                amount: form.amount,
            },
        ]
        const body = {
            tipo: 'agreement',
            agreement: agreement._id,
            subsidiary: agreement.subsidiary,
            quantityType: 'total',
            lot: lot.id,
            totalPaid: Math.ceil(form.amount),
            paymentForm: pForms,
            reference: form.reference,
            notes: form.notes,
        }
        if (agreement.subsidiary === 'Fomento') {
            body.invoices = agreement.invoices.map(it => it._id)
        } else if (agreement.subsidiary === 'Sistemas') {
            body.waterInvoices = agreement.waterInvoices.map(it => it._id)
        }
        console.log("se manda: ", body)
        // return
        setForm(initialForm)
        dispatch(doOneMontlhyPaymentAction(body)).then((r) => {
            onCancel()
            if (r) {
                dispatch({ type: "SET_FOR_PRINTING", payload: r })
                setPrinting(true)
            }
        })
    }

    const dataSource = React.useMemo(() => {
        return {
            name: 'Pago de convenio',
            desc: 'Mensualidad / Convenio',
            total: form.amount,
        }
    }, [form])

    if (fetching) return <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin /></Card>
    return (
        <div>
            <Card
                title="Resumen de compra"
                style={{
                    width: 586,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <section style={{ gap: 15, display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <span style={{ color: 'grey', marginRight: 20 }}>Fecha:</span>
                        <span style={{ color: 'black' }}>
                            {moment(Date.now()).format('D/MMM/YYYY')}
                        </span>
                    </div>

                    <article style={{ display: 'flex', gap: 30 }}>
                        <div style={{ color: 'grey', margin: 0, display: 'flex' }}>
                            <div>
                                <p>Forma de pago</p>
                                <Select
                                    onChange={(value) =>
                                        onChange({ target: { name: 'paymentMethod', value } })
                                    }
                                    value={form.paymentMethod}
                                    style={{
                                        width: 125,
                                        marginRight: 24,
                                    }}
                                >
                                    {paymentMethod.map((ser) => (
                                        <Option key={ser.code}>{ser.code}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <p>Cantidad</p>
                                <InputNumber
                                    disabled
                                    formatter={(value) =>
                                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    }
                                    onChange={(value) =>
                                        onChange({ target: { value, name: 'amount' } })
                                    }
                                    placeholder="$00.00"
                                    value={Math.floor(form.amount)}
                                    style={{
                                        width: 250,
                                        borderColor: errors.tag ? 'red' : '#d9d9d9',
                                    }}
                                />{' '}
                MXN
                <span style={styles.error}>{errors.paymentMethod}</span>
                            </div>
                        </div>
                    </article>

                    <span style={styles.error}>{errors.amount}</span>

                    <span style={styles.error}>{errors.tag}</span>
                    <Divider />
                    <article style={{ display: 'flex', gap: 30 }}>
                        <div style={{ color: 'grey', margin: 0 }}>
                            <span>Referencia</span>
                            <Input
                                onChange={onChange}
                                placeholder={'Escribe una referencia'}
                                value={form.reference}
                                name="reference"
                                style={{ width: 250 }}
                            />
                        </div>
                        <div style={{ color: 'grey', margin: 0 }}>
                            <span>Autorizado por:</span>
                            <Input value={displayName} style={{ width: 250 }} />
                        </div>
                    </article>

                    <div style={styles.formItem}>
                        <span>Notas:</span>
                        <Input
                            onChange={onChange}
                            placeholder="Ingresa un comentario"
                            value={form.notes}
                            name="notes"
                            style={{ width: '100%' }}
                        />
                    </div>
                </section>
                <Divider />
                <section>
                    <h3>Servicio</h3>
                    <Table
                        onRow={(record) => ({
                            onClick: () => removeInvoiceOfAgreement(record),
                        })}
                        size="small"
                        pagination={false}
                        dataSource={[dataSource]}
                        columns={[
                            {
                                title: 'Nombre',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Descripción',
                                dataIndex: 'desc',
                                key: 'desc',
                            },
                            {
                                title: 'Importe',
                                dataIndex: 'total',
                                key: 'total',
                                render: (_) => formatMoney(form.amount),
                            },
                        ]}
                    />
                    <span style={styles.error}>{errors.invoices}</span>
                    <div
                        style={{
                            marginTop: 150, // change this when real table
                            display: 'flex',
                            gap: 100,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <span>Total: {formatMoney(form.amount, true)}</span>
                        <span>
                            {/* {formatMoney(Object.values(invoices).reduce((acc, inv) => acc + inv.total, 0), true)} */}
                        </span>
                    </div>
                </section>
            </Card>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    padding: 40,
                }}
            >
                <Button
                    type="primary"
                    loading={fetching}
                    onClick={saveReceipt}
                    disabled={fetching || !lot.id || lot.delinquency}
                    style={
                        fetching || Object.keys(errors).length
                            ? styles.disabledButton
                            : styles.enabledButton
                    }
                >
                    Pagar
        </Button>
            </div>

            <PrintingModal
                onFinish={() => dispatch(clearFomentInfoAction())}
                show={printing}
                agua={true}
                onCancel={() => setPrinting(false)}
            />
        </div>
    )
}

const styles = {
    tag: { borderRadius: 20, cursor: 'pointer' },
    error: { color: 'red' },
}

export default GenerateCovenantReceipt
