import React, { useEffect, useState } from 'react'

import { Button, Popover, Tag } from 'antd'
import Search from 'antd/lib/input/Search'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { getAgreementsAction } from '../../redux/agreementsDuck'
import AgreementCard from './AgreementCard'
import { useHistory } from 'react-router-dom'

const Agreements = ({ subsidiary = 'Fomento' }) => {
  const history = useHistory()
  const { items, fetching } = useSelector(({ agreements }) => agreements)
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({
    status: null,
  })

  useEffect(() => {
    dispatch(getAgreementsAction(null, subsidiary))
  }, [subsidiary])

  useEffect(() => {
    if (!filters.status) dispatch(getAgreementsAction())
    else if (filters.status === 'pendiente')
      dispatch(getAgreementsAction(`paid=false`, subsidiary))
    else dispatch(getAgreementsAction(`paid=true`, subsidiary))
  }, [filters])

  const searchByFolio = (folio) =>
    dispatch(getAgreementsAction(`folio=${folio}`))
  const toggleFilter = (key) =>
    key === filters.status
      ? setFilters({ ...filters, status: null })
      : setFilters({ ...filters, status: key })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: 10,
        }}
      >
        <Search
          allowClear
          placeholder="Busca un convenio por folio"
          onSearch={searchByFolio}
          // enterButton
          loading={fetching}
          size="large"
        />
        <Button
          onClick={() => history.push('/admin/convenios/nuevo')}
          size="large"
          shape="circle"
          style={{
            background: '#95bf48',
            color: '#ffffff',
            boxShadow: '0px 4px 4px rgba(149, 191, 72, 0.25)',
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <Popover
          style={{ margin: 0 }}
          trigger="click"
          placement="bottomRight"
          content={<Filters toggleFilter={toggleFilter} filters={filters} />}
        >
          <Button
            size="large"
            shape="circle"
            style={{
              border: '#ffffff',
              color: 'grey',
              backgroundColor: '#ffffff',
              fontSize: '20px',
              display: 'grid',
              placeContent: 'center',
              boxShadow: '0px 4px 4px rgba(196, 196, 196, 0.25)',
            }}
            icon={
              <FontAwesomeIcon
                color={filters.status && '#95bf48'}
                icon={faFilter}
              />
            }
          />
        </Popover>
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 20,
        }}
      >
        {items.map((item) => (
          <AgreementCard loading={fetching} {...item} />
        ))}
      </div>
      {/* <Button
        style={{
          // marginTop: 30,
          alignSelf: 'center',
        }}
      >
        Cargar más
      </Button> */}
    </div>
  )
}

const Filters = ({ toggleFilter, filters }) => {
  return (
    <div
      style={{
        cursor: 'pointer',
      }}
    >
      <span>Por estatus: </span>
      <div>
        <Tag
          onClick={() => toggleFilter('pendiente')}
          color={filters.status === 'pendiente' ? '#95bf48' : null}
        >
          Pendiente
        </Tag>
        <Tag
          onClick={() => toggleFilter('pagado')}
          color={filters.status === 'pagado' ? '#95bf48' : null}
        >
          Pagado
        </Tag>
      </div>
    </div>
  )
}

export default Agreements
