import React from 'react';
import PolygonsSvg from '../../../assets/svgs/Polygons.png';
import styles from './Ticket.module.css';

function index({ children }) {
  return (
    <div className={styles.ticket}>
      {children}
      <img alt="polygon" src={PolygonsSvg} className={styles.ticket__polygon} />
    </div>
  );
}

export default index;
