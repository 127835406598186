import React from 'react';

import styles from './ListDetailItem.module.css';

function ListDetailItem({
  label, description
}) {
  return (
    <div className={styles.listdetail}>
      <div className={styles.listdetail__item}>
        {label}
      </div>
      <div className={styles['listdetail__item--bold']}>
        <BoldText>
          {description}
        </BoldText>

      </div>
    </div>
  );
}

const BoldText = ({ children }) => (<span style={{ fontWeight: "500" }}>{children}</span>)

export default ListDetailItem;
