import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ color, width, height }) => {
  return (
    <svg viewBox="0 0 120 30" fill={color} {...{ width, height }}>
      <path d="M24.7227 7.5614V0.0528768H26.2023V6.28178H30.0837V7.5614H24.7227Z" />
      <path d="M36.3782 7.61427C35.8206 7.61427 35.3131 7.50852 34.8556 7.29701C34.4053 7.07845 34.0158 6.79292 33.687 6.44041C33.3653 6.08084 33.1151 5.67193 32.9364 5.21366C32.7577 4.7554 32.6684 4.28655 32.6684 3.80714C32.6684 3.30657 32.7613 2.82715 32.9471 2.36889C33.1401 1.91062 33.401 1.50523 33.7298 1.15272C34.0658 0.800204 34.4625 0.521719 34.92 0.317261C35.3775 0.105754 35.8742 0 36.4103 0C36.9607 0 37.4611 0.112804 37.9114 0.338412C38.3689 0.55697 38.7585 0.846031 39.0801 1.20559C39.4018 1.56516 39.652 1.97407 39.8307 2.43234C40.0094 2.8906 40.0987 3.35592 40.0987 3.82829C40.0987 4.32886 40.0058 4.80827 39.8199 5.26654C39.6341 5.71776 39.3768 6.11962 39.048 6.47213C38.7192 6.8176 38.326 7.09608 37.8685 7.30759C37.4111 7.51204 36.9143 7.61427 36.3782 7.61427ZM34.1694 3.80714C34.1694 4.13145 34.2195 4.44518 34.3195 4.74835C34.4196 5.05151 34.5626 5.31942 34.7484 5.55207C34.9414 5.78473 35.1737 5.97156 35.4454 6.11257C35.7241 6.25357 36.0386 6.32408 36.3889 6.32408C36.7463 6.32408 37.0608 6.25357 37.3324 6.11257C37.6112 5.96451 37.8435 5.77063 38.0294 5.53092C38.2224 5.29121 38.3653 5.02331 38.4582 4.72719C38.5583 4.42403 38.6084 4.11735 38.6084 3.80714C38.6084 3.48283 38.5547 3.16909 38.4475 2.86593C38.3475 2.56277 38.2009 2.29486 38.0079 2.0622C37.8221 1.82954 37.5898 1.64623 37.311 1.51228C37.0394 1.37127 36.732 1.30077 36.3889 1.30077C36.0315 1.30077 35.7134 1.3748 35.4346 1.52285C35.1559 1.66386 34.9236 1.85422 34.7377 2.09393C34.5519 2.32658 34.4089 2.59449 34.3088 2.89765C34.2159 3.19376 34.1694 3.49693 34.1694 3.80714Z" />
      <path d="M50.0687 7.5614V2.59097L48.1066 6.29235H47.3024L45.3403 2.59097V7.5614H43.8606V0.0528768H45.4475L47.6991 4.32533L49.9722 0.0528768H51.5483V7.5614H50.0687Z" />
      <path d="M57.9304 0.0528768H59.2385L62.1335 7.5614H60.611L59.9033 5.68955H57.2442L56.5473 7.5614H55.0248L57.9304 0.0528768ZM59.6352 4.66374L58.5845 1.71321L57.4908 4.66374H59.6352Z" />
      <path d="M70.0156 2.0199C69.9656 1.97055 69.8726 1.90357 69.7368 1.81897C69.6082 1.73436 69.4473 1.65328 69.2543 1.57573C69.0685 1.49113 68.8648 1.42063 68.6432 1.36422C68.4216 1.30782 68.1964 1.27962 67.9677 1.27962C67.5602 1.27962 67.2565 1.35365 67.0563 1.5017C66.8562 1.64976 66.7561 1.85774 66.7561 2.12565C66.7561 2.28076 66.7918 2.41119 66.8633 2.51694C66.9419 2.61564 67.0527 2.70377 67.1957 2.78132C67.3387 2.85888 67.5174 2.92938 67.7318 2.99283C67.9534 3.05628 68.2071 3.12326 68.4931 3.19376C68.8648 3.29247 69.2007 3.39822 69.5009 3.51103C69.8083 3.62383 70.0656 3.76484 70.2729 3.93404C70.4874 4.10325 70.6518 4.3077 70.7661 4.54741C70.8805 4.78007 70.9377 5.06913 70.9377 5.41459C70.9377 5.81646 70.8591 6.16192 70.7018 6.45098C70.5517 6.73299 70.3444 6.96213 70.0799 7.13838C69.8226 7.31464 69.5224 7.44507 69.1793 7.52967C68.8433 7.60722 68.4895 7.646 68.1178 7.646C67.546 7.646 66.9813 7.5614 66.4237 7.39219C65.8662 7.22298 65.3658 6.98328 64.9226 6.67306L65.5767 5.41459C65.641 5.47805 65.7554 5.56265 65.9198 5.6684C66.0913 5.76711 66.2915 5.86934 66.5202 5.97509C66.7489 6.07379 67.0027 6.1584 67.2815 6.2289C67.5602 6.2994 67.8462 6.33465 68.1392 6.33465C68.9541 6.33465 69.3615 6.07732 69.3615 5.56265C69.3615 5.40049 69.3151 5.26301 69.2222 5.15021C69.1292 5.03741 68.997 4.9387 68.8254 4.8541C68.6539 4.7695 68.4466 4.69194 68.2036 4.62144C67.9605 4.55094 67.6889 4.47339 67.3887 4.38878C67.0241 4.29008 66.7061 4.18433 66.4344 4.07152C66.17 3.95167 65.9484 3.81419 65.7697 3.65908C65.591 3.49693 65.4552 3.31362 65.3622 3.10916C65.2765 2.89765 65.2336 2.64737 65.2336 2.35831C65.2336 1.9776 65.3051 1.63918 65.448 1.34307C65.591 1.04696 65.7875 0.800204 66.0377 0.602796C66.2951 0.405389 66.5917 0.257334 66.9277 0.158631C67.2636 0.0528768 67.6246 0 68.0106 0C68.5467 0 69.0399 0.084603 69.4902 0.253809C69.9405 0.415965 70.3337 0.609847 70.6696 0.835455L70.0156 2.0199Z" />
      <path d="M79.8854 7.5614V0.0528768H82.6517C83.2735 0.0528768 83.8168 0.15158 84.2814 0.348987C84.7532 0.546395 85.1463 0.814304 85.4608 1.15272C85.7753 1.49113 86.0112 1.88947 86.1685 2.34773C86.3257 2.79895 86.4044 3.28189 86.4044 3.79656C86.4044 4.36763 86.315 4.88583 86.1363 5.35114C85.9648 5.80941 85.7146 6.20422 85.3858 6.53558C85.057 6.8599 84.6603 7.11371 84.1956 7.29701C83.7382 7.47327 83.2235 7.5614 82.6517 7.5614H79.8854ZM84.914 3.79656C84.914 3.437 84.864 3.10564 84.7639 2.80248C84.6638 2.49931 84.5173 2.23846 84.3243 2.0199C84.1313 1.80134 83.8918 1.63213 83.6059 1.51228C83.3271 1.39242 83.0091 1.3325 82.6517 1.3325H81.365V6.28178H82.6517C83.0162 6.28178 83.3379 6.21832 83.6166 6.09142C83.9026 5.96451 84.1385 5.79178 84.3243 5.57323C84.5173 5.34762 84.6638 5.08323 84.7639 4.78007C84.864 4.47691 84.914 4.14907 84.914 3.79656Z" />
      <path d="M95.4733 6.28178V7.5614H90.1873V0.0528768H95.3768V1.3325H91.667V3.14089H94.8728V4.32533H91.667V6.28178H95.4733Z" />
      <path d="M0 23.119C0 22.3043 0.146137 21.5021 0.438412 20.7125C0.743394 19.9103 1.18181 19.1959 1.75365 18.5692C2.32549 17.9425 3.02441 17.4349 3.8504 17.0463C4.67639 16.6578 5.61675 16.4635 6.67148 16.4635C7.91682 16.4635 8.99061 16.733 9.89285 17.2719C10.8078 17.8109 11.4877 18.5128 11.9324 19.3776L9.91191 20.7501C9.73401 20.3741 9.51798 20.0607 9.26383 19.81C9.00968 19.5468 8.73646 19.34 8.44419 19.1896C8.15192 19.0392 7.84693 18.9327 7.52924 18.87C7.21155 18.7948 6.90022 18.7572 6.59524 18.7572C5.93444 18.7572 5.35625 18.8888 4.86065 19.152C4.37776 19.4152 3.97112 19.7599 3.64072 20.186C3.32303 20.5997 3.08159 21.0759 2.91639 21.6149C2.75119 22.1413 2.66859 22.6677 2.66859 23.1942C2.66859 23.7832 2.7639 24.3535 2.95451 24.905C3.14513 25.444 3.41199 25.9203 3.75509 26.3339C4.1109 26.7475 4.53025 27.0796 5.01314 27.3303C5.50874 27.581 6.04881 27.7063 6.63336 27.7063C6.93834 27.7063 7.25603 27.6687 7.58643 27.5935C7.91682 27.5183 8.22816 27.3992 8.52044 27.2363C8.82542 27.0734 9.10498 26.8666 9.35914 26.6159C9.61329 26.3527 9.81661 26.0393 9.9691 25.6758L12.123 26.8979C11.907 27.3992 11.5957 27.8442 11.189 28.2327C10.7951 28.6088 10.344 28.9284 9.83567 29.1916C9.34007 29.4548 8.80636 29.6553 8.23451 29.7932C7.66267 29.9311 7.10354 30 6.55711 30C5.59134 30 4.70816 29.7995 3.90758 29.3984C3.107 28.9973 2.41444 28.4771 1.82989 27.8379C1.25805 27.1862 0.806932 26.4529 0.476534 25.6382C0.158845 24.811 0 23.9713 0 23.119Z" />
      <path d="M24.2024 29.9812C23.2112 29.9812 22.309 29.7932 21.4957 29.4172C20.6951 29.0286 20.0026 28.521 19.418 27.8943C18.8462 27.2551 18.4014 26.5281 18.0837 25.7134C17.766 24.8988 17.6072 24.0653 17.6072 23.213C17.6072 22.3231 17.7724 21.4708 18.1028 20.6561C18.4459 19.8414 18.9097 19.1207 19.4943 18.494C20.0915 17.8673 20.7968 17.3722 21.6101 17.0087C22.4234 16.6327 23.3066 16.4447 24.2596 16.4447C25.2381 16.4447 26.1276 16.6453 26.9282 17.0463C27.7415 17.4349 28.4341 17.9488 29.0059 18.588C29.5777 19.2272 30.0225 19.9542 30.3402 20.7689C30.6579 21.5836 30.8167 22.4108 30.8167 23.2506C30.8167 24.1405 30.6515 24.9928 30.3211 25.8074C29.9907 26.6096 29.5333 27.324 28.9487 27.9507C28.3642 28.5649 27.6653 29.06 26.852 29.436C26.0387 29.7995 25.1555 29.9812 24.2024 29.9812ZM20.2758 23.213C20.2758 23.7895 20.3647 24.3473 20.5427 24.8862C20.7206 25.4252 20.9747 25.9015 21.3051 26.3151C21.6482 26.7287 22.0612 27.0608 22.5441 27.3115C23.0397 27.5622 23.5988 27.6875 24.2215 27.6875C24.8569 27.6875 25.416 27.5622 25.8989 27.3115C26.3945 27.0483 26.8075 26.7036 27.1379 26.2775C27.481 25.8513 27.7351 25.375 27.9003 24.8486C28.0783 24.3097 28.1672 23.7644 28.1672 23.213C28.1672 22.6364 28.0719 22.0786 27.8813 21.5397C27.7034 21.0007 27.4429 20.5245 27.0998 20.1108C26.7694 19.6972 26.3564 19.3714 25.8608 19.1332C25.3779 18.8825 24.8315 18.7572 24.2215 18.7572C23.5861 18.7572 23.0206 18.8888 22.525 19.152C22.0294 19.4027 21.6164 19.7411 21.286 20.1672C20.9556 20.5809 20.7015 21.0571 20.5236 21.5961C20.3584 22.1225 20.2758 22.6615 20.2758 23.213Z" />
      <path d="M36.685 23.119C36.685 22.3043 36.8311 21.5021 37.1234 20.7125C37.4283 19.9103 37.8668 19.1959 38.4386 18.5692C39.0104 17.9425 39.7094 17.4349 40.5354 17.0463C41.3613 16.6578 42.3017 16.4635 43.3564 16.4635C44.6018 16.4635 45.6756 16.733 46.5778 17.2719C47.4928 17.8109 48.1726 18.5128 48.6174 19.3776L46.5969 20.7501C46.419 20.3741 46.2029 20.0607 45.9488 19.81C45.6946 19.5468 45.4214 19.34 45.1291 19.1896C44.8369 19.0392 44.5319 18.9327 44.2142 18.87C43.8965 18.7948 43.5852 18.7572 43.2802 18.7572C42.6194 18.7572 42.0412 18.8888 41.5456 19.152C41.0627 19.4152 40.6561 19.7599 40.3257 20.186C40.008 20.5997 39.7665 21.0759 39.6013 21.6149C39.4361 22.1413 39.3535 22.6677 39.3535 23.1942C39.3535 23.7832 39.4489 24.3535 39.6395 24.905C39.8301 25.444 40.0969 25.9203 40.44 26.3339C40.7959 26.7475 41.2152 27.0796 41.6981 27.3303C42.1937 27.581 42.7338 27.7063 43.3183 27.7063C43.6233 27.7063 43.941 27.6687 44.2714 27.5935C44.6018 27.5183 44.9131 27.3992 45.2054 27.2363C45.5104 27.0734 45.7899 26.8666 46.0441 26.6159C46.2982 26.3527 46.5016 26.0393 46.6541 25.6758L48.808 26.8979C48.592 27.3992 48.2806 27.8442 47.874 28.2327C47.48 28.6088 47.0289 28.9284 46.5206 29.1916C46.025 29.4548 45.4913 29.6553 44.9195 29.7932C44.3476 29.9311 43.7885 30 43.2421 30C42.2763 30 41.3931 29.7995 40.5925 29.3984C39.792 28.9973 39.0994 28.4771 38.5148 27.8379C37.943 27.1862 37.4919 26.4529 37.1615 25.6382C36.8438 24.811 36.685 23.9713 36.685 23.119Z" />
      <path d="M60.8874 29.9812C59.8962 29.9812 58.994 29.7932 58.1807 29.4172C57.3801 29.0286 56.6875 28.521 56.103 27.8943C55.5311 27.2551 55.0864 26.5281 54.7687 25.7134C54.451 24.8988 54.2922 24.0653 54.2922 23.213C54.2922 22.3231 54.4574 21.4708 54.7878 20.6561C55.1309 19.8414 55.5947 19.1207 56.1792 18.494C56.7765 17.8673 57.4818 17.3722 58.295 17.0087C59.1083 16.6327 59.9915 16.4447 60.9446 16.4447C61.9231 16.4447 62.8126 16.6453 63.6132 17.0463C64.4265 17.4349 65.119 17.9488 65.6909 18.588C66.2627 19.2272 66.7075 19.9542 67.0252 20.7689C67.3428 21.5836 67.5017 22.4108 67.5017 23.2506C67.5017 24.1405 67.3365 24.9928 67.0061 25.8074C66.6757 26.6096 66.2182 27.324 65.6337 27.9507C65.0491 28.5649 64.3502 29.06 63.5369 29.436C62.7236 29.7995 61.8405 29.9812 60.8874 29.9812ZM56.9608 23.213C56.9608 23.7895 57.0497 24.3473 57.2276 24.8862C57.4055 25.4252 57.6597 25.9015 57.9901 26.3151C58.3332 26.7287 58.7462 27.0608 59.2291 27.3115C59.7247 27.5622 60.2838 27.6875 60.9065 27.6875C61.5418 27.6875 62.101 27.5622 62.5839 27.3115C63.0795 27.0483 63.4924 26.7036 63.8228 26.2775C64.166 25.8513 64.4201 25.375 64.5853 24.8486C64.7632 24.3097 64.8522 23.7644 64.8522 23.213C64.8522 22.6364 64.7569 22.0786 64.5662 21.5397C64.3883 21.0007 64.1278 20.5245 63.7847 20.1108C63.4543 19.6972 63.0413 19.3714 62.5457 19.1332C62.0628 18.8825 61.5164 18.7572 60.9065 18.7572C60.2711 18.7572 59.7056 18.8888 59.21 19.152C58.7144 19.4027 58.3014 19.7411 57.971 20.1672C57.6406 20.5809 57.3865 21.0571 57.2085 21.5961C57.0434 22.1225 56.9608 22.6615 56.9608 23.213Z" />
      <path d="M74.8023 16.5387L78.1381 22.9685L81.531 16.5387H84.3712L79.4533 25.2246V29.8872H76.8419V25.187L71.9431 16.5387H74.8023Z" />
      <path d="M95.3944 29.9812C94.4032 29.9812 93.501 29.7932 92.6877 29.4172C91.8871 29.0286 91.1946 28.521 90.61 27.8943C90.0382 27.2551 89.5934 26.5281 89.2757 25.7134C88.958 24.8988 88.7992 24.0653 88.7992 23.213C88.7992 22.3231 88.9644 21.4708 89.2948 20.6561C89.6379 19.8414 90.1017 19.1207 90.6863 18.494C91.2835 17.8673 91.9888 17.3722 92.8021 17.0087C93.6154 16.6327 94.4986 16.4447 95.4516 16.4447C96.4301 16.4447 97.3196 16.6453 98.1202 17.0463C98.9335 17.4349 99.6261 17.9488 100.198 18.588C100.77 19.2272 101.215 19.9542 101.532 20.7689C101.85 21.5836 102.009 22.4108 102.009 23.2506C102.009 24.1405 101.844 24.9928 101.513 25.8074C101.183 26.6096 100.725 27.324 100.141 27.9507C99.5562 28.5649 98.8573 29.06 98.044 29.436C97.2307 29.7995 96.3475 29.9812 95.3944 29.9812ZM91.4678 23.213C91.4678 23.7895 91.5567 24.3473 91.7346 24.8862C91.9126 25.4252 92.1667 25.9015 92.4971 26.3151C92.8402 26.7287 93.2532 27.0608 93.7361 27.3115C94.2317 27.5622 94.7908 27.6875 95.4135 27.6875C96.0489 27.6875 96.608 27.5622 97.0909 27.3115C97.5865 27.0483 97.9995 26.7036 98.3299 26.2775C98.673 25.8513 98.9271 25.375 99.0923 24.8486C99.2702 24.3097 99.3592 23.7644 99.3592 23.213C99.3592 22.6364 99.2639 22.0786 99.0733 21.5397C98.8954 21.0007 98.6349 20.5245 98.2918 20.1108C97.9614 19.6972 97.5484 19.3714 97.0528 19.1332C96.5699 18.8825 96.0235 18.7572 95.4135 18.7572C94.7781 18.7572 94.2126 18.8888 93.717 19.152C93.2214 19.4027 92.8084 19.7411 92.478 20.1672C92.1476 20.5809 91.8935 21.0571 91.7156 21.5961C91.5504 22.1225 91.4678 22.6615 91.4678 23.213Z" />
      <path d="M107.877 23.119C107.877 22.3043 108.023 21.5021 108.315 20.7125C108.62 19.9103 109.059 19.1959 109.631 18.5692C110.202 17.9425 110.901 17.4349 111.727 17.0463C112.553 16.6578 113.494 16.4635 114.548 16.4635C115.794 16.4635 116.868 16.733 117.77 17.2719C118.685 17.8109 119.365 18.5128 119.809 19.3776L117.789 20.7501C117.611 20.3741 117.395 20.0607 117.141 19.81C116.887 19.5468 116.613 19.34 116.321 19.1896C116.029 19.0392 115.724 18.9327 115.406 18.87C115.089 18.7948 114.777 18.7572 114.472 18.7572C113.811 18.7572 113.233 18.8888 112.738 19.152C112.255 19.4152 111.848 19.7599 111.518 20.186C111.2 20.5997 110.959 21.0759 110.793 21.6149C110.628 22.1413 110.546 22.6677 110.546 23.1942C110.546 23.7832 110.641 24.3535 110.831 24.905C111.022 25.444 111.289 25.9203 111.632 26.3339C111.988 26.7475 112.407 27.0796 112.89 27.3303C113.386 27.581 113.926 27.7063 114.51 27.7063C114.815 27.7063 115.133 27.6687 115.463 27.5935C115.794 27.5183 116.105 27.3992 116.397 27.2363C116.702 27.0734 116.982 26.8666 117.236 26.6159C117.49 26.3527 117.694 26.0393 117.846 25.6758L120 26.8979C119.784 27.3992 119.473 27.8442 119.066 28.2327C118.672 28.6088 118.221 28.9284 117.713 29.1916C117.217 29.4548 116.683 29.6553 116.111 29.7932C115.54 29.9311 114.98 30 114.434 30C113.468 30 112.585 29.7995 111.785 29.3984C110.984 28.9973 110.291 28.4771 109.707 27.8379C109.135 27.1862 108.684 26.4529 108.354 25.6382C108.036 24.811 107.877 23.9713 107.877 23.119Z" />
    </svg>
  );
};

export default Logo;

Logo.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Logo.defaultProps = {
  color:' #252525',
  width: '120',
  height: '30',
};
