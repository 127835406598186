import { message, notification } from 'antd'
import axios from 'axios'
import { Axios } from '../services/api'

const url = process.env.REACT_APP_BACKEND_ROUTE || 'http://localhost:8000'

const initialState = {
  lot: {
    initial: true,
    residents: [
      {
        firstName: '',
        lastName: '',
        surName: '',
      },
    ],
    foments: [],
    percent: [],
  },
  fetching: false,
  servicesPayable: [],
  foment: [],
  paginatedFoment: { total: 0, page: 1, limit: 50, items: [] },
  print: {},
  deposit: { receipts: [] },
  waterInvoices: [],
  water: { total: 0, page: 1, limit: 50, items: [] },
  lomasReceiptsPaginated: { total: 0, page: 1, limit: 50, items: [] },
}

const GET_PENDING_WATER_INVOICES = 'GET_PENDING_WATER_INVOICES'
const GET_PENDING_WATER_INVOICES_ERROR = 'GET_PENDING_WATER_INVOICES_ERROR'
const GET_PENDING_WATER_INVOICES_SUCCESS = 'GET_PENDING_WATER_INVOICES_SUCCESS'

const GET_WATER_INVOICES = 'GET_WATER_INVOICES'
const GET_WATER_INVOICES_ERROR = 'GET_WATER_INVOICES_ERROR'
const GET_WATER_INVOICES_SUCCESS = 'GET_WATER_INVOICES_SUCCESS'
const GET_WATER_PAGINATED_SUCCESS = 'GET_WATER_PAGINATED_SUCCESS'

const CREATE_RECEIPT = 'CREATE_RECEIPT'
const CREATE_RECEIPT_ERROR = 'CREATE_RECEIPT_ERROR'
const CREATE_RECEIPT_SUCCESS = 'CREATE_RECEIPT_SUCCESS'

const GET_LOMAS_RECEIPTS_PAGINATED = 'GET_LOMAS_RECEIPTS_PAGINATED'
const GET_LOMAS_RECEIPTS_PAGINATED_ERROR = 'GET_LOMAS_RECEIPTS_PAGINATED_ERROR'
const GET_LOMAS_RECEIPTS_PAGINATED_SUCCESS =
  'GET_LOMAS_RECEIPTS_PAGINATED_SUCCESS'

const GET_FOMENT_BY_LOT = 'GET_FOMENT_BY_LOT'
const GET_FOMENT_BY_LOT_SUCCESS = 'GET_FOMENT_BY_LOT_SUCCESS'
const GET_FOMENT_BY_LOT_ERROR = 'GET_FOMENT_BY_LOT_ERROR'
const GET_FOMENT_BY_LOT_SUCCESS_PAGINATED =
  'GET_FOMENT_BY_LOT_SUCCESS_PAGINATED'

const CLEAR_FOMENT = 'CLEAR_FOMENT'

const GENERATE_FOMENT = 'GENERATE_FOMENT'
const GENERATE_FOMENT_SUCCESS = 'GENERATE_FOMENT_SUCCESS'
const GENERATE_FOMENT_ERROR = 'GENERATE_FOMENT_ERROR'

const FIND_LOT = 'FIND_LOT'
const FIND_LOT_ERROR = 'FIND_LOT_ERROR'
const FIND_LOT_SUCCESS = 'FIND_LOT_SUCCESS'

const HANDLE_DEBT_TO_RECEIPT = 'HANDLE_DEBT_TO_RECEIPT'
const CLEAR_SERVICE_PAYABLE = 'CLEAR_SERVICE_PAYABLE'
const SET_FOR_PRINTING = 'SET_FOR_PRINTING'

const GET_DEPOSIT_BY_LOT = 'GET_DEPOSIT_BY_LOT'
const GET_DEPOSIT_BY_LOT_ERROR = 'GET_DEPOSIT_BY_LOT_ERROR'
const GET_DEPOSIT_BY_LOT_SUCCESS = 'GET_DEPOSIT_BY_LOT_SUCCESS'

const UPDATE_DEPOSIT = 'UPDATE_DEPOSIT'
const UPDATE_DEPOSIT_ERROR = 'UPDATE_DEPOSIT_ERROR'
const UPDATE_DEPOSIT_SUCCESS = 'UPDATE_DEPOSIT_SUCCESS'

const CREATE_DEPOSITE_RECEIPT = 'CREATE_DEPOSITE_RECEIPT'
const CREATE_DEPOSITE_RECEIPT_ERROR = 'CREATE_DEPOSITE_RECEIPT_ERROR'
const CREATE_DEPOSITE_RECEIPT_SUCCESS = 'CREATE_DEPOSITE_RECEIPT_SUCCESS'

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_FOR_PRINTING:
      return { ...state, print: payload }

    case GET_PENDING_WATER_INVOICES_SUCCESS:
      return { ...state, fetching: false, waterInvoices: payload }
    case CREATE_RECEIPT_SUCCESS:
      return { ...state, fetching: false, lomasReceiptsPaginated: payload }
    case CREATE_DEPOSITE_RECEIPT_SUCCESS:
      return { ...state, fetching: false }
    case UPDATE_DEPOSIT_SUCCESS:
    case GET_DEPOSIT_BY_LOT_SUCCESS:
      return { ...state, fetching: false, deposit: payload }
    case GET_WATER_PAGINATED_SUCCESS:
      return { ...state, fetching: false, water: payload }
    case GET_WATER_INVOICES_SUCCESS:
      return { ...state, fetching: false, waterInvoices: payload }
    case CREATE_RECEIPT_SUCCESS:
      return { ...state, fetching: false } // should we save the new receipt?
    case GET_LOMAS_RECEIPTS_PAGINATED_SUCCESS:
      return { ...state, fetching: false, lomasReceiptsPaginated: payload }

    case GET_PENDING_WATER_INVOICES:
    case GET_LOMAS_RECEIPTS_PAGINATED:
    case GET_WATER_INVOICES:
    case CREATE_DEPOSITE_RECEIPT:
    case UPDATE_DEPOSIT:
    case GET_DEPOSIT_BY_LOT:
    case CREATE_RECEIPT:
    case GET_FOMENT_BY_LOT:
      return { ...state, fetching: true }

    case GET_PENDING_WATER_INVOICES_ERROR:
    case GET_LOMAS_RECEIPTS_PAGINATED_ERROR:
    case GET_WATER_INVOICES_ERROR:
    case CREATE_DEPOSITE_RECEIPT_ERROR:
    case UPDATE_DEPOSIT_ERROR:
    case GET_DEPOSIT_BY_LOT_ERROR:
    case CREATE_RECEIPT_ERROR:
    case GET_FOMENT_BY_LOT_ERROR:
      return { ...state, fetching: false, error: payload }

    case GET_FOMENT_BY_LOT_SUCCESS_PAGINATED:
      return {
        ...state,
        fetching: false,
        paginatedFoment: payload,
      }
    case GET_FOMENT_BY_LOT_SUCCESS:
      return {
        ...state,
        fetching: false,
        foment: payload,
      }
    case CLEAR_FOMENT:
      return { ...initialState }

    case FIND_LOT:
      return { ...state, fetching: true, lot: initialState.lot }
    case FIND_LOT_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
        lot: initialState.lot,
      }
    case FIND_LOT_SUCCESS:
      return { ...state, fetching: false, lot: payload }
    case HANDLE_DEBT_TO_RECEIPT:
      return { ...state, servicesPayable: payload }
    case CLEAR_SERVICE_PAYABLE:
      return { ...state, servicesPayable: [] }

    default:
      return state
  }
}

//thunks

// SISTEMAS
export const addWaterToReceiptAction = (debt) => (dispatch, getState) => {
  const { servicesPayable } = getState().fomento
  if (servicesPayable.findIndex((item) => item.id === debt.id) < 0) {
    dispatch({
      type: HANDLE_DEBT_TO_RECEIPT,
      payload: [
        ...servicesPayable,
        debt,
        // summaryDebt
      ],
    })
  }
}

export const getPendingWaterByLot = (lotId) => (dispatch, getState) => {
  dispatch({ type: GET_PENDING_WATER_INVOICES })
  const { token } = getState().user
  return Axios.get('/services/agua/facturas/pendientes/' + lotId, {
    headers: { Authorization: token },
  })
    .then(({ data }) => {
      dispatch({ type: GET_PENDING_WATER_INVOICES_SUCCESS, payload: data })
      // extra data
      // dispatch(getFomentInvoicesAction(data._id))
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GET_PENDING_WATER_INVOICES_ERROR, payload: err })
      // message.error(err.response?.data)
    })
}

export const getWaterInvoicesByLotAction = (lotId, paginated) => (
  dispatch,
  getState,
) => {
  if (!lotId) {
    console.log('Sin lote')
    return
  }
  dispatch({ type: GET_WATER_INVOICES })
  const { token } = getState().user
  return Axios.get('/services/agua/byLot?lotId=' + lotId, {
    headers: { Authorization: token },
    params: {
      p: paginated && paginated.p ? paginated.p : 1,
      l: paginated && paginated.l ? paginated.l : 50,
      q: paginated && paginated.q ? paginated.q : null,
    },
  })
    .then(({ data }) => {
      dispatch({ type: GET_WATER_INVOICES_SUCCESS, payload: data.items })
      dispatch({ type: GET_WATER_PAGINATED_SUCCESS, payload: { ...data } })
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GET_WATER_INVOICES_ERROR, payload: err })
      dispatch({ type: CLEAR_FOMENT })
      // message.error(err.response?.data)
    })
}

// LOMAS
export const createDepositReceiptAction = (body) => (dispatch, getState) => {
  dispatch({ type: CREATE_DEPOSITE_RECEIPT })
  const { token } = getState().user
  return Axios.post('/deposit/receipts', body, {
    headers: { Authorization: token },
  })
    .then(({ data }) => {
      dispatch({ type: CREATE_DEPOSITE_RECEIPT_SUCCESS, payload: data })
      message.success('Recibo creado exitosamente')
      getDepositByLot(body.lotId)(dispatch, getState) // <-- update deposit
      dispatch({ type: SET_FOR_PRINTING, payload: { ...data } })
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: CREATE_DEPOSITE_RECEIPT_ERROR, payload: err })
      // message.error(err.response?.data)
    })
}

export const getLomasReceiptsByLotAction = (lotId, paginated) => (
  dispatch,
  getState,
) => {
  if (!lotId) {
    console.log('Sin lote')
    return
  }
  dispatch({ type: GET_LOMAS_RECEIPTS_PAGINATED })
  const { token } = getState().user
  return Axios.get('/receipts/lomas/byLot/' + lotId, {
    headers: { Authorization: token },
    params: {
      p: paginated && paginated.p ? paginated.p : 1,
      l: paginated && paginated.l ? paginated.l : 50,
      q: paginated && paginated.q ? paginated.q : null,
    },
  })
    .then(({ data }) => {
      dispatch({ type: GET_LOMAS_RECEIPTS_PAGINATED_SUCCESS, payload: data })
      // extra data
      // dispatch(getFomentInvoicesAction(data._id))
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GET_LOMAS_RECEIPTS_PAGINATED_ERROR, payload: err })
      // message.error(err.response?.data)
    })
}

export const updateDepositAction = (body, depositId) => (
  dispatch,
  getState,
) => {
  dispatch({ type: UPDATE_DEPOSIT })
  const { token } = getState().user
  return Axios.patch('/deposit/' + depositId, body, {
    headers: { Authorization: token },
  })
    .then(({ data }) => {
      dispatch({ type: CREATE_DEPOSITE_RECEIPT_SUCCESS, payload: data })
      // extra data
      // dispatch(getFomentInvoicesAction(data._id))
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: UPDATE_DEPOSIT_ERROR, payload: err })
      // message.error(err.response?.data)
    })
}

export const getDepositByLot = (lotId) => (dispatch, getState) => {
  dispatch({ type: GET_DEPOSIT_BY_LOT })
  const { token } = getState().user
  return Axios.get('/deposit/byLot?lotId=' + lotId, {
    headers: { Authorization: token },
  })
    .then(({ data }) => {
      const answer = { ...data }
      dispatch({ type: GET_DEPOSIT_BY_LOT_SUCCESS, payload: data })
      // extra data
      // dispatch(getFomentInvoicesAction(data._id))
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GET_DEPOSIT_BY_LOT_ERROR, payload: err })
      dispatch({ type: CLEAR_FOMENT })
      // message.error(err.response?.data)
    })
}

// FOMENT

export const getFomentByLotPaginatedAction = (lotId, paginated) => (
  dispatch,
  getState,
) => {
  if (!lotId) {
    console.log('Sin lote')
    return
  }
  dispatch({ type: GET_FOMENT_BY_LOT })
  const { token } = getState().user
  return Axios.get('/foments/byLot/paginated/' + lotId, {
    headers: { Authorization: token },
    params: {
      p: paginated && paginated.p ? paginated.p : 1,
      l: paginated && paginated.l ? paginated.l : 50,
      q: paginated && paginated.q ? paginated.q : null,
    },
  })
    .then(({ data }) => {
      dispatch({ type: GET_FOMENT_BY_LOT_SUCCESS_PAGINATED, payload: data })
      // extra data
      // dispatch(getFomentInvoicesAction(data._id))
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GET_FOMENT_BY_LOT_ERROR, payload: err })
      // message.error(err.response?.data)
    })
}

export const createReceiptAction = (receipt) => (dispatch, getState) => {
  dispatch({ type: CREATE_RECEIPT })
  const { token } = getState().user
  return Axios.post('/receipts', receipt, { headers: { Authorization: token } })
    .then(({ data }) => {
      dispatch({
        type: SET_FOR_PRINTING,
        payload: { ...receipt, ...data, folio: data.folio },
      })
      dispatch({ type: CREATE_RECEIPT_SUCCESS, payload: data })
      notification.success({
        message: 'Recibo creado con éxito',
        description: 'Se ha guardado el recibo exitosamente',
        duration: 30,
      })
      dispatch(getFomentByLot(receipt.lot))
      dispatch(clearServicesPayableAction())
      return true
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: CREATE_RECEIPT_ERROR, payload: err })
      notification.error({
        message: 'Error en el servidor',
        description:
          'Ocurrió un error en el servidor, inicia sesión nuevamente.',
      })
    })
}

export const getFomentByLot = (lotId) => (dispatch, getState) => {
  dispatch({ type: GET_FOMENT_BY_LOT })
  const { token } = getState().user
  return Axios.get('/foments/' + lotId, {
    headers: { Authorization: token },
  })
    .then(({ data }) => {
      dispatch({ type: GET_FOMENT_BY_LOT_SUCCESS, payload: data })
      // extra data
      // dispatch(getFomentInvoicesAction(data._id))
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GET_FOMENT_BY_LOT_ERROR, payload: err })
      // message.error(err.response?.data)
    })
}

export const generateFomentAction = (foment) => (dispatch, getState) => {
  dispatch({ type: GENERATE_FOMENT })
  const { token } = getState().user
  return Axios.post('/foments', foment, { headers: { Authorization: token } })
    .then(({ data }) => {
      dispatch({ type: GENERATE_FOMENT_SUCCESS, payload: data })
      // extra data
      // dispatch(getFomentInvoicesAction(data._id))
      message.success('Fomento Creado para el año ' + foment.year)
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GENERATE_FOMENT_ERROR, payload: err })
      message.error(err.response.data)
    })
}

export const findLotAction = (query) => (dispatch, getState) => {
  dispatch({ type: FIND_LOT })
  dispatch({ type: CLEAR_FOMENT })
  return axios
    .post(`${url}/lots/find`, { query })
    .then(({ data }) => {
      dispatch({ type: FIND_LOT_SUCCESS, payload: data })
      // extra data
      // dispatch(getFomentInvoicesAction(data._id))
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: FIND_LOT_ERROR, payload: err })
    })
}

export const addDebtsToReceiptAction = (debt) => (dispatch, getState) => {
  const { servicesPayable } = getState().fomento
  if (servicesPayable.findIndex((item) => item.id === debt.id) < 0) {
    const recargos =
      debt.chargesBefore + debt.chargesAfter + debt.chargesCluster
    const importe =
      debt.originalBefore1500 +
      debt.originalAfter1500 +
      debt.originalClusterAmount
    // console.log("el descuento: ", debt.area < 501 ? debt.originalBefore1500 * .1 : (500 * debt.price1) * .1)
    const summaryDebt = {
      id: debt.id,
      // discount: debt.originalBefore1500 * .1,
      discount:
        debt.area < 501
          ? debt.originalBefore1500 * 0.1
          : 500 * debt.price1 * 0.1,
      cuota: 1,
      descripcion: `Servicios Públicos Urbanos ${debt.year}`,
      recargos,
      importe,
      total: recargos + importe,
    }
    dispatch({
      type: HANDLE_DEBT_TO_RECEIPT,
      payload: [...servicesPayable, summaryDebt],
    })
  }
}

export const removeDebtFromReceiptAction = (id) => (dispatch, getState) => {
  const { servicesPayable } = getState().fomento
  const currentDebts = servicesPayable.filter((item) => item.id !== id)
  dispatch({
    type: HANDLE_DEBT_TO_RECEIPT,
    payload: currentDebts,
  })
}

export const clearServicesPayableAction = () => (dispatch) => {
  dispatch({ type: CLEAR_SERVICE_PAYABLE })
}

export const clearFomentInfoAction = () => (dispatch) => {
  dispatch({ type: CLEAR_FOMENT })
}
