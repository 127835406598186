import React, {useEffect} from 'react';
import { SideLayout } from '../Common';
import LotSearcher from './LotSearcher';
import Deposit from './Deposit';
import DepositSummary from './DepositSummary';
import { useSelector } from 'react-redux';
import { clearFomentInfoAction } from '../../redux/fomento.duck';
import {useDispatch} from 'react-redux'


function Caja({history}) {
    const dispatch = useDispatch()
    const { lot, foment, deposit, fetching } = useSelector(({ fomento }) => fomento)
    const role = useSelector(({ user }) => user.role.toLowerCase());
    useEffect(()=>()=>dispatch(clearFomentInfoAction()),[])
    return (
        <SideLayout type={role}>
            <h2 className="title">Lomas</h2>
            <h3 className="title-description">
            Cobra abonos de los residentes y haz el pago de su luz y teléfono.
            </h3>
            <div style={{
                display: "flex",
                gap: 40,
            }}>
                <section style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 40
                }}>
                    <LotSearcher disabled />
                    <Deposit />
                    
                </section>
                <section style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 40
                }}>
                    <DepositSummary />
                    
                </section>
            </div>
        </SideLayout >
    );
}

export default Caja;
