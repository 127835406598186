const initialState = {
  receipt: '',
  date: '00/00/0000',
  amount: 0,
  paymentMethod: '',
  surface: 0,
  serviceData: [],
  seccion: '',
  lomas: '',
  manzana: '',
  lote: '',
  id: '',
  residentName: '',
  serviceType: '',
  medidor: '',
};

const SET_PDF_DATA = 'SET_PDF_DATA';
const HANDLE_CLICK = 'HANDLE_CLICK';

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_PDF_DATA:
      return { ...state, ...payload };
    case HANDLE_CLICK:
      return { ...state, handleClickPDF: payload };
    default:
      return state;
  }
};

export const setPdfData = (headerObj) => (dispatch) => {
  console.log(headerObj);
  dispatch({ type: SET_PDF_DATA, payload: headerObj });
};

export const collectDataReport = () => (dispatch, getState) => {
  const flag = getState().reports.handleClickPDF;
  dispatch({ type: HANDLE_CLICK, payload: !flag });
};
