import React, { useState } from 'react'
import { Table, Tag, Button, Pagination, Card, Empty, Switch, Popconfirm, Skeleton } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getPaginatedServiceInvoicesAction, getServicesGroupedAction, payMultipleServicesAction } from '../../../redux/servicesDuck'
import { formatMoney } from '../../../helpers/formatMoney'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import RenderLotTable from './RenderLotTable'

import {
    INVOICES_DETAIL_TAG,
    ADMIM_INVOICE_RECEIPT,
} from '../../../constants/routes'
import { getDepositsAction } from '../../../redux/lotsDuck'
import moment from 'moment'

const LomasServiceInvoices = () => {
    const [handleLink, setHandleLink] = useState(false)
    const receipt = ADMIM_INVOICE_RECEIPT
    const columns = [
        {
            title: 'Lote',
            dataIndex: 'lot',
            key: 'lot',
            render: (lot) =>
                lot ? (
                    `Sección ${lot.seccion} Manzana ${lot.manzana} Lote ${lot.lote}`
                ) : (
                    <Tag color="#f0b046">Sin lote asignado</Tag>
                ),
        },
        {
            title: 'Residente',
            dataIndex: 'lot',
            key: 'lot',
            render: (lot) => {
                if (!lot) return null
                const { residents = [] } = lot
                return `${residents[0].firstName} ${residents[0].lastName} ${residents[0].surName}`
            }
        },
        {
            title: 'Fecha ultimo pago',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: date => moment(date).format('ll')
        },
        {
            title: 'Saldo',
            dataIndex: 'currentTotal',
            key: 'currentTotal',
            render: (val) => formatMoney(val, true),
        },
    ]
    // const history = useHistory()
    const [type, setType] = useState()
    const [allChecked, setAllChecked] = useState(false)
    // const [enough, setEnough] = useState([])
    const [objKeys, setObjKeys] = useState({})
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [pop, setPop] = useState(false)

    const [totalToPay, setTotalToPay] = React.useState(0)
    const [selected, setSelected] = React.useState([])

    const dispatch = useDispatch()
    const { grouped, fetching } = useSelector(
        ({ services }) => services,
    )

    const { items, page, pages, limit, total } = useSelector(({ lots }) => lots.deposits)

    const { items: services = [] } = grouped

    React.useEffect(() => {
        // dispatch(getPaginatedServiceInvoicesAction())
        dispatch(getDepositsAction())
    }, [])

    React.useEffect(() => {
        // if (!selected.length) { return }
        // const k = selected.map(lotId => services[lotId].map(item => item._id)).reduce((acc, arr) => [...acc, ...arr], [])
        // const items = k.map(id => Object.values(services).reduce((acc, arr) => [...acc, ...arr], []).find(it => it._id === id))
        const total = selected.reduce((acc, record) => acc + record.amount + record.surcharge, 0)
        setTotalToPay(total)
    }, [selected])

    const changePage = (nextPage) => {
        const paginated = {
            p: nextPage,
            l: limit,
        }
        dispatch(getDepositsAction(paginated))
    }

    const changeGroupedPage = (nextPage) => {
        const paginated = {
            p: nextPage,
            l: limit,
        }
        dispatch(getServicesGroupedAction(type, paginated))
    }

    const groupServices = service => {
        if (!service) { return }
        setAllChecked(false)
        setSelectedRowKeys([])
        setObjKeys({})
        setType(serv => serv === service ? null : service)
        dispatch(getServicesGroupedAction(service))
    }

    const renderNormally = () => (
        <>
            <Table
                loading={fetching}
                columns={columns}
                dataSource={items}
                pagination={false}
            />
            <Pagination
                showSizeChanger={false}
                pageSize={limit || 50}
                current={page}
                onChange={changePage}
                total={total}
                style={{ marginTop: 32 }}
                pages={pages}
            />
        </>
    )

    const combineKeys = (lot, records) => {
        const o = { ...objKeys, [lot]: records }
        setObjKeys(o)
        const flatMap = Object.values(o).flatMap(i => i)
        // return console.log(flatMap)
        setSelectedRowKeys(flatMap.map(item => item._id))
        setSelected(flatMap)
        // if (allChecked) {
        //     return checkAll(false)
        // }
        // const o = { ...objKeys, [lot]: keys }
        // setObjKeys(o)
        // const k = Object.values(o).reduce((acc, items) => [...acc, ...items], [])
        // setSelectedRowKeys(k)
        // const items = k.map(id => Object.values(services).reduce((acc, arr) => [...acc, ...arr], []).find(it => it._id === id))
        const total = flatMap.reduce((acc, item) => acc + item.amount + item.surcharge, 0)
        setTotalToPay(total)
    }

    const onSelectAll = (dataSource) => {
        setSelected(selected => [...new Set([...selected, ...dataSource])])
        // setTotalToPay(total => total + amount)
    }

    const renderGrouped = () => {
        if (fetching) { return <Skeleton active /> }
        if (!services.length) { return <Empty description={`No hay servicios de ${type === 'telefono' ? 'teléfono' : type} por pagar`} /> }
        return (
            <div style={{ flexDirection: 'column', display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                {services.map(group => {
                    return <RenderLotTable
                        onChange={combineKeys}
                        allSelected={allChecked}
                        dataSource={group}
                        onSelectAll={onSelectAll}
                    />
                })}
            </div>
        )

    }
    //     <div style={{ flexDirection: 'column', display: 'flex', flexWrap: 'wrap', gap: 10 }}>
    //         {lots.map(lot => <RenderLotTable
    //             loading={fetching}
    //             onInsuficient={value => setEnough(value)}
    //             allSelected={allChecked}
    //             services={services}
    //             lot={lot}
    //             onChange={combineKeys}
    //             onSelectAll={onSelectAll}
    //         />)}
    //     </div>
    // ) : 


    // // para apgarlo
    const checkAll = (custom) => {
        if (!custom || allChecked) {
            setSelectedRowKeys([])
            setObjKeys({})
            setSelected([])
            setTotalToPay(0)
            setPop(false)
        }
        setAllChecked(check => !check)
    }
    // //


    const payInvoices = () => {
        setPop(false)
        // if (allChecked) {
        // const invoices = selected.map(lotId => services[lotId].map(item => item._id)).reduce((acc, arr) => [...acc, ...arr], [])
        const keys = selected.map(record => record._id)
        dispatch(payMultipleServicesAction(keys))
            .then(result => {
                if (result) {
                    groupServices('luz')
                }
            })
        // } else {
        //     dispatch(payMultipleServicesAction(selectedRowKeys))
        //         .then(result => {
        //             if (result) {
        //                 groupServices('luz')
        //             }
        //         })
        // }

    }


    return (
        <div>
            <div style={{ display: 'flex', gap: 20, margin: '20px 0' }}>
                <Button onClick={() => groupServices('luz')} type={type === 'luz' && 'primary'}>Agrupar Luz</Button>
                <Button onClick={() => groupServices('gas')} type={type === 'gas' && 'primary'}>Agrupar Gas</Button>
                <Button onClick={() => groupServices('telefono')} type={type === 'telefono' && 'primary'}>Agrupar Teléfono</Button>
                <Popconfirm
                    title="¿Estas seguro de marcar como pagadas estas facturas?"
                    visible={pop}
                    onVisibleChange={() => setPop(true)}
                    onConfirm={payInvoices}
                    onCancel={() => setPop(false)}
                    okText="Si, Pagar"
                    cancelText="Cancelar"
                    disabled={selected.length ? false : selectedRowKeys.length ? false : true}
                >
                    <Button
                        style={{ position: 'absolute', right: 100 }}
                        disabled={selected.length ? false : selectedRowKeys.length ? false : true}
                        type={'primary'}>
                        Pagar facturas
                    </Button>
                </Popconfirm>
            </div>

            {!type && renderNormally()}
            {type && <div style={{ margin: '20px 0' }}> Seleccionar todas con saldo: <Switch checked={allChecked} onChange={checkAll} /></div>}
            {type && <h4>Total a pagar: {formatMoney(totalToPay)}</h4>}
            {type && renderGrouped()}
            {type && <Pagination
                showSizeChanger={false}
                pageSize={grouped.limit || 50}
                current={grouped.page}
                onChange={changeGroupedPage}
                total={grouped.total}
                style={{ marginTop: 32 }}
            />}

        </div>
    )
}

export default LomasServiceInvoices
