import React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Select, message } from 'antd';

import { USERS } from '../../../constants/routes';
import { Rules } from '../../../helpers';
import { Card, UserFormActions } from '../index';
import { useDispatch, useSelector } from 'react-redux'
import { saveUserAction, updateUserAction } from '../../../redux/adminDuck'
import cardStyles from '../Card/Card.module.css';

function UserForm({ isCreate }) {
  const dispatch = useDispatch()
  const location = useHistory();
  const { userId = '' } = useParams();
  const [sendMail, setSendMail] = useState(false);
  const { self, userToEdit, fetching } = useSelector(({ user, admin }) => ({
    self: user,
    userToEdit: admin.users.filter(userItem => userItem.id === userId).pop() || {},
    fetching: admin.fetching,
  }));
  const { Option } = Select;

  if (!userToEdit.id && !isCreate) location.push(USERS);

  const sendUserEmail = (values) => {
    dispatch(saveUserAction(values))
      .then(result => result && location.push(USERS))
    setSendMail(true);
    setTimeout(() => setSendMail(false), 4000)

  };

  const updateUser = (values) => {
    const { id, email } = userToEdit;
    dispatch(updateUserAction(id, { ...values, email }))
      .then((result) => result && location.push(USERS))
  };

  return (
    <Card>
      <h4 className={cardStyles.card__title}>Información del usuario</h4>
      <p className={cardStyles.card__subtitle}>
        {isCreate
          ? 'Al agregar un usuario, se enviará una liga a su email para que establezca la contraseña de su cuenta.'
          : 'Recuerda que puedes actualizar todos los datos de un usuario a excepción de su email.'}
      </p>
      <Form
        onFinish={isCreate ? sendUserEmail : updateUser}
        layout="vertical"
        requiredMark={false}
        initialValues={userToEdit}
      >
        <Row gutter={[15, 0]}>
          <Col span={8}>
            <Form.Item label="Nombre:" name="displayName" rules={[Rules.basic]}>
              <Input placeholder="Juan" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Apellido Paterno:"
              name="lastName"
              rules={[Rules.basic]}
            >
              <Input placeholder="López" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Apellido Materno:"
              name="surName"
              rules={[Rules.basic]}
            >
              <Input placeholder="López" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Email:"
          name="email"
          rules={isCreate && [Rules.basic]}
        >
          <Input
            disabled={!isCreate}
            type="email"
            placeholder="ejemplo@lomasdecococyoc.com"
          />
        </Form.Item>
        <Form.Item label="Teléfono:" name="tel" rules={[Rules.basic]}>
          <Input placeholder="55 555 55 55" />
        </Form.Item>
        <Form.Item label="Rol:" name="role" rules={[Rules.basic]}>
          <Select placeholder="Selecciona un rol" >
            <Option value="CAJERO">Cajero</Option>
            <Option value="MANAGER">Manager</Option>
            {self.role === "ADMIN" && <Option value="ADMIN">Administrador</Option>}
          </Select>
        </Form.Item>
        <UserFormActions
          isCreate={isCreate}
          send={sendMail}
          handleSubmit={setSendMail}
          loading={fetching}
        />
      </Form>
    </Card>
  );
}

export default UserForm;

UserForm.defaultProps = {
  isCreate: false,
};

UserForm.propTypes = {
  isCreate: PropTypes.bool,
};
