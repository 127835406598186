import { message } from 'antd';
import { Axios } from '../services/api';

const initialState = {
    lots: [],
    lot: { residents: [], invoices: [] },
    fetching: false,
    deposits: {},
}

const UPDATE_MEASURER = "UPDATE_MEASURER"
const UPDATE_MEASURER_ERROR = "UPDATE_MEASURER_ERROR"
const UPDATE_MEASURER_SUCCESS = "UPDATE_MEASURER_SUCCESS"

const GET_LOTS_BY_DEPOSIT = "GET_LOTS_BY_DEPOSIT"
const GET_LOTS = "GET_LOTS"
const GET_LOTS_SUCCESS = "GET_LOTS_SUCCESS"
const GET_LOTS_ERROR = "GET_LOTS_ERROR"
const GET_LOTS_SUCCESS_PAGINATED = "GET_LOTS_SUCCESS_PAGINATED"

const GET_SINGLE_LOT = "GET_SINGLE_LOT"
const GET_SINGLE_LOT_SUCCESS = "GET_SINGLE_LOT_SUCCESS"
const GET_SINGLE_LOT_ERROR = "GET_SINGLE_LOT_ERROR"
const CLEAR_LOT = "CLEAR_LOT"

const CREATE_LOT = "CREATE_LOT"
const CREATE_LOT_SUCCESS = "CREATE_LOT_SUCCESS"
const CREATE_LOT_ERROR = "CREATE_LOT_ERROR"

const UPDATE_LOT = "UPDATE_LOT"
const UPDATE_LOT_SUCCESS = "UPDATE_LOT_SUCCESS"
const UPDATE_LOT_ERROR = "UPDATE_LOT_ERROR"

const DELETE_LOT = "DELETE_LOT"
const DELETE_LOT_SUCCESS = "DELETE_LOT_SUCCESS"
const DELETE_LOT_ERROR = "DELETE_LOT_ERROR"

const GET_DEPOSITS = "GET_DEPOSITS"
const GET_DEPOSITS_SUCCESS = "GET_DEPOSITS_SUCCESS"
const GET_DEPOSITS_ERROR = "GET_DEPOSITS_ERROR"

const FIND_LOT_BY_SERVICE = "FIND_LOT_BY_SERVICE"
const FIND_LOT_BY_SERVICE_SUCCESS = "FIND_LOT_BY_SERVICE_SUCCESS"
const FIND_LOT_BY_SERVICE_ERROR = "FIND_LOT_BY_SERVICE_ERROR"

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FIND_LOT_BY_SERVICE:
        case GET_DEPOSITS:
        case GET_LOTS_BY_DEPOSIT:
        case UPDATE_MEASURER:
        case GET_LOTS:
        case CREATE_LOT:
        case UPDATE_LOT:
        case DELETE_LOT:
        case GET_SINGLE_LOT:
            return { ...state, fetching: true }

        case FIND_LOT_BY_SERVICE_ERROR:
        case GET_DEPOSITS_ERROR:
        case UPDATE_MEASURER_ERROR:
        case GET_LOTS_ERROR:
        case CREATE_LOT_ERROR:
        case UPDATE_LOT_ERROR:
        case DELETE_LOT_ERROR:
        case GET_SINGLE_LOT_ERROR:
            return { ...state, fetching: false, error: payload }
        case CLEAR_LOT:
            return { ...state, lot: { residents: [] } }

        // case UPDATE_MEASURER_SUCCESS:{

        // }
        case FIND_LOT_BY_SERVICE_SUCCESS:
            return { ...state, fetching: false, lot: payload, error: null }
        case GET_DEPOSITS_SUCCESS:
            return { ...state, deposits: payload, fetching: false }
        case GET_LOTS_SUCCESS_PAGINATED:
            return { ...state, lots: payload.items, fetching: false, ...payload }
        case GET_SINGLE_LOT_SUCCESS:
            return { ...state, lot: payload, fetching: false }
        case GET_LOTS_SUCCESS:
            return { ...state, lots: payload, fetching: false }
        case CREATE_LOT_SUCCESS:
            return { ...state, lots: [payload, ...state.lots], fetching: false }
        case UPDATE_LOT_SUCCESS:
            return { ...state, lots: state.lots.map(lot => lot._id === payload._id ? payload : lot), fetching: false }
        case DELETE_LOT_SUCCESS:
            return { ...state, lots: state.lots.filter(lot => lot._id !== payload._id), fetching: false }
        default:
            return { ...state }
    }
}

// measurer
export const updateMeasurer = (measurer) => (dispatch, getState) => {
    dispatch({ type: UPDATE_MEASURER })
    const { token } = getState().user
    const { lot } = getState().lots
    return Axios.patch(`/lots/measurer/${measurer._id}`, measurer, { headers: { Authorization: token } })
        .then(() => {
            dispatch({ type: UPDATE_MEASURER_SUCCESS })
            dispatch(getLotAction(lot))
            message.success("Medidor actualizado con éxito")
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: UPDATE_MEASURER_ERROR, payload: e })
            message.error(e.response?.data?.message)
        })
}

//clear Lot
export const clearLotAction = () => (dispatch) => {
    dispatch({ type: CLEAR_LOT })
}

// getItems
export const getDepositsAction = (paginated) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: GET_DEPOSITS })
    return Axios.get(`/lots/deposits`,

        {
            headers: { Authorization: token },
            params: {
                p: paginated && paginated.p ? paginated.p : 1,
                l: paginated && paginated.l ? paginated.l : 50,
                q: paginated && paginated.q ? paginated.q : null
            },
        })
        .then(res => {
            if (res.data.items) return dispatch({ type: GET_DEPOSITS_SUCCESS, payload: res.data })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: GET_DEPOSITS_ERROR, payload: e })
            message.error(e.response?.data?.message)
        })
}

export const getLotsByDepositAction = (paginated) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: GET_LOTS })
    return Axios.post(`/lots/byDeposit`,
        {
            p: paginated && paginated.p ? paginated.p : 1,
            l: paginated && paginated.l ? paginated.l : 50,
            q: paginated && paginated.q ? paginated.q : null
        },
        {
            headers: { Authorization: token },
        })
        .then(res => {
            if (res.data.items) return dispatch({ type: GET_LOTS_SUCCESS_PAGINATED, payload: res.data })
            dispatch({ type: GET_LOTS_SUCCESS, payload: res.data })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: GET_LOTS_ERROR, payload: e })
            message.error(e.response?.data?.message)
        })
}

export const getLotsAction = (paginated) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: GET_LOTS })
    return Axios.get(`/lots`, {
        headers: { Authorization: token },
        params: {
            p: paginated && paginated.p ? paginated.p : 1,
            l: paginated && paginated.l ? paginated.l : 50,
            q: paginated && paginated.q ? paginated.q : null
        }
    })
        .then(res => {
            if (res.data.items) return dispatch({ type: GET_LOTS_SUCCESS_PAGINATED, payload: res.data })
            dispatch({ type: GET_LOTS_SUCCESS, payload: res.data })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: GET_LOTS_ERROR, payload: e })
            message.error(e.response?.data?.message)
        })
}

export const getLotAction = (lot) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: GET_SINGLE_LOT })
    return Axios.get('/lots/' + lot.id, { headers: { Authorization: token } })
        .then(({ data }) => {
            dispatch({ type: GET_SINGLE_LOT_SUCCESS, payload: data })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: GET_SINGLE_LOT_ERROR, payload: e })
            message.error(e.response ? e.response.data : "Algo malo sucedió")
        })
}

// CreateItem
export const createLotAction = (lot) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: CREATE_LOT })
    return Axios.post('/lots', lot, { headers: { Authorization: token } })
        .then(res => {
            dispatch({ type: CREATE_LOT_SUCCESS, payload: res.data })
            message.success("El lote se ha creado con éxito")
            // dispatch(getLotsAction()(dispatch, getState))
            return true
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: CREATE_LOT_ERROR, payload: e })
            message.error(`${e.response?.data?.message} Medidor: ${e.response?.data?.number}`)
            return false
        })
}

// updateItem
export const updateLotAction = (lot) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: UPDATE_LOT })
    return Axios.patch(`/lots/${lot.id}`, lot, { headers: { Authorization: token } })
        .then(res => {
            dispatch({ type: UPDATE_LOT_SUCCESS, payload: res.data })
            getLotAction(lot)(dispatch, getState)
            message.success("El lote se actualizó con éxito", 0)
            return true
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: UPDATE_LOT_ERROR, payload: e })
            message.error(`${e.response.data.message} Medidor: ${e.response.data.number}`, 10)
            return false
        })
}
// deleteItem
export const deleteLotAction = (lot) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: DELETE_LOT })
    return Axios.patch(`/lots/${lot.id}`, { headers: { Authorization: token } })
        .then(res => {
            dispatch({ type: DELETE_LOT_SUCCESS, payload: res.data })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: DELETE_LOT_ERROR, payload: e })
            message.error(e.response.data)
        })
}

// findLotByService
export const findLotByServiceAction = ({ service, number }) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: FIND_LOT_BY_SERVICE })
    return Axios.get(`/lots/find/byService`, {
        headers: { Authorization: token },
        params: { service, number }
    })
        .then(res => {
            dispatch({ type: FIND_LOT_BY_SERVICE_SUCCESS, payload: res.data })
            return res.data
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: FIND_LOT_BY_SERVICE_ERROR, payload: "Servicio sin lote asignado" })
            // message.error("Servicio sin lote asignado")
            // return -1
        })
}
