import { Axios } from '../services/api';
import { getLotsAction } from './lotsDuck';
import { getresidentsAction } from './residentsDuck';

// Constants
const initialState = {
  loggedIn: false,
  loading: false,
  photoURL: '',
  success: false,
  role: "CAJERO",
  stats: {},
  generalStats:{},
};

const GET_STATS = "GET_STATS"
const GET_GENERAL_STATS = "GET_GENERAL_STATS"
const GET_GENERAL_STATS_SUCCESS = "GET_GENERAL_STATS_SUCCESS"
const GET_STATS_SUCCESS = "GET_STATS_SUCCESS"
const GET_STATS_ERROR = "GET_STATS_ERROR"

const SIGN_IN = 'SIGN_IN';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_STATUS = 'CHANGE_PASSWORD_STATUS';
const CHANGE_PASSWORD_RESET_STATUS = 'CHANGE_PASSWORD_RESET_STATUS';

const UPDATE_PROFILE_PHOTO = 'UPDATE_PROFILE_PHOTO';
const UPDATE_PROFILE_PHOTO_SUCCESS = 'UPDATE_PROFILE_PHOTO_SUCCESS';
const UPDATE_PROFILE_PHOTO_ERROR = 'UPDATE_PROFILE_PHOTO_ERROR';
const UPDATE_PROFILE_PHOTO_FINISH = 'UPDATE_PROFILE_PHOTO_FINISH';

const SET_IS_ADMIN = 'SET_IS_ADMIN';

const LOG_OUT = 'LOG_OUT';

// Reducer
function Reducer(state = initialState, { type, payload }) {
  switch (type) {

    case GET_STATS_SUCCESS:
      return { ...state, loading: false, stats: payload };
    case GET_GENERAL_STATS_SUCCESS:
      return { ...state, loading: false, generalStats: payload };



    case GET_GENERAL_STATS:
    case GET_STATS:
    case SIGN_IN:
      return { ...state, loading: true };
    
    case LOG_OUT:
      return { ...initialState };

    case GET_STATS_ERROR:
    case SIGN_IN_ERROR:
      return { ...state, loading: false, error: payload };
    case SIGN_IN_SUCCESS:
      return { ...state, loading: false, ...payload, loggedIn: true };
    case CHANGE_PASSWORD:
      return { ...state, loading: true };
    case CHANGE_PASSWORD_STATUS:
      return { ...state, loading: false, status: payload };
    case CHANGE_PASSWORD_RESET_STATUS:
      return { ...state, status: payload };
    case UPDATE_PROFILE_PHOTO:
      return { ...state, loading: true, success: false };
    case UPDATE_PROFILE_PHOTO_SUCCESS:
      return { ...state, loading: false, photoURL: payload, success: true };
    case UPDATE_PROFILE_PHOTO_ERROR:
      return { ...state, loading: false, error: payload, success: false };
    case UPDATE_PROFILE_PHOTO_FINISH:
      return { ...state, error: undefined, success: false };
    case SET_IS_ADMIN:
      return { ...state, isAdmin: payload };
    default:
      return state;
  }
}

export default Reducer;

// Auxiliar actions
const saveSessionStorage = (storage) => {
  localStorage.user = JSON.stringify(storage);
};

export const restoreSessionAction = () => (dispatch) => {
  let storage = localStorage.getItem('user');
  storage = JSON.parse(storage);
  if (storage && storage.user) {
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: storage.user,
    });
  }
};

// Main actions

export const getGeneralStatsAction = () => (dispatch, getState) => {
  dispatch({type: GET_GENERAL_STATS})
  const { token } = getState().user
  return Axios.get(`/users/stats`, { headers: { Authorization: token } })
    .then(res => {
      dispatch({ type: GET_STATS_SUCCESS, payload: res.data })
    })
    .catch(e => {
      console.log(e)
      dispatch({ type: GET_STATS_ERROR, payload: e })
      // message.error(e.response?.data?.message)
    })
}

export const getStatsByUserAction = (userId) => (dispatch, getState) => {
  dispatch({type: GET_STATS})
  const id = userId || getState().user.id
  const { token } = getState().user
  return Axios.get(`/users/${id}/stats`, { headers: { Authorization: token } })
    .then(res => {
      dispatch({ type: GET_STATS_SUCCESS, payload: res.data })
    })
    .catch(e => {
      console.log(e)
      dispatch({ type: GET_STATS_ERROR, payload: e })
      // message.error(e.response?.data?.message)
    })
}

export const logoutAction = () => (dispatch) => {
  dispatch({ type: LOG_OUT });
  localStorage.removeItem('user');
};

export const loginAction = (email, password) => async (dispatch, getState) => {
  dispatch({ type: SIGN_IN });
  try {
    const { data } = await Axios.post('/auth/login', { email, password });
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: {
        id: data.user.id,
        email: data.user.email,
        displayName: data.user.username,
        role: data.user.role,
        photoURL: data.user.photoURL,
        token: data.token,
      },
    });
    saveSessionStorage(getState());
  } catch (error) {
    dispatch({
      type: SIGN_IN_ERROR,
      payload: {
        validateStatus: 'error',
        help: 'El email o contraseña son incorrectos.',
      },
    });
  }
};

export const changePasswordAction = (password) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: CHANGE_PASSWORD });
  try {
    const { token, email } = getState().user;
    const { data } = await Axios.post(
      '/auth/change-password',
      { email, password },
      { headers: { Authorization: token } },
    );
    console.log(data);
    dispatch({
      type: CHANGE_PASSWORD_STATUS,
      payload: {
        type: 'success',
        message: 'Contraseña actualizada.',
      },
    });
  } catch (error) {
    dispatch({
      type: CHANGE_PASSWORD_STATUS,
      payload: {
        type: 'error',
        message: 'No se pudo actualizar la contraseña.',
      },
    });
  }
};

export const changePasswordResetStatusAction = () => async (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_RESET_STATUS,
    payload: {
      type: '',
    },
  });
};

export const updateProfilePhotoAction = (photo) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: UPDATE_PROFILE_PHOTO });
  try {
    const { token } = getState().user;
    console.warn(photo);
    let formData = new FormData();
    formData.append('photo', photo);

    const { data } = await Axios.patch('/auth/self', formData, {
      headers: { Authorization: token, 'Content-Type': 'multipart/form-data' },
    });
    dispatch({
      type: UPDATE_PROFILE_PHOTO_SUCCESS,
      payload: data.photoURL,
    });
    saveSessionStorage(getState());
  } catch (error) {
    console.error(error);
    dispatch({
      type: UPDATE_PROFILE_PHOTO_ERROR,
      payload: 'No se pudo actualizar la foto.',
    });
  }
};

export const finishPhotoUpdateAction = () => (dispatch, getState) => {
  dispatch({ type: UPDATE_PROFILE_PHOTO_FINISH });
  saveSessionStorage(getState());
};

export const setIsAdminAction = (isAdmin) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_ADMIN,
    payload: isAdmin,
  });
  saveSessionStorage(getState());
  if (isAdmin) {
    dispatch(getLotsAction())
    dispatch(getresidentsAction())
  }
};
