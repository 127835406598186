import { message, notification } from 'antd'
import axios from 'axios'
import { Axios } from '../services/api'

const initialState = {
  items: [1, 2, 3],
  fetching: false,
  agreement: {},
}

const GET_ONE_AGREEMENT = 'GET_ONE_AGREEMENT'
const GET_ONE_AGREEMENT_ERROR = 'GET_ONE_AGREEMENT_ERROR'
const GET_ONE_AGREEMENT_SUCCESS = 'GET_ONE_AGREEMENT_SUCCESS'

const CREATE_AGREEMENT = 'CREATE_AGREEMENT'
const CREATE_AGREEMENT_ERROR = 'CREATE_AGREEMENT_ERROR'
const CREATE_AGREEMENT_SUCCESS = 'CREATE_AGREEMENT_SUCCESS'

const GET_AGREEMENTS = 'GET_AGREEMENTS'
const GET_AGREEMENTS_ERROR = 'GET_AGREEMENTS_ERROR'
const GET_AGREEMENTS_SUCCESS = 'GET_AGREEMENTS_SUCCESS'

const DO_ONE_MONTHLY_PAYMENT = 'DO_ONE_MONTHLY_PAYMENT'
const DO_ONE_MONTHLY_PAYMENT_ERROR = 'DO_ONE_MONTHLY_PAYMENT_ERROR'
const DO_ONE_MONTHLY_PAYMENT_SUCCESS = 'DO_ONE_MONTHLY_PAYMENT_SUCCESS'

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_ONE_AGREEMENT_SUCCESS:
      return { ...state, fetching: false, agreement: payload }
    case CREATE_AGREEMENT_SUCCESS:
      return { ...state, fetching: false, items: [payload, ...state.items] }
    case GET_AGREEMENTS_SUCCESS:
      return { ...state, fetching: false, ...payload, items: payload.items }

    case DO_ONE_MONTHLY_PAYMENT_SUCCESS:
    case DO_ONE_MONTHLY_PAYMENT:
    case GET_ONE_AGREEMENT:
    case CREATE_AGREEMENT:
    case GET_AGREEMENTS:
      return { ...state, fetching: true }
    case DO_ONE_MONTHLY_PAYMENT_ERROR:
    case GET_ONE_AGREEMENT_ERROR:
    case CREATE_AGREEMENT_ERROR:
    case GET_AGREEMENTS_ERROR:
      return { ...state, fetching: false, error: payload }
    default:
      return state
  }
}

// thunks

export const doOneMontlhyPaymentAction = (receipt) => (dispatch, getState) => {
  dispatch({ type: DO_ONE_MONTHLY_PAYMENT })
  const { token } = getState().user
  return Axios.post(`/agreements/${receipt.agreement}/pay`, receipt, { headers: { Authorization: token } })
    .then(({ data }) => {
      dispatch({ type: DO_ONE_MONTHLY_PAYMENT_SUCCESS })
      getOneAgreementAction(receipt.agreement)(dispatch, getState)
      return data
    })
    .catch((err) => {
      console.log(err.response)
      dispatch({ type: DO_ONE_MONTHLY_PAYMENT_ERROR, payload: err })
      notification.error({
        message: 'No se encontró el acuerdo',
      })
      return false
    })
}

export const getOneAgreementAction = (id) => (dispatch, getState) => {
  dispatch({ type: GET_ONE_AGREEMENT })
  const { token } = getState().user
  return Axios.get(`/agreements/${id}`, { headers: { Authorization: token } })
    .then(({ data }) => {
      dispatch({ type: GET_ONE_AGREEMENT_SUCCESS, payload: { ...data } })
      return data
    })
    .catch((err) => {
      console.log(err.response)
      dispatch({ type: GET_ONE_AGREEMENT_ERROR, payload: err })
      notification.error({
        message: 'No se encontró el acuerdo',
      })
      return false
    })
}

export const createNewAgreementAction = (body) => (dispatch, getState) => {
  dispatch({ type: CREATE_AGREEMENT })
  const { token } = getState().user
  return Axios.post('/agreements', body, { headers: { Authorization: token } })
    .then(({ data }) => {
      dispatch({ type: CREATE_AGREEMENT_SUCCESS, payload: { ...data } })
      notification.success({
        message: 'Convenio creado con éxito',
        description: `El convenio con folio ${data.folio} Se ha creado correctamente`,
      })
      return true
    })
    .catch((err) => {
      console.log(err.response)
      dispatch({ type: CREATE_AGREEMENT_ERROR, payload: err })
      notification.error({
        message: 'Validación de datos erronea',
        description: err.response?.data,
      })
      return false
    })
}

export const getAgreementsAction = (q, subsidiary) => (dispatch, getState) => {
  dispatch({ type: GET_AGREEMENTS })
  const { token } = getState().user
  return Axios.get('/agreements', {
    headers: { Authorization: token },
    params: {
      q: q || null,
      subsidiary,
    },
  })
    .then(({ data }) => {
      dispatch({ type: GET_AGREEMENTS_SUCCESS, payload: { ...data } })
      return true
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GET_AGREEMENTS_ERROR, payload: err })
      notification.error(err.response?.data)
    })
}
