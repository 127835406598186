import React from 'react'
import { Tag } from 'antd'
import { formatMoney } from '../../../helpers/formatMoney'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default {
  systemsColumns: [
    {
      title: 'Número de medidor',
      dataIndex: 'measurerNumber',
      key: 'measurerNumber',
    },
    {
      title: 'Bimestre',
      dataIndex: 'twomonths',
      key: 'twomonths',
    },
    {
      title: 'Año',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Importe',
      dataIndex: 'amount',
      key: 'amount',
      render: (val) => formatMoney(val),
    },
    {
      title: 'Recargo',
      dataIndex: 'surcharge',
      key: 'surcharge',
      render: (val) => formatMoney(val),
    },
    {
      title: 'Estado',
      dataIndex: 'measurerNumber',
      key: 'measurerNumber',
      render: (val, record) =>
        !val ? (
          <Tag color="red">El medidor no existe</Tag>
        ) : record.paid ? (
          <Tag color="#95bf48">Pagada</Tag>
        ) : (
          <Tag color="#f0b046">Pendiente</Tag>
        ),
    },
    {
      title: 'Recibo',
      dataIndex: 'receipts',
      key: 'receipts',
      render: (arr) => {
        console.log(arr)
        return (
          arr.length < 1 ? null : arr.map(rec => <><br /><Link to={`/admin/recibos/${rec.id}`} style={{ color: '#2D9CDB' }}>
            {rec.folio}
          </Link></>)
        )
      }
    },
  ],
  fomentColumns: [
    {
      title: 'Factura',
      dataIndex: 'folio',
      key: 'folio',
    },
    {
      title: 'Código',
      dataIndex: 'serviceCode', // add this value, is missing rightnow
      key: 'serviceCode',
    },
    {
      title: 'Año',
      dataIndex: 'year',
      key: 'year',
    },
    // {
    //   title: 'Fecha de pago',
    //   dataIndex: 'paymentDate',
    //   key: 'paymentDate', // depends on receips
    // },
    {
      title: '#Recibo', // add this value is missing now
      dataIndex: 'receipts',
      key: 'receipts',
      render: (array) =>
        array?.length ? array.map((item) => item.folio) : null,
    },
    {
      title: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      align: 'right',
      render: (_, { before1500, after1500, clusterAmount }) =>
        formatMoney(
          Number(before1500) + Number(after1500) + Number(clusterAmount),
        ),
    },
    {
      title: 'Estatus', // this needs more logic from other fields change it.
      dataIndex: 'paid',
      key: 'paid',
      render: (value) =>
        value ? (
          <Tag color="#95bf48">Pagado</Tag>
        ) : (
          <Tag color="#F0B046">Pendiente</Tag>
        ),
    },
  ],
  lomasColumns: [
    {
      title: 'Recibo',
      dataIndex: 'folio',
      key: 'folio',
      render: (val, rec) => (
        <Link to={`/admin/recibos/${rec.id}`} style={{ color: '#2D9CDB' }}>
          {val}
        </Link>
      ),
    },
    { title: 'Tipo de recibo', dataIndex: 'tipo', key: 'tipo' },
    {
      title: 'Fecha de pago',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (val) => moment(val).format('ll'),
    },
    {
      title: 'Formas de pago',
      dataIndex: 'paymentForm',
      key: 'paymentForm',
      render: (value) =>
        value.map((val) => `${val.tipo} : ${formatMoney(val.amount)}`),
    },
    {
      title: 'Descuento',
      dataIndex: 'discountQuantity',
      key: 'discountQuantity',
      render: (val) => formatMoney(val),
    },
    {
      title: 'Pagado',
      dataIndex: 'totalPaid',
      key: 'totalPaid',
      render: (val) => formatMoney(val),
    },
  ],
}
