import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import Card from '../Card/Card';

import { Button } from 'antd';

import styles from './InvoiceType.module.css';

function InvoiceType({
  title, description, asset,
  downlable, buttonTitle, onButtonClick
}) {
  return (
    <Card className={styles.invoicetype}>
      <h3>
        { title }
      </h3>
      <p>
        { description }
      </p>
      <div className={styles.asset}>
        { asset }
      </div>
      {downlable && (
        <a href={require('../../../assets/docs/cocoyoc_invoice_template.xlsx')}>
          <Button
            className={styles.download}
            icon={<FontAwesomeIcon icon={faDownload} style={{ marginRight: 16 }} />}
            type="text"
          >
            Descargar platilla
          </Button>
        </a>
      )}
      <Button className={styles.button} type="primary" onClick={() => onButtonClick ? onButtonClick() : null}>
        { buttonTitle }
      </Button>
    </Card>
  );
}

export default InvoiceType;
