import React from 'react';
import { Table } from 'antd';

import { Seeker, Card, TableCustomFooter } from '../../index';

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Lote',
    dataIndex: 'lote',
    key: 'lote',
  },
  {
    title: 'Medidor',
    dataIndex: 'medidor',
    key: 'medidor',
  },
  {
    title: 'Residente',
    dataIndex: 'residente',
    key: 'residente',
	},
	{
    title: '% Part.',
    dataIndex: 'porcentaje',
    key: 'porcentaje',
  },
  {
    title: 'Superficie',
    dataIndex: 'superficie',
    key: 'superficie',
  },
  {
    title: 'Clúster',
    dataIndex: 'cluster',
    key: 'cluster',
  },
  {
    title: 'Adeudo total',
    dataIndex: 'adeudo',
    key: 'adeudo',
  },
  {
    title: 'Estatus',
    dataIndex: 'estatus',
    key: 'estatus',
    render: (text) => <span className="text-green">{text}</span>,
  },
];

const data = [
  {
    id: '1',
    lote: 'Sec. 1 Lt. 4 Mz. 6',
    medidor: '10909090',
		residente: 'Jose Luis Enriquez',
		porcentaje: '20%',
    superficie: '2.000 m2',
    cluster: '2.000 m2',
    adeudo: '$0.00',
    estatus: 'Al corriente',
  },
];

function Lotes() {
  return (
    <>
      <Seeker />
      <Card>
        <Table columns={columns} dataSource={data} pagination={false} />
        <TableCustomFooter total={data.length} totalAmount="$1,140,000.00" />
      </Card>
    </>
  );
}

export default Lotes;
