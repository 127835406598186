import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, Button, Typography, notification } from 'antd';
import { Link, useHistory, Redirect } from 'react-router-dom';

import { SplitScreen } from '../Common';
import { recoveryPassword } from '../../services/authServices';
import { Rules } from '../../helpers';
import { CASHIER, SIGN_IN } from '../../constants/routes';

import styles from './ForgotPassword.module.css';

function ForgotPassword() {
  const { loggedIn } = useSelector(({ user }) => user);
  const history = useHistory();
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');

  const { Text } = Typography;

  const handleSubmit = (event) => {
    if (email)
      recoveryPassword(email).then(() => {
        notification.open({
          message: 'Contraseña provisional',
          description:
            'Te hemos enviado un correo electrónico con una contraseña provisional, no olvides cambiarla al entrar.',
        });
        setEmail('');
        history.push('signin');
      });
  };

  if (loggedIn) return <Redirect to={CASHIER} />;

  return (
    <SplitScreen>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className={styles.form}
        onSubmitCapture={handleSubmit}
      >
        <p className={styles.form__title}>¿Olvidaste tu contraseña?</p>
        <p className={styles.form__subtitle}>
          Ingresa tu email y recibirás un email para reestablecer tu contraseña.
        </p>

        <Form.Item
          label="Email"
          name="email"
          rules={[Rules.email]}
          className={`${styles.form__group} c_label`}
        >
          <Input
            className={styles.form__input}
            placeholder="correo@dominio.com"
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
          />
        </Form.Item>

        <div className={styles.form__group}>
          <Button
            htmlType="submit"
            shape="round"
            type="primary"
            className={styles.form__button}
          >
            Enviar
          </Button>
        </div>

        <div className={styles.form__group}>
          <Text className={styles.form__text}>
            ¿Tienes una cuenta?{' '}
            <Link to={SIGN_IN} className={styles.form__link}>
              Inicia Sesión
            </Link>
          </Text>
        </div>
      </Form>
    </SplitScreen>
  );
}

export default ForgotPassword;
