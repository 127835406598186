import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

import styles from './BackButton.module.css';

const BackButton = () => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.back__button} onClick={handleBack}>
      <FontAwesomeIcon className={styles.back__button__icon} icon={faLongArrowAltLeft} />
      <span className={styles.back__button__text}>Volver</span>
    </div>
  );
};

export default BackButton;
