import React from 'react';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillAlt,
  faPollH,
  faWarehouse,
  faUserCircle,
  faUserCog,
  faPowerOff,
  faUserFriends,
  faHandshake,
} from '@fortawesome/free-solid-svg-icons';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Logo } from '../index';
import {
  CASHIER,
  INVOICES,
  BATCHES,
  RESIDENTS,
  USERS,
  PROFILE,
  ADMIN_SUMMARY,
  ADMIN_BATCHES,
  ADMIN_PROFILE,
  ADMIN_INVOICES_GENERATE,
  CONVENANTS,
} from '../../../constants/routes';

import { logoutAction } from '../../../redux/user.duck';
import styles from './Sidebar.module.css';

function SideBar() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAdmin, role } = useSelector(({ user }) => user);
  const type = role.toLowerCase();

  const { SubMenu } = Menu;

  const activedMenuOption = location.pathname.split('/').pop();
  const invoicesOptions = {
    generar: 'f1',
    facturas: 'f2'
  }

  const handleLink = (routeName) => {
    console.log('ROUTE NAME', routeName);
    const routeDestructure = routeName.split('/');
    const prefixedRoute = routeDestructure.splice(1);
    prefixedRoute[0] = isAdmin ? 'admin' : 'cajero';

    history.push('/' + prefixedRoute.join('/'));
  };

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar__logo}>
        <Logo color="#ffffff" />
      </div>
      <Menu
        className={styles.sidemenu}
        mode="inline"
        defaultSelectedKeys={
          location.pathname.includes('facturas')
            ? [invoicesOptions[activedMenuOption]]
            : [activedMenuOption]
        }
        defaultOpenKeys={location.pathname.includes('facturas') ? ['facturas'] : []}
      >
        {(role === 'ADMIN' || role === 'MANAGER') && (
          <Menu.Item
            key="resumen"
            icon={
              <FontAwesomeIcon
                icon={faMoneyBillAlt}
                className={styles.sidemenu__icon}
              />
            }
            onClick={() => handleLink(ADMIN_SUMMARY)}
          >
            Resumen
          </Menu.Item>
        )}
        <Menu.Item
          key="caja"
          icon={
            <FontAwesomeIcon
              icon={faMoneyBillAlt}
              className={styles.sidemenu__icon}
            />
          }
          onClick={() => handleLink(CASHIER)}
        >
          Caja
        </Menu.Item>
        <SubMenu
          title="Facturas"
          key="facturas"
          icon={
            <FontAwesomeIcon icon={faPollH} className={styles.sidemenu__icon} />
          }
        >
          <Menu.Item
            key="1"
            onClick={() => handleLink(ADMIN_INVOICES_GENERATE)}
          >
            Generar Facturas
          </Menu.Item>
          <Menu.Item
            key="f2"
            onClick={() => handleLink(INVOICES)}
          >
            Historial de Facturas
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="convenios"
          icon={
            <FontAwesomeIcon
              icon={faHandshake}
              className={styles.sidemenu__icon}
            />
          }
          onClick={() => handleLink(CONVENANTS)}
        >
          Convenios
        </Menu.Item>
        <Menu.Item
          key="lotes"
          icon={
            <FontAwesomeIcon
              icon={faWarehouse}
              className={styles.sidemenu__icon}
            />
          }
          onClick={() => handleLink(isAdmin ? ADMIN_BATCHES : BATCHES)}
        >
          Lotes
        </Menu.Item>
        <Menu.Item
          key="residentes"
          icon={
            <FontAwesomeIcon
              icon={faUserCircle}
              className={styles.sidemenu__icon}
            />
          }
          onClick={() => history.push(`/${role.toLowerCase()}${RESIDENTS}`)}
        >
          Residentes
        </Menu.Item>
        <Menu.Item
          key="perfil"
          icon={
            <FontAwesomeIcon
              icon={faUserCog}
              className={styles.sidemenu__icon}
            />
          }
          onClick={() => handleLink(isAdmin ? ADMIN_PROFILE : PROFILE)}
        >
          Mi perfil
        </Menu.Item>
        {type === 'admin' && (
          <Menu.Item
            key="usuarios"
            icon={
              <FontAwesomeIcon
                icon={faUserFriends}
                className={styles.sidemenu__icon}
              />
            }
            onClick={() => handleLink(USERS)}
          >
            Usuarios
          </Menu.Item>
        )}
        <Menu.Item
          style={{ marginTop: 162 }}
          key="logout"
          icon={
            <FontAwesomeIcon
              icon={faPowerOff}
              className={styles.sidemenu__icon}
            />
          }
          onClick={handleLogout}
        >
          Cerrar sesión
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default SideBar;

SideBar.defaultProps = {
  type: 'cajero',
};
