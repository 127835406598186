import { message } from 'antd'
import axios from 'axios'

const url = process.env.REACT_APP_BACKEND_ROUTE

const initialState = {
    todayServices: [],
    fetching: false,
    items: [],
    grouped: { items: [] }
}

const SAVE_SERVICE_INVOICE = 'SAVE_SERVICE_INVOICE'
const SAVE_SERVICE_INVOICE_ERROR = 'SAVE_SERVICE_INVOICE_ERROR'
const SAVE_SERVICE_INVOICE_SUCCESS = 'SAVE_SERVICE_INVOICE_SUCCESS'

const GET_SERVICE_INVOICE = 'GET_SERVICE_INVOICE'
const GET_SERVICE_INVOICE_ERROR = 'GET_SERVICE_INVOICE_ERROR'
const GET_SERVICE_INVOICE_SUCCESS = 'GET_SERVICE_INVOICE_SUCCESS'

const GET_SERVICE_INVOICE_PAGINATED = 'GET_SERVICE_INVOICE_PAGINATED'
const GET_SERVICE_INVOICE_PAGINATED_ERROR =
    'GET_SERVICE_INVOICE_PAGINATED_ERROR'
const GET_SERVICE_INVOICE_PAGINATED_SUCCESS =
    'GET_SERVICE_INVOICE_PAGINATED_SUCCESS'

const DELETE_SERVICE_INVOICE = 'DELETE_SERVICE_INVOICE'
const DELETE_SERVICE_INVOICE_ERROR = 'DELETE_SERVICE_INVOICE_ERROR'
const DELETE_SERVICE_INVOICE_SUCCESS = 'DELETE_SERVICE_INVOICE_SUCCESS'

const GET_SERVICES_GROUPED = 'GET_SERVICES_GROUPED'
const GET_SERVICES_GROUPED_ERROR = 'GET_SERVICES_GROUPED_ERROR'
const GET_SERVICES_GROUPED_SUCCESS = 'GET_SERVICES_GROUPED_SUCCESS'

const PAY_MULTIPLE_SERVICES = 'PAY_MULTIPLE_SERVICES'
const PAY_MULTIPLE_SERVICES_ERROR = 'PAY_MULTIPLE_SERVICES_ERROR'
const PAY_MULTIPLE_SERVICES_SUCCESS = 'PAY_MULTIPLE_SERVICES_SUCCESS'

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case PAY_MULTIPLE_SERVICES_SUCCESS:
            return { ...state, fetching: false, }
        case GET_SERVICE_INVOICE_PAGINATED_SUCCESS:
            return { ...state, fetching: false, ...payload }

        case GET_SERVICES_GROUPED_SUCCESS:
            return { ...state, fetching: false, grouped: payload }

        case GET_SERVICE_INVOICE_SUCCESS:
        case SAVE_SERVICE_INVOICE_SUCCESS:
            return { ...state, fetching: false, todayServices: payload }

        case PAY_MULTIPLE_SERVICES_ERROR:
        case DELETE_SERVICE_INVOICE_ERROR:
        case GET_SERVICE_INVOICE_PAGINATED_ERROR:
        case GET_SERVICE_INVOICE_ERROR:
        case DELETE_SERVICE_INVOICE_ERROR:
        case SAVE_SERVICE_INVOICE_ERROR:
            return { ...state, fetching: false, error: payload }

        case PAY_MULTIPLE_SERVICES:
        case GET_SERVICES_GROUPED:
        case DELETE_SERVICE_INVOICE:
        case DELETE_SERVICE_INVOICE_SUCCESS:
        case GET_SERVICE_INVOICE:
        case DELETE_SERVICE_INVOICE:
        case SAVE_SERVICE_INVOICE:
            return { ...state, fetching: true }

        default:
            return { ...state }
    }
}

// thunks

export const payMultipleServicesAction = (services) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: PAY_MULTIPLE_SERVICES })
    return axios
        .post(`${url}/services/paymultiple`, { services }, {
            headers: { Authorization: token },
        })
        .then(() => {
            dispatch({ type: PAY_MULTIPLE_SERVICES_SUCCESS })
            message.success('Se han pagado las facturas exitosamente')
            return true
        })
        .catch((e) => {
            console.error(e)
            dispatch({ type: PAY_MULTIPLE_SERVICES_ERROR, payload: e })
            message.error('Ocurrió un error en el servidor')
        })
}

export const getServicesGroupedAction = (service, paginated = {}) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: GET_SERVICES_GROUPED })
    return axios
        .get(`${url}/services/grouped`, {
            headers: { Authorization: token },
            params: {
                service,
                p: paginated.p ? paginated.p : 1,
                l: paginated.l ? paginated.l : 50,

            },
        })
        .then(({ data }) => {
            dispatch({ type: GET_SERVICES_GROUPED_SUCCESS, payload: data })
            return true
        })
        .catch((e) => {
            console.error(e)
            dispatch({ type: GET_SERVICES_GROUPED_ERROR, payload: e })
            message.error('Ocurrió un error en el servidor')
        })
}

export const getPaginatedServiceInvoicesAction = (paginated = {}) => (
    dispatch,
    getState,
) => {
    const { token } = getState().user
    dispatch({ type: GET_SERVICE_INVOICE_PAGINATED })
    return axios
        .get(`${url}/services/invoices/paginated`, {
            headers: { Authorization: token },
            params: {
                p: paginated.p ? paginated.p : 1,
                l: paginated.l ? paginated.l : 50,
            },
        })
        .then(({ data }) => {
            dispatch({ type: GET_SERVICE_INVOICE_PAGINATED_SUCCESS, payload: data })
            return true
        })
        .catch((e) => {
            console.error(e)
            dispatch({ type: GET_SERVICE_INVOICE_PAGINATED_ERROR, payload: e })
            message.error('Ocurrió un error en el servidor')
        })
}

export const deleteServiceInvoiceAction = (id) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: DELETE_SERVICE_INVOICE })
    return axios
        .delete(`${url}/services/invoices/${id}`, {
            headers: { Authorization: token },
        })
        .then(({ data }) => {
            dispatch({ type: DELETE_SERVICE_INVOICE_SUCCESS, payload: data })
            message.info('Se ha eliminado la factura')
            getTodayServiceInvoicesAction()(dispatch, getState)
            return true
        })
        .catch((e) => {
            console.error(e)
            dispatch({ type: DELETE_SERVICE_INVOICE_ERROR, payload: e })
            message.error('Ocurrió un error en el servidor')
        })
}

export const getTodayServiceInvoicesAction = () => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: GET_SERVICE_INVOICE })
    return axios
        .get(`${url}/services/invoices/today`, {
            headers: { Authorization: token },
        })
        .then(({ data }) => {
            dispatch({ type: GET_SERVICE_INVOICE_SUCCESS, payload: data })
            return true
        })
        .catch((e) => {
            console.error(e)
            dispatch({ type: GET_SERVICE_INVOICE_ERROR, payload: e })
            message.error('Ocurrió un error en el servidor')
        })
}

export const saveServiceInvoiceAction = (invoice) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: SAVE_SERVICE_INVOICE })
    return axios
        .post(`${url}/services/invoices`, invoice, {
            headers: { Authorization: token },
        })
        .then(({ data }) => {
            dispatch({ type: SAVE_SERVICE_INVOICE_SUCCESS, payload: data })
            message.success('Servicio guardado con éxito')
            return true
        })
        .catch((e) => {
            console.error(e)
            dispatch({ type: SAVE_SERVICE_INVOICE_ERROR, payload: e })
            message.error('No fue posible guardar')
        })
}
