import React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';

import { USERS_REGISTER, ADMIN_TYPE } from '../../constants/routes';
import { SideLayout, UserForm } from '../Common/index';

function UsersManage() {
  const { pathname } = useLocation();
  const [isCreate] = useState(pathname === USERS_REGISTER);

  return (
    <SideLayout type={ADMIN_TYPE}>
      <h2 className="title">{isCreate ? 'Agregar' : 'Editar'} Usuario</h2>
      <h3 className="title-description">
        {isCreate
          ? 'Completa la siguiente información para agregar un usuario.'
          : 'Actualiza la información del usuario.'}
      </h3>
      <Row gutter={[24, 0]}>
        <Col span={14}>
          <UserForm isCreate={isCreate} />
        </Col>
      </Row>
    </SideLayout>
  );
}

export default UsersManage;
