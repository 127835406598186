// Routes types
export const ADMIN_TYPE = 'admin'
export const CASHIER_TYPE = 'cajero'

// Auth Routes
export const SIGN_IN = '/signin'
export const RESET_PASSWORD = '/reset-password'
export const FORGOT_PASSWORD = '/forgot-password'

// Cashier Routes
export const CASHIER = '/cajero/caja'
export const CASHIER_SYSTEMS = `${CASHIER}/sistemas`
export const CASHIER_PROMOTION = `${CASHIER}/fomento`
export const CASHIER_LOMAS = `${CASHIER}/lomas`
export const INVOICES = '/cajero/facturas'
export const INVOICES_REGISTER = `${INVOICES}/cargar/:type`
export const INVOICES_REGISTER_TAG = `${INVOICES}/cargar/`
export const INVOICES_DETAIL = `${INVOICES}/detalle/:reciept`
export const BATCHES = '/cajero/lotes'
export const BATCHES_DETAIL = `${BATCHES}/detalle/:batch`
export const BATCHES_MESSAGES = `${BATCHES_DETAIL}/mensajes`
export const INVOICES_DETAIL_HASH = `${INVOICES}/detalle/:invoice`
export const INVOICES_DETAIL_TAG = `${INVOICES}/detalle/recibo/:receipt/:type`
export const INVOICES_GENERATE = `${INVOICES}/generar`
export const INVOICES_ADD = `${INVOICES}/agregar`
export const INVOICES_CREATE = `${INVOICES}/crear`
export const PENALTY_GENERATE = '/cajero/multa/generar'
// export const RESIDENTS = '/cajero/residentes';
// export const RESIDENTS_DETAIL = `${RESIDENTS}/detalle/:id/:status`;
// export const RESIDENTS_DETAIL_TAG = `${RESIDENTS}/detalle/`;
// export const RESIDENTS_ADD = `${RESIDENTS}/agregar`;
export const CLIENTS = '/cajero/clientes'
export const LOMASRECEIPT = '/cajero/recibo-lomas'
export const LDCRECEIPT = '/cajero/recibo-ldc'
export const PROFILE = '/cajero/perfil'
export const PROFILE_CHANGE_PASSWORD = `${PROFILE}/cambiar-contraseña`
export const CONVENANTS = '/cajero/convenios'

// Residents
export const RESIDENTS = '/residentes'
export const RESIDENTS_DETAIL = `${RESIDENTS}/detalle/:residentId`
export const RESIDENTS_ADD = `${RESIDENTS}/agregar`
export const RESIDENTS_EDIT = `${RESIDENTS}/editar/:residentId`

// Admin Routes
export const ADMIN_PREFIX = '/admin'
export const ADMIN_BOX = `${ADMIN_PREFIX}/caja`
export const USERS = '/admin/usuarios'
export const USERS_REGISTER = `${USERS}/registrar`
export const USERS_EDIT = `${USERS}/editar/:userId`
export const ADMIN_SUMMARY = `${ADMIN_PREFIX}/resumen`
export const ADMIN_SISTEMS = `${ADMIN_PREFIX}/caja/sistemas`
export const ADMIN_PROMOTION = `${ADMIN_PREFIX}/caja/fomento`
export const ADMIN_LOMAS = `${ADMIN_PREFIX}/caja/lomas`
export const ADMIN_INVOICES = `${ADMIN_PREFIX}/facturas`
export const ADMIN_INVOICE_REGISTER = `${ADMIN_PREFIX}/facturas/crear`
export const ADMIN_INVOICE_DETAIL = `${ADMIN_PREFIX}/facturas/detalle/:invoice`
export const ADMIM_INVOICE_RECEIPT = `${ADMIN_PREFIX}/recibos/:receipt`
export const ADMIN_INVOICES_GENERATE = `${ADMIN_INVOICES}/generar`
//Agregar agua
export const ADMIN_INVOICES_ADD = `${ADMIN_INVOICES}/agregar`
export const ADMIN_INVOICES_CREATE = `${ADMIN_INVOICES}/crear`
export const ADMIN_PENALTY_GENERATE = '/cajero/multa/generar'
export const ADMIN_BATCHES = `${ADMIN_PREFIX}/lotes`
export const ADMIN_RESIDENTS = `${ADMIN_PREFIX}/residentes`
export const ADMIN_BATCHES_DETAIL = `${ADMIN_BATCHES}/detalle/:batch`
export const ADMIN_BATCHES_MESSAGES = `${ADMIN_BATCHES_DETAIL}/mensajes`
export const ADMIN_PROFILE = `${ADMIN_PREFIX}/perfil`
export const ADMIN_PROFILE_CHANGE_PASSWORD = `${ADMIN_PROFILE}/cambiar-contraseña`
export const ADMIN_CONVENANTS = '/admin/convenios'

export const NEW_MESSAGE = '/lotes/detalle/:batch/mensajes/nuevo'
export const EDIT_MESSAGE = '/lotes/detalle/:batch/mensajes/editar/:id'
