import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Input, InputNumber, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SideLayout } from '../Common'
import ServicesList from './ServicesList'
import { saveServiceInvoiceAction } from '../../redux/servicesDuck'
import { findLotByServiceAction } from '../../redux/lotsDuck'

const initialForm = {
    year: '2021',
    bimester: '',
    month: '',
    amount: '',
    number: '',
}

const ErrorText = ({ message }) =>
    message ? <p style={{ color: 'red' }}>{message}</p> : null

const AddServiceInvoice = () => {
    const dispatch = useDispatch()
    /** luz | gas | telefono */
    const [service, setService] = React.useState('luz')
    const [form, setForm] = React.useState(initialForm)
    const [errors, setErrors] = React.useState({})

    const { fetching, lot, error } = useSelector(({ lots }) => lots)

    React.useEffect(() => {
        //return // remove
        if (!service || form.number === '' || form.number.length < 3) { return console.log("wat") }
        if (service === 'luz' && form.number.length === 12) {
            dispatch(findLotByServiceAction({ service, number: form.number }))
        }
        if (service === 'telefono' && form.number.length === 10) {
            dispatch(findLotByServiceAction({ service, number: form.number }))
        }
    }, [form, service])

    const onChange = ({ target: { name, value } }) => {
        setForm({ ...form, [name]: value })
    }

    const handleScanChange = ({ target: { name, value } }) => {
        if (service === 'luz' && value.length > 29) {
            const number = value.slice(2, 14)
            const year = value.slice(14, 16)
            const month = Number(value.slice(16, 18))
            const bimester = month < 3 ? 1 : month < 5 ? 2 : month < 7 ? 3 : month < 9 ? 4 : month < 11 ? 5 : 6
            const amount = value.slice(20, value.length - 1)
            setForm({
                ...form, number, bimester,
                amount
            })
        } else if (service === 'telefono' && value.length > 19) {
            const number = value.slice(0, 10)
            const ints = value.slice(10, 17)
            const decimals = value.slice(17, 19)
            const amount = Number(`${ints}.${decimals}`)
            setForm({
                ...form,
                number,
                amount,
            })
        } else {
            setForm({ ...form, [name]: value })
        }

    }

    const onServiceChange = (serviceTag) => setService(serviceTag)
    const isFormValid = () => {
        const err = {}
        if (!form.number || form.number.length < 4) {
            err.number = 'Rellena este campo'
        }
        if (!form.year || form.year.length < 4 || form.year.length > 4) {
            err.year = 'Rellena este campo con 4 dígitos'
        }
        if (service === 'luz' && !form.bimester) {
            err.bimester = 'Selecciona un bimestre'
        }
        if (service !== 'luz' && !form.month) {
            err.month = 'Selecciona un mes'
        }
        if (!form.amount) {
            err.amount = 'Rellena este campo'
        }
        setErrors(err)
        return !!!Object.keys(err).length
    }
    const saveService = () => {
        if (!isFormValid()) return
        const body = {
            ...form,
            service,
            measurerNumber: form.number,
            twomonths: form.bimester,
        }
        dispatch(saveServiceInvoiceAction(body))
        // remove form
        setForm(initialForm)
    }

    return (
        <SideLayout backButton={false} classNameContainer={'container'}>
            <h2 className="title">Agregar Factura de servicio</h2>
            <h3 className="title-description">
                Agrega los recibos de servicios como luz, teléfono o gas.
            </h3>
            <Card style={{ width: '60%' }}>
                <div>
                    <h3>Servicio a capturar</h3>
                    <Select
                        value={service}
                        onChange={onServiceChange}
                        placeholder="Selecciona el servicio a capturar"
                        style={{
                            width: '200px',
                        }}
                    >
                        <Option value="luz">Luz</Option>
                        <Option value="gas">Gas</Option>
                        <Option value="telefono">Teléfono</Option>
                    </Select>
                </div>
                <Divider />
                <div style={{ marginBottom: 24 }}>
                    <h3>No. de servicio</h3>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 12 }}>
                        <Input
                            onFocus={e => e.target.select()}
                            onClick={e => e.target.select()}
                            value={form.number}
                            onChange={handleScanChange}
                            name="number"
                            placeholder="No. de servicio"
                        />
                        <Button>||||||||||</Button>
                    </div>
                    <ErrorText message={error} />
                    {!error && lot.seccion && <> <Input style={{ width: 120 }} disabled value={`Sección: ${lot.seccion}`} />  <Input style={{ width: 150 }} disabled value={`Manzana: ${lot.manzana}`} />  <Input style={{ width: 120 }} disabled value={`Lote: ${lot.lote}`} /> </>}
                </div>
                <div style={{ marginBottom: 24, display: 'flex', gap: 50 }}>
                    <div>
                        <h3>Año</h3>
                        <Input
                            style={{ minWidth: 120 }}
                            onChange={onChange}
                            value={form.year}
                            name="year"
                            placeholder="Año"
                        />
                        <ErrorText message={errors.year} />
                    </div>
                    {service === 'luz' && (
                        <div>
                            <h3>Bimestre</h3>
                            <Select
                                value={form.bimester}
                                onChange={(val) => setForm({ ...form, bimester: val })}
                                placeholder="Selecciona el bimestre"
                                style={{
                                    width: '100px',
                                }}
                            >
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                                <Option value="6">6</Option>
                            </Select>
                            <ErrorText message={errors.bimester} />
                        </div>
                    )}
                    {service !== 'luz' && (
                        <div>
                            <h3>Mes de facturación</h3>
                            <Select
                                value={form.month}
                                onChange={(val) => setForm({ ...form, month: val })}
                                placeholder="Selecciona el mes"
                                style={{
                                    width: '200px',
                                }}
                            >
                                <Option value="Enero">Enero</Option>
                                <Option value="Febrero">Febrero</Option>
                                <Option value="Marzo">Marzo</Option>
                                <Option value="Abril">Abril</Option>
                                <Option value="Mayo">Mayo</Option>
                                <Option value="Junio">Junio</Option>
                                <Option value="Julio">Julio</Option>
                                <Option value="Agosto">Agosto</Option>
                                <Option value="Septiembre">Septiembre</Option>
                                <Option value="Octubre">Octubre</Option>
                                <Option value="Noviembre">Noviembre</Option>
                                <Option value="Diciembre">Diciembre</Option>
                            </Select>
                            <ErrorText message={errors.month} />
                        </div>
                    )}
                    <div>
                        <h3>Cantidad</h3>
                        <InputNumber
                            style={{ minWidth: 150 }}
                            onChange={(val) => setForm({ ...form, amount: val })}
                            formatter={(value) =>
                                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            value={form.amount}
                            name="amount"
                            placeholder="Cantidad"
                        />
                        <ErrorText message={errors.amount} />
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 48,
                        display: 'flex',
                        gap: 20,
                        justifyContent: 'flex-end',
                    }}
                >
                    {/* <Button type="danger">Terminar</Button> */}
                    <Button disabled={error} onClick={saveService} type="primary">
                        Guardar y Capturar otro
                    </Button>
                </div>
            </Card>
            <ServicesList />
        </SideLayout>
    )
}

export default AddServiceInvoice
