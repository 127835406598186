import React from 'react';

import styles from './Row.module.css'

function Row({
  titleOne, titleTwo,
  detailOne, detailTwo,
  titleThree, detailThree,
  titleFour, detailFour
}) {
  return (
    <div className={styles.row__table__row}>
      <div className={styles.row__table__label}>
        {titleOne}
      </div>
      <div className={styles.row__table__amount}>
        {detailOne && detailOne}
      </div>
      <div className={styles.row__table__divider} />
      <div className={styles.row__table__label}>
        {titleTwo}
      </div>
      <div className={styles.row__table__amount}>
        {detailTwo && detailTwo}
      </div>
      <div className={styles.row__table__divider} />
      <div className={styles.row__table__label}>
        {titleThree}
      </div>
      <div className={styles.row__table__amount}>
        {detailThree && detailThree}
      </div>
      {titleFour && <>
        <div className={styles.row__table__label}>
          {titleFour}
        </div>
        <div className={styles.row__table__amount}>
          {detailFour}
        </div></>}
    </div>
  );
}

export default Row;
