import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Table, Col, Row, Modal, message } from 'antd'
import { Seeker, SideLayout } from '../Common'
import styles from './BatchesDetail.module.css'
import { EDIT_MESSAGE, NEW_MESSAGE } from '../../constants/routes'
import {
  deleteMessageAction,
  getMessagesByLotAction,
} from '../../redux/messagesDuck'
import moment from 'moment'

function BatchesDetail() {
  const dispatch = useDispatch()
  const { lot } = useSelector(({ lots }) => lots)
  const role = useSelector(({ user }) => user.role.toLowerCase())
  const { items: messages } = useSelector(({ messages }) => messages)
  const [messageId, setMessageId] = useState(null)

  const [modalVisibility, setModalVisibility] = useState(false)
  const history = useHistory()
  const { batch } = useParams()

  React.useEffect(() => {
    if (!lot.id) return history.goBack()
    dispatch(getMessagesByLotAction(lot.id))
  }, [])

  const handleEdit = (id) => {
    history.push(
      `/${role}${EDIT_MESSAGE.replace(':batch', batch).replace(':id', id)}`,
    )
  }

  const handleNew = () => {
    history.push(`/${role}${NEW_MESSAGE.replace(':batch', batch)}`)
  }

  const handleDeleteModal = (id) => {
    setMessageId(id)
    setModalVisibility(true)
  }

  const handleDelete = () => {
    console.log(messageId)
    if (!messageId) return
    dispatch(deleteMessageAction(messageId))
    setModalVisibility(false)
  }

  const handleCancel = () => {
    setModalVisibility(false)
    setMessageId(null)
  }

  const columns = [
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('ll'),
    },
    {
      title: 'Ultima actualización',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => moment(date).format('ll'),
    },
    {
      title: 'Usuario',
      dataIndex: 'creator',
      key: 'creator',
      render: (creator) => creator.username,
    },
    { title: 'Observaciones', dataIndex: 'body', key: 'body' },
    {
      title: 'Acciones',
      dataIndex: '_id',
      key: '_id',
      render: (id) => (
        <div className={styles.actions}>
          <span className="text-green" onClick={() => handleEdit(id)}>
            Editar
          </span>
          <span className="text-coal" onClick={() => handleDeleteModal(id)}>
            Eliminar
          </span>
        </div>
      ),
    },
  ]

  return (
    <>
      <SideLayout type={role === 'admin' ? 'admin' : 'cajero'}>
        <h2 className={styles.title}>{`Mensajes del lote ${lot.number}`}</h2>
        <h3>{`Sec ${lot.seccion} Mz ${lot.manzana} Lt ${lot.lote}`}</h3>

        <Row>
          <Col span={24}>
            <Seeker noUpload noFilter btnAdd={{ action: handleNew }} />
          </Col>
        </Row>

        <div className={styles.divider}>
          <Table
            className="batches-table"
            style={{ padding: '40' }}
            size="small"
            columns={columns}
            dataSource={messages}
          />
        </div>
      </SideLayout>
      <Modal
        title="Eliminar mensaje"
        visible={modalVisibility}
        onOk={handleDelete}
        onCancel={handleCancel}
      >
        <p>¿Estas seguro de eliminar este mensaje? </p>
      </Modal>
    </>
  )
}

export default BatchesDetail
