// Impresion de recibo de fomento
import React from 'react'
import { useSelector } from 'react-redux'
import styles from './FomentReceipt.module.css'
import { translateMoney } from '../../tools/moneyToWords'
// import { addCommas } from '../../tools/formatNumbers';
import moment from 'moment'
import './receiptStyles.css'
import fondo from '../../assets/recibo.png'
import { formatMoney } from '../../helpers/formatMoney'
import sectionNames from '../Common/CashierForm/sectionNames'

const LomasReceipt = ({ currentRef, format = false }) => {
  let { print: PDF = {} } = useSelector(({ fomento }) => fomento)

  if (format) {
    PDF = {
      lotDetails: {
        residentName: `${PDF.lot?.residents?.[0].firstName} ${PDF.lot?.residents?.[0].lastName} ${PDF.lot?.residents[0]?.surName}`,
        ...PDF.lot
      },
      invoiceDocs: PDF.invoices.map(inv => ({
        description: inv.serviceCode,
        importe: inv.originalBefore1500,
        recargos: inv.chargesBefore,
        total: inv.interestBefore,
      })),
      ...PDF
    }
  }

  // console.log('AVERS:', PDF)

  const renderItem = (item = {}) => {

    // <>
    //   <div className={styles.table__field}>{item.cuota}</div>
    //   <div className={styles.table__columns}>{item.descripcion}</div>
    //   <div className={styles.table__columns}>{formatNumberToPesos(item.importe)}</div>
    //   <div className={styles.table__columns}>{formatNumberToPesos(item.recargos)}</div>
    //   <div className={styles.table__fields}>{formatNumberToPesos(item.total)}</div>
    // </>
    return (
      <div
        style={{
          display: 'flex',
          gap: 50,
        }}
      >
        <span style={{ width: 150 }}>{item.cuota}</span>
        <span style={{ width: 400 }}>{item.descripcion}</span>
        <span style={{ width: 300 }}>{formatMoney(item.importe)}</span>
        <span style={{ width: 300 }}>{formatMoney(item.recargos)}</span>
        <span style={{ width: 300 }}>{formatMoney(item.total)}</span>
      </div>
    )
  }

  // PDF = {
  //   folio:'123-FAC',
  //   createdAt:Date.now(),
  //   sectionName:'LOMAS II',
  //   lotDetails:{
  //     residentName:"Héctor Feliciano Campos Muñoz",
  //     seccion:10,
  //     manzana:20,
  //     lote:'20-1',
  //     area:3780
  //   },
  //   paymentForm:[{tipo:"TDDC"}],
  //   totalPaid:56000,
  //   invoiceDocs:[]
  // }
  const dateReceipt = PDF.createdAt

  return (
    <div
      style={{
        // backgroundImage:`url('${fondo}')`,
        backgroundSize: 'cover',
        width: 816,
      }}
      // className={styles.mainwrapper}
      ref={currentRef}
    >
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: 0,
        }}
      >
        <Divider
          style={{
            flexGrow: 9,
          }}
          title=""
        />
        <span
          style={{
            paddingTop: 45,
            paddingBottom: 5,
            flexGrow: 1,
            paddingLeft: 15,
          }}
        >
          {PDF.folio || 'FAC-1010'}
        </span>
      </section>
      <section
        style={{
          display: 'flex',
        }}
      >
        <span
          style={{
            padding: '26px 50px 10px 50px',
            width: 200,
            flexGrow: 8,
          }}
        >
          {PDF.lotDetails?.residentName}
        </span>
        <span
          style={{
            padding: '36px 0px 0px 145px',
            flexGrow: 1,
          }}
        >
          {moment(dateReceipt).format('DD')} <span style={{ marginLeft: 15 }} />
          {moment(dateReceipt).format('MM')}
          <span style={{ marginLeft: 15 }} />
          {moment(dateReceipt).format('YYYY')}
        </span>
      </section>
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Divider
          style={{
            paddingTop: 56,
          }}
        />
        <span
          style={{
            paddingLeft: 20,
            width: 120,
          }}
        >
          {sectionNames[PDF.lotDetails?.seccion]}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.seccion}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.manzana}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.lote}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.area}
        </span>
        <span
          style={{
            width: 170,
            textAlign: 'center',
          }}
        >
          {PDF.paymentForm?.length < 2 ? PDF.paymentForm[0].tipo : 'Mixto'}
        </span>
        <span
          style={{
            width: 170,
            textAlign: 'center',
          }}
        >
          {formatMoney(PDF.totalPaid, true)}
        </span>
      </section>
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Divider
          style={{
            paddingTop: 80,
          }}
        />
      </section>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-around',
          paddingLeft: 35,
          height: 130,
        }}
      >
        {PDF.invoiceDocs?.map(renderItem)}
        {/* {[1].map(renderItem)} */}
      </section>
      <section>
        <Divider
          style={{
            paddingTop: 480,
          }}
        />
      </section>
      <section>
        <span
          style={{
            paddingLeft: 50,
          }}
        >
          {translateMoney(Math.floor(PDF.totalPaid))}
        </span>
      </section>
      <section>
        <Divider
          style={{
            paddingTop: 50,
          }}
        />
      </section>
    </div>
  )
}
// const color = "rgba(230,230,150,.7)"
const color = 'transparent'

const Divider = ({ style, title }) => (
  <div style={{ backgroundColor: color, padding: '22px 10px', ...style }}>
    {title}
  </div>
)

export default LomasReceipt
