import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import { SideLayout, ResidentInfo, ResidentUpdate } from '../Common';
import { CASHIER_TYPE, RESIDENTS_EDIT } from '../../constants/routes';

import './ResidentsDetail.module.css';
import { getOneResidentAction } from '../../redux/residentsDuck';

function Users() {
  const dispatch = useDispatch()
  const history = useHistory();
  const { residentId } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const prefix = useSelector(({ user }) => `/${user.role.toLowerCase()}`);
  const resident = useSelector(({ residents }) => residents.resident)

  useEffect(() => {
    dispatch(getOneResidentAction(residentId))
  }, [])

  return (
    <SideLayout type={CASHIER_TYPE}>
      <h2 className="title">
        {`${resident.firstName || ''} ${resident.lastName || ''} ${resident.surName || ''}`}
      </h2>
      {!isEditing ? (
        <ResidentInfo
          setIsEditing={setIsEditing}
          onEdit={() => history.push(prefix + RESIDENTS_EDIT.replace(':residentId', resident.id))}
          {...{ resident }}
        />
      ) : (
          <ResidentUpdate setIsEditing={setIsEditing} />
        )}
    </SideLayout>
  );
}

export default Users;
