import React, { useState } from 'react'
import {
  Select,
  Tag,
  Card,
  Input,
  Divider,
  Table,
  Button,
  InputNumber,
} from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { formatMoney } from '../../helpers/formatMoney'
import { useHistory } from 'react-router-dom'
import {
  clearFomentInfoAction,
  createDepositReceiptAction,
  createReceiptAction,
  removeDebtFromReceiptAction,
} from '../../redux/fomento.duck'
import PrintingModal from './PrintingModal'
import { PlusOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'

const MultaSummary = ({ variant = 'tag' }) => {
  // constants
  const { Option } = Select
  const paymentMethod = [
    { code: 'EFEC', tag: 'Efectivo' },
    { code: 'TRANS', tag: 'Transferencia bancaria' },
    { code: 'CHEQ', tag: 'Cheque' },
    { code: 'TDDC', tag: 'Tarjeta de crédito' },
  ]
  const initialForm = {
    amount: 0,
    paymentMethod: 'EFEC',
    secondPaymentMethod: 'TDDC',
    reference: '',
    notes: '',
    model: '',
    vehicleYear: '',
    brand: '',
    plates: '',
  }
  //
  const dispatch = useDispatch()
  const history = useHistory()
  const { lot, fetching } = useSelector(({ fomento }) => fomento)
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})

  const [printing, setPrinting] = useState(false)
  const { displayName, id: userId } = useSelector(({ user }) => user)
  // methods
  const onChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value })
  }

  const removeInvoiceOfAgreement = (record) => {
    dispatch(removeDebtFromReceiptAction(record.id))
  }

  // Validation
  const isValidForm = () => {
    if (!lot.id) return
    const err = {}
    if (!form.amount || form.amount < 1) err.amount = 'Introduce una cantidad'
    if (!form.amount || typeof form.amount !== 'number' || form.amount < 1) {
      err.amount = 'Ingresa una cantidad'
    }
    if (!form.paymentMethod) err.paymentMethod = 'Selecciona una forma de pago'
    if (!displayName) {
      alert('Inicia sesión nuevamente porfavor')
      history.push('/signin')
    }
    // tag
    if (variant === 'tag') {
      if (
        !form.vehicleYear ||
        form.vehicleYear.length < 4 ||
        !form.plates ||
        form.plates.length < 4 ||
        !form.model ||
        !form.brand
      ) {
        err.tag = 'Introduce todos los datos del vehículo'
      }
    }

    setErrors(err)
    return !!!Object.keys(err).length
  }

  // Backend commute
  const saveReceipt = () => {
    if (!isValidForm()) return
    const pForms = [
      {
        tipo: form.paymentMethod,
        amount: form.amount,
      },
    ]
    const body = {
      tipo: variant === 'tag' ? 'tag' : variant === 'mult' ? 'multa' : 'extra',
      subsidiary: 'Sistemas',
      quantityType: 'total',
      lot: lot.id,
      totalPaid: form.amount,
      paymentForm: pForms,
      reference: form.reference,
      notes: form.notes,
    }
    setForm(initialForm)
    dispatch(createReceiptAction(body)).then((r) => {
      if (r) setPrinting(true)
    })
  }

  const dataSource = React.useMemo(() => {
    if (variant === 'tag') {
      return {
        name: 'Tag',
        desc: 'Acceso vehicular',
        total: form.amount,
      }
    } else if (variant === 'mult') {
      return {
        name: 'Multa',
        desc: 'Multa residencial',
        total: form.amount,
      }
    } else if (variant === 'ext') {
      return {
        name: 'Extras',
        desc: 'Productos / Servicios extra',
        total: form.amount,
      }
    }
  }, [variant])

  return (
    <div>
      <Card
        title="Resumen de compra"
        style={{
          width: 586,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <section style={{ gap: 15, display: 'flex', flexDirection: 'column' }}>
          <div>
            <span style={{ color: 'grey', marginRight: 20 }}>Fecha:</span>
            <span style={{ color: 'black' }}>
              {moment(Date.now()).format('D/MMM/YYYY')}
            </span>
          </div>

          <article style={{ display: 'flex', gap: 30 }}>
            <div style={{ color: 'grey', margin: 0, display: 'flex' }}>
              <div>
                <p>Forma de pago</p>
                <Select
                  onChange={(value) =>
                    onChange({ target: { name: 'paymentMethod', value } })
                  }
                  value={form.paymentMethod}
                  style={{
                    width: 125,
                    marginRight: 24,
                  }}
                >
                  {paymentMethod.map((ser) => (
                    <Option key={ser.code}>{ser.code}</Option>
                  ))}
                </Select>
              </div>
              <div>
                <p>Cantidad</p>
                <InputNumber
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  onChange={(value) =>
                    onChange({ target: { value, name: 'amount' } })
                  }
                  placeholder="$00.00"
                  value={Math.floor(form.amount)}
                  style={{
                    width: 250,
                    borderColor: errors.tag ? 'red' : '#d9d9d9',
                  }}
                />{' '}
                MXN
                <span style={styles.error}>{errors.paymentMethod}</span>
              </div>
            </div>
          </article>

          <span style={styles.error}>{errors.amount}</span>

          {variant === 'tag' && (
            <article style={{ display: 'flex', gap: 30 }}>
              <div style={{ color: 'grey', margin: 0 }}>
                <span>Placas del vehículo</span>
                <Input
                  onChange={onChange}
                  placeholder={'Escribe las placas del vehículo'}
                  value={form.plates}
                  name="plates"
                  style={{
                    width: 250,
                    borderColor: errors.tag ? 'red' : '#d9d9d9',
                  }}
                />
              </div>
              <div style={{ color: 'grey', margin: 0 }}>
                <span>Año del vehículo:</span>
                <Input
                  name="vehicleYear"
                  placeholder={'Escribe el año del vehículo'}
                  onChange={onChange}
                  value={form.vehicleYear}
                  style={{
                    width: 250,
                    borderColor: errors.tag ? 'red' : '#d9d9d9',
                  }}
                />
              </div>
            </article>
          )}

          {variant === 'tag' && (
            <article style={{ display: 'flex', gap: 30 }}>
              <div style={{ color: 'grey', margin: 0 }}>
                <span>Marca del vehículo</span>
                <Input
                  onChange={onChange}
                  placeholder={'Escribe la marca del vehículo'}
                  value={form.brand}
                  name="brand"
                  style={{
                    width: 250,
                    borderColor: errors.tag ? 'red' : '#d9d9d9',
                  }}
                />
              </div>
              <div style={{ color: 'grey', margin: 0 }}>
                <span>Modelo del vehículo:</span>
                <Input
                  name="model"
                  placeholder={'Escribe el modelo del vehículo'}
                  onChange={onChange}
                  value={form.model}
                  style={{
                    width: 250,
                    borderColor: errors.tag ? 'red' : '#d9d9d9',
                  }}
                />
              </div>
            </article>
          )}
          <span style={styles.error}>{errors.tag}</span>
          <Divider />
          <article style={{ display: 'flex', gap: 30 }}>
            <div style={{ color: 'grey', margin: 0 }}>
              <span>Referencia</span>
              <Input
                onChange={onChange}
                placeholder={'Escribe una referencia'}
                value={form.reference}
                name="reference"
                style={{ width: 250 }}
              />
            </div>
            <div style={{ color: 'grey', margin: 0 }}>
              <span>Autorizado por:</span>
              <Input value={displayName} style={{ width: 250 }} />
            </div>
          </article>

          <div style={styles.formItem}>
            <span>Notas:</span>
            <Input
              onChange={onChange}
              placeholder="Ingresa un comentario"
              value={form.notes}
              name="notes"
              style={{ width: '100%' }}
            />
          </div>
        </section>
        <Divider />
        <section>
          <h3>Servicio</h3>
          <Table
            onRow={(record) => ({
              onClick: () => removeInvoiceOfAgreement(record),
            })}
            size="small"
            pagination={false}
            dataSource={[dataSource]}
            columns={[
              {
                title: 'Nombre',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Descripción',
                dataIndex: 'desc',
                key: 'desc',
              },
              {
                title: 'Importe',
                dataIndex: 'total',
                key: 'total',
                render: (_) => formatMoney(form.amount),
              },
            ]}
          />
          <span style={styles.error}>{errors.invoices}</span>
          <div
            style={{
              marginTop: 150, // change this when real table
              display: 'flex',
              gap: 100,
              justifyContent: 'flex-end',
            }}
          >
            <span>Total: {formatMoney(form.amount, true)}</span>
            <span>
              {/* {formatMoney(Object.values(invoices).reduce((acc, inv) => acc + inv.total, 0), true)} */}
            </span>
          </div>
        </section>
      </Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: 40,
        }}
      >
        <Button
          type="primary"
          loading={fetching}
          onClick={saveReceipt}
          disabled={fetching || !lot.id || lot.delinquency}
          style={
            fetching || Object.keys(errors).length
              ? styles.disabledButton
              : styles.enabledButton
          }
        >
          Pagar
        </Button>
      </div>

      <PrintingModal
        onFinish={() => dispatch(clearFomentInfoAction())}
        show={printing}
        agua={true}
        onCancel={() => setPrinting(false)}
      />
    </div>
  )
}

const styles = {
  tag: { borderRadius: 20, cursor: 'pointer' },
  error: { color: 'red' },
}

export default MultaSummary
