import React, { useState } from 'react'

import { useSelector } from 'react-redux'

import { Tabs } from 'antd'

import { SideLayout } from '../Common'
import Agreements from './Agreements'

function Convenants() {
  const { TabPane } = Tabs
  const role = useSelector(({ user }) => user.role.toLowerCase())
  const [key, setKey] = useState('1')
  return (
    <SideLayout type={role}>
      <h2 className="title">Convenios</h2>
      <h3 className="title-description">
        Genera un convenio para negociar facturas pendientes.
      </h3>
      <div>
        <Tabs onChange={(k) => setKey(k)} defaultActiveKey="1">
          <TabPane tab="Sistemas" key="1">
            {key === '1' && <Agreements subsidiary="Sistemas" />}
          </TabPane>
          <TabPane tab="Fomento" key="2">
            {key === '2' && <Agreements subsidiary="Fomento" />}
          </TabPane>
        </Tabs>
      </div>
    </SideLayout>
  )
}

export default Convenants
