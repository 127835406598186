import React, { useState } from 'react'
import { Row, Col } from 'antd'

import { CashierForm, CashierDebts, CashierTicket, SideLayout } from '../Common'
import { CASHIER_TYPE } from '../../constants/routes'
import TagSummary from './TagSummary'

export const columnsPromotion = [
  {
    title: 'Año',
    dataIndex: 'col0',
    key: 'col0',
    width: 70,
    fixed: 'left',
  },
  {
    title: 'Hasta 1500 m2',
    dataIndex: 'col1',
    key: 'col1',
    width: 150,
  },
  {
    title: 'Más de 1500 m2',
    dataIndex: 'col2',
    key: 'col2',
    width: 150,
  },
  {
    title: 'Clúster',
    dataIndex: 'col3',
    key: 'col3',
    width: 150,
  },
  {
    title: 'Rec. Hasta 1500 m2',
    dataIndex: 'col4',
    key: 'col4',
    width: 150,
  },
  {
    title: 'Rec. Más de 1500 m2',
    dataIndex: 'col5',
    key: 'col5',
    width: 150,
  },
  {
    title: 'Rec. Clúster',
    dataIndex: 'col6',
    key: 'col6',
    width: 150,
  },
  {
    title: 'Abono',
    dataIndex: 'col7',
    key: 'col7',
    width: 100,
  },
  {
    title: 'Total',
    dataIndex: 'col8',
    key: 'col8',
    width: 100,
    fixed: 'right',
  },
]

export const columnsTag = [
  {
    title: 'Nombre',
    dataIndex: 'nombre',
    key: 'nombre',
  },
  {
    title: 'Descripción',
    dataIndex: 'descripcion',
    key: 'descripcion',
  },
  {
    title: 'Importe',
    dataIndex: 'importe',
    key: 'importe',
  },
]

function CashierPromotion() {
  const [tag, setTag] = useState('MANT21')

  const onTagChange = (value) => {
    setTag(value)
  }

  return (
    <SideLayout type={CASHIER_TYPE}>
      <h2 className="title">Fomento</h2>
      <h3 className="title-description">
        Cobros de mantenimiento y servicios internos.
      </h3>
      <Row gutter={[24, 0]}>
        <Col span={14}>
          <CashierForm
            onTagChange={onTagChange}
            type="promotion"
          // activeTag={(status) => setTagStatus(status)}
          />
          {tag === 'MANT21' && <CashierDebts columns={columnsTag} />}
        </Col>
        <Col span={10}>
          {tag === 'MANT21' && (
            <CashierTicket type="promotion" columns={columnsTag} />
          )}
          {tag === 'TAG001' && <TagSummary />}
          {tag === 'MULT002' && <TagSummary variant="mult" />}
          {tag === 'EXT003' && <TagSummary variant="ext" />}
        </Col>
      </Row>
    </SideLayout>
  )
}

export default CashierPromotion
