export default {
    1: "LOMAS I",
    2: "BARRANCA",
    3: "PIRAMIDE",
    4: "LOMAS IV",
    5: "LAGO V",
    6: "LAGO VI",
    7: "POPOCATEPETL",
    8: "TEPOZTECO I",
    9: "TEPOZTECO II",
    10: "VISTA HERMOSA",
    11: "PANORAMA X-A",
    12: "PANORAMA X-B",
    13: "PANORAMA X-C",
    14: "VOLCANES XI",
    15: "PANORAMA XII",
    16: "PANORAMA XIII",
    17: "PANORAMA XIV",
    18: "PANORAMA XV",
    19: "PAISAJES I",
    20: "PAISAJES II",
    21: "FINCAS",
    22: "MIRADOR IV",
    23: "RANCHO VIEJO",
    24: "PAISAJES V",
    25: "PAISAJES VI",
    26: "BOSQUES",
    27: "FUENTES",
    28: "CONJUNTO DEL LAGO",
    29: "MIRADOR XVII",
    30: "VOLCANES XVIII",
    31: "NUEVA VIDA",
    32: "VOLCANES XIX",
    33: "VOLCANES XXI",
    34: "CENTRO COMERCIAL",
    35: "VOLCANES XXII",
    36: "PAISAJES VII",
    37: "FOMENTO"
}