import React, { useState } from 'react';
import { generateFomentAction } from '../../redux/fomento.duck'
import { useDispatch } from 'react-redux'

import {
  Form, InputNumber, Col,
  Button, Modal, Row,
} from 'antd';

import { SideLayout, Card } from '../Common';

import uploadingFilesImage from '../../assets/svgs/UploadingFiles.svg';
import { Rules } from '../../helpers';
import styles from './InvoicesCreate.module.css';

function InvoicesCreate() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  function createFoment(values) {
    setLoading(true)
    dispatch(generateFomentAction(values))
      .then(() => {
        setLoading(false)
      })
  }

  return (
    <SideLayout backButton={false}>
      <h2 className="title">Generar facturas</h2>
      <h3 className="title-description">
        Genera las facturas de mantenimiento del año.
      </h3>

      <Card style={{ maxWidth: 580 }}>
        <h3 className="subtitle-strong-24">
          Información de mantenimiento
        </h3>
        <div style={{ marginBottom: 32 }}>
          Actualiza la siguiente información antes de generar las facturas de mantenimiento.
        </div>
        <Form
          onFinish={createFoment}
          layout="vertical">
          <Form.Item name="year" label="Año" required rules={[Rules.basic]}>
            <InputNumber
              maxLength={4}
              placeholder="2000"
              max={new Date().getFullYear() + 1}
              min={2000}
              className={styles.fullwidth}
            />
          </Form.Item>

          <Row>
            <Col span={12}>
              <Form.Item name="price1" label="Costo por m2 hasta 1500m2" rules={[Rules.basic]}>
                <InputNumber
                  placeholder="$00.00"
                  formatter={(value) => value ? `$${value}` : null}
                  className={styles.fullwidth}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col>
              <Form.Item name="rate1" label="Tasa de interes mensual" rules={[Rules.basic]}>
                <InputNumber
                  placeholder="0%"
                  min={0}
                  max={100}
                  formatter={(value) => value ? `${value}%` : ''}
                  className={styles.fullwidth}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item name="price2" label="Costo por m2 a partir de 1500m2" rules={[Rules.basic]}>
                <InputNumber
                  placeholder="$00.00"
                  formatter={(value) => value ? `$${value}` : null}
                  className={styles.fullwidth}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col>
              <Form.Item name="rate2" label="Tasa de interes mensual" rules={[Rules.basic]}>
                <InputNumber
                  placeholder="0%"
                  min={0}
                  max={100}
                  formatter={(value) => value ? `${value}%` : ''}
                  className={styles.fullwidth}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item name="clusterPrice" label="Costo por m2 en clúster" rules={[Rules.basic]}>
                <InputNumber
                  placeholder="$00.00"
                  formatter={(value) => value ? `$${value}` : null}
                  className={styles.fullwidth}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col>
              <Form.Item name="rate3" label="Tasa de interes mensual" rules={[Rules.basic]}>
                <InputNumber
                  placeholder="0%"
                  min={0}
                  max={100}
                  formatter={(value) => value ? `${value}%` : ''}
                  className={styles.fullwidth}
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ textAlign: 'center' }}>
            <Button htmlType="submit" type="primary">
              Generar Facturas
            </Button>
          </div>
        </Form>
      </Card>

      <Modal
        visible={loading}
        title="Cargando facturas"
        onCancel={() => setLoading(false)}
        onOk={() => {
          setLoading(false);
        }}
      >
        <p className="textColor">
          Espera un momento, estamos cargando la información.
        </p>
        <Row justify="center">
          <img src={uploadingFilesImage} alt="upload files" />
        </Row>
      </Modal>
    </SideLayout>
  );
}

export default InvoicesCreate;
