import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html, name) => {
    savePDF(html, {
      paperSize: 'Letter',
      fileName: name,
      margin: '0cm',
      scale: 0.75
    })
  }
}

const Doc = new DocService();
export default Doc;