import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Tag, Divider, Button } from 'antd'
import { SideLayout } from '../Common/index'
import { formatMoney } from '../../helpers/formatMoney'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getOneAgreementAction } from '../../redux/agreementsDuck'
import Modal from 'antd/lib/modal/Modal'
import GenerateCovenantReceipt from './GenerateCovenantReceipt'

function AgreementDetail({
  match: {
    params: { agreementId },
  },
}) {
  const dispatch = useDispatch()
  const { agreement, fetching } = useSelector(({ agreements }) => agreements)
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    dispatch(getOneAgreementAction(agreementId))
  }, [])

  const renderInvoice = (inv, i) =>
    agreement.subsidiary === 'Fomento' ? (
      <><Link
        style={{ color: 'var(--blueAnt)' }}
        key={i} to={`/admin/facturas/detalle/${inv.id}`}>{inv.folio}</Link><br /></>
    ) : (
      <><Link
        style={{ color: 'var(--blueAnt)' }}
        key={i} to={`/admin/facturas/detalle/${inv.id}/agua`}>
        {inv._id?.slice(inv._id.length - 5)}
      </Link><br /></>
    )

  const renderReceipt = (inv, i) =>
    agreement.subsidiary === 'Fomento' ? (
      <><Link style={{ color: 'var(--blueAnt)' }} key={i} to={`/admin/recibos/${inv.id}`}>   {inv.folio}</Link><br /></>
    ) : (
      <><Link
        style={{ color: 'var(--blueAnt)' }}
        key={i} to={`/admin/recibos/${inv.id}`}>
        {inv.folio}
      </Link><br /></>
    )

  const getMainResident = (lot) => {
    // returns a resident
    if (!lot) return {}
    let max = 0
    let id
    for (let elem of lot.percent) {
      if (Number(elem.percent) > max) {
        max = Number(elem.percent)
        id = elem.resident
      }
    }
    const resident = lot.residents.find((res) => res.id == id)
    if (resident.id)
      resident.displayName = resident.firstName + ' ' + resident.lastName
    return resident
  }

  const resident = getMainResident(agreement.lot)

  // console.log('Agreemen: ', agreement)

  return (
    <>
      <SideLayout>
        <h2 className="title">{`Convenio ${agreement.folio || ''}`}</h2>
        <Row gutter={[24, 0]} style={{ position: 'relative' }}>
          <Col span={12}>
            <Card
              loading={fetching}
              extra={
                agreement.cancelled ? <Tag color="red">Cancelado</Tag> : null
              }
              title={'Información del convenio'}
              loading={fetching}
              style={{ minWidth: 400 }}
            >
              <section
                style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
              >
                <span>
                  Fecha: {'   '}{' '}
                  <BoldText>
                    {moment(agreement.createdAt).format('DD/MMM/YYYY')}
                  </BoldText>
                </span>
                <span>
                  Calle: {'   '} <BoldText>{agreement.lot?.street}</BoldText>
                </span>
                <span>
                  Dirección: {'   '}{' '}
                  <BoldText>
                    Secc {agreement.lot?.seccion}, Mz {agreement.lot?.manzana}, Lt{' '}
                    {agreement.lot?.lote}
                  </BoldText>
                </span>
                <span>
                  Residente: {'   '} <BoldText>{resident.displayName}</BoldText>
                </span>
                <span>
                  Superficie: {'   '}
                  <BoldText>
                    {agreement.lot?.area}
                    {agreement.lot?.measureUnit}
                  </BoldText>
                </span>
                <span>
                  Cluster: {'   '}
                  <BoldText>
                    {agreement.lot?.cluster}
                    {agreement.lot?.measureUnit}
                  </BoldText>
                </span>
                <span>
                  {/* Cambiar esto por el displayName del user */}
                Authorizado por: {'   '}{' '}
                  <BoldText>{agreement.authorizedBy?.email}</BoldText>
                </span>
                <Divider />
                <span>
                  Monto original: {'   '}
                  <BoldText>{formatMoney(agreement.total)}</BoldText>
                </span>
                <span>
                  Monto pendiente: {'   '}
                  <BoldText>{formatMoney(agreement.pendingAmount > 0 ? agreement.pendingAmount : 0)}</BoldText>
                </span>
                <span>
                  Mensualidades: {'   '}
                  <BoldText>
                    {agreement.paymentsDone} de {agreement.paymentsNumber}
                  </BoldText>
                </span>
                <span>
                  Pago mensual: {'   '}
                  <BoldText>
                    {formatMoney(agreement.monthlyPaymentAmount)}
                  </BoldText>
                </span>

                <span>
                  Facturas en convenio: {'   '}{' '}
                  {agreement.subsidiary === 'Sistemas'
                    ? agreement.waterInvoices?.map(renderInvoice)
                    : agreement.invoices?.map(renderInvoice)}
                </span>
              </section>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              extra={
                agreement.paid ? (
                  <Tag color="#95bf48">Pagado</Tag>
                ) : (
                  // <Tag color="#2D9CDB">Pendiente</Tag>
                  <Button onClick={() => setShowModal(true)} type="primary">Pagar</Button>
                )
              }
              loading={fetching}
              title={'Recibos'}
              loading={fetching}
              style={{ minWidth: 400 }}
            >
              {agreement.receipts?.map(renderReceipt)}
            </Card>
          </Col>
        </Row>
      </SideLayout>
      <Modal
        width={650}
        onCancel={() => setShowModal(false)}
        footer={() => null}
        visible={showModal}
      >
        <GenerateCovenantReceipt
          fetching={fetching}
          onCancel={() => setShowModal(false)}
          agreement={agreement}
          lot={agreement.lot}
        />
      </Modal>
    </>
  )
}

const BoldText = ({ children }) => (
  <span style={{ fontWeight: '500' }}>{children}</span>
)

export default AgreementDetail
