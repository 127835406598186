import React, { useState } from 'react';
import {Modal, Tooltip, Upload, Spin, message, Table, Alert, Button, Tag} from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import readXlsxFile from 'read-excel-file'
import { formatMoney } from '../../../helpers/formatMoney';
import { useDispatch } from 'react-redux';
import { saveMultipleWaterInvoicesAction } from '../../../redux/adminDuck';



const { Dragger } = Upload;


const AddXLSX = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    const columns = [
        {
            title: 'No de lote',
            dataIndex: 'noLote',
            key: 'noLote',
          },
          {
            title: 'Serie del medidor',
            dataIndex: 'measurerNumber',
            key: 'measurerNumber',
          },
          {
            title: 'Año',
            dataIndex: 'year',
            key: 'year',
          },
          {
            title: 'Bimestre',
            dataIndex: 'twomonths',
            key: 'twomonths',
          },
          {
            title: 'Lectura anterior',
            dataIndex: 'previousMeasurement',
            key: 'previousMeasurement',
          },
          {
            title: 'Lectura actual',
            dataIndex: 'currentMeasurement',
            key: 'currentMeasurement',
          },
          {
            title: 'Importe',
            dataIndex: 'amount',
            key: 'amount',
            render:val=>val ? formatMoney(val) : <Tag color="red" >Error</Tag>
          },
          {
            title: 'Recargo',
            dataIndex: 'surcharge',
            key: 'surcharge',
            render:val=>val != null ? formatMoney(val) : <Tag color="red" >Error</Tag>
          },

    ]
    
    const formatArray = (rows) => {
        const objectsArray = rows.slice(1).reduce((arr, item)=>{
            if(!item[6]|| item.[7] == null ){
                setError("Algunas filas no tienen importe o recargo")
            }
            return [...arr, {
                noLote: item[0],
                measurerNumber:item[1],
                year:item[2],
                twomonths:item[3],
                previousMeasurement:item[4],
                currentMeasurement:item[5],
                amount:item[6],
                surcharge:item[7]
            }]
        },[])
        setData(objectsArray)
        return objectsArray
    }

    const handleUploadedFile = ({file:{originFileObj}}) => {
        setError(null)
        message.destroy()
        //
        setLoading(true)
        setStep(2)
        readXlsxFile(originFileObj)
        .then((rows) => {
            setStep(3)
            setLoading(false)
            formatArray(rows)
        })
        .catch(e=>{
            console.log("No se pudo leer el archivo: ", e)
            message.error('No fue posible leer el archivo');
            setLoading(false)
            setStep(1)
            setError("Hay problemas con el archivo, porfavor verifica el formato del archivo XLSX")
        })
    }

    const handleInvoiceCreation = () => {
        if(!data.length) return
        setLoading(true)
        setStep(4)
        dispatch(saveMultipleWaterInvoicesAction(data))
        .then(res=>{
            if(!res) {
                setLoading(false)
                setStep(1)
                return setError("No se pudieron generar las facturas")
            }
            setStep(1)
            setData([])
            setLoading(false)
            setError(null)
        })
    }

    const restart = () => {
        setError(null)
        setStep(1)
        setData([])
    }



    return ( <>
        <div>
            {loading &&  <div>Espera, esto puede tardar algunos minutos,<b> no cierres la ventana</b>...<Spin /></div>}

            {step===3 && <>
            <div  style={{display:'flex', justifyContent:'center',alignItems:'center', gap:20}}>
            <span>Se crearán  <strong>{data.length} </strong> facturas de agua</span>
            <Button 
                onClick={restart}
                type="secondary">
                Subir otro archivo
            </Button>
            <Button 
                disabled={loading || error}
                type="primary"
                onClick={handleInvoiceCreation}
            >
                Cargar
            </Button>
            {error && <Alert type="error" message={error}/>}
            </div>
              
                <Table
                columns={columns}
                dataSource={data}
                />
            </>}

            {!loading && step === 1 && <><Dragger
                accept=".xlsx"
                showUploadList={false}
                onChange={handleUploadedFile}
            >
                <p>
                <InboxOutlined />
                </p>
                <p >
                Haz clic o arrastra el archivo .xlsx correspondiente a las lecturas de agua del mes. 
              No olvides utilizar la plantilla del archivo con estructura establecida. 
                </p>
            </Dragger>
            {error && <Alert message={error} type="error" />}
            </>}
            
            
        </div>
    </> );
}
 
export default AddXLSX;