import React, { useState } from 'react'
import {
  Select,
  Tag,
  Card,
  Input,
  Divider,
  Table,
  Button,
  InputNumber,
} from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { formatMoney } from '../../helpers/formatMoney'
import { useHistory } from 'react-router-dom'
import {
  clearFomentInfoAction,
  createDepositReceiptAction,
} from '../../redux/fomento.duck'
import PrintingModal from '../Common/CashierPrint/PrintingModal'

const DepositSummary = () => {
  // constants
  const { Option } = Select
  const paymentMethod = [
    { code: 'EFEC', tag: 'Efectivo' },
    { code: 'TRANS', tag: 'Transferencia bancaria' },
    { code: 'CHEQ', tag: 'Cheque' },
    { code: 'TDDC', tag: 'Tarjeta de crédito' },
  ]
  const initialForm = {
    amount: 0,
    paymentMethod: 'EFEC',
    reference: '',
    notes: '',
  }
  //
  const dispatch = useDispatch()
  const history = useHistory()
  const { lot, deposit, fetching } = useSelector(({ fomento }) => fomento)
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [printing, setPrinting] = useState(false)
  const { displayName, id: userId } = useSelector(({ user }) => user)
  // methods
  const onChange = ({ target: { name, value } }) =>
    setForm({ ...form, [name]: value })
  const removeInvoiceOfAgreement = (record) => {
    // delete invoices[record.id]
    // const inv = { ...invoices }
    // setInvoices({ ...inv })
  }

  React.useEffect(() => {
    if (!lot.id || !deposit.id) {
      setForm(initialForm)
    }
  }, [lot])

  // Validation
  const isValidForm = () => {
    if (!lot.id || !deposit.id) return
    const err = {}
    if (!form.amount || typeof form.amount !== 'number' || form.amount < 1) {
      err.amount = 'Ingresa una cantidad'
    }
    if (!form.paymentMethod) err.paymentMethod = 'Selecciona una forma de pago'
    if (!displayName) {
      alert('Inicia sesión nuevamente porfavor')
      history.push('/signin')
    }
    setErrors(err)
    return !!!Object.keys(err).length
  }

  // Backend commute
  const saveDeposit = () => {
    if (!isValidForm()) return
    const body = {
      depositId: deposit.id,
      lotId: lot.id,
      totalPaid: form.amount,
      paymentForm: form.paymentMethod,
      reference: form.reference,
      notes: form.notes,
    }
    dispatch(createDepositReceiptAction(body))
    setPrinting(true)
  }

  return (
    <div>
      <Card
        title="Resumen del abono"
        style={{
          width: 586,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <section style={{ gap: 20, display: 'flex', flexDirection: 'column' }}>
          <div>
            <span style={{ color: 'grey', marginRight: 20 }}>Fecha:</span>
            <span style={{ color: 'black' }}>
              {moment(Date.now()).format('D/MMM/YYYY')}
            </span>
          </div>
          <article style={{ display: 'flex', gap: 30 }}>
            <div style={{ color: 'grey', margin: 0 }}>
              <p>Cantidad:</p>
              <InputNumber
                disabled={!lot.id}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                onChange={(value) =>
                  onChange({ target: { value, name: 'amount' } })
                }
                placeholder="$00.00"
                value={form.amount}
                style={{ width: 250 }}
              />{' '}
              MXN
            </div>
          </article>
          <span style={styles.error}>{errors.amount}</span>
          <article style={{ display: 'flex', gap: 30 }}>
            <div style={{ color: 'grey', margin: 0 }}>
              <p>Forma de pago</p>
              <Select
                onChange={(value) =>
                  onChange({ target: { name: 'paymentMethod', value } })
                }
                value={form.paymentMethod}
                style={{
                  width: 125,
                  marginRight: 24,
                }}
              >
                {paymentMethod.map((ser) => (
                  <Option key={ser.code}>{ser.code}</Option>
                ))}
              </Select>
              <Input
                placeholder={form.monthlyPaymentAmount}
                value={
                  paymentMethod.find((pm) => pm.code === form.paymentMethod)
                    ?.tag
                }
                style={{ width: 200 }}
              />
              <span style={styles.error}>{errors.paymentMethod}</span>
            </div>
          </article>

          <article style={{ display: 'flex', gap: 30 }}>
            <div style={{ color: 'grey', margin: 0 }}>
              <span>Referencia</span>
              <Input
                onChange={onChange}
                placeholder={'Escribe una referencia'}
                value={form.reference}
                name="reference"
                style={{ width: 250 }}
              />
            </div>
            <div style={{ color: 'grey', margin: 0 }}>
              <span>Autorizado por:</span>
              <Input value={displayName} style={{ width: 250 }} />
            </div>
          </article>

          <div style={styles.formItem}>
            <span>Notas:</span>
            <Input
              onChange={onChange}
              placeholder="Ingresa un comentario"
              value={form.notes}
              name="notes"
              style={{ width: '100%' }}
            />
          </div>
        </section>
        <Divider />
        <section>
          <h3>Servicio</h3>
          <Table
            onRow={(record) => ({
              onClick: () => removeInvoiceOfAgreement(record),
            })}
            size="small"
            pagination={false}
            dataSource={[{ amount: form.amount }]}
            columns={[
              {
                title: 'Descripción',
                dataIndex: 'after1500',
                key: 'after1500',
                render: (val) => 'Depósito a cuenta',
              },
              {
                title: 'Importe',
                dataIndex: 'amount',
                key: 'amount',
                render: () => formatMoney(form.amount),
              },
              {
                title: 'Total',
                dataIndex: 'amount',
                key: 'amount',
                render: () => formatMoney(form.amount),
              },
            ]}
          />
          <span style={styles.error}>{errors.invoices}</span>
          <div
            style={{
              marginTop: 150, // change this when real table
              display: 'flex',
              gap: 100,
              justifyContent: 'flex-end',
            }}
          >
            <span>Total: {formatMoney(form.amount, true)}</span>
            <span>
              {/* {formatMoney(Object.values(invoices).reduce((acc, inv) => acc + inv.total, 0), true)} */}
            </span>
          </div>
        </section>
      </Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: 40,
        }}
      >
        <Button
          type="primary"
          loading={fetching}
          onClick={saveDeposit}
          disabled={fetching || !lot.id || lot.delinquency}
          style={
            fetching || Object.keys(errors).length
              ? styles.disabledButton
              : styles.enabledButton
          }
        >
          Pagar
        </Button>
      </div>

      <PrintingModal
        onFinish={() => dispatch(clearFomentInfoAction())}
        show={printing}
        onCancel={() => setPrinting(false)}
      />
    </div>
  )
}

const styles = {
  tag: { borderRadius: 20, cursor: 'pointer' },
  error: { color: 'red' },
}

export default DepositSummary
