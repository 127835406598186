import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import {
    Select,
    Tag,
    Card,
    Input,
    Divider,
} from 'antd'
import { findLotAction, getDepositByLot, getFomentByLot, updateDepositAction } from '../../redux/fomento.duck';
import sectionNames from '../Common/CashierForm/sectionNames'
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select

const serviceCodes = [
    { code: 'MANT006', tag: 'Servicios Públicos Urbanos' },
    { code: 'TAG001', tag: 'Tag Residencial' },
    { code: 'MULT002', tag: 'Multas Residenciales' },
    { code: 'EXT003', tag: 'Productos extra' },
    { code: 'DEP004', tag: 'Depósitos' }
];

const LotSearcher = ({ disabled, serviceCode = 'DEP004', onFind, }) => {
    const dispatch = useDispatch()
    const { lot, foment, deposit, fetching } = useSelector(({ fomento }) => fomento)
    useEffect(() => { // despues de encontrar un lote
        if (!lot.id) {
            setForm({ ...form, resident: {} })
            return
        }
        const resident = getMainResident(lot)
        setForm({ ...form, resident })
    }, [lot])
    const [form, setForm] = useState({
        sectionName: undefined,
        serviceCode: serviceCode,
        monthlyPaymentAmount: 0,
        resident: {},
        services: []
    })
    const onChange = ({ target: { name, value } }) => {
        if (name === 'seccion') {
            const sectionName = sectionNames[value]
            setForm({ ...form, [name]: value, sectionName })
            // }else if (name === "paymentsNumber") {
            // setForm({ ...form, [name]: value, monthlyPaymentAmount: (Object.values(invoices).reduce((acc, inv) => acc + inv.total, 0) / value) })
        } else {
            setForm({ ...form, [name]: value?.toUpperCase() })
        }
    }
    const chekIfEnterKey = e => { // focus and select
        if (e.key === "Enter") {
            const form = e.target.form;
            let index = Array.prototype.indexOf.call(form, e.target);
            let next = index + 1
            if (!form.elements[next]) { findLot(); next = 0; }
            // if(form.elements[next])
            if (form.elements[next].name === "sectionName") next++
            form.elements[next].focus();
            form.elements[next].select()
            e.preventDefault();
        }
    }

    const getMainResident = (lot) => { // returns a resident
        let max = 0
        let id
        for (let elem of lot.percent) {
            if (Number(elem.percent) > max) {
                max = Number(elem.percent)
                id = elem.resident
            }
        }
        const resident = lot.residents.find(res => res.id == id)
        if (resident && resident.id) {
            resident.displayName = resident.firstName + " " + resident.lastName
            return resident
        }
        return null
    }

    const findLot = () => {
        // find lot
        const query = {
            seccion: form.seccion,
            manzana: form.manzana,
            lote: form.lote
        }
        dispatch(findLotAction(query))
    }
    return (<>
        <section style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24
        }}>
            <Card
                style={{
                    width: 600
                }}
                title={"Información del lote"}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20
                }}>
                    <form
                        onKeyDown={chekIfEnterKey}
                        style={{ gap: 20, display: "flex", alignItems: "flex-end" }}>
                        <div style={styles.formItem}>
                            <span>Sección:</span>
                            <Input
                                name="seccion"
                                onChange={onChange}
                                style={{ width: 60 }} />
                        </div>
                        <div style={styles.formItem}>
                            <Input
                                name="sectionName"
                                value={form.sectionName}
                            />
                        </div>
                        <div style={styles.formItem}>
                            <span>Manzana:</span>
                            <Input
                                name="manzana"
                                onChange={onChange}
                                style={{ width: 60 }} />
                        </div>
                        <div style={styles.formItem}>
                            <span>Lote:</span>
                            <Input
                                name="lote"
                                onChange={onChange}
                                style={{ width: 60 }} />
                        </div>
                    </form>

                    <section
                        onKeyDown={chekIfEnterKey}
                        style={{ gap: 20, display: "flex", alignItems: "flex-end" }}>
                        <div style={styles.formItem}>
                            <span>Id:</span>
                            <Input
                                value={form.resident?.number}
                                name="resident.number"
                                style={{ width: 60 }} />
                        </div>
                        <div style={styles.formItem}>
                            <span>Nombre del residente:</span>
                            <Input
                                value={form.resident?.displayName || 'Sin nombre'}
                                name="resident.displayName"
                                style={{ width: 360 }}
                            />
                        </div>
                        <a href="#!">
                            Ver detalle
                        </a>
                    </section>
                </div>
                <Divider />
                <h3>Información del servicio</h3>

                <section
                    onKeyDown={chekIfEnterKey}
                    style={{ gap: 20, display: "flex", alignItems: "flex-end" }}>
                    <div style={styles.formItem}>
                        <span>Código:</span>
                        <Select
                            disabled={disabled}
                            onChange={val => setForm({ ...form, serviceCode: val })}
                            defaultValue={"DEP004"}
                            value={form.serviceCode}
                            name="serviceCode"
                            style={{
                                width: 120
                            }}
                            placeholder="Código de servicio"
                        >
                            {serviceCodes.map(ser => <Option key={ser.code}>{ser.code}</Option>)}
                        </Select>
                    </div>
                    <div style={styles.formItem}>
                        <Input
                            value={serviceCodes.find(ser => ser.code === form.serviceCode)?.tag}
                            name="serviceName"
                            style={{ width: 360 }}
                        />
                    </div>
                </section>
                <hr style={{ border: "none", marginTop: 20 }} />

            </Card>

        </section>
    </>);
}

const styles = {
    tag: { borderRadius: 20, cursor: "pointer" }
}

LotSearcher.propTypes = {
    serviceCode: PropTypes.string,
    onFind: PropTypes.func.isRequired
}

export default LotSearcher;