import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Modal, Button } from 'antd';
import PropTypes from 'prop-types';

import { USERS } from '../../../constants/routes';

import SendMailImage from '../../../assets/svgs/SendMail.svg';
import styles from './UserFormActions.module.css';

function UserFormActions({ send, handleSubmit, isCreate, loading }) {
  const history = useHistory();

  const goBack = () => {
    history.push(USERS);
  };

  return (
    <>
      <Row justify="center" gutter={[30, 0]} className={styles.actions}>
        {isCreate ? (
          <Button
            htmlType="submit"
            type="primary"
          >
            Agregar
          </Button>
        ) : (
            <>
              <Col>
                <Button
                  onClick={goBack}
                  htmlType="button"
                  className={styles.btn__cancel}
                >
                  Cancelar
              </Button>
              </Col>
              <Col>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Guardar
              </Button>
              </Col>
            </>
          )}
      </Row>
      <Modal
        title="Enviando email"
        centered
        visible={send}
        onOk={() => handleSubmit(false)}
        onCancel={() => handleSubmit(false)}
        className={styles.modal__print}
        footer={[
          <Button
            key="back"
            onClick={() => handleSubmit(false)}
            className={styles.modal__print__btn}
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={true}
            onClick={() => handleSubmit(false)}
            className={styles.modal__print__btn}
          />,
        ]}
      >
        <p className="textColor">
          Espera un momento, estamos enviando el mail de invitación al nuevo
          usuario.
        </p>
        <Row justify="center">
          <img src={SendMailImage} alt="print files" />
        </Row>
      </Modal>
    </>
  );
}

export default UserFormActions;

UserFormActions.defaultProps = {
  send: false,
  isCreate: false,
  loading: false,
};

UserFormActions.propTypes = {
  send: PropTypes.bool,
  handleSubmit: PropTypes.any,
  isCreate: PropTypes.bool,
  loading: PropTypes.bool,
};
