import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, message, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Card } from '../index';
import cardStyles from '../Card/Card.module.css';
import { addDebtsToReceiptAction, getFomentByLot } from '../../../redux/fomento.duck';

import styles from './CashierDebts.module.css';
import { formatMoney } from '../../../helpers/formatMoney';
import { getOneAgreementAction } from '../../../redux/agreementsDuck';
import { Link } from 'react-router-dom';
function CashierDebts({ type }) {
  // const { foments } = useSelector((state) => state.fomento.lot);
  const dispatch = useDispatch();
  const { foment, lot } = useSelector(({ fomento }) => fomento);

  const columns = [
    {
      title: 'Año',
      dataIndex: 'year',
      key: 'year',
      fixed: 'left'
    },
    {
      title: 'Hasta 1500 m2',
      dataIndex: 'before1500',
      key: 'before1500',
      render: (value) => formatMoney(value, true),
    },
    {
      title: 'Más de 1500 m2',
      dataIndex: 'after1500',
      key: 'after1500',
      render: (value) => formatMoney(value, true),
    },
    {
      title: 'Clúster',
      dataIndex: 'clusterAmount',
      key: 'clusterAmount',
      render: (value) => formatMoney(value, true),
    },
    {
      title: 'Abono',
      dataIndex: 'receipts',
      key: 'receipts',
      render: (value = 0) => formatMoney(value, true),
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
      fixed: 'right',
      render: (_, record) => formatMoney(Number(record.before1500) + Number(record.after1500) + Number(record.clusterAmount), true),
    },
  ];

  const tempData = [
    {
      id: 1,
      year: '2017',
      col1: 120000,
      m2: 30000,
      col3: 7500,
      col4: 400,
      col5: 400,
      col6: 9000,
      receipts: 400,
      amount: 20000,
    },
    {
      id: 2,
      year: '2020',
      col1: 40000,
      m2: 10000,
      col3: 10500,
      col4: 400,
      col5: 400,
      col6: 800,
      receipts: 400,
      amount: 40000,
    },
    {
      id: 3,
      year: '2019',
      col1: 12300,
      m2: 11700,
      col3: 1200,
      col4: 400,
      col5: 400,
      col6: 800,
      receipts: 400,
      amount: 60000,
    },
  ];

  useEffect(() => {
    if (lot.id) {
      dispatch(getFomentByLot(lot.id))
        .then(() => {
          // console.log("el foment: ", foment)
        })
    }

  }, [lot])

  useEffect(() => {
    // console.log("el foment: ", foment)
  }, [foment])

  const handleRowClick = (debt) => {
    if( debt.agreement && debt.agreement.length>5 ){ // existe un convenio
      dispatch(getOneAgreementAction(debt.agreement))
      .then(res=>{
        if(res){
          notification.info({
            message:"Factura en Convenio",
            description:<p>Esta factura tiene un convenio con el folio <a href={`/admin/convenios/${res.id}`}>{res.folio}</a></p>,
            duration:0
        })
        }
      })

    } else dispatch(addDebtsToReceiptAction(debt));
  };
  return (
    <Card>
      <h4 className={cardStyles.card__title}>Adeudos</h4>
      <Table
        columns={columns}
        dataSource={lot.id && foment.length ? foment : []}
        scroll={{ x: 700, y: 300 }}
        pagination={false}
        size="small"
        className={cardStyles.card__table__responsive}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
      <Row gutter={[15, 0]} justify="end" className={styles.debts__footer}>
        Deuda
        <Col className={styles.debts__footer__money}>{formatMoney(foment.reduce((acc, inv) => acc + inv.before1500 + inv.after1500 + inv.clusterAmount, 0), true)}</Col>
        {/* {type === 'systems' && (
          <>
            <Col className={styles.debts__footer__money}>$20,000.00</Col>
            <Col className={styles.debts__footer__money}>$00.00</Col>
            <Col className={styles.debts__footer__money}>$1,140,000.00</Col>
          </>
        )} */}
      </Row>
    </Card>
  );
}

export default CashierDebts;

CashierDebts.defaultProps = {
  data: [],
  columns: [],
};

CashierDebts.propTypes = {
  type: PropTypes.oneOf(['promotion', 'services', 'systems']),
  data: PropTypes.array,
  columns: PropTypes.array,
};
