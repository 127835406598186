import { Button, Pagination, Table } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeMultipleWaterInvoicesAction } from '../../../redux/adminDuck'
import {
  getFomentByLotPaginatedAction,
  getWaterInvoicesByLotAction,
  getLomasReceiptsByLotAction,
} from '../../../redux/fomento.duck'
import columns from './Columns'

/**  variant = 'sistemas' | 'fomento' | 'lomas' */

const InvoicesTable = ({ lot, variant = 'sistemas' }) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = React.useState([])
  // review this
  const {
    fetching,
    water,
    paginatedFoment,
    lomasReceiptsPaginated,
  } = useSelector(({ fomento }) => fomento)

  React.useEffect(() => {
    switch (variant) {
      case 'lomas':
        return getLomasReceipts()
      case 'fomento':
        return getFomentInvoices()
      default:
        return getWater()
    }
  }, [lot])

  const paginated = React.useMemo(() => {
    switch (variant) {
      case 'lomas':
        return lomasReceiptsPaginated
      case 'fomento':
        return paginatedFoment
      default:
        return water
    }
  }, [lot, water, paginatedFoment, lomasReceiptsPaginated])

  const getLomasReceipts = (pages) => {
    dispatch(getLomasReceiptsByLotAction(lot.id, pages))
  }

  const getWater = (pages) => {
    dispatch(getWaterInvoicesByLotAction(lot.id, pages))
  }

  const getFomentInvoices = (pages) => {
    dispatch(getFomentByLotPaginatedAction(lot.id, paginated))
  }

  const { total, page, limit, items } = paginated

  const changePage = (nextPage) => {
    const pages = {
      p: nextPage,
      l: limit,
    }
    switch (variant) {
      case 'lomas':
        return getLomasReceipts(pages)
      case 'fomento':
        return getFomentInvoices(pages)
      default:
        return getWater(pages)
    }
  }

  const deleteMultiple = () => {
    if (!window.confirm(`Estas seguro de eliminar ${selected.length} facturas de agua`)) {
      return
    }
    switch (variant) {
      case 'lomas':
      // return getLomasReceipts(pages)
      case 'fomento':
      // return getFomentInvoices(pages)
      default:
        return dispatch(removeMultipleWaterInvoicesAction(selected)).then(() => {
          getWater()
          setSelected([])
        })
    }


  }

  return (
    <div>
      {!!selected.length && <Button onClick={deleteMultiple} type="danger">Eliminar {selected.length}</Button>}
      <Table
        rowKey="_id"
        loading={fetching}
        rowSelection={variant === 'sistemas' && {
          type: 'checkbox',
          onChange: ((keys) => {
            console.log(keys)
            setSelected(keys)
          }),
          getCheckboxProps: (record) => ({
            disabled: record.paid,
          }),
        }}
        columns={
          variant === 'sistemas'
            ? columns.systemsColumns
            : variant === 'fomento'
              ? columns.fomentColumns
              : columns.lomasColumns
        }
        dataSource={items} // to show actual invoices
        pagination={false}
      />
      {/* <TableCustomFooter
          total={data.length}
          totalAmount="$1,140,000.00"
          type="systems"
        /> */}

      <Pagination
        showSizeChanger={false}
        pageSize={limit || 50}
        current={page}
        onChange={changePage}
        total={total}
        style={{ marginTop: 32 }}
      />
    </div>
  )
}

export default InvoicesTable
