import { Skeleton, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getGeneralStatsAction, getStatsByUserAction } from '../../../redux/user.duck';
import { Card } from '../index';
import Row from '../Row';

import styles from './Total.module.css';

function Total({ isGlobal }) {

  const dispatch = useDispatch()
  const { stats, loading } = useSelector(({ user }) => user)
  const {
    totalCancelledAmount,
    totalCancelledReceipts,
    count,
    tddcTotal,
    cashTotal,
    greatTotal,
    fomentoTotal,
    sistemasTotal,
    lomasTotal,
    transferTotal,
    cheqTotal,

    sistemsCashTotal,
    sistemsTddcTotal,
    sistemsTransferTotal,
    sistemsCheqTotal,

    fomentCashTotal,
    fomentTddcTotal,
    fomentTransferTotal,
    fomentCheqTotal,

    lomasCashTotal,
    lomasTddcTotal,
    lomasTransferTotal,
    lomasCheqTotal,

  } = stats

  useEffect(() => {
    if (isGlobal) dispatch(getGeneralStatsAction())
    else dispatch(getStatsByUserAction())
  }, [])

  const formatMoney = (number, showMoney = false) => {
    showMoney = showMoney ? "MXN" : ""
    return `$${Math.floor(Number(number)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00 ${showMoney}`
  }

  if (loading) return <Card ><Skeleton /></Card>

  return (
    <Card>
      <h2 className="title--green">
        Total {formatMoney(greatTotal, true)}
      </h2>
      <div className={styles.total__table}>
        <Row
          titleOne="Sistemas"
          titleTwo="Fomento"
          titleThree="Lomas"
        />
        <Row
          titleOne="Efectivo"
          detailOne={formatMoney(sistemsCashTotal)}
          titleTwo="Efectivo"
          detailTwo={formatMoney(fomentCashTotal)}
          titleThree="Efectivo"
          detailThree={formatMoney(lomasCashTotal)}
        />
        <Row
          titleOne="Tarjeta de Crédito"
          detailOne={formatMoney(sistemsTddcTotal)}
          titleTwo="Tarjeta de Crédito"
          detailTwo={formatMoney(fomentTddcTotal)}
          titleThree="Tarjeta de Crédito"
          detailThree={formatMoney(lomasTddcTotal)}
        />
        <Row
          titleOne="Transferencia Bancaría"
          detailOne={formatMoney(sistemsTransferTotal)}
          titleTwo="Transferencia Bancaría"
          detailTwo={formatMoney(fomentTransferTotal)}
          titleThree="Transferencia Bancaría"
          detailThree={formatMoney(lomasTransferTotal)}
        />
        <Row
          titleOne="Cheques"
          detailOne={formatMoney(sistemsCheqTotal)}
          titleTwo="Cheques"
          detailTwo={formatMoney(fomentCheqTotal)}
          titleThree="Cheques"
          detailThree={formatMoney(lomasCheqTotal)}
        />
        <Row
          titleOne="Total"
          detailOne={formatMoney(sistemsCheqTotal + sistemsTransferTotal + sistemsTddcTotal + sistemsCashTotal)}
          titleTwo="Total"
          detailTwo={formatMoney(fomentCheqTotal + fomentTransferTotal + fomentTddcTotal + fomentCashTotal)}
          titleThree="Total"
          detailThree={formatMoney(lomasCheqTotal + lomasTransferTotal + lomasTddcTotal + lomasCashTotal)}
        />

      </div>
    </Card>
  );
}

export default Total;