import React from 'react';
import { Row, Col, Tag, Card } from 'antd';
// import Line from '../../../assets'
// import Card from '../Card/Card';
import ListItem from '../ListDetailItem/index';

// import { INVOICES_DETAIL_TAG } from '../../../constants/routes';
import { formatMoney } from '../../../helpers/formatMoney';


function InvoiceDetailsTickets({ lot = {}, year, serviceCode, paid, receipts, fetching = true }) {
  const monto = receipts?.reduce((acc, rec) => acc + rec.totalPaid, 0)
  const descuento = receipts?.reduce((acc, rec) => acc + rec.discountQuantity, 0)

  const getMainResident = (lot) => { // returns a resident
    //validation
    if (!lot.percent) return {}
    let max = 0
    let id
    for (let elem of lot.percent) {
      if (Number(elem.percent) > max) {
        max = Number(elem.percent)
        id = elem.resident
      }
    }
    const resident = lot.residents.find(res => res.id == id)
    if (resident.id) resident.displayName = resident.firstName + " " + resident.lastName
    return resident
  }

  const resident = getMainResident(lot)

  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Card
            loading={fetching}
            style={{ maxWidth: 600 }}>
            <h3 className="subtitle-strong-18">Información del lote</h3>
            {/* <ListItem label="Medidor:" description={<Link>#23456</Link>} /> */}
            <ListItem label="Dirección:" description={`Sección: ${lot.seccion}, Manzana: ${lot.manzana}, Lote:${lot.lote}`} />
            <ListItem label="Residente:" description={resident.displayName} />
            <ListItem label="Superficie:" description={lot.area + "m2"} />
            <ListItem label="Clúster:" description={lot.cluster + "m2"} />
          </Card>
        </Col>

      </Row>
      <br />
      <Col span={12}>
        <Card
          loading={fetching}
          style={{ maxWidth: 600 }}>
          <h3 className="subtitle-strong-18">Detalle de cobro</h3>
          {/* <ListItem label="Código:" description="SISTE12" /> */}
          <ListItem label="Servicio:" description={serviceCode} />
          <ListItem label="Año:" description={year} />
          {paid &&
            <>
              <ListItem label="Descuento" description={formatMoney(descuento, true)} />
              <ListItem
                label="Monto Pagado:"
                description={formatMoney(monto, true)}
              />
              <ListItem label="Estatus:" description={<Tag color="#97CD74">Pagado</Tag>} />
            </>
          }
          {!paid && <ListItem label="Estatus:" description={<Tag color="#F0B046">Pendiente</Tag>} />}
        </Card>
      </Col>
    </div>
  );
}

export default InvoiceDetailsTickets;
