import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import styles from './ButtonBordered.module.css';

function ButtonBordered(props) {
  const getColorStyle = () => {
    switch (props.color) {
      case 'default':
        return styles.btn__bordered;
      case 'blue':
        return styles.btn__bordered__blue;
      case 'green':
        return styles.btn__bordered__green;
      default:
        return styles.btn__bordered;
    }
  };

  return (
    <Button onClick={props.onClick} className={getColorStyle()} {...props}>
      {props.children}
    </Button>
  );
}

export default ButtonBordered;

ButtonBordered.defaultProps = {
  color: '',
};

ButtonBordered.propTypes = {
  color: PropTypes.oneOf(['', 'blue', 'green', 'default']),
};
