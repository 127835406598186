import React, { useEffect, useState } from 'react'
import { SideLayout } from '../Common'
import LotSearcher from './LotSearcher'
import Debts from './Debts'
import ReceiptSummary from './ReceiptSummary'
import { useSelector } from 'react-redux'
import { clearFomentInfoAction } from '../../redux/fomento.duck'
import { useDispatch } from 'react-redux'
import MultaSummary from './MultaSummary'
import { Empty } from 'antd'

function Caja({ history }) {
  const dispatch = useDispatch()
  // const { } = useSelector(({ fomento }) => fomento)
  const role = useSelector(({ user }) => user.role.toLowerCase())
  const [code, setCode] = useState('AGU003')
  useEffect(() => () => dispatch(clearFomentInfoAction()), [])
  return (
    <SideLayout type={role}>
      <h2 className="title">Sistemas</h2>
      <h3 className="title-description">
        Cobra servicios de los residentes y haz el pago de su agua.
      </h3>
      <div
        style={{
          display: 'flex',
          gap: 40,
        }}
      >
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 40,
          }}
        >
          <LotSearcher onCodeChange={(val) => setCode(val)} />
          {code === 'AGU003' && <Debts />}
        </section>
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 40,
          }}
        >
          {code === 'MULT002' ? (
            <MultaSummary variant="mult" />
          ) : (
            <ReceiptSummary />
          )}
        </section>
      </div>
    </SideLayout>
  )
}

export default Caja
