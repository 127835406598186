import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Table,
  Table as AntTable,
  Col,
  Row,
  Tag,
  Tooltip,
  Modal,
  Input,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'
import {
  Seeker,
  Card,
  SideLayout,
  TableCustomFooter,
  ButtonBordered,
  EditLotModal,
} from '../Common'
import { Route } from 'react-router-dom'
import styles from './BatchesDetail.module.css'
import { getLotAction, updateMeasurer } from '../../redux/lotsDuck'
import {
  BATCHES_MESSAGES,
  ADMIN_BATCHES_MESSAGES,
} from '../../constants/routes'
import { formatMoney } from '../../helpers/formatMoney'
import moment from 'moment'
import { getMessagesByLotAction } from '../../redux/messagesDuck'
import InvoicesTable from './internal/InvoicesTable'
import DepositCard from './internal/DepositCard'

function LotDetail({ match, history }) {
  const [measurerEdit, setMeasurerEdit] = useState(null)
  const { isAdmin, role } = useSelector(({ user }) => user)
  const { TabPane } = Tabs
  const [modalVisible, setModalVisible] = useState(false)

  const dispatch = useDispatch()
  const { lot } = useSelector(({ lots }) => lots)

  const { invoices = [] } = lot
  const totalDebt = invoices.reduce(
    (acc, inv) =>
      inv.paid
        ? acc + 0
        : acc + inv.before1500 + inv.after1500 + inv.clusterAmount,
    0,
  )

  useEffect(() => {
    if (match.params.batch) {
      dispatch(getLotAction({ id: match.params.batch }))
    }
  }, [])

  const handleOpenModal = () => {
    history.push(`/admin/lotes/detalle/${lot.id}/edit`)
  }

  const handleShowMessages = () => {
    history.push(
      (isAdmin ? ADMIN_BATCHES_MESSAGES : BATCHES_MESSAGES).replace(
        ':batch',
        lot.id,
      ),
    )
  }

  const openMeasurerModal = () => { }

  const [modal, contextHolder] = Modal.useModal()

  return (
    <>
      <SideLayout type={isAdmin ? 'admin' : 'cajero'}>
        <h2 className={styles.title}>
          <span style={{ marginRight: 15 }}>Id {lot.number}</span>
          <ButtonBordered onClick={handleOpenModal} color="blue">
            Editar lote
          </ButtonBordered>
        </h2>

        <div className={styles.topContainer}>
          <div className={styles.batchesInformation}>
            <Row justify="space-between">
              <Col>
                <h3 className={styles.batchesInformation_title}>
                  {' '}
                  Información del lote{' '}
                </h3>
                <hr style={style.hr} />
              </Col>
              {(isAdmin || role === 'MANAGER') && (
                <Col>
                  <a onClick={() => handleShowMessages()} href="#!">
                    Ver mensajes
                  </a>
                </Col>
              )}
            </Row>

            <p
              className={styles.batchesInformation__column}
              style={{ color: ' #828282' }}
            >
              {' '}
              Calle: <span style={style.basicInfo}>{lot.street}</span>
              {/* <span style={{ color: '#95BF48', weight: '800' }}></span> */}
            </p>
            <p style={{ color: ' #828282' }}>
              {' '}
              Dirección:
              <span style={style.basicInfo}>
                Sección: {lot.seccion} - Manzana: {lot.manzana} - Lote:{' '}
                {lot.lote}
                <span style={{ color: '#595959', weight: '800' }}></span>{' '}
              </span>
            </p>
            <p style={{ color: ' #828282' }}>
              {' '}
              Residentes:{' '}
              <span style={style.basicInfo}>
                {lot.residents.map((r) => (
                  <span key={r.id}>{`${r.firstName} ${r.lastName},`} </span>
                ))}
                <span style={{ color: '#595959', weight: '800' }}></span>{' '}
              </span>
            </p>
            <p style={{ color: ' #828282' }}>
              Superficie:{' '}
              <span style={style.basicInfo}>
                {lot.area}
                <span style={{ color: '#595959', weight: '800' }}>
                  {lot.measureUnit}
                </span>{' '}
              </span>
            </p>
            <p style={{ color: ' #828282' }}>
              {' '}
              Clúster:{' '}
              <span style={style.basicInfo}>
                <span style={{ color: '#595959', weight: '800' }}>
                  {lot.cluster === 0 || !lot.cluster ? 'N/A' : lot.cluster}
                </span>{' '}
              </span>
            </p>
            <p style={{ color: ' #828282' }}>
              {' '}
              Fecha de compra:{' '}
              <span style={style.basicInfo}>
                <span style={{ color: '#595959', weight: '800' }}>
                  {lot.yearOfPurchase}
                </span>{' '}
              </span>
            </p>
            <p style={{ color: ' #828282' }}>
              {' '}
              Medidores de agua:{' '}
              <span style={style.basicInfo}>
                {lot.measurer?.map((m) => (
                  <Tooltip key={m.id} title="Editar medidor">
                    <Tag
                      onClick={() => {
                        setMeasurerEdit({ ...m })
                        setModalVisible(true)
                      }}
                      style={style.tag}
                      color="#95bf48"
                    >
                      {`#${m.number}`}{' '}
                    </Tag>
                  </Tooltip>
                ))}
              </span>
            </p>
            <p style={{ color: ' #828282' }}>
              Servicios de luz:{' '}
              {lot.luz?.map((luz) => (
                <Tag style={style.tag} color="#95bf48">
                  {luz}
                </Tag>
              ))}
            </p>
            <p style={{ color: ' #828282' }}>
              Servicios de gas:{' '}
              {lot.gas?.map((gas) => (
                <Tag style={style.tag} color="#95bf48">
                  {gas}
                </Tag>
              ))}
            </p>
            <p style={{ color: ' #828282' }}>
              Servicios de teléfono:{' '}
              {lot.telefono?.map((telefono) => (
                <Tag style={style.tag} color="#95bf48">
                  {telefono}
                </Tag>
              ))}
            </p>
          </div>

          {/* <div className={styles.messagesInfo}>
            <section className={styles.messagesTable}>
              <header className={styles.messages_header}>
                <h3 style={{ fontSize: '18px' }}>Mensajes</h3>
                <span
                  className={styles.link_view}
                  style={{ color: '#2D9CDB', fontSize: '14px' }}
                  onClick={handleShowMessages}
                >
                  Ver todos{' '}
                </span>
              </header>
              <AntTable
                scroll={{ y: 150 }}
                columns={usersMessages}
                dataSource={messages}
                pagination={false}
              />
            </section>
          </div> */}
        </div>

        <div className={styles.divider}>
          <div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Sistemas" key="1">
                <InvoicesTable lot={lot} />
              </TabPane>
              <TabPane tab="Fomento" key="2">
                <InvoicesTable variant="fomento" lot={lot} />
              </TabPane>
              <TabPane tab="Lomas" key="3">
                <div style={{ display: 'flex', gap: 20 }}>
                  <DepositCard lot={lot} />
                  <InvoicesTable lot={lot} variant="lomas" />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </SideLayout>
      {/* <EditBatchModal
        invoice={dataDetail}
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
      /> */}
      <Route path={`/admin/lotes/detalle/:id/edit`} component={EditLotModal} />

      <Modal
        title="Editar Medidor"
        visible={modalVisible}
        onOk={() => {
          Modal.confirm({
            title: '¿Estas segur@ de modificar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Esta acción no es reversible',
            okText: 'Si',
            cancelText: 'No',
            onOk: () => {
              dispatch(updateMeasurer(measurerEdit))
              setModalVisible(false)
            },
            onCancel: () => setModalVisible(false),
          })
        }}
        onCancel={() => setModalVisible(false)}
        okText="Guardar"
      >
        <p> Modifica el número del medidor </p>
        <Input
          onChange={({ target: { value } }) =>
            setMeasurerEdit({ ...measurerEdit, number: value })
          }
          value={measurerEdit?.number}
        />
        <span style={{ color: 'red', fontSize: 10 }}>
          <ExclamationCircleOutlined /> Si modificas el número del medidor,
          todos los registros relacionados serán actualizados
        </span>
      </Modal>
    </>
  )
}

const style = {
  basicInfo: { color: '#595959', fontWeight: '500' },
  hr: { backgroundColor: '#ccc', border: 'none', height: 1 },
  tag: { borderRadius: 20, cursor: 'pointer' },
}

export default LotDetail
