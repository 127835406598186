import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { Drawer, List, Avatar } from 'antd';

const exampleData = [
  { title: 'Cancelación de recibo', message: 'Jose Luis ha cancelado un recibo por $1,000.00 MXN', date: '12:30 hrs' },
  { title: 'Cancelación de recibo', message: 'Jose Luis ha cancelado un recibo por $1,000.00 MXN', date: '20/oct/2020' },
  { title: 'Cancelación de recibo', message: 'Jose Luis ha cancelado un recibo por $1,000.00 MXN', date: '17/oct/2020' },
]

function Notifications({ visible, onClose }) {
  return (
    <Drawer
      title={<span style={{ color: '#95BF48', fontSize: 18, fontWeight: 800 }}>Notificaciones</span>}
      placement="right"
      closable={false}
      visible={visible}
      onClose={() => onClose()}
      width={360}
    >
      <List
        itemLayout="horizontal"
        dataSource={exampleData}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<FontAwesomeIcon icon={faUserAlt} />} />}
              title={<div style={{ fontSize: 14 }}>{ item.title }</div>}
              description={(
                <div style={{ display: 'flex', flexFlow: 'row' }}>
                  <div style={{ fontSize: 12 }}>
                    { item.message }
                  </div>
                  <div style={{ fontSize: 10, minWidth: 75, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    <div style={{ textAlign: 'right'}}>
                      { item.date }
                    </div>
                  </div>
                </div>
              )}
            />
          </List.Item>
        )}
      />
    </Drawer>
  );
}

export default Notifications;

Notifications.defaultProps = {
  visible: false,
};
