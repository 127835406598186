import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Row, Col, Divider, Card, Tag, Empty } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { SideLayout, InvoiceDetailTickets } from '../Common/index'
import { formatMoney } from '../../helpers/formatMoney'
import { useDispatch, useSelector } from 'react-redux'
import { getOneWaterInvoiceAction } from '../../redux/invoicesDuck'
import { Link } from 'react-router-dom'
import ListItem from '../Common/ListDetailItem'

const line = require('../../assets/svgs/Line_21.svg')

function WaterInvoiceDetail({
  match: {
    params: { id: invoiceId },
  },
}) {
  const dispatch = useDispatch()
  const { waterInvoice, fetching } = useSelector(({ invoices }) => invoices)
  const {
    pathname,
    // state
  } = useLocation()
  const routeType = pathname.split('/')[1]
  useEffect(() => {
    dispatch(getOneWaterInvoiceAction(invoiceId))
  }, [])

  const total = React.useMemo(
    () => waterInvoice.surcharge + waterInvoice.amount,
    [waterInvoice],
  )

  const invoice = React.useMemo(() => ({ ...waterInvoice }), [waterInvoice])
  const { lot = {}, agreement, measurer } = invoice
  const resident = React.useMemo(() => {
    // returns a resident
    //validation
    if (!lot || !lot.percent) return {}
    let max = 0
    let id
    for (let elem of lot.percent) {
      if (Number(elem.percent) > max) {
        max = Number(elem.percent)
        id = elem.resident
      }
    }
    const resident = lot.residents.find((res) => res.id == id)
    if (!resident) return
    if (resident.id) {
      resident.displayName = resident.firstName + ' ' + resident.lastName
    }
    return resident
  }, [invoice])

  return (
    <SideLayout type={routeType}>
      {invoice._id && (
        <h2 className="title">{`Factura ${invoice._id?.slice(
          invoice._id.length - 4,
        )}`}</h2>
      )}
      <Row gutter={[24, 0]} style={{ position: 'relative' }}>
        <Col span={12}>
          <Card loading={fetching} style={{ maxWidth: 600 }} title={'Monto'}>
            <h1 style={{ fontWeight: 'bold' }}>{formatMoney(total, true)}</h1>
          </Card>
        </Col>

        <Col span={12}>
          <Card loading={fetching}>
            <h3 className="subtitle-strong-18">
              Recibos <FontAwesomeIcon icon={faReceipt} />{' '}
            </h3>
            <Divider />
            {invoice.receipts?.map((rec) => (
              <><Link style={{ color: 'var(--blueAnt)' }} to={`/admin/recibos/${rec.id}`}>{rec.folio}</Link><br /></>
            ))}
            {!invoice.receipts?.length && <Empty />}
          </Card>
        </Col>
      </Row>
      <Card loading={fetching} style={{ maxWidth: 600 }}>
        <h3 className="subtitle-strong-18">Información del lote</h3>
        <Divider />
        <ListItem
          label="Dirección:"
          description={`Sección: ${lot.seccion}, Manzana: ${lot.manzana}, Lote:${lot.lote}`}
        />
        <ListItem label="Residente:" description={resident.displayName} />
        <ListItem label="Superficie:" description={lot.area + 'm2'} />
        <ListItem label="Clúster:" description={lot.cluster + 'm2'} />
      </Card>
      <br />
      <Card loading={fetching} style={{ maxWidth: 600 }}>
        <h3 className="subtitle-strong-18">Información de la factura</h3>
        <Divider />
        <ListItem label="Año:" description={invoice.year} />
        <ListItem label="Bimestre:" description={invoice.twomonths} />
        <ListItem label="Importe:" description={formatMoney(invoice.amount)} />
        <ListItem label="Medidor:" description={invoice.measurer?.number} />
        <ListItem
          label="Lectura anterior:"
          description={invoice.previousMeasurement}
        />
        <ListItem
          label="Lectura actual:"
          description={invoice.currentMeasurement}
        />

        <ListItem
          label="Recargo:"
          description={formatMoney(invoice.surcharge)}
        />
        {invoice.paid && (
          <ListItem
            label="Estatus:"
            description={<Tag color="#97CD74">Pagado</Tag>}
          />
        )}
        {!invoice.paid && (
          <ListItem
            label="Estatus:"
            description={<Tag color="#F0B046">Pendiente</Tag>}
          />
        )}
      </Card>
    </SideLayout>
  )
}

export default WaterInvoiceDetail
