import React from 'react'
import { Table, Pagination } from 'antd'
import { useLocation, Link, useHistory, Route } from 'react-router-dom'

import { Seeker, Card, SideLayout, EditLotModal } from '../Common'
import { BATCHES_DETAIL, ADMIN_BATCHES_DETAIL } from '../../constants/routes'
import { useSelector } from 'react-redux'
import styles from './Batches.module.css'
import { useDispatch } from 'react-redux'
import { clearLotAction, getLotsAction } from '../../redux/lotsDuck'

function Batches() {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const type = pathname.split('/')[1]
  const { isAdmin } = useSelector(({ user }) => user)
  const history = useHistory()
  const { lots, page, total, limit } = useSelector(({ lots }) => lots)

  const columns = [
    {
      title: 'Id',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Dirección',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => (
        <>
          {record.seccion} - {record.manzana} - {record.lote}
        </>
      ),
    },
    {
      title: 'Calle',
      dataIndex: 'street',
      key: 'street',
    },

    // {
    //   title: 'Residentes',
    //   dataIndex: 'residents',
    //   key: 'residents',
    //   render: value => value.map(v => <span>{v}, </span>)
    // },
    {
      title: 'Superficie',
      dataIndex: 'area',
      key: 'area',
      render: (value, record) => `${value}  ${record.measureUnit}`,
    },
    {
      title: 'Clúster',
      dataIndex: 'cluster',
      key: 'cluster',
    },
    // {
    //   title: 'Adeudo total',
    //   dataIndex: 'adeudo',
    //   key: 'adeudo',
    //   className: 'debt',
    // },
    {
      title: 'Estatus',
      dataIndex: 'delinquency',
      key: 'delinquency',
      render: (val) => renderTag(val),
    },
  ]

  React.useEffect(() => {
    changePage(1)
  }, [])

  const renderTag = (val) => {
    if (val === false) {
      return <div className={styles.paid}> Al corriente </div>
    } else return <div className={styles.pending}> Con adeudo </div>
  }

  const changePage = (nextPage) => {
    const paginated = {
      p: nextPage,
      l: limit,
    }
    dispatch(getLotsAction(paginated))
  }

  const onSearch = ({ target: { value } }) => {
    if (value === '') return dispatch(getLotsAction())
    if (value.length < 3) return
    const spl = value.split(' ')
    if (spl.length > 2) {
      const paginated = {
        q: `seccion=${spl[0]},manzana=${spl[1]},lote=${spl[2]}`,
      }

      dispatch(getLotsAction(paginated))
    } else if (isNaN(spl[0])) {
      const paginated = {
        q: `street=${spl[0]}`,
      }
      dispatch(getLotsAction(paginated))
    } else {
      const paginated = {
        q: `area=${spl[0]}`,
      }
      dispatch(getLotsAction(paginated))
    }
  }

  return (
    <SideLayout type={type} backButton={false}>
      <h2 className="title">Lotes</h2>
      <h3 className="title-description">
        Consulta la información de los lotes del residencial
      </h3>
      <Seeker
        onSearch={onSearch}
        btnAdd={{
          action: () => {
            dispatch(clearLotAction())
            history.push(`/admin/lotes/nuevo`)
          },
        }}
      />
      <Card>
        <Table
          className="batches-table"
          columns={columns}
          dataSource={lots}
          pagination={false}
          onRow={(record) => ({
            onClick: () =>
              history.push(
                (isAdmin ? ADMIN_BATCHES_DETAIL : BATCHES_DETAIL).replace(
                  ':batch',
                  record.id,
                ),
                {
                  batch: record.id,
                },
              ),
          })}
        />
        <Pagination
          showSizeChanger={false}
          pageSize={limit || 50}
          current={page}
          onChange={changePage}
          total={total}
          style={{ marginTop: 32 }}
        />
      </Card>

      <Route path={`/admin/lotes/nuevo`} component={EditLotModal} />
    </SideLayout>
  )
}

export default Batches
