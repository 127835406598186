import React, { useEffect, useState } from 'react'
import { Button, Card, Divider, Input, notification, Select, Table } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { SideLayout } from '../Common'
import sectionNames from '../Common/CashierForm/sectionNames'
import moment from 'moment'
import {
  findLotAction,
  getFomentByLot,
  getPendingWaterByLot,
} from '../../redux/fomento.duck'
import { formatMoney } from '../../helpers/formatMoney'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  createNewAgreementAction,
  getOneAgreementAction,
} from '../../redux/agreementsDuck'

const { Option } = Select
const serviceCodes = [
  { code: 'MANT006', tag: 'Servicios Públicos Urbanos' },
  { code: 'AGU003', tag: 'Agua' },
]

const aguaColumns = [
  {
    title: 'Fecha',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val) => moment(val).format('ll'),
  },
  {
    title: 'Año',
    dataIndex: 'year',
    key: 'year',
  },
  {
    title: 'Bimestre',
    dataIndex: 'twomonths',
    key: 'twomonths',
  },
  {
    title: 'Total',
    dataIndex: 'id',
    key: 'id',
    render: (_, rec) => formatMoney(rec.amount + rec.surcharge, true),
  },
]

function NewAgreement({ history }) {
  const dispatch = useDispatch()
  const { lot, foment, waterInvoices } = useSelector(({ fomento }) => fomento)
  const role = useSelector(({ user }) => user.role.toLowerCase())
  const userName = useSelector(({ user }) => user.displayName)
  const { fetching } = useSelector(({ agreements }) => agreements)
  const [form, setForm] = useState({
    sectionName: sectionNames[1],
    serviceCode: 'MANT006',
    monthlyPaymentAmount: 0,
    resident: {},
  })
  const [errors, setErrors] = useState({})
  const [invoices, setInvoices] = useState({})

  useEffect(() => {
    // despues de encontrar un lote
    if (!lot.id) return
    const resident = getMainResident(lot)
    setForm({ ...form, resident })
    // find invoices:
    getInvoices()
  }, [lot])

  useEffect(() => {
    if (Object.keys(invoices).length) isValidForm()
  }, [form, invoices])

  useEffect(() => {
    getInvoices()
  }, [form.serviceCode])

  const onChange = ({ target: { name, value } }) => {
    if (name === 'paymentsNumber') {
      setForm({
        ...form,
        [name]: value,
        monthlyPaymentAmount:
          form.serviceCode === 'AGU003'
            ? Math.ceil(Object.values(invoices).reduce(
              (acc, inv) => acc + inv.surcharge + inv.amount,
              0,
            ) / value)
            : Math.ceil(Object.values(invoices).reduce((acc, inv) => acc + inv.total, 0) /
              value),
      })
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  const chekIfEnterKey = (e) => {
    // focus and select
    if (e.key === 'Enter') {
      const form = e.target.form
      const index = Array.prototype.indexOf.call(form, e.target)
      let next = index + 1
      if (!form.elements[next]) return findLot()
      // if(form.elements[next])
      if (form.elements[next].name === 'sectionName') next++
      form.elements[next].focus()
      form.elements[next].select()
      e.preventDefault()
    }
  }

  const getMainResident = (lot) => {
    // returns a resident
    let max = 0
    let id
    for (let elem of lot.percent) {
      if (Number(elem.percent) > max) {
        max = Number(elem.percent)
        id = elem.resident
      }
    }
    const resident = lot.residents.find((res) => res.id == id)
    if (resident.id)
      resident.displayName = resident.firstName + ' ' + resident.lastName
    return resident
  }

  const findLot = () => {
    // find lot
    const query = {
      seccion: form.seccion,
      manzana: form.manzana,
      lote: form.lote,
    }
    dispatch(findLotAction(query))
  }

  const getInvoices = () => {
    const { serviceCode } = form
    switch (serviceCode) {
      case 'AGU003':
        return dispatch(getPendingWaterByLot(lot.id))
      default:
        return dispatch(getFomentByLot(lot.id))
    }
  }

  const addInvoiceToAgreement = (record) => {
    if (record.agreement && record.agreement.length > 5) {
      // existe un convenio
      dispatch(getOneAgreementAction(record.agreement)).then((res) => {
        if (res) {
          notification.info({
            message: 'Factura en Convenio',
            description: (
              <p>
                Esta factura tiene un convenio con el folio{' '}
                <a href={`/admin/convenios/${res.id}`}>{res.folio}</a>
              </p>
            ),
            duration: 0,
          })
        }
      })
      return
    }

    const r = {
      ...record,
      total:
        Number(record.before1500) +
        Number(record.after1500) +
        Number(record.clusterAmount),
    }
    setInvoices({ ...invoices, [record.id]: r })
  }

  const removeInvoiceOfAgreement = (record) => {
    delete invoices[record.id]
    const inv = { ...invoices }
    setInvoices({ ...inv })
  }

  const isValidForm = () => {
    const err = {}
    if (!Object.keys(invoices).length)
      err.invoices = 'No hay ninguna factura seleccionada'
    if (
      !form.paymentsNumber ||
      form.paymentsNumber === '' ||
      form.monthlyPaymentAmount === 0
    ) {
      err.paymentsNumber = 'Selecciona el número de mensualidades'
    }
    setErrors(err)
    return !!!Object.keys(err).length
  }

  const saveAgreement = () => {
    if (!isValidForm()) return
    const total =
      form.serviceCode === 'AGU003'
        ? Object.values(invoices).reduce(
          (acc, inv) => acc + inv.amount + inv.surcharge,
          0,
        )
        : Object.values(invoices).reduce((acc, inv) => acc + inv.total, 0)
    const body = {
      serviceCode: form.serviceCode,
      subsidiary: form.serviceCode === 'AGU003' ? 'Sistemas' : 'Fomento',
      lot: lot.id,
      total,
      pendingAmount: total,
      years: Object.values(invoices).map((inv) => inv.year),
      paymentsNumber: form.paymentsNumber,
      monthlyPaymentAmount: Math.ceil(form.monthlyPaymentAmount),
      reference: form.reference,
      notes: form.notes,
      invoices: Object.values(invoices).map((inv) => inv.id), // could be water invoices...
      waterInvoices:
        form.serviceCode === 'AGU003'
          ? Object.values(invoices).map((inv) => inv.id)
          : [],
    }
    dispatch(createNewAgreementAction(body)).then((res) => {
      if (res) history.goBack()
    })
  }

  return (
    <SideLayout type={role}>
      <h2 className="title">Generación de convenio</h2>
      <h3 className="title-description">
        Genera un convenio de pago para agua o mantenimiento
      </h3>
      <div
        style={{
          display: 'flex',
          gap: 40,
          // justifyContent: "space-around"
        }}
      >
        <Card
          style={{
            width: 600,
          }}
          title={'Información del lote'}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
            }}
          >
            <form
              onKeyDown={chekIfEnterKey}
              style={{ gap: 20, display: 'flex', alignItems: 'flex-end' }}
            >
              <div style={styles.formItem}>
                <span>Sección:</span>
                <Input
                  name="seccion"
                  onChange={onChange}
                  style={{ width: 60 }}
                />
              </div>
              <div style={styles.formItem}>
                <Input
                  name="sectionName"
                  // disabled
                  value={form.sectionName}
                />
              </div>
              <div style={styles.formItem}>
                <span>Manzana:</span>
                <Input
                  name="manzana"
                  onChange={onChange}
                  style={{ width: 60 }}
                />
              </div>
              <div style={styles.formItem}>
                <span>Lote:</span>
                <Input name="lote" onChange={onChange} style={{ width: 60 }} />
              </div>
            </form>

            <section
              onKeyDown={chekIfEnterKey}
              style={{ gap: 20, display: 'flex', alignItems: 'flex-end' }}
            >
              <div style={styles.formItem}>
                <span>Id:</span>
                <Input
                  value={form.resident.number}
                  name="resident.number"
                  style={{ width: 60 }}
                />
              </div>
              <div style={styles.formItem}>
                <span>Nombre del residente:</span>
                <Input
                  value={form.resident.displayName}
                  name="resident.displayName"
                  style={{ width: 360 }}
                />
              </div>
              <a href="#!">Ver detalle</a>
            </section>
          </div>
          <Divider />
          <h3>Información del servicio</h3>

          <section
            onKeyDown={chekIfEnterKey}
            style={{ gap: 20, display: 'flex', alignItems: 'flex-end' }}
          >
            <div style={styles.formItem}>
              <span>Código:</span>
              <Select
                onChange={(val) => setForm({ ...form, serviceCode: val })}
                defaultValue={'MANT21'}
                value={form.serviceCode}
                name="serviceCode"
                style={{
                  width: 120,
                }}
                placeholder="Código de servicio"
              >
                {serviceCodes.map((ser) => (
                  <Option key={ser.code}>{ser.code}</Option>
                ))}
              </Select>
            </div>
            <div style={styles.formItem}>
              <Input
                value={
                  serviceCodes.find((ser) => ser.code === form.serviceCode)?.tag
                }
                name="serviceName"
                style={{ width: 360 }}
              />
            </div>
          </section>
          <hr style={{ border: 'none', marginTop: 20 }} />
          <Table
            onRow={(record) => ({
              onClick: () => addInvoiceToAgreement(record),
            })}
            size="small"
            pagination={false}
            dataSource={form.serviceCode === 'AGU003' ? waterInvoices : foment}
            columns={
              form.serviceCode === 'AGU003'
                ? aguaColumns
                : [
                  {
                    title: 'Folio',
                    dataIndex: 'folio',
                    key: 'folio',
                    render: (val, record) =>
                      val ? val : record._id.slice(record._id.length - 4),
                  },
                  {
                    title: 'Año',
                    dataIndex: 'year',
                    key: 'year',
                  },
                  {
                    title: 'Superficie m2',
                    dataIndex: 'area',
                    key: 'area',
                    render: (_, rec) =>
                      Number(rec.cluster) + Number(rec.area) + 'm2',
                  },
                  {
                    title: 'Total',
                    dataIndex: 'id',
                    key: 'id',
                    render: (_, rec) =>
                      formatMoney(
                        rec.before1500 + rec.after1500 + rec.clusterAmount,
                        true,
                      ),
                  },
                ]
            }
          />
        </Card>
        {/* Second form */}

        <Card
          title="Resumen del convenio"
          style={{
            width: 586,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <section
            style={{ gap: 20, display: 'flex', flexDirection: 'column' }}
          >
            <div>
              <span style={{ color: 'grey', marginRight: 20 }}>Fecha:</span>
              <span style={{ color: 'black' }}>
                {moment(Date.now()).format('D/MMM/YYYY')}
              </span>
            </div>
            <article style={{ display: 'flex', gap: 30 }}>
              <div style={styles.formItem}>
                <span>No. de mensualidades</span>
                <Select
                  onChange={(value) =>
                    onChange({ target: { name: 'paymentsNumber', value } })
                  }
                  value={form.paymentsNumber}
                  style={{
                    width: 250,
                  }}
                  placeholder="Número de mensualidades"
                >
                  {[3, 6, 12, 18].map((ser) => (
                    <Option key={ser}>{ser + ' mensualidades'}</Option>
                  ))}
                </Select>
                <span style={styles.error}>{errors.paymentsNumber}</span>
              </div>

              <div style={styles.formItem}>
                <span>Pago mensual:</span>
                <Input
                  placeholder="$00.00"
                  value={formatMoney(form.monthlyPaymentAmount)}
                  name="monthlyPaymentAmount"
                  style={{ width: 250 }}
                />
              </div>
            </article>
            <article style={{ display: 'flex', gap: 30 }}>
              <div style={styles.formItem}>
                <span>Referencia</span>
                <Input
                  onChange={onChange}
                  placeholder={'Escribe una referencia'}
                  value={form.reference}
                  name="reference"
                  style={{ width: 250 }}
                />
              </div>
              <div style={styles.formItem}>
                <span>Autorizado por:</span>
                <Input
                  placeholder="$00.00"
                  value={userName}
                  name="authorizedBy"
                  style={{ width: 250 }}
                />
              </div>
            </article>
            <div style={styles.formItem}>
              <span>Notas:</span>
              <Input
                onChange={onChange}
                placeholder="Ingresa un comentario"
                value={form.notes}
                name="notes"
                style={{ width: '100%' }}
              />
            </div>
          </section>
          <Divider />
          <section>
            <h3>Servicio</h3>
            <Table
              onRow={(record) => ({
                onClick: () => removeInvoiceOfAgreement(record),
              })}
              size="small"
              pagination={false}
              dataSource={Object.values(invoices)}
              columns={
                form.serviceCode === 'AGU003'
                  ? aguaColumns
                  : [
                    {
                      title: 'Folio',
                      dataIndex: 'folio',
                      key: 'folio',
                    },

                    {
                      title: 'Antes de 1500m',
                      dataIndex: 'before1500',
                      key: 'before1500',
                      render: (val) => formatMoney(val),
                    },
                    {
                      title: 'Despues de 1500m',
                      dataIndex: 'after1500',
                      key: 'after1500',
                      render: (val) => formatMoney(val),
                    },
                    {
                      title: 'Cluster',
                      dataIndex: 'clusterAmount',
                      key: 'clusterAmount',
                      render: (val) => formatMoney(val),
                    },
                    {
                      title: 'Total',
                      dataIndex: 'id',
                      key: 'id',
                      render: (_, rec) =>
                        formatMoney(
                          rec.before1500 + rec.after1500 + rec.clusterAmount,
                          false,
                        ),
                    },
                  ]
              }
            />
            <span style={styles.error}>{errors.invoices}</span>
            <div
              style={{
                marginTop: 150, // change this when real table
                display: 'flex',
                gap: 100,
                justifyContent: 'flex-end',
              }}
            >
              <span>Total:</span>
              {form.serviceCode === 'AGU003' ? (
                <span>
                  {formatMoney(
                    Object.values(invoices).reduce(
                      (acc, inv) => acc + inv.surcharge + inv.amount,
                      0,
                    ),
                    true,
                  )}
                </span>
              ) : (
                <span>
                  {formatMoney(
                    Object.values(invoices).reduce(
                      (acc, inv) => acc + inv.total,
                      0,
                    ),
                    true,
                  )}
                </span>
              )}
            </div>
            <Button
              loading={fetching}
              onClick={saveAgreement}
              disabled={fetching || Object.keys(errors).length}
              style={
                fetching || Object.keys(errors).length
                  ? styles.disabledButton
                  : styles.enabledButton
              }
            >
              Generar Convenio
            </Button>
          </section>
        </Card>
      </div>
    </SideLayout>
  )
}

const styles = {
  error: { color: 'red' },
  enabledButton: {
    color: 'white',
    left: '33%',
    background: '#95BF48',
  },
  disabledButton: {
    marginTop: 20,
    color: 'white',
    left: '33%',
    background: '#C4C4C4',
  },
  header: {
    minWidth: 90,
    margin: 10,
    fontSize: 12,
    fontWeight: 'bold',
  },
  formItem: {
    color: '#828282',
    display: 'flex',
    flexDirection: 'column',
  },
}

export default NewAgreement
