import React from 'react';
import PropTypes from 'prop-types';

import { StatusBar, Sidebar } from '../../Common';

import styles from './SideLayout.module.css';

const SideLayout = ({ children, type, backButton, className, classNameContainer }) => {
  return (
    <div className={`${styles.layout} ${className}`}>
      <Sidebar {...{ type }} />
      <div className={`${styles.layout__content} ${classNameContainer}`}>
        <StatusBar {...{ backButton }} />
        {children}
      </div>
    </div>
  );
}
export default SideLayout;

SideLayout.propTypes = {
	type: PropTypes.oneOf(['admin', 'cashier', 'manager']).isRequired,
  backButton: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

SideLayout.defaultProps = {
	type: null,
  backButton: true,
  className: '',
  classNameContainer: '',
};
