import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Col, Row, DatePicker, Form, Input, Button, message } from 'antd'
import moment from 'moment'
import { SideLayout, Card } from '../Common'

import Rules from '../../helpers/rules'

import cardStyles from '../Common/Card/Card.module.css'
import styles from './BatchMessageForm.module.css'
import {
  createMessageAction,
  getMessagesByLotAction,
  getOneMessageAction,
  updateMessageAction,
} from '../../redux/messagesDuck'

function BatchesDetail({ history }) {
  const { isAdmin, displayName, email } = useSelector(({ user }) => user)
  const { id } = useParams()
  const [isEdit] = useState(!!id)
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']

  const dispatch = useDispatch()
  const { lot } = useSelector(({ lots }) => lots)
  const [form] = Form.useForm()
  const { TextArea } = Input

  const { message } = useSelector(({ messages }) => messages)

  React.useEffect(() => {
    if (id) {
      dispatch(getOneMessageAction(id))
    }
  }, [])

  React.useEffect(() => {
    if (!message) return
    if (Object.keys(message).length) {
      form.setFieldsValue({ body: message.body })
    }
  }, [message])

  const saveMessage = () => {
    if (message && lot && !message._id && !lot.id)
      return history.push('/admin/lotes')
    console.log('mensaje: ', message)
    if (message._id) {
      return dispatch(
        updateMessageAction({
          id: message._id,
          body: form.getFieldValue('body'),
        }),
      ).then(() => {
        history.goBack()
      })
    }
    console.log('no debería llegar aqui')
    dispatch(
      createMessageAction({ body: form.getFieldValue('body'), lot: lot.id }),
    ).then(() => {
      history.goBack()
    })
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <SideLayout type={isAdmin ? 'admin' : 'cajero'}>
      <h2 className="title">{`${isEdit ? 'Editar' : 'Agregar'} mensaje`}</h2>
      <h3 className="title-description">{`${
        isEdit
          ? 'Actualiza el contenido del mensaje.'
          : 'Completa la siguiente información para agregar el mensaje.'
      }`}</h3>
      <Row>
        <Col lg={12} md={24} sm={24} xs={24}>
          <Card>
            <h4 className={cardStyles.card__title}>Contenido del mensaje</h4>
            <p className="text-coal">
              Escribe el mensaje relacionado a este lote.
            </p>
            <Form
              initialValues={{ displayName }}
              form={form}
              layout="vertical"
              requiredMark={false}
              onFinish={saveMessage}
            >
              <Row gutter={[15, 0]}>
                <Col span={12}>
                  <Form.Item label="Creado por:" name="displayName">
                    <Input placeholder="1234567" disabled />
                  </Form.Item>
                </Col>

                {/* <Col span={12}>
                  <Form.Item
                    label="Fecha de vencimiento:"
                    name="dueDate"
                    rules={[Rules.basic]}
                  >
                    <DatePicker
                      defaultValue={moment(new Date(), dateFormatList[0])}
                      format={dateFormatList}
                    />
                  </Form.Item>
                </Col> */}
              </Row>
              <Form.Item label="Mensaje:" name="body" rules={[Rules.basic]}>
                <TextArea
                  placeholder="Máximo 180 caracteres"
                  maxLength={180}
                  showCount
                  allowClear
                  rows={3}
                />
              </Form.Item>
              <Row
                justify="center"
                gutter={[50, 0]}
                className={styles.footer__form}
              >
                {isEdit && (
                  <Col>
                    <Button
                      className={`${styles.btn__footer} ${styles.text__default}`}
                      type="default"
                      htmlType="button"
                      onClick={handleCancel}
                    >
                      Cancelar
                    </Button>
                  </Col>
                )}
                <Col>
                  <Button
                    className={styles.btn__footer}
                    type="primary"
                    htmlType="submit"
                  >
                    {isEdit ? 'Guardar' : 'Agregar'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </SideLayout>
  )
}

export default BatchesDetail
