import React from 'react';
import PropTypes from 'prop-types';

import styles from './Divider.module.css'

function Divider({ type, style }) {
  return <div className={type === 'vertical' ? styles['divider-v'] : styles['divider-h']} {...{ style  }} />
}

export default Divider;

Divider.propTypes = {
  type: PropTypes.oneOf(['horizontal', 'vertical']),
};

Divider.defaultProps = {
  type: 'horizontal',
};
