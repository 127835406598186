import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Form, Input, Button, Typography } from 'antd';

import { SplitScreen } from '../Common';
import { Rules } from '../../helpers';
import { CASHIER } from '../../constants/routes';

import backgroundImage from '../../assets/images/residences.jpg';
import styles from './ResetPassword.module.css';

function ResetPassword() {
  const { loggedIn } = useSelector(({ user }) => user);
  const [form] = Form.useForm();
  const { Text } = Typography;

  if (loggedIn) return <Redirect to={CASHIER} />;

  return (
    <SplitScreen image={backgroundImage}>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className={styles.form}
      >
        <legend className={styles.form__title}>Ingresa tu contraseña</legend>

        <div className={styles.form__group}>
          <Text className={styles.form__subtitle}>
            Has sido invitado a formar parte de Lomas de Cocoyoc, ingresa la
            contraseña que usarás para acceder al sistema.
          </Text>
        </div>

        <Form.Item
          name="password"
          label="Contraseña"
          rules={[Rules.passwordPattern]}
          className={`${styles.form__group} c_label`}
        >
          <Input.Password
            placeholder="Mínimo 8 caracteres"
            className={styles.form__input}
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirmar contraseña"
          className={`${styles.form__group} c_label`}
          dependencies={['password']}
          hasFeedback
          rules={[Rules.passwordPattern, Rules.passwordMatch]}
        >
          <Input.Password
            placeholder="Mínimo 8 caracteres"
            className={styles.form__input}
          />
        </Form.Item>

        <div className={styles.form__group}>
          <Button
            htmlType="submit"
            shape="round"
            type="primary"
            className={styles.form__button}
          >
            Registrarme
          </Button>
        </div>

        <div className={styles.form__group}>
          <Text className={styles.form__text}>
            ¿Tienes una cuenta?{' '}
            <Link to="/" className={styles.form__link}>
              Inicia sesión
            </Link>
          </Text>
        </div>
      </Form>
    </SplitScreen>
  );
}

export default ResetPassword;
