import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Row, Col } from 'antd';

function TableCustomFooter({ total, totalAmount, type, pagination = {} }) {
  const { limit, page, changePage, totalPages } = pagination
  const getDimentions = () => {
    let dimentions = {
      col1: 16,
      col2: 2,
      col3: 3,
      ml: '2rem',
    };

    switch (type) {
      case 'systems':
        dimentions.col1 = 17;
        dimentions.col2 = 3;
        dimentions.col3 = 4;
        dimentions.ml = '-0.5rem';
        break;

      case 'promotion':
        dimentions.col1 = 17;
        dimentions.col2 = 3;
        dimentions.col3 = 4;
        dimentions.ml = '-1rem';
        break;

      case 'convenants':
        dimentions.col1 = 20;
        dimentions.col2 = 2;
        dimentions.col3 = null;
        dimentions.ml = '0.2rem';
        break;

      case 'batches-systems':
        dimentions.col1 = 17;
        dimentions.col2 = 3;
        dimentions.col3 = null;
        dimentions.ml = '-1.5rem';
        dimentions.lml = '0.9rem'
        break;

      default:
        return dimentions;
    }

    return dimentions;
  };

  return (
    <Row style={{ marginTop: 32 }}>
      <Col span={getDimentions().col1}>
        {/* <Pagination {...{ total }} /> */}
        <Pagination
          showSizeChanger={false}
          pageSize={limit || 50}
          current={page}
          onChange={changePage}
          total={totalPages}
          style={{ marginTop: 32 }}
        />
      </Col>
      <Col span={getDimentions().col2}>
        <div className="text-strong-12" style={{ marginLeft: getDimentions().lml || 0 }}>Deuda total</div>
      </Col>
      <Col span={getDimentions().col3}>
        <div
          className="text-strong-12"
          style={{ textAlign: 'left', marginLeft: getDimentions().ml }}
        >
          {totalAmount}
        </div>
      </Col>
    </Row>
  );
}

export default TableCustomFooter;

TableCustomFooter.defaultProps = {
  total: 0,
  totalAmount: 0,
  type: 'residents',
};

TableCustomFooter.propTypes = {
  type: PropTypes.oneOf(['invoices', 'residents', 'promotion']),
  totalAmount: PropTypes.number,
  total: PropTypes.number,
};
