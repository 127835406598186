import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Card, Tag, Empty, Modal, Checkbox, Divider } from 'antd'
import ListItem from '../Common/ListDetailItem'
import { formatMoney } from '../../helpers/formatMoney'
import moment from 'moment'
import { getDepositByLot, updateDepositAction } from '../../redux/fomento.duck'

const CheckboxGroup = Checkbox.Group

const Deposit = () => {
  const dispatch = useDispatch()
  const { lot, deposit, fetching } = useSelector(({ fomento }) => fomento)
  const [form, setForm] = useState({
    services: [],
  })
  const [showModal, setShowModal] = useState(false)
  const plainOptions = ['Agua', 'Teléfono', 'Luz', 'Gas']
  const [indeterminate, setIndeterminate] = React.useState(true)
  const [checkAll, setCheckAll] = React.useState(false)
  useEffect(() => {
    if (lot.id == null) return
    getDeposit()
  }, [lot])
  useEffect(() => {
    if (!Object.keys(deposit).length) return
    setForm({ ...form, services: deposit.services })
  }, [deposit])

  const onCheck = (list) => {
    setForm({ services: list })
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = (e) => {
    setForm({ services: e.target.checked ? plainOptions : [] })
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }
  const updateServices = () => {
    setShowModal(false)
    const body = {
      services: [...form.services],
    }
    dispatch(updateDepositAction(body, deposit.id))
  }

  const getDeposit = () => dispatch(getDepositByLot(lot.id))

  if (lot.delinquency)
    return (
      <Alert
        message="Este cliente no puede hacer abonos por adeudos pendientes."
        type="error"
        showIcon
      />
    )
  return (
    <>
      {lot.id ? (
        <Card
          loading={fetching}
          extra={
            <a href="#!" onClick={() => setShowModal(true)}>
              Editar
            </a>
          }
          style={{ width: 300 }}
          title="Depósito"
          loading={fetching}
          style={{ maxWidth: 600 }}
        >
          {/* <h3 className="subtitle-strong-18"></h3> */}
          {/* <ListItem label="Medidor:" description={<Link>#23456</Link>} /> */}
          <ListItem
            label="Saldo total:"
            description={formatMoney(deposit.currentTotal, true)}
          />
          <ListItem
            label="Último depósito:"
            description={formatMoney(
              deposit.receipts[deposit.receipts?.length - 1]?.totalPaid,
              true,
            )}
          />
          <ListItem
            label="Fecha del último depósito:"
            description={moment(
              deposit.receipts[deposit.receipts.length - 1]?.createdAt || '',
            ).format('ll')}
          />
          <ListItem
            label="Servicios:"
            description={
              <>
                {form.services?.map((ser) => (
                  <Tag key={ser} style={styles.tag} color="#95BF48">
                    {ser}
                  </Tag>
                ))}
              </>
            }
          />
        </Card>
      ) : (
        <Empty description="Consulta un lote" />
      )}

      <Modal
        title="Editar servicios"
        visible={showModal}
        onOk={updateServices}
        onCancel={() => setShowModal(false)}
      >
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Seleccionar todos
        </Checkbox>
        <Divider />
        <CheckboxGroup
          options={plainOptions}
          value={form.services}
          onChange={onCheck}
        />
      </Modal>
    </>
  )
}

const styles = {
  tag: { borderRadius: 20, cursor: 'pointer' },
}

export default Deposit
