import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilter,
  faDownload,
  faUpload,
  faCalendarDay,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { Input, Button, Popover, DatePicker } from 'antd'

import Divider from '../Divider/Divider'
import TagSwitch from '../TagSwitch'

import styles from './Seeker.module.css'
import { useDispatch } from 'react-redux'
import { getLotsAction, getLotsByDepositAction } from '../../../redux/lotsDuck'

function Seeker({
  noUpload = false,
  noFilter = false,
  btnAdd,
  onSearch = () => false,
  onUploadButtonClick = () => false,
}) {
  const dispatch = useDispatch()
  const { Search } = Input
  const { RangePicker } = DatePicker
  const [date, setDate] = useState(null)
  const chipsInitial = {
    losing: false,
    pending: false,
    paid: false,
  }
  const [chips, setChips] = useState(chipsInitial)
  const [services, setServices] = useState([])
  const [activFilter, setActiveFilter] = useState(false)

  // useEffect(()=>{ // vuelame
  //   console.log(services)
  // },[services])

  const queryLotsByDeposit = () => {
    setActiveFilter(true)
    const paginated = {
      q: { services },
    }
    dispatch(getLotsByDepositAction(paginated))
  }

  const resetLots = () => {
    setActiveFilter(false)
    dispatch(getLotsAction())
  }

  const handleDepositFilter = (service) => {
    if (services.includes(service)) {
      setServices((prev) => prev.filter((item) => item !== service))
    } else {
      setServices((prev) => [...prev, service])
    }
  }

  const MenuComponent = (
    <div style={{ width: 250 }}>
      <div className="text-strong-14">Filtrar por:</div>
      {/* <div style={{ margin: '8px 0', fontSize: 12 }}>Periodo de tiempo</div>
      <RangePicker
        locale={{ lang: 'es' }}
        style={{ fontSize: 12 }}
        suffixIcon={<FontAwesomeIcon icon={faCalendarDay} color="#C0C0C0" />}
        onChange={momentDates => setDate(momentDates)}
        value={date}
      /> */}
      {/* <div style={{ margin: '8px 0', fontSize: 12 }}>Estatus</div> */}
      {/* <div>
        <TagSwitch
          onClick={() => setChips(prev => ({ ...prev, losing: !prev.losing }))}
          tagIsActive={chips.losing}
        >
          Con adeudo
        </TagSwitch>
        {/* <TagSwitch
          onClick={() => setChips(prev => ({ ...prev, pending: !prev.pending }))}
          tagIsActive={chips.pending}
        >
          Pendiente
        </TagSwitch> */}
      {/* <TagSwitch
          onClick={() => setChips(prev => ({ ...prev, paid: !prev.paid }))}
          tagIsActive={chips.paid}
        >
          Al corriente
        </TagSwitch>
      </div> */}
      <Divider style={{ marginBottom: 16 }} />
      <div style={{ margin: '8px 0', fontSize: 12 }}>Depósito</div>
      <div>
        <TagSwitch
          onClick={() => handleDepositFilter('Agua')}
          tagIsActive={services.includes('Agua')}
        >
          Agua
        </TagSwitch>
        <TagSwitch
          onClick={() => handleDepositFilter('Luz')}
          tagIsActive={services.includes('Luz')}
        >
          Luz
        </TagSwitch>
        <TagSwitch
          onClick={() => handleDepositFilter('Teléfono')}
          tagIsActive={services.includes('Teléfono')}
        >
          Teléfono
        </TagSwitch>
        <TagSwitch
          onClick={() => handleDepositFilter('Gas')}
          tagIsActive={services.includes('Gas')}
        >
          Gas
        </TagSwitch>
      </div>
      <Divider style={{ marginBottom: 16 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type="text"
          style={{ color: '#828282', fontSize: 12 }}
          onClick={() => {
            // setChips(chipsInitial);
            // setDate(null);
            resetLots()
          }}
        >
          Restablecer
        </Button>
        <Button
          onClick={queryLotsByDeposit}
          type="primary"
          style={{ height: 24, lineHeight: 1, fontSize: 12 }}
        >
          Aplicar
        </Button>
      </div>
    </div>
  )

  return (
    <div
      className={`${styles.seeker_container} seeker`}
      style={{ flexGrow: 1 }}
    >
      <Search
        onChange={onSearch}
        placeholder="Buscar"
        size="large"
        className={styles.seeker__input}
      />
      {btnAdd.action && (
        <Button
          size="large"
          shape="circle"
          onClick={btnAdd.action}
          className={`${styles.seeker__filter} ${styles.filter__add}`}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      )}
      {!noFilter && (
        <Popover
          trigger="click"
          placement="bottomRight"
          content={MenuComponent}
        >
          <Button
            size="large"
            shape="circle"
            className={styles.seeker__filter}
            icon={
              <FontAwesomeIcon
                color={activFilter && '#95bf48'}
                icon={faFilter}
              />
            }
          />
        </Popover>
      )}
      {!noUpload && (
        <Button
          onClick={onUploadButtonClick}
          size="large"
          shape="circle"
          className={styles.seeker__filter}
          // icon={<FontAwesomeIcon icon={faDownload} />}
          icon={<FontAwesomeIcon icon={faUpload} />}
        />
      )}
    </div>
  )
}

export default Seeker

Seeker.defaultProps = {
  btnAdd: { action: undefined },
  section: 'invoices',
}

Seeker.propTypes = {
  btnAdd: PropTypes.shape({
    action: PropTypes.oneOfType([
      PropTypes.func.isRequired,
      PropTypes.instanceOf(undefined),
    ]),
  }),
  section: PropTypes.oneOf(['invoices', 'batches']),
}
