import React, { useEffect } from 'react'
import { Row, Modal, Button } from 'antd'
import { useState, useRef } from 'react'
import {
  // useDispatch,
  useSelector,
} from 'react-redux'
import TagReceipt from '../CashierPromotion/TagReceipt'
import componentToPdf from '../../tools/componentToPdf'
// import { collectDataReport } from '../../../redux/reports.duck';
import printFilesImage from '../../assets/svgs/PrintFiles.svg'
import styles from '../CashierPromotion/Cashier.module.css'

function PrintingModal({ show, onCancel, onFinish = () => false }) {
  const { lot } = useSelector(({ fomento }) => fomento)
  const { print, fetching } = useSelector(({ fomento }) => fomento)

  const [printing, setPrinting] = useState(false)
  const componentRef = useRef()
  const { print: PDF } = useSelector(({ fomento }) => fomento)

  const printReceipt = () => {
    setPrinting(true)
    createPDF(componentRef.current)
    setTimeout(() => {
      onCancel()
      setPrinting(false)
      onFinish()
    }, 5000)
  }

  const handleCancel = () => {
    onFinish()
    onCancel()
  }

  const createPDF = (html) => {
    componentToPdf.createPdf(html, `FACTURA_${PDF.folio}`)
    // onFinish()
  }

  return (
    <>
      <Modal
        title="Imprimir Recibo"
        centered
        visible={show}
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        className={styles.modal__print}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Cancelar
          </Button>,
          <Button onClick={printReceipt} type="primary" loading={printing}>
            Imprimir
          </Button>,
        ]}
      >
        <div style={{ position: 'fixed', zIndex: -10000, bottom: '-300vh' }}>
          <TagReceipt printData={{ ...print, lot }} currentRef={componentRef} />
        </div>
        <p className="textColor">El recibo se generará para impresión.</p>
        <Row justify="center">
          <img src={printFilesImage} alt="print files" />
        </Row>
      </Modal>
    </>
  )
}

export default PrintingModal
