import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Avatar, Typography, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

import { Card, SideLayout } from '../Common';
import { PROFILE_CHANGE_PASSWORD, ADMIN_PROFILE_CHANGE_PASSWORD } from '../../constants/routes';
import { updateProfilePhotoAction, finishPhotoUpdateAction } from '../../redux/user.duck';

import styles from './Profile.module.css';

function MyProfile() {
	const { pathname } = useLocation();
  const type = pathname.split('/')[1];

  const { Title, Paragraph } = Typography;
  const userInfo = useSelector(({ user }) => user);
  const { photoURL, loading, error, success } = userInfo;
	const dispatch = useDispatch();
	const [photo, setPhoto] = useState(photoURL);
	const [uploadedPhoto, setUploadedPhoto] = useState(photoURL);

  useEffect(() => {
    if (success) {
			message.success('Foto actualizada correctamente.');
			setUploadedPhoto(photo);
		}
		if (error) {
      message.error('No se pudo actualizar la foto de perfil.');
		}
		dispatch(finishPhotoUpdateAction());
  }, [error, success, dispatch, photo]);

  const handleAvatar = async (event) => {
    let target = event.target;
		dispatch(updateProfilePhotoAction(target.files[0]));
		let photoUri = URL.createObjectURL(target.files[0]); 
		setPhoto(photoUri);
  };

  const ListItem = ({ title, value, link = undefined }) => (
    <li className={styles.profile__info__item}>
      <span className={styles.info__title}>{title}</span>{' '}
      {link ? (
        <Link to={link} className={styles.info__value}>
          {value}
        </Link>
      ) : (
        <span className={styles.info__value}>{value}</span>
      )}
    </li>
  );

  return (
    <SideLayout type={type}>
      <h2 className="title">Mi Perfil</h2>
      <h3 className="title-description">Actualiza tu información </h3>
      <Row>
        <Col lg={7} md={24} sm={24} xs={24}>
          <Card>
            <div className={styles.profile}>
              <div className={styles.profile__avatar__content}>
                <Avatar
                  src={uploadedPhoto}
                  size={180}
									className={`${styles.profile__avatar} ${loading && styles.avatar__loading}`}
                />
                <label htmlFor="avatar">
                  <FontAwesomeIcon icon={faCamera} />
                </label>
                <input
                  type="file"
                  name="avatar"
                  id="avatar"
                  onChange={handleAvatar}
                />
              </div>
              <Title className={styles.profile__name}>
                {userInfo.displayName}
              </Title>
              <Paragraph className={styles.profile__email}>
                {userInfo.email}
              </Paragraph>
              <ul className={styles.profile__info}>
                <ListItem title="Usuario" value={userInfo.displayName} />
                <ListItem title="Rol" value={userInfo.role} />
                <ListItem
                  title="Contraseña"
                  value="Cambiar"
                  link={type === 'admin' ? ADMIN_PROFILE_CHANGE_PASSWORD : PROFILE_CHANGE_PASSWORD}
                />
              </ul>
            </div>
          </Card>
        </Col>
      </Row>
    </SideLayout>
  );
}

export default MyProfile;
