import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  SignIn,
  ForgotPassword,
  ResetPassword,
  Cashier,
  // CashierSystems,
  CashierPromotion,
  Invoices,
  InvoicesRegister,
  InvoicesDetail,
  Batches,
  Residents,
  ResidentsDetail,
  Users,
  UsersManage,
  AdminBox,
  MyProfile,
  ChangePassword,
  FomentReceipt,
  InvoiceGenerate,
  InvoicesAdd,
  InvoicesCreate,
  PenaltyGenerate,
  Covenenants,
  BatchesMessages,
  BatchMessageForm,
} from './components'
import WaterInvoiceDetail from './components/InvoicesDetail/WaterInvoiceDetail'
import BatchesDetail from './components/BatchesDetail'
import AddResident from './components/AddResident'

import {
  SIGN_IN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CASHIER,
  CASHIER_SYSTEMS,
  CASHIER_PROMOTION,
  INVOICES,
  INVOICES_REGISTER,
  INVOICES_DETAIL_TAG,
  INVOICES_DETAIL_HASH,
  ADMIN_BOX,
  BATCHES,
  RESIDENTS,
  RESIDENTS_ADD,
  RESIDENTS_EDIT,
  RESIDENTS_DETAIL,
  USERS,
  USERS_REGISTER,
  USERS_EDIT,
  BATCHES_DETAIL,
  BATCHES_MESSAGES,
  PROFILE,
  PROFILE_CHANGE_PASSWORD,
  CONVENANTS,
  ADMIN_SUMMARY,
  ADMIN_SISTEMS,
  ADMIN_PROMOTION,
  // ADMIN_LOMAS,
  ADMIN_INVOICES,
  ADMIN_INVOICE_REGISTER,
  ADMIN_INVOICE_DETAIL,
  ADMIM_INVOICE_RECEIPT,
  ADMIN_BATCHES,
  ADMIN_RESIDENTS,
  ADMIN_BATCHES_DETAIL,
  ADMIN_BATCHES_MESSAGES,
  ADMIN_PROFILE,
  ADMIN_PROFILE_CHANGE_PASSWORD,
  // INVOICES
  INVOICES_GENERATE,
  INVOICES_ADD,
  INVOICES_CREATE,
  PENALTY_GENERATE,
  // ADMIN_INVOICES
  ADMIN_INVOICES_GENERATE,
  ADMIN_INVOICES_ADD,
  ADMIN_INVOICES_CREATE,
  ADMIN_PENALTY_GENERATE,
  // ADMIN_COVENANTS
  ADMIN_CONVENANTS,
  //MESSAGES
  NEW_MESSAGE,
  EDIT_MESSAGE,
  ADMIN_NEW_MESSAGE,
  ADMIN_EDIT_MESSAGE,
} from './constants/routes'
import Convenants from './components/Convenants'
import NewAgreement from './components/Convenants/NewAgreement'
import ReceiptDetail from './components/Invoices/ReceiptDetail'
import AgreementDetail from './components/Convenants/AgreementDetail'
import DownloadButtons from './components/DownloadWaterReceipt'
// import LomasReceipt from './components/FomentReceipt'
import AguaReceipt from './components/Common/CashierPrint/AguaReceipt'
import LomasCaja from './components/Lomas/Caja'
import SystemsCaja from './components/Systems/Caja'
import DepositReceipt from './components/Common/CashierPrint/DepositReceipt'
import AddServiceInvoice from './components/Servicios/AddServiceInvoice'

const PrivateRoute = ({ path, component, ...props }) => {
  let isLoggedIn = useSelector(({ user }) => user?.loggedIn)
  if (isLoggedIn) return <Route path={path} component={component} {...props} />
  return <Redirect to={SIGN_IN} {...props} />
}

function Routes() {
  const role = useSelector(({ user }) => user.role.toLowerCase())

  return (
    <Switch>
      {/* Reports */}
      <Route path="/blissito" component={AguaReceipt} />
      {/* Start Admin Routes */}
      <PrivateRoute exact path={ADMIN_SUMMARY} component={AdminBox} />
      <PrivateRoute exact path={ADMIN_BOX} component={Cashier} />
      {/* Caja systemas */}
      <PrivateRoute path={ADMIN_SISTEMS} component={SystemsCaja} />
      {/* Caja systemas */}
      <PrivateRoute path={ADMIN_PROMOTION} component={CashierPromotion} />
      <PrivateRoute exact path={ADMIN_INVOICES} component={Invoices} />
      <PrivateRoute
        path={ADMIN_INVOICE_REGISTER}
        component={InvoicesRegister}
      />
      {/* Detalle de facturas y recibos */}
      <PrivateRoute
        path="/admin/facturas/detalle/:id/agua"
        component={WaterInvoiceDetail}
      />
      <PrivateRoute path={ADMIN_INVOICE_DETAIL} component={InvoicesDetail} />
      <PrivateRoute path={ADMIM_INVOICE_RECEIPT} component={ReceiptDetail} />
      <PrivateRoute
        path={`/${role}${NEW_MESSAGE}`}
        component={BatchMessageForm}
      />
      <PrivateRoute
        path={`/${role}${EDIT_MESSAGE}`}
        component={BatchMessageForm}
      />
      <PrivateRoute
        exact
        path={ADMIN_BATCHES_MESSAGES}
        component={BatchesMessages}
      />
      <PrivateRoute path={ADMIN_BATCHES_DETAIL} component={BatchesDetail} /> //
      exact block the correct execution of the innner routes
      <PrivateRoute path={ADMIN_BATCHES} component={Batches} /> // exact block
      the correct execution of the innner routes
      <PrivateRoute exact path={ADMIN_PROFILE} component={MyProfile} />
      <PrivateRoute
        exact
        path={ADMIN_PROFILE_CHANGE_PASSWORD}
        component={ChangePassword}
      />
      <PrivateRoute
        exact
        path={INVOICES_GENERATE}
        component={InvoiceGenerate}
      />
      <PrivateRoute exact path={INVOICES_ADD} component={InvoicesAdd} />
      <PrivateRoute exact path={INVOICES_CREATE} component={InvoicesCreate} />
      <PrivateRoute exact path={PENALTY_GENERATE} component={PenaltyGenerate} />
      //Convenios
      <PrivateRoute exact path={ADMIN_CONVENANTS} component={Convenants} />
      <PrivateRoute
        exact
        path={'/admin/convenios/nuevo'}
        component={NewAgreement}
      />
      <PrivateRoute
        path={'/admin/convenios/:agreementId'}
        component={AgreementDetail}
      />
      //Lomas depositos
      <Route path="/caja/lomas/preview" component={DepositReceipt} />
      <PrivateRoute path={'/caja/lomas'} component={LomasCaja} />
      //
      {/* End Admin Routes */}
      <Route exact path={SIGN_IN} component={SignIn} />
      <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={RESET_PASSWORD} component={ResetPassword} />
      <PrivateRoute exact path={CASHIER} component={Cashier} />
      <PrivateRoute exact path={CASHIER_SYSTEMS} component={SystemsCaja} />
      <PrivateRoute exact path={INVOICES} component={Invoices} />
      <PrivateRoute
        exact
        path={INVOICES_REGISTER}
        component={InvoicesRegister}
      />
      <PrivateRoute
        exact
        path={INVOICES_DETAIL_TAG}
        component={InvoicesDetail}
      />
      <PrivateRoute
        exact
        path={INVOICES_DETAIL_HASH}
        component={InvoicesDetail}
      />
      <PrivateRoute
        exact
        path={ADMIN_INVOICES_GENERATE}
        component={InvoiceGenerate}
      />
      <PrivateRoute
        exact
        path={'/admin/facturas/generar'}
        component={InvoiceGenerate}
      />
      {/* Agregar agua facturas */}
      <PrivateRoute
        exact
        path={'/cajero/facturas/agregar/servicios'}
        component={AddServiceInvoice}
      />
      <PrivateRoute exact path={ADMIN_INVOICES_ADD} component={InvoicesAdd} />
      <PrivateRoute
        exact
        path={ADMIN_INVOICES_CREATE}
        component={InvoicesCreate}
      />
      <PrivateRoute
        exact
        path={ADMIN_PENALTY_GENERATE}
        component={PenaltyGenerate}
      />
      <PrivateRoute exact path={PROFILE} component={MyProfile} />
      <PrivateRoute
        exact
        path={PROFILE_CHANGE_PASSWORD}
        component={ChangePassword}
      />
      // Fomento
      <PrivateRoute
        exact
        path={CASHIER_PROMOTION}
        component={CashierPromotion}
      />
      //
      <PrivateRoute exact path={BATCHES_MESSAGES} component={BatchesMessages} />
      <PrivateRoute exact path={BATCHES} component={Batches} />
      <PrivateRoute exact path={BATCHES_DETAIL} component={BatchesDetail} />
      <PrivateRoute exact path={`/${role}${RESIDENTS}`} component={Residents} />
      <PrivateRoute
        exact
        path={`/${role}${RESIDENTS_ADD}`}
        component={AddResident}
      />
      <PrivateRoute
        exact
        path={`/${role}${RESIDENTS_EDIT}`}
        component={AddResident}
      />
      <PrivateRoute
        exact
        path={`/${role}${RESIDENTS_DETAIL}`}
        component={ResidentsDetail}
      />
      <PrivateRoute exact path={USERS} component={Users} />
      <PrivateRoute exact path={USERS_REGISTER} component={UsersManage} />
      <PrivateRoute exact path={USERS_EDIT} component={UsersManage} />
      <PrivateRoute exact path={CONVENANTS} component={Convenants} />
      <Route path="/">
        <Redirect to={SIGN_IN} />
      </Route>
    </Switch>
  )
}

export default Routes
