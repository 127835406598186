import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../../assets/svgs/Logo.svg';
import housesImage from '../../../assets/images/housesTop.jpg';
import styles from './SplitScreen.module.css';

function SplitScreen({ image, children }) {
  return (
    <div className={styles.sign}>
      <div style={{ backgroundImage: `url(${image})` }} className={styles.sign__left} />
      <div className={styles.sign__right}>
        <img src={logo} alt="logo" />
        <div className={styles.sign__content}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default SplitScreen;

SplitScreen.propTypes = {
  image: PropTypes.string,
};

SplitScreen.defaultProps = {
  image: housesImage,
};
