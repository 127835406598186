import React from 'react'
import { Card, Tag } from 'antd'
import ListItem from '../../Common/ListDetailItem'
import { useDispatch, useSelector } from 'react-redux'
import { formatMoney } from '../../../helpers/formatMoney'
import { getDepositByLot } from '../../../redux/fomento.duck'
import moment from 'moment'

const DepositCard = ({ lot }) => {
  const dispatch = useDispatch()
  const { deposit, fetching } = useSelector(({ fomento }) => fomento)
  React.useEffect(() => {
    if (!lot.id) return
    getDeposit()
  }, [lot])
  const getDeposit = () => dispatch(getDepositByLot(lot.id))
  return (
    <Card
      loading={fetching}
      //   extra={
      //     <a href="#!" onClick={() => setShowModal(true)}>
      //       Editar
      //     </a>
      //   }
      style={{ width: 300 }}
      title="Depósito"
      loading={fetching}
      style={{ maxWidth: 600 }}
    >
      {/* <h3 className="subtitle-strong-18"></h3> */}
      {/* <ListItem label="Medidor:" description={<Link>#23456</Link>} /> */}
      <ListItem
        label="Saldo total:"
        description={formatMoney(deposit.currentTotal, true)}
      />
      <ListItem
        label="Último depósito:"
        description={formatMoney(
          deposit.receipts[deposit.receipts?.length - 1]?.totalPaid,
          true,
        )}
      />
      <ListItem
        label="Fecha del último depósito:"
        description={
          deposit.receipts[deposit.receipts.length - 1]?.createdAt
            ? moment(
                deposit.receipts[deposit.receipts.length - 1]?.createdAt,
              ).format('ll')
            : ''
        }
      />
      <ListItem
        label="Servicios:"
        description={
          <>
            {deposit.services?.map((ser) => (
              <Tag
                key={ser}
                style={{ borderRadius: 20, cursor: 'pointer' }}
                color="#95BF48"
              >
                {ser}
              </Tag>
            ))}
          </>
        }
      />
    </Card>
  )
}

export default DepositCard
