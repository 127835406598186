import { message, notification } from 'antd'
import { Axios } from '../services/api'

const initialState = {
  fetching: false,
  users: [],
  user: {},
  waterInvoices: [],
}

const SAVE_USER = 'SAVE_USER'
const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS'
const SAVE_USER_ERROR = 'SAVE_USER_ERROR'

const GET_ALL_USERS = 'GET_ALL_USERS'
const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'

const UPDATE_USER = 'UPDATE_USER'
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

const DELETE_USER = 'DELETE_USER'
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

const CREATE_MULTIPLE_WATER_INVOICES = 'CREATE_MULTIPLE_WATER_INVOICES'
const CREATE_MULTIPLE_WATER_INVOICES_SUCCESS =
  'CREATE_MULTIPLE_WATER_INVOICES_SUCCESS'
const CREATE_MULTIPLE_WATER_INVOICES_ERROR =
  'CREATE_MULTIPLE_WATER_INVOICES_ERROR'

const GET_WATER_INVOICES = 'GET_WATER_INVOICES'
const GET_WATER_INVOICES_SUCCESS = 'GET_WATER_INVOICES_SUCCESS'
const GET_WATER_INVOICES_ERROR = 'GET_WATER_INVOICES_ERROR'

const REMOVE_MULTIPLE_WATER_INVOICES = 'REMOVE_MULTIPLE_WATER_INVOICES'
const REMOVE_MULTIPLE_WATER_INVOICES_SUCCESS =
  'REMOVE_MULTIPLE_WATER_INVOICES_SUCCESS'
const REMOVE_MULTIPLE_WATER_INVOICES_ERROR =
  'REMOVE_MULTIPLE_WATER_INVOICES_ERROR'

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_WATER_INVOICES_SUCCESS:
    case CREATE_MULTIPLE_WATER_INVOICES_SUCCESS:
      return {
        ...state,
        fetching: false,
        waterInvoices: payload?.items,
        ...payload,
      }

    case REMOVE_MULTIPLE_WATER_INVOICES_SUCCESS:
    case REMOVE_MULTIPLE_WATER_INVOICES_ERROR:
    case GET_WATER_INVOICES_ERROR:
    case CREATE_MULTIPLE_WATER_INVOICES_ERROR:
    case SAVE_USER_ERROR:
      return { ...state, fetching: false, error: payload }

    case REMOVE_MULTIPLE_WATER_INVOICES:
    case GET_WATER_INVOICES:
    case CREATE_MULTIPLE_WATER_INVOICES:
    case SAVE_USER:
      return { ...state, fetching: true }

    case SAVE_USER_SUCCESS:
      return { ...state, fetching: false, user: payload }
    case GET_ALL_USERS_ERROR:
      return { ...state, fetching: false, error: payload }
    case GET_ALL_USERS_SUCCESS:
      return { ...state, fetching: false, users: payload }
    case GET_ALL_USERS:
      return { ...state, fetching: true }

    case UPDATE_USER_ERROR:
      return { ...state, fetching: false, error: payload }
    case UPDATE_USER_SUCCESS:
      return { ...state, fetching: false }
    case UPDATE_USER:
      return { ...state, fetching: true }

    case DELETE_USER_ERROR:
      return { ...state, fetching: false, error: payload }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        users: state.users.filter(({ _id }) => _id !== payload),
      }
    case DELETE_USER:
      return { ...state, fetching: true }
    default:
      return state
  }
}

// Main actions
export const findWaterInvoiceWithNumberAction = (number) => (
  dispatch,
  getState,
) => {
  const { token } = getState().user
  dispatch({ type: GET_WATER_INVOICES })
  return Axios.get('/services/agua/find/' + number, {
    headers: { Authorization: token },
  })
    .then((res) => {
      dispatch({ type: GET_WATER_INVOICES_SUCCESS, payload: res.data })
      return true
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: GET_WATER_INVOICES_ERROR, payload: e })
      return false
    })
}

export const getWaterInvoicesPaginatedAction = (paginated) => (
  dispatch,
  getState,
) => {
  dispatch({ type: GET_WATER_INVOICES })
  const { token } = getState().user
  return Axios.get('/services/agua/facturas', {
    headers: { Authorization: token },
    params: {
      p: paginated && paginated.p ? paginated.p : 1,
      l: paginated && paginated.l ? paginated.l : 50,
      q: paginated && paginated.q ? paginated.q : null,
    },
  })
    .then((res) => {
      dispatch({ type: GET_WATER_INVOICES_SUCCESS, payload: res.data })
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: GET_WATER_INVOICES_ERROR, payload: e })
      message.error(e.response?.data?.message)
    })

  // const regEx = new RegExp(value, "i")
  // const options = residents.filter(res => (regEx.test(res.firstName) || regEx.test(res.residentNumber) || regEx.test(res.lastName)))
  // if (options.length) dispatch({ type: SET_OPTIONS, payload: options })
  // else
}

export const removeMultipleWaterInvoicesAction = (ids) => (
  dispatch,
  getState,
) => {
  const { token } = getState().user
  dispatch({ type: REMOVE_MULTIPLE_WATER_INVOICES })
  return Axios.post(
    '/services/agua/delete',
    { ids },
    { headers: { Authorization: token } },
  )
    .then((res) => {
      dispatch({ type: REMOVE_MULTIPLE_WATER_INVOICES_SUCCESS })
      getWaterInvoicesPaginatedAction()(dispatch, getState)
      // message.success("Las facturas se crearon con éxito")
      notification.info({
        message: 'Facturas eliminadas',
        description: 'Las facturas se eliminaron con éxito.',
        duration: 0,
      })
      return true
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: REMOVE_MULTIPLE_WATER_INVOICES_ERROR, payload: e })
      return false
    })
}

export const saveMultipleWaterInvoicesAction = (docs) => (
  dispatch,
  getState,
) => {
  const { token } = getState().user
  dispatch({ type: CREATE_MULTIPLE_WATER_INVOICES })
  return Axios.post(
    '/services/agua/xlsx',
    { docs },
    { headers: { Authorization: token } },
  )
    .then((res) => {
      dispatch({
        type: CREATE_MULTIPLE_WATER_INVOICES_SUCCESS,
        payload: res.data,
      })
      // message.success("Las facturas se crearon con éxito")
      notification.success({
        message: 'Facturas creadas',
        description: 'Las facturas se crearon con éxito.',
        duration: 0,
      })
      return true
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: CREATE_MULTIPLE_WATER_INVOICES_ERROR, payload: e })
      return false
    })
}

export const saveUserAction = (user) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: SAVE_USER })
  if (user._id) {
    return Axios.patch('/users/' + user._id, user, {
      headers: { Authorization: token },
    })
      .then((res) => {
        dispatch({ type: SAVE_USER_SUCCESS, payload: res.data })
      })
      .catch((e) => {
        console.log(e)
        dispatch({ type: SAVE_USER_ERROR, payload: e })
      })
  } else {
    return Axios.post('/users', user, { headers: { Authorization: token } })
      .then((res) => {
        dispatch({ type: SAVE_USER_SUCCESS, payload: res.data })
        message.success('Usuario registrado con éxito')
        return true
      })
      .catch((e) => {
        console.log(e)
        dispatch({ type: SAVE_USER_ERROR, payload: e })
        message.error(e.response.data)
        console.log(e)
        console.log(e.response)
        return false
      })
  }
}

export const getAllUsersAction = () => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: GET_ALL_USERS })
  return Axios.get('/users', { headers: { Authorization: token } })
    .then((res) => {
      dispatch({ type: GET_ALL_USERS_SUCCESS, payload: res.data })
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: GET_ALL_USERS_ERROR, payload: e })
    })
}

export const updateUserAction = (userId, userData) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: UPDATE_USER })
  return Axios.patch(`/users/${userId}`, userData, {
    headers: { Authorization: token },
  })
    .then(({ data: payload }) =>
      dispatch({ type: UPDATE_USER_SUCCESS, payload }),
    )
    .catch((error) => dispatch({ type: UPDATE_USER_ERROR, payload: error }))
}

export const deleteUserAction = (userId) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: DELETE_USER })
  return Axios.delete(`/users/${userId}`, { headers: { Authorization: token } })
    .then(({ data }) =>
      dispatch({ type: DELETE_USER_SUCCESS, payload: userId }),
    )
    .catch((error) => dispatch({ type: DELETE_USER_ERROR, payload: error }))
}
