import React from 'react';
import { useState } from 'react';
import { Form, Input, Row, Col, Select, Table, Checkbox } from 'antd';
import PropTypes from 'prop-types';

import { Ticket, Divider } from '../index';
import { Rules } from '../../../helpers';

import cardStyles from '../Card/Card.module.css';
import ticketStyles from '../Ticket/Ticket.module.css';
import styles from './InvoicesReciept.module.css';

function InvoicesReciept({ receipt, type, columns, tagStatus }) {
  const paymentMethod = [
    { code: 'EFEC', tag: 'Efectivo' },
    { code: 'TRANS', tag: 'Transferencia bancaria' },
    { code: 'CHEQ', tag: 'Cheque' },
    { code: 'TDDC', tag: 'Tarjeta de crédito' },
  ];
  const [form] = Form.useForm();
  const { Option } = Select;
  const [selectOptions] = useState(paymentMethod);

  const handleSelectCode = (value) => {
    let { tag } = selectOptions.find((option) => option.code === value);
    form.setFieldsValue({ tag });
  };

  const renderSelectOptions = (option) => {
    return (
      <Option key={option.code} value={option.code}>
        {option.code}
      </Option>
    );
  };

  const TicketFooterItem = ({ title, qty }) => (
    <span>
      {`${title}: `}
      <span className={ticketStyles.ticket__money}>{`$${qty}`}</span>
    </span>
  );

  return (
    <Ticket>
      <Row justify="space-between">
        <Col>
          <h4 className={cardStyles.card__title}>
            {type === 'systems' || type === 'promotion'
              ? 'Resumen del recibo'
              : 'Resumen del la factura'}
          </h4>
        </Col>
        <Col>
          <span className={styles.cancel__text}>CANCELADO</span>
        </Col>
      </Row>
      <Row gutter={[15, 15]} className="text-coal">
        <Col>Número de recibo:</Col>
        <Col>
          <span className={styles.receipt__number}>#{receipt}</span>
        </Col>
      </Row>
      <p className="text-coal">
        Fecha: <span className="text-dark">12/oct/2020</span>
      </p>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          codes: selectOptions[0]?.code,
          tag: selectOptions[0]?.tag,
        }}
        requiredMark={false}
      >
        <Row gutter={[15, 0]}>
          <Col span={8}>
            <Form.Item
              label={tagStatus ? 'Cantidad:' : 'Pago:'}
              rules={[Rules.basic]}
            >
              {tagStatus ? (
                <Input placeholder="$00.00" disabled type="number" />
              ) : (
                  <Select defaultValue="total" disabled>
                    <Option value="total">Total</Option>
                    <Option value="partial">Parcial</Option>
                  </Select>
                )}
            </Form.Item>
          </Col>
          <Col span={tagStatus ? 16 : 8}>
            <Form.Item
              label={tagStatus ? 'Tipo de pago:' : 'Cantidad:'}
              rules={[Rules.basic]}
            >
              {tagStatus ? (
                <Select defaultValue="invitado">
                  <Option value="invitado">Tag Invitado</Option>
                </Select>
              ) : (
                  <Input placeholder="$00.00" disabled type="number" />
                )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 0]}>
          <Col md={9} lg={8}>
            <Form.Item
              label="Forma de pago:"
              name="codes"
              rules={[Rules.basic]}
            >
              <Select onChange={handleSelectCode} disabled>
                {selectOptions.map(renderSelectOptions)}
              </Select>
            </Form.Item>
          </Col>
          <Col md={tagStatus ? 16 : 9} lg={tagStatus ? 16 : 8}>
            <Form.Item label={' '} name="tag" rules={[Rules.basic]}>
              <Input disabled />
            </Form.Item>
          </Col>
          {type === 'promotion' && !tagStatus && (
            <Col md={24} lg={8}>
              <Form.Item label="Descuento:">
                <Checkbox disabled checked>
                  INAPAM
                </Checkbox>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={[15, 0]}>
          <Col span={12}>
            <Form.Item label="Referencia:">
              <Input placeholder="Ingresa una referencia" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Autorizado por:">
              <Input placeholder="1234556" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Notas:">
              <Input placeholder="Ingresa un comentario" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <h4 className={cardStyles.card__title}>Servicio</h4>
        {type === 'promotion' && (
          <p className="text-coal">
            Superficie: <span className="text-dark">0 m2</span>
          </p>
        )}
        <Table
          columns={columns}
          scroll={{ y: 300 }}
          pagination={false}
          size="small"
          className={cardStyles.card__table__responsive}
        />
        <div className={ticketStyles.ticket__footer}>
          {!tagStatus && (
            <>
              <TicketFooterItem title="Saldo" qty="00.00" />
              <TicketFooterItem title=" Desc" qty="00.00" />
            </>
          )}
          <TicketFooterItem title="Total" qty="00.00" />
        </div>
      </Form>
    </Ticket>
  );
}

export default InvoicesReciept;

InvoicesReciept.defaultProps = {
  columns: [],
};

InvoicesReciept.propTypes = {
  type: PropTypes.oneOf(['promotion', 'services', 'systems', 'detail']),
  columns: PropTypes.array,
  receipt: PropTypes.string,
};
