// impresion de recibos
import React, { useEffect } from 'react'
import { Row, Modal, Button } from 'antd'
import { useState, useRef } from 'react'
import {
  useDispatch,
  // useDispatch,
  useSelector,
} from 'react-redux'
import AguaReceipt from './AguaReceipt'
import componentToPdf from '../../../tools/componentToPdf'
import FomentReceipt from '../../FomentReceipt'
// import { collectDataReport } from '../../../redux/reports.duck';
import printFilesImage from '../../../assets/svgs/PrintFiles.svg'
import styles from './CashierPrint.module.css'
import DepositReceipt from './DepositReceipt'

function PrintingModal({
  agua = false,
  mantenimiento = false,
  show,
  onCancel,
  onFinish = () => false,
}) {
  const { lot } = useSelector(({ fomento }) => fomento)
  const { print, fetching } = useSelector(({ fomento }) => fomento)

  const [aguaPrint, setAguaPrint] = useState(null)
  const [printing, setPrinting] = useState(false)
  const componentRef = useRef()
  const { print: PDF } = useSelector(({ fomento }) => fomento)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (agua) {
      setAguaPrint({ ...print, lot })
    } else if (mantenimiento) {
      dispatch({ type: 'SET_FOR_PRINTING', payload: mantenimiento })
    }
    // console.log("ready for printing: ", !!Object.keys(print).length,print)
  }, [print])

  const printReceipt = () => {
    setPrinting(true)
    createPDF(componentRef.current)
    setTimeout(() => {
      onCancel()
      setPrinting(false)
      onFinish()
    }, 5000)
  }

  const handleCancel = () => {
    onFinish()
    onCancel()
  }

  const createPDF = (html) => {
    componentToPdf.createPdf(html, `FACTURA_${PDF.folio}`)
    // onFinish()
  }

  console.log("agua prinnt", aguaPrint)

  return (
    <>
      <Modal
        title="Imprimir Recibo"
        centered
        visible={show}
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        className={styles.modal__print}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Cancelar
          </Button>,
          <Button onClick={printReceipt} type="primary" loading={printing}>
            Imprimir
          </Button>,
        ]}
      >
        <div style={{ position: 'fixed', zIndex: -10000, bottom: '-300vh' }}>
          {agua ? (
            <AguaReceipt printData={aguaPrint?.lot?.seccion ? aguaPrint : PDF} currentRef={componentRef} />
          ) : mantenimiento ? <FomentReceipt format printData={aguaPrint} currentRef={componentRef} /> : (
            <DepositReceipt printData={print} currentRef={componentRef} />
          )}
        </div>
        <p className="textColor">El recibo se generará para impresión.</p>
        <Row justify="center">
          <img src={printFilesImage} alt="print files" />
        </Row>
      </Modal>
    </>
  )
}

export default PrintingModal
