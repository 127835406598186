import React, { useState } from 'react'
import {
  Select,
  Tag,
  Card,
  Input,
  Divider,
  Table,
  Button,
  InputNumber,
} from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { formatMoney } from '../../helpers/formatMoney'
import { useHistory } from 'react-router-dom'
import {
  clearFomentInfoAction,
  createDepositReceiptAction,
  createReceiptAction,
  removeDebtFromReceiptAction,
} from '../../redux/fomento.duck'
import PrintingModal from '../Common/CashierPrint/PrintingModal'
import { PlusOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'

const ReceiptSummary = () => {
  // constants
  const { Option } = Select
  const paymentMethod = [
    { code: 'EFEC', tag: 'Efectivo' },
    { code: 'TRANS', tag: 'Transferencia bancaria' },
    { code: 'CHEQ', tag: 'Cheque' },
    { code: 'TDDC', tag: 'Tarjeta de crédito' },
  ]
  const initialForm = {
    amount: 0,
    amountTwo: 0,
    paymentMethod: 'EFEC',
    secondPaymentMethod: 'TDDC',
    reference: '',
    notes: '',
  }
  //
  const dispatch = useDispatch()
  const history = useHistory()
  const { lot, servicesPayable, fetching } = useSelector(
    ({ fomento }) => fomento,
  )
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})

  const [twoPayments, setTwoPayments] = useState(false)

  const [printing, setPrinting] = useState(false)
  const { displayName, id: userId } = useSelector(({ user }) => user)
  // methods
  const onChange = ({ target: { name, value } }) => {
    if (name === 'amountTwo' && Number(value) > Number(totalAmount)) return
    else if (name === 'amountTwo') {
      setForm({
        ...form,
        amount: Math.floor(Number(totalAmount) - Number(value)),
        [name]: Number(value),
      })
    } else setForm({ ...form, [name]: value })
  }

  const removeInvoiceOfAgreement = (record) => {
    dispatch(removeDebtFromReceiptAction(record.id))
  }

  // Validation
  const isValidForm = () => {
    if (!lot.id) return
    const err = {}
    if (servicesPayable.length < 1) err.amount = 'Selecciona una factura'
    if (
      twoPayments &&
      (!form.amount || typeof form.amount !== 'number' || form.amount < 1)
    ) {
      err.amount = 'Ingresa una cantidad'
    }
    if (
      twoPayments &&
      (!form.amountTwo ||
        typeof form.amountTwo !== 'number' ||
        form.amountTwo < 1)
    ) {
      err.amount = 'Ingresa una cantidad'
    }
    if (!form.paymentMethod) err.paymentMethod = 'Selecciona una forma de pago'
    if (twoPayments && !form.secondPaymentMethod)
      err.paymentMethod = 'Selecciona una forma de pago'
    if (!displayName) {
      alert('Inicia sesión nuevamente porfavor')
      history.push('/signin')
    }
    setErrors(err)
    return !!!Object.keys(err).length
  }

  // Backend commute
  const saveReceipt = () => {
    if (!isValidForm()) return
    const pForms = [
      {
        tipo: form.paymentMethod,
        amount: twoPayments ? form.amount : totalAmount,
      },
    ]
    if (twoPayments)
      pForms.push({ tipo: form.secondPaymentMethod, amount: form.amountTwo })
    const body = {
      tipo: 'agua',
      subsidiary: 'Sistemas',
      quantityType: 'total',
      lot: lot.id,
      totalPaid: twoPayments ? form.amount + form.amountTwo : totalAmount,
      paymentForm: pForms,
      reference: form.reference,
      notes: form.notes,
      waterInvoices: servicesPayable.map((inv) => inv.id),
    }
    // return console.log(body)
    setForm(initialForm)
    dispatch(createReceiptAction(body)).then((r) => {
      if (r) setPrinting(true)
    })
  }

  const totalAmount = React.useMemo(
    () =>
      servicesPayable.reduce(
        (acc, item) => acc + (item.surcharge + item.amount),
        0,
      ),
    [servicesPayable],
  )

  return (
    <div>
      <Card
        title="Resumen del abono"
        style={{
          width: 586,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <section style={{ gap: 15, display: 'flex', flexDirection: 'column' }}>
          <div>
            <span style={{ color: 'grey', marginRight: 20 }}>Fecha:</span>
            <span style={{ color: 'black' }}>
              {moment(Date.now()).format('D/MMM/YYYY')}
            </span>
          </div>

          <article style={{ display: 'flex', gap: 30 }}>
            <div style={{ color: 'grey', margin: 0, display: 'flex' }}>
              <div>
                <p>Forma de pago</p>
                <Select
                  onChange={(value) =>
                    onChange({ target: { name: 'paymentMethod', value } })
                  }
                  value={form.paymentMethod}
                  style={{
                    width: 125,
                    marginRight: 24,
                  }}
                >
                  {paymentMethod.map((ser) => (
                    <Option key={ser.code}>{ser.code}</Option>
                  ))}
                </Select>
              </div>
              <div>
                <p>Cantidad</p>
                <InputNumber
                  disabled
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  onChange={(value) =>
                    onChange({ target: { value, name: 'amount' } })
                  }
                  placeholder="$00.00"
                  value={
                    twoPayments
                      ? Math.floor(form.amount)
                      : Math.floor(totalAmount)
                  }
                  style={{ width: 250 }}
                />{' '}
                MXN
                <span style={styles.error}>{errors.paymentMethod}</span>
              </div>
            </div>
          </article>

          <span style={styles.error}>{errors.amount}</span>
          {twoPayments ? (
            <article style={{ display: 'flex', gap: 30 }}>
              <div style={{ color: 'grey', margin: 0, display: 'flex' }}>
                <div>
                  <p>Forma de pago</p>
                  <Select
                    onChange={(value) =>
                      onChange({
                        target: { name: 'secondPaymentMethod', value },
                      })
                    }
                    value={form.secondPaymentMethod}
                    style={{
                      width: 125,
                      marginRight: 24,
                    }}
                  >
                    {paymentMethod
                      .filter((m) => m.code !== form.paymentMethod)
                      .map((ser) => (
                        <Option key={ser.code}>{ser.code}</Option>
                      ))}
                  </Select>
                </div>
                <div>
                  <p>Cantidad</p>
                  <input
                    value={form.amountTwo}
                    name="amountTwo"
                    onChange={onChange}
                  />
                  {/* <InputNumber
                    disabled={!lot.id}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    onChange={(value) =>
                      onChange({ target: { value, name: 'amountTwo' } })
                    }
                    placeholder="$00.00"
                    value={form.amountTwo}
                    style={{ width: 250 }}
                  />{' '} */}
                  <span>MXN</span>
                  <Button
                    onClick={() => {
                      setTwoPayments(false)
                      setForm({ ...form, amountTwo: 0 })
                    }}
                    size="small"
                    shape="circle"
                    style={{
                      marginLeft: 10,
                      background: 'transparent',
                      color: '#aaa',
                      //   boxShadow: '0px 4px 4px rgba(149, 191, 72, 0.25)',
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                  <span style={styles.error}>{errors.paymentMethod}</span>
                </div>
              </div>
            </article>
          ) : (
            <Button
              type="dashed"
              onClick={() => {
                setForm({ ...form, amount: totalAmount })
                setTwoPayments(true)
              }}
              block
              icon={<PlusOutlined />}
            >
              Agregar forma de pago
            </Button>
          )}

          <article style={{ display: 'flex', gap: 30 }}>
            <div style={{ color: 'grey', margin: 0 }}>
              <span>Referencia</span>
              <Input
                onChange={onChange}
                placeholder={'Escribe una referencia'}
                value={form.reference}
                name="reference"
                style={{ width: 250 }}
              />
            </div>
            <div style={{ color: 'grey', margin: 0 }}>
              <span>Autorizado por:</span>
              <Input value={displayName} style={{ width: 250 }} />
            </div>
          </article>

          <div style={styles.formItem}>
            <span>Notas:</span>
            <Input
              onChange={onChange}
              placeholder="Ingresa un comentario"
              value={form.notes}
              name="notes"
              style={{ width: '100%' }}
            />
          </div>
        </section>
        <Divider />
        <section>
          <h3>Servicio</h3>
          <Table
            onRow={(record) => ({
              onClick: () => removeInvoiceOfAgreement(record),
            })}
            size="small"
            pagination={false}
            dataSource={servicesPayable}
            columns={[
              {
                title: 'Año',
                dataIndex: 'year',
                key: 'year',
              },
              {
                title: 'Bimestre',
                dataIndex: 'twomonths',
                key: 'twomonths',
              },
              {
                title: 'Importe',
                dataIndex: 'amount',
                key: 'amount',
                render: (val) => formatMoney(val),
              },
              {
                title: 'Recargos',
                dataIndex: 'surcharge',
                key: 'surcharge',
                render: (val) => formatMoney(val),
              },
              {
                title: 'Total',
                dataIndex: 'surcharge',
                key: 'surcharge',
                render: (_, record) =>
                  formatMoney(record.amount + record.surcharge),
              },
            ]}
          />
          <span style={styles.error}>{errors.invoices}</span>
          <div
            style={{
              marginTop: 150, // change this when real table
              display: 'flex',
              gap: 100,
              justifyContent: 'flex-end',
            }}
          >
            <span>Total: {formatMoney(totalAmount, true)}</span>
            <span>
              {/* {formatMoney(Object.values(invoices).reduce((acc, inv) => acc + inv.total, 0), true)} */}
            </span>
          </div>
        </section>
      </Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: 40,
        }}
      >
        <Button
          type="primary"
          loading={fetching}
          onClick={saveReceipt}
          disabled={fetching || !lot.id}
          style={
            fetching || Object.keys(errors).length
              ? styles.disabledButton
              : styles.enabledButton
          }
        >
          Pagar
        </Button>
      </div>

      <PrintingModal
        onFinish={() => dispatch(clearFomentInfoAction())}
        show={printing}
        agua={true}
        onCancel={() => setPrinting(false)}
      />
    </div>
  )
}

const styles = {
  tag: { borderRadius: 20, cursor: 'pointer' },
  error: { color: 'red' },
}

export default ReceiptSummary
