import React from 'react';
import moment from 'moment';

import { Row, Col, Button, Tabs } from 'antd';
import { Card } from '../index';
import Lomas from './Lomas';
import Lotes from './Lotes';
import cardStyles from '../Card/Card.module.css';
import styles from './ResidentInfo.module.css';

const ResidentInfo = ({ setIsEditing, resident, onEdit }) => {

  console.log("El resi: ", resident)

  const { TabPane } = Tabs;

  const ItemInfo = ({ title, value, index }) => (
    <Row className={styles.item}>
      <span className={styles.item__title}>{`${title}:`}</span>
      <span
        className={index === 0 ? styles.item__value__main : styles.item__value}
      >
        {value}
      </span>
    </Row>
  );

  // const handleEdit = () => {
  //   setIsEditing(true);
  // };

  return (
    <>
      <Row>
        <Col sm={24} xs={24} md={12} lg={12}>
          <Card>
            <Row justify="space-between">
              <h1 className={cardStyles.card__title}>
                Información del residente
              </h1>
              <Button type="link" onClick={() => onEdit ? onEdit() : null} className={styles.link}>
                Editar
              </Button>
            </Row>
            <ItemInfo title="Número de residente" value={`#${resident.number}`} index={0} />
            {/* <ItemInfo
              title="Dirección"
              value="Seccón 1, Lomas, Lote 4, Mz. 8"
            /> */}
            <ItemInfo title="Teléfono" value={resident.tel} />
            <ItemInfo title="Email" value={resident.email} />
            <ItemInfo title="Registrado" value={moment(resident.createdAt).format('ll')} />
          </Card>
        </Col>
      </Row>
      {/* <Tabs defaultActiveKey="1">
        <TabPane tab="Lotes" key="1">
          <Lotes />
        </TabPane>
        <TabPane tab="Lomas" key="2">
          <Lomas />
        </TabPane>
      </Tabs> */}
    </>
  );
};

export default ResidentInfo;
