import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Row,
  Modal,
  Button,
  Form,
  Col,
  Input,
  Select,
  message,
  notification,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import styles from './EditBatchModal.module.css'
import { Rules } from '../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { createLotAction, updateLotAction } from '../../../redux/lotsDuck'
import { searchOptionsAction } from '../../../redux/residentsDuck'

const baseUrl =
  process.env.REACT_APP_BACKEND_ROUTE || 'https://cocoyoc-backend.herokuapp.com'

const { Option } = Select

function EditLotModal({ invoice = {}, match, history }) {
  const { pathname } = useLocation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { fetching, error, lot } = useSelector(({ lots }) => lots)
  const role = useSelector(({ user }) => user.role.toLowerCase())
  const [isEditing, setIsEditing] = useState(false)
  const [residentSelected, setResidents] = useState([])
  const isNew = pathname === `/${role}/lotes/nuevo`
  //residents
  const { options } = useSelector(({ residents }) => residents)
  //residents percent
  const [percentError, setPercentError] = useState(null)
  // Effects
  useEffect(() => {
    if (match.params.id) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
      form.resetFields()
    }
  }, [])

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue({
        ...lot,
        measurer: lot.measurer?.map((el) => el.number),
        residents: lot.residents.map((el) => ({
          // resident: el.firstName + ' ' + el.lastName,
          resident: el.id,
          percent: lot.percent.find((item) => item.resident === el.id)?.percent,
          id: el._id,
        })),
      })
    }
  }, [isEditing])

  const handleResidentSearch = (value) => {
    if (value.length > 2) {
      dispatch(searchOptionsAction(value))
    }
  }

  const handleResidentChange = (value) => {
    setResidents(value)
  }

  const createLot = (values) => {
    // validate percentages
    if (values.residents.length) {
      let total = 0
      for (let resident of values.residents) {
        total += Number(resident.percent)
      }
      if (total < 100 || total > 100) {
        setPercentError('error')
        return message.error('Verifica los porcentajes')
      } else {
        setPercentError(null)
      }
    }
    if (isEditing) {
      dispatch(updateLotAction({ ...lot, ...values })).then((e) => {
        if (e) history.goBack()
      })
      return
    }

    dispatch(createLotAction(values)).then((e) => {
      if (e) history.goBack()
    })
  }

  const generateDates = () => {
    const arr = []
    const currentYear = new Date().getFullYear() + 1
    for (let i = 1980; i < currentYear; i++) {
      arr.push(<Option value={i}>{i}</Option>)
    }
    return arr
  }

  return (
    <>
      <Modal
        forceRender
        title={isNew ? 'Agregar lote' : 'Editar lote'}
        centered
        visible={true}
        onOk={() => {}}
        onCancel={() => {
          history.goBack()
        }}
        footer={[
          <Button key="back" onClick={() => history.goBack()}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={fetching}
            onClick={() => form.submit()}
          >
            {isNew ? 'Agregar' : 'Guardar'}
          </Button>,
        ]}
      >
        <Form
          form={form}
          requiredMark={false}
          layout="vertical"
          onFinish={createLot}
          initialValues={{ residents: [] }}
        >
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Form.Item
                label="Id:"
                name="number"
                required
                rules={[Rules.basic]}
              >
                <Input defaultValue={isEditing ? lot.key : null} />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item label="Medidor:" name="medidor" >
                <Input />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label="Calle:"
                name="street"
                required
                rules={[Rules.basic]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={5}>
              <Form.Item
                label="Sección:"
                name="seccion"
                required
                rules={[Rules.basic]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Manzana:"
                name="manzana"
                required
                rules={[Rules.basic]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Lote"
                name="lote"
                required
                rules={[Rules.basic]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Form.Item
                label="Superficie:"
                name="area"
                required
                rules={[Rules.basic]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Clúster" name="cluster" required>
                <Input defaultValue={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Form.Item label="Unidad de medida:" name="measureUnit">
                <Input defaultValue="M2" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Construcción" name="construction">
                <Input defaultValue={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Form.Item
                label="Año de compra"
                name="yearOfPurchase"
                required
                // rules={[Rules.basic]}
              >
                <Select>{generateDates()}</Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Medidor de agua" name="measurer">
                {/* <Input defaultValue="" /> */}
                <Select
                  // defaultValue={measurer}
                  mode="tags"
                  // disabled={!isEditing}
                  style={{ width: '100%' }}
                  placeholder={'Presiona enter al terminar'}
                  // onChange={handleMeasurerChange}
                >
                  {/* {measurer.map(res => <Option value={res} >{`${res}`}</Option>)} */}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Form.Item label="Servicio de luz" name="luz">
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={'Presiona enter al terminar'}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Servicio de Gas" name="gas">
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={'Presiona enter al terminar'}
                ></Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Form.Item label="Servicio de Teléfono" name="telefono">
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={'Presiona enter al terminar'}
                ></Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.List name="residents">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    validateStatus={percentError && 'error'}
                    required={true}
                    label={index === 0 && 'Residente'}
                    key={field.key}
                  >
                    <Row gutter={[10, 0]}>
                      <Col span={14}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          name={[field.name, 'resident']}
                          fieldKey={[field.fieldKey, 'resident']}
                          // rules={[
                          //   {
                          //     required: true,
                          //     whitespace: true,
                          //     message: 'Ingresa un residente.',
                          //   },
                          // ]}
                          value={index === 0 && invoice.name}
                          className={styles.residents__list__resident}
                        >
                          <Select
                            showSearch
                            value={residentSelected}
                            placeholder="Nombre del residente"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleResidentSearch}
                            onChange={handleResidentChange}
                            notFoundContent={null}
                          >
                            {options.map((res) => (
                              <Option value={res.id}>{`${res.number} ${
                                res.firstName
                              } ${res.lastName || ''}`}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'percent']}
                          validateTrigger={['onChange', 'onBlur']}
                          fieldKey={[field.fieldKey, 'percent']}
                          rules={[
                            { required: true, message: 'Campo obligatorio.' },
                          ]}
                          className={styles.residents__list__percent}
                        >
                          <Input placeholder="%" type="number" min={1} />
                        </Form.Item>
                      </Col>
                      {fields.length > 0 && (
                        <Col span={2} className={styles.residents__list__btn}>
                          <DeleteOutlined
                            className={styles.btn__delete}
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      )}
                    </Row>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="text"
                    onClick={() => add()}
                    className={styles.btn__add}
                  >
                    + Agregar residente
                  </Button>
                  {/* <Form.ErrorList
                    // errors={errors}
                  /> */}
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

export default EditLotModal
