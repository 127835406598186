import { message, notification } from 'antd'
import axios from 'axios'
import { Axios } from '../services/api'

const url = process.env.REACT_APP_BACKEND_ROUTE || 'http://localhost:8000'

const initialState = {
  fomentInvoices: [],
  fetching: false,
  receipt: {},
  waterInvoice: {},
  found: null,
}

const GET_ONE_WATER_INVOICE = 'GET_ONE_WATER_INVOICE'
const GET_ONE_WATER_INVOICE_SUCCESS = 'GET_ONE_WATER_INVOICE_SUCCESS'
const GET_ONE_WATER_INVOICE_ERROR = 'GET_ONE_WATER_INVOICE_ERROR'

const GET_ONE_RECEIPT = 'GET_ONE_RECEIPT'
const GET_ONE_RECEIPT_SUCCESS = 'GET_ONE_RECEIPT_SUCCESS'
const GET_ONE_RECEIPT_ERROR = 'GET_ONE_RECEIPT_ERROR'

const CANCEL_RECEIPT = 'CANCEL_RECEIPT'
const CANCEL_RECEIPT_SUCCESS = 'CANCEL_RECEIPT_SUCCESS'
const CANCEL_RECEIPT_ERROR = 'CANCEL_RECEIPT_ERROR'

const GET_FOMENT_INVOICES = 'GET_FOMENT_INVOICES'
const GET_FOMENT_INVOICES_SUCCESS = 'GET_FOMENT_INVOICES_SUCCESS'
const GET_FOMENT_INVOICES_SUCCESS_PAGINATED =
  'GET_FOMENT_INVOICES_SUCCESS_PAGINATED'
const GET_FOMENT_INVOICES_ERROR = 'GET_FOMENT_INVOICES_ERROR'

const FIND_INVOICE_BY_LOT = 'FIND_INVOICE_BY_LOT'
const FIND_INVOICE_BY_LOT_ERROR = 'FIND_INVOICE_BY_LOT_ERROR'
const FIND_INVOICE_BY_LOT_SUCCESS = 'FIND_INVOICE_BY_LOT_SUCCESS'
const CLEAR_INVOICE = 'CLEAR_INVOICE'

export default (state = initialState, { payload, type }) => {
  switch (type) {

    case FIND_INVOICE_BY_LOT_ERROR:
    case CANCEL_RECEIPT_ERROR:
    case GET_ONE_WATER_INVOICE_ERROR:
    case GET_ONE_RECEIPT_ERROR:
      return {
        ...state,
        error: payload,
        // fetching: false // just for testing
      }

    case CLEAR_INVOICE:
      return { ...state, found: null, }
    case FIND_INVOICE_BY_LOT_SUCCESS:
      return { ...state, fetching: false, found: payload, }
    case CANCEL_RECEIPT_SUCCESS:
      return { ...state, receipt: payload, fetching: false }
    case GET_ONE_WATER_INVOICE_SUCCESS:
      return { ...state, waterInvoice: payload, fetching: false }
    case GET_ONE_RECEIPT_SUCCESS:
      return { ...state, receipt: payload, fetching: false }

    case FIND_INVOICE_BY_LOT:
    case CANCEL_RECEIPT:
    case GET_ONE_WATER_INVOICE:
    case GET_FOMENT_INVOICES:
    case GET_ONE_RECEIPT:
      return { ...state, fetching: true }
    case GET_FOMENT_INVOICES_SUCCESS_PAGINATED:
      return {
        ...state,
        fomentInvoices: payload.items,
        fetching: false,
        ...payload,
      }
    case GET_FOMENT_INVOICES_SUCCESS:
      return { ...state, fomentInvoices: payload, fetching: false }
    default:
      return state
  }
}

// THUUNKS

export const cancelReceiptAction = (id) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: CANCEL_RECEIPT })
  return Axios.delete(`/receipts/${id}`, {
    headers: { Authorization: token },
  })
    .then((res) => {
      // if (res.data.items) return dispatch({ type: CANCEL_RECEIPT_SUCCESS, payload: res.data })
      return dispatch({ type: CANCEL_RECEIPT_SUCCESS, payload: res.data })
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: CANCEL_RECEIPT_ERROR, payload: e })
      message.error('Ocurrió un error en el servidor')
    })
}

export const getReceiptAction = (id) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: GET_ONE_RECEIPT })
  return Axios.get(`/receipts/${id}`, {
    headers: { Authorization: token },
  })
    .then((res) => {
      // if (res.data.items) return dispatch({ type: GET_ONE_RECEIPT_SUCCESS, payload: res.data })
      return dispatch({ type: GET_ONE_RECEIPT_SUCCESS, payload: res.data })
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: GET_ONE_RECEIPT_ERROR, payload: e })
      message.error('Ocurrió un error en el servidor')
    })
}

export const getFomentInvoicesAction = (paginated) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: GET_FOMENT_INVOICES })
  return Axios.get(`/foments/invoices`, {
    headers: { Authorization: token },
    params: {
      p: paginated && paginated.p ? paginated.p : 1,
      l: paginated && paginated.l ? paginated.l : 50,
      q: paginated && paginated.q ? paginated.q : null,
    },
  })
    .then((res) => {
      if (res.data.items)
        return dispatch({
          type: GET_FOMENT_INVOICES_SUCCESS_PAGINATED,
          payload: res.data,
        })
      dispatch({ type: GET_FOMENT_INVOICES_SUCCESS, payload: res.data })
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: GET_FOMENT_INVOICES_ERROR, payload: e })
      message.error(e.response?.data?.message)
    })
}

export const getOneWaterInvoiceAction = (id) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: GET_ONE_WATER_INVOICE })
  return Axios.get(`/services/agua/facturas/${id}`, {
    headers: { Authorization: token },
    // params: {
    //   p: paginated && paginated.p ? paginated.p : 1,
    //   l: paginated && paginated.l ? paginated.l : 50,
    //   q: paginated && paginated.q ? paginated.q : null,
    // },
  })
    .then((res) => {
      dispatch({ type: GET_ONE_WATER_INVOICE_SUCCESS, payload: res.data })
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: GET_ONE_WATER_INVOICE_ERROR, payload: e })
      message.error(e.response?.data?.message)
    })
}

export const findInvoiceByLotAction = (query) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: FIND_INVOICE_BY_LOT })
  return axios
    .post(`${url}/foments/find`, { query }, {
      headers: { Authorization: token },
    })
    .then(({ data }) => {
      dispatch({ type: FIND_INVOICE_BY_LOT_SUCCESS, payload: data })
      return data
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: FIND_INVOICE_BY_LOT_ERROR, payload: err })
    })
}

export const clearInvoiceAction = () => (dispatch) => {
  dispatch({ type: CLEAR_INVOICE })
}
