import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Form, Input, Button, Typography } from 'antd';

import { SplitScreen } from '../Common';
import { Rules } from '../../helpers';
import { loginAction } from '../../redux/user.duck';
import { ADMIN_SUMMARY, CASHIER, FORGOT_PASSWORD } from '../../constants/routes';

import styles from './Signin.module.css';

function SignIn() {
  const dispatch = useDispatch();
  const { loading, error, loggedIn, role } = useSelector(({ user }) => user);
  const { Text } = Typography;

  const signInAction = ({ email, password }) => {
    dispatch(loginAction(email, password));
  };

  if (role === "ADMIN" && loggedIn) return <Redirect to={ADMIN_SUMMARY} />
  if (loggedIn) return <Redirect to={CASHIER} />;

  return (
    <SplitScreen>
      <Form
        onFinish={signInAction}
        layout="vertical"
        requiredMark={false}
        className={styles.form}
      >
        <legend className={styles.form__title}>Inicia sesión</legend>

        <Form.Item
          name="email"
          label="Email"
          className={`${styles.form__group} c_label`}
          rules={[Rules.email]}
          {...error}
        >
          <Input
            placeholder="correo@dominio.com"
            className={styles.form__input}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Contraseña"
          rules={[Rules.password]}
          className={`${styles.form__group} c_label`}
          {...error}
        >
          <Input.Password
            placeholder="••••••••"
            className={styles.form__input}
          />
        </Form.Item>

        <div className={styles.form__group}>
          <Text className={styles.form__text}>
            ¿Olvidaste tu contraseña?{' '}
            <Link to={FORGOT_PASSWORD} className={styles.form__link}>
              Recuperar contraseña
            </Link>
          </Text>
        </div>

        <div className={styles.form__group}>
          <Button
            htmlType="submit"
            shape="round"
            type="primary"
            loading={loading}
            className={styles.form__button}
          >
            {loading ? 'Iniciando' : 'Iniciar'} sesión
          </Button>
        </div>
      </Form>
    </SplitScreen>
  );
}

export default SignIn;
