import React from 'react'
import { Card, Tag } from 'antd'
import { formatMoney } from '../../helpers/formatMoney'
import { useHistory } from 'react-router-dom'

const AgreementCard = ({
    id,
    paid,
    folio,
    lot = {},
    total,
    pendingAmount,
    paymentsDone,
    paymentsNumber,
    loading,
    onClick = () => {}
}) => {
    const history = useHistory()

    onClick = ()=>{
        history.push(`/admin/convenios/${id}`)
    }

    return (
        <Card
            key={id}
            style={{
                minWidth: 400,
                cursor:'pointer'
            }}
            loading={loading}
            onClick={onClick}
            title={`Convenio ${folio}`} extra={
                paid ? <Tag color="#95bf48">Pagado</Tag> :
                    <Tag color="#2d9cdb">Pendiente</Tag>
            }>

            <div>
                <p>Lote:{" "}
                    <BoldText>
                        {`Sec ${lot.seccion || ""}, Mz ${lot.manzana || ""}, Lt ${lot.lote || ""}`}
                    </BoldText>
                </p>
                <p>
                    Importe original:{" "}
                    <BoldText>
                        {formatMoney(total)}
                    </BoldText>
                </p>
                <p>
                    Importe pendiente:{" "}
                    <BoldText>
                        {formatMoney(pendingAmount)}
                    </BoldText>
                </p>
                <p>
                    Pagos pendientes:{" "}
                    <BoldText>
                        {paymentsNumber - paymentsDone}
                    </BoldText>
                </p>
            </div>

        </Card>
    )
}

const BoldText = ({ children }) => (<span style={{ fontWeight: "bold" }}>{children}</span>)

export default AgreementCard