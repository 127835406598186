import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Typography, Table, Button } from 'antd';

import { SideLayout, InvoicesModalUpload } from '../Common';
import { CASHIER_TYPE, INVOICES } from '../../constants/routes';

import styles from './InvoicesRegister.module.css';

function InvoicesRegister() {
  const { type } = useParams();
  const { Title, Paragraph } = Typography;
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    type: '',
    charge: '',
    action: '',
  });
  const [mockData] = useState([
    {
      key: '1',
      factura: '109090',
      residente: 'Enrique Días Cruz',
      lote: 'Sec. 1 Lt. 4 Mz. 6',
      codigo: '123456789',
      año: '2020',
      bim: 'b3',
      consumo: '280m3',
      costo: '$23.00',
      importe: '$10000.00',
    },
    {
      key: '2',
      factura: '109091',
      residente: 'Juan Diego Slas Jimenez',
      lote: 'Sec. 1 Lt. 4 Mz. 6',
      codigo: '123',
      año: '2020',
      bim: 'b3',
      consumo: '280m3',
      costo: '$23.00',
      importe: '$10000.00',
    },
    {
      key: '3',
      factura: '109093',
      residente: 'Maria Gaspar Morales',
      lote: 'Sec. 1 Lt. 4 Mz. 6',
      codigo: '1234567890',
      año: '2020',
      bim: 'b3',
      consumo: '280m3',
      costo: '$26.00',
      importe: '$10500.00',
    },
  ]);
  const [simulateBarCode/*, setsimulateBarCode */] = useState('');

  useEffect(() => {
    let args = type.split('-');
    setParams({ type: args[0], charge: args[1], action: args[2] });
  }, [type]);

  useEffect(() => {
    if (params.type === 'luz') {
      let newRow = mockData.filter((row) => row.codigo === simulateBarCode);
      if (newRow.length > 0) setTableData([...tableData, newRow[0]]);
    }
  }, [params, simulateBarCode, mockData, tableData]);

  const getTableHeaders = () => {
    switch (params.type) {
      case 'agua':
        return [
          {
            title: 'Factura',
            dataIndex: 'Factura',
            key: 'Factura',
          },
          {
            title: 'Residente',
            dataIndex: 'Residente',
            key: 'Residente',
          },
          {
            title: 'Lote',
            dataIndex: 'Lote',
            key: 'Lote',
          },
          {
            title: 'Año',
            dataIndex: 'Año',
            key: 'Año',
          },
          {
            title: 'Bim',
            dataIndex: 'Bim',
            key: 'Bim',
          },
          {
            title: 'Lec. anterior',
            dataIndex: 'Lec. anterior',
            key: 'Lec. anterior',
          },
          {
            title: 'Lec. actual',
            dataIndex: 'Lec. actual',
            key: 'Lec. actual',
          },
          {
            title: 'Consumo',
            dataIndex: 'Consumo',
            key: 'Consumo',
          },
          {
            title: 'Costo x m3',
            dataIndex: 'Costo x m3',
            key: 'Costo x m3',
          },
          {
            title: 'Importe',
            dataIndex: 'Importe',
            key: 'Importe',
          },
        ];
      case 'luz':
        return [
          {
            title: 'Factura',
            dataIndex: 'factura',
            key: 'factura',
          },
          {
            title: 'Residente',
            dataIndex: 'residente',
            key: 'residente',
          },
          {
            title: 'Lote',
            dataIndex: 'lote',
            key: 'lote',
          },
          {
            title: 'Código de barras',
            dataIndex: 'codigo',
            key: 'codigo',
          },
          {
            title: 'Año',
            dataIndex: 'año',
            key: 'año',
          },
          {
            title: 'Bim',
            dataIndex: 'bim',
            key: 'bim',
          },
          {
            title: 'Consumo',
            dataIndex: 'consumo',
            key: 'consumo',
          },
          {
            title: 'Costo x kw',
            dataIndex: 'costo',
            key: 'costo',
          },
          {
            title: 'Importe',
            dataIndex: 'importe',
            key: 'importe',
          },
        ];
      default:
        return [];
    }
  };

  const saveInvoices = () => {
    setLoading(true);
    setTimeout(() => {
      history.push(INVOICES);
    }, 3000);
  };

  const Footer = () => (
    <span className={styles.container__footer__content}>
      <span className={styles.container__footer__text}>Total de registros</span>{' '}
      {tableData.length}
    </span>
  );

  return (
    <SideLayout type={CASHIER_TYPE}>
      <h2 className="title">Agregar facturas</h2>
      <h3 className="title-description">
        Agrega las faturas de agua, luz o teléfono de los residentes.
      </h3>
      {/* <input type="text" onChange={(e) => setsimulateBarCode(e.target.value)} /> */}
      <div className={styles.container_card}>
        {!tableData.length ? (
          <div className={styles.container__copy}>
            <Title level={5}>Facturas</Title>

            {params.type === 'agua' && (
              <>
                <Paragraph>
                  Elige el archivo .xls o .csv con las lecturas de agua de los
                  residentes. No olvides que el archivo debe tener la estructura
                  establecida. <Link className="text-green"> Ver más</Link>
                </Paragraph>
                <InvoicesModalUpload
                  setTableData={setTableData}
                  type={params.type}
                />
              </>
            )}

            {params.type === 'luz' && (
              <>
                <Paragraph>
                  Pasa el lector de código por el código de barras de cada uno
                  de los recibo de luz de los residentes.
                </Paragraph>
                <InvoicesModalUpload
                  setTableData={setTableData}
                  type={params.type}
                />
              </>
            )}
          </div>
        ) : (
          <div className={styles.table}>
            <Table
              dataSource={tableData}
              columns={getTableHeaders()}
              pagination={{ position: ['bottomLeft'] }}
            />
            <Footer />
          </div>
        )}
      </div>
      {tableData.length > 0 && (
        <div className={styles.table__actions}>
          <Button loading={loading} onClick={saveInvoices} type="primary">
            Guardar facturas
          </Button>
        </div>
      )}
    </SideLayout>
  );
}

export default InvoicesRegister;
