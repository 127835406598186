import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

// Reducers
import user, { restoreSessionAction } from './user.duck'
import fomento from './fomento.duck'
import reports from './reports.duck'
import admin from './adminDuck'
import lots from './lotsDuck'
import residents from './residentsDuck'
import invoices from './invoicesDuck'
import agreements from './agreementsDuck'
import messages from './messagesDuck'
import services from './servicesDuck'

const rootReducer = combineReducers({
  services,
  agreements,
  invoices,
  user,
  fomento,
  reports,
  admin,
  lots,
  residents,
  messages,
})

const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function generateStore() {
  const store = createStore(
    rootReducer,
    composeEnhacers(applyMiddleware(thunk)),
  )
  restoreSessionAction()(store.dispatch)
  return store
}

export default generateStore
