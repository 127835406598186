import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Card, Tag, Empty, Checkbox, Divider, Table } from 'antd'
import ListItem from '../Common/ListDetailItem'
import { formatMoney } from '../../helpers/formatMoney'
import moment from 'moment'
import {
  addWaterToReceiptAction,
  getPendingWaterByLot,
} from '../../redux/fomento.duck'

const CheckboxGroup = Checkbox.Group

const Debts = () => {
  const dispatch = useDispatch()
  const { lot, waterInvoices, fetching, servicesPayable } = useSelector(
    ({ fomento }) => fomento,
  )
  const [form, setForm] = useState({
    services: [],
  })
  const [showModal, setShowModal] = useState(false)
  const plainOptions = ['Agua', 'Teléfono', 'Luz', 'Gas']
  const [indeterminate, setIndeterminate] = React.useState(true)
  const [checkAll, setCheckAll] = React.useState(false)

  const totalDebt = React.useMemo(() => waterInvoices.reduce((acc, record) => acc + record.amount + record.surcharge, 0), [waterInvoices])

  useEffect(() => {
    if (lot.id == null) return
    getWater()
  }, [lot])

  const columns = [
    {
      title: 'Año',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Bimestre',
      dataIndex: 'twomonths',
      key: 'twomonths',
    },
    {
      title: 'Importe',
      dataIndex: 'amount',
      key: 'amount',
      render: (val) => formatMoney(val),
    },
    {
      title: 'Recargos',
      dataIndex: 'surcharge',
      key: 'surcharge',
      render: (val) => formatMoney(val),
    },
    {
      title: 'Total',
      dataIndex: 'surcharge',
      key: 'surcharge',
      render: (_, record) => formatMoney(record.amount + record.surcharge),
    },
  ]
  //   const getWater = () => dispatch(getWaterInvoicesByLotAction(lot.id))
  const getWater = () => dispatch(getPendingWaterByLot(lot.id))

  const addServicePayable = (record) => {
    dispatch(addWaterToReceiptAction(record))
  }

  // if(lot.delinquency) return <Alert message="Este cliente no puede hacer abonos por adeudos pendientes." type="error" showIcon />

  return (
    <>
      {lot.id ? (
        <Card
          loading={fetching}
          // extra={<a href="#!" onClick={()=>setShowModal(true)}>Editar</a>} style={{ width: 300 }}
          extra={`Adeudo total: ${formatMoney(totalDebt)}`}
          title="Adeudos"
          loading={fetching}
          style={{ maxWidth: 600 }}
        >
          <Table
            dataSource={waterInvoices}
            columns={columns}
            onRow={(record) => ({
              onClick: () => addServicePayable(record),
            })}
          />
        </Card>
      ) : (
        <Empty description="Consulta un lote" />
      )}
    </>
  )
}

const styles = {
  tag: { borderRadius: 20, cursor: 'pointer' },
}

export default Debts
