import React from "react";
import { ConfigProvider } from 'antd';

import Routes from "./Routes";

import "./App.less";
import esES from 'antd/es/locale/es_ES';

function App() {
  return (
    <ConfigProvider locale={esES}>
      <Routes />
    </ConfigProvider>
  );
}

export default App;
