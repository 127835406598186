import { message } from 'antd'
import { Axios } from '../services/api'

const initialState = {
  items: [],
  message: {},
}

const GET_MESSAGES = 'GET_MESSAGES'
const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR'
const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'

const CREATE_MESSAGE = 'CREATE_MESSAGE'
const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR'
const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'

const DELETE_MESSAGE = 'DELETE_MESSAGE'
const DELETE_MESSAGE_ERROR = 'DELETE_MESSAGE_ERROR'
const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS'

const GET_ONE_MESSAGE = 'GET_ONE_MESSAGE'
const GET_ONE_MESSAGE_ERROR = 'GET_ONE_MESSAGE_ERROR'
const GET_ONE_MESSAGE_SUCCESS = 'GET_ONE_MESSAGE_SUCCESS'

const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR'
const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS'

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_MESSAGE:
      return { ...state, fetching: false, message: payload }
    case GET_ONE_MESSAGE_SUCCESS:
      return { ...state, fetching: false, message: payload }
    case DELETE_MESSAGE_SUCCESS:
      return { ...state, fetching: false }
    case CREATE_MESSAGE_SUCCESS:
      return { ...state, fetching: false, items: [payload, ...state.items] }
    case GET_MESSAGES_SUCCESS:
      return { ...state, fetching: false, ...payload }

    case UPDATE_MESSAGE:
    case GET_ONE_MESSAGE:
    case DELETE_MESSAGE:
    case CREATE_MESSAGE:
    case GET_MESSAGES:
      return { ...state, fetching: true }
    case UPDATE_MESSAGE_ERROR:
    case GET_ONE_MESSAGE_ERROR:
    case DELETE_MESSAGE_ERROR:
    case CREATE_MESSAGE_ERROR:
    case GET_MESSAGES_ERROR:
      return { ...state, fetching: false, error: payload }
    default:
      return { ...state }
  }
}

export const getMessagesByLotAction = (lotId, paginated) => (
  dispatch,
  getState,
) => {
  const { token } = getState().user
  dispatch({ type: GET_MESSAGES })
  return Axios.get(`/messages/byLot/${lotId}`, {
    headers: { Authorization: token },
    params: {
      p: paginated && paginated.p ? paginated.p : 1,
      l: paginated && paginated.l ? paginated.l : 50,
      q: paginated && paginated.q ? paginated.q : null,
    },
  })
    .then(({ data }) => dispatch({ type: GET_MESSAGES_SUCCESS, payload: data }))
    .catch((e) => {
      console.log(e)
      dispatch({ type: GET_MESSAGES_ERROR, payload: e })
      message.error(e.response?.data?.message)
    })
}

export const createMessageAction = (msg) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: CREATE_MESSAGE })
  console.log(msg)
  return Axios.post('/messages', msg, { headers: { Authorization: token } })
    .then((res) => {
      dispatch({ type: CREATE_MESSAGE_SUCCESS, payload: res.data })
      message.success('El mensaje se ha guardado')
      // dispatch(getLotsAction()(dispatch, getState))
      return true
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: CREATE_MESSAGE_ERROR, payload: e })
      message.error(`No se pudo guardar, intenta más tarde`)
      return false
    })
}

export const deleteMessageAction = (messageId) => (dispatch, getState) => {
  const { token } = getState().user
  const { lot } = getState().lots
  dispatch({ type: DELETE_MESSAGE })
  return Axios.delete(`/messages/${messageId}`, {
    headers: { Authorization: token },
  })
    .then((res) => {
      dispatch({ type: DELETE_MESSAGE_SUCCESS })
      getMessagesByLotAction(lot.id)(dispatch, getState)
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: DELETE_MESSAGE_ERROR, payload: e })
      message.error(e.response.data)
    })
}

export const getOneMessageAction = (id) => (dispatch, getState) => {
  const { token } = getState().user
  dispatch({ type: GET_ONE_MESSAGE })
  return Axios.get(`/messages/${id}`, {
    headers: { Authorization: token },
  })
    .then((res) => {
      dispatch({ type: GET_ONE_MESSAGE_SUCCESS, payload: res.data })
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: GET_ONE_MESSAGE_ERROR, payload: e })
      message.error(e.response?.data?.message)
    })
}

export const updateMessageAction = (msg) => (dispatch, getState) => {
  dispatch({ type: UPDATE_MESSAGE })
  const { token } = getState().user
  const { lot } = getState().lots
  return Axios.patch(`/messages/${msg.id}`, msg, {
    headers: { Authorization: token },
  })
    .then(() => {
      dispatch({ type: UPDATE_MESSAGE_SUCCESS })
      getMessagesByLotAction(lot.id)(dispatch, getState)
      message.success('Mensaje actualizado con éxito')
    })
    .catch((e) => {
      console.log(e)
      dispatch({ type: UPDATE_MESSAGE_ERROR, payload: e })
      message.error(e.response?.data?.message)
    })
}

// // getItems
// export const getLotsByDepositAction = (paginated) => (dispatch, getState) => {
//     const { token } = getState().user
//     dispatch({ type: GET_LOTS })
//     return Axios.post(`/lots/byDeposit`,
//     {
//         p: paginated && paginated.p ? paginated.p : 1,
//         l: paginated && paginated.l ? paginated.l : 50,
//         q: paginated && paginated.q ? paginated.q : null
//     },
//     {
//         headers: { Authorization: token },
//     })
//         .then(res => {
//             if (res.data.items) return dispatch({ type: GET_LOTS_SUCCESS_PAGINATED, payload: res.data })
//             dispatch({ type: GET_LOTS_SUCCESS, payload: res.data })
//         })
//         .catch(e => {
//             console.log(e)
//             dispatch({ type: GET_LOTS_ERROR, payload: e })
//             message.error(e.response?.data?.message)
//         })
// }

// export const getLotAction = (lot) => (dispatch, getState) => {
//     const { token } = getState().user
//     dispatch({ type: GET_SINGLE_LOT })
//     return Axios.get('/lots/' + lot.id, { headers: { Authorization: token } })
//         .then(({ data }) => {
//             dispatch({ type: GET_SINGLE_LOT_SUCCESS, payload: data })
//         })
//         .catch(e => {
//             console.log(e)
//             dispatch({ type: GET_SINGLE_LOT_ERROR, payload: e })
//             message.error(e.response ? e.response.data : "Algo malo sucedió")
//         })
// }

// // CreateItem
// export const createLotAction = (lot) => (dispatch, getState) => {
//     const { token } = getState().user
//     dispatch({ type: CREATE_LOT })
//     return Axios.post('/lots', lot, { headers: { Authorization: token } })
//         .then(res => {
//             dispatch({ type: CREATE_LOT_SUCCESS, payload: res.data })
//             message.success("El lote se ha creado con éxito")
//             // dispatch(getLotsAction()(dispatch, getState))
//             return true
//         })
//         .catch(e => {
//             console.log(e)
//             dispatch({ type: CREATE_LOT_ERROR, payload: e })
//             message.error(`${e.response?.data?.message} Medidor: ${e.response?.data?.number}`)
//             return false
//         })
// }

// // updateItem
// export const updateLotAction = (lot) => (dispatch, getState) => {
//     const { token } = getState().user
//     dispatch({ type: UPDATE_LOT })
//     return Axios.patch(`/lots/${lot.id}`, lot, { headers: { Authorization: token } })
//         .then(res => {
//             dispatch({ type: UPDATE_LOT_SUCCESS, payload: res.data })
//             getLotAction(lot)(dispatch, getState)
//             message.success("El lote se actualizó con éxito")
//             return true
//         })
//         .catch(e => {
//             console.log(e)
//             dispatch({ type: UPDATE_LOT_ERROR, payload: e })
//             message.error(`${e.response.data.message} Medidor: ${e.response.data.number}`)
//             return false
//         })
// }
// // deleteItem
