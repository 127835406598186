import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Typography, Input, Button, message } from 'antd';

import { Card, SideLayout } from '../Common';
import Rules from '../../helpers/rules';
import { changePasswordAction, changePasswordResetStatusAction } from '../../redux/user.duck';

import styles from './ProfileChangePassword.module.css';
import cardStyles from '../Common/Card/Card.module.css';

function ProfileChangePassword() {
	const { pathname } = useLocation();
  const type = pathname.split('/')[1];
  const { Paragraph } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
	const { loading, status } = useSelector(({ user }) => user);
	const history = useHistory();

  useEffect(() => {
    if (status?.type && status.message) {
      if (status.type === 'success') {
				message.success(status.message);
				history.goBack();
      } else {
        message.error(status.message);
			}
			dispatch(changePasswordResetStatusAction());
    }
  }, [status, history, dispatch]);

  const checkPasswords = async () => {
    if (
      form.getFieldValue('password') === form.getFieldValue('passwordConfirm')
    ) {
      await dispatch(changePasswordAction(form.getFieldValue('password')));
    } else {
      message.error('Las contraseñas no coinciden.');
    }
  };

  return (
    <SideLayout type={type}>
      <h2 className="title">Cambiar contraseñal</h2>
      <h3 className="title-description">
        Cambia la contraseña con la que ingresarás a Lomas de Cocoyoc.
      </h3>
      <Row>
        <Col lg={12} md={24} sm={24} xs={24}>
          <Card>
            <h4 className={cardStyles.card__title}>
              Actualización de contraseña
            </h4>
            <Paragraph className={cardStyles.card__subtitle}>
              Recuerda que tu contraseña debe contener al menos 8 caracteres,
              una mayúscula y un número.
            </Paragraph>
            <Form
              form={form}
              onFinish={checkPasswords}
              layout="vertical"
              requiredMark={false}
            >
              <Form.Item
                label="Contraseña"
                name="password"
                rules={[Rules.passwordPattern]}
              >
                <Input type="password" placeholder="**********" />
              </Form.Item>

              <Form.Item
                label="Confirmar Contraseña"
                name="passwordConfirm"
                rules={[Rules.passwordPattern]}
              >
                <Input type="password" placeholder="**********" />
              </Form.Item>

              <Form.Item className={styles.footer__form}>
                <Button
                  className={styles.btn__save}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </SideLayout>
  );
}

export default ProfileChangePassword;
