import { message } from 'antd';
import { Axios } from '../services/api';

const initialState = {
    residents: [],
    resident: {},
    options: [],
    fetching: false
}

const GET_ONE_RESIDENT = "GET_ONE_RESIDENT"
const GET_ONE_RESIDENT_SUCCESS = "GET_ONE_RESIDENT_SUCCESS"
const GET_ONE_RESIDENT_ERROR = "GET_ONE_RESIDENT_ERROR"

const SET_RESIDENT = "SET_RESIDENT"
const SET_OPTIONS = "SET_OPTIONS"

const GET_RESIDENTS = "GET_RESIDENTS"
const GET_RESIDENTS_SUCCESS = "GET_RESIDENTS_SUCCESS"
const GET_RESIDENTS_ERROR = "GET_RESIDENTS_ERROR"
const GET_RESIDENTS_SUCCESS_PAGINATED = "GET_RESIDENTS_SUCCESS_PAGINATED"

const CREATE_RESIDENT = "CREATE_RESIDENT"
const CREATE_RESIDENT_SUCCESS = "CREATE_RESIDENT_SUCCESS"
const CREATE_RESIDENT_ERROR = "CREATE_RESIDENT_ERROR"

const UPDATE_RESIDENT = "UPDATE_RESIDENT"
const UPDATE_RESIDENT_SUCCESS = "UPDATE_RESIDENT_SUCCESS"
const UPDATE_RESIDENT_ERROR = "UPDATE_RESIDENT_ERROR"

const DELETE_RESIDENT = "DELETE_RESIDENT"
const DELETE_RESIDENT_SUCCESS = "DELETE_RESIDENT_SUCCESS"
const DELETE_RESIDENT_ERROR = "DELETE_RESIDENT_ERROR"

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_RESIDENT:
            return { ...state, resident: payload }
        case SET_OPTIONS:
            return { ...state, options: payload }

        case GET_ONE_RESIDENT:
        case GET_RESIDENTS:
        case CREATE_RESIDENT:
        case UPDATE_RESIDENT:
        case DELETE_RESIDENT:
            return { ...state, fetching: true }

        case GET_ONE_RESIDENT_ERROR:
        case GET_RESIDENTS_ERROR:
        case CREATE_RESIDENT_ERROR:
        case UPDATE_RESIDENT_ERROR:
        case DELETE_RESIDENT_ERROR:
            return { ...state, fetching: false, error: payload }

        case GET_ONE_RESIDENT_SUCCESS:
            return { ...state, fetching: false, resident: payload }
        case GET_RESIDENTS_SUCCESS_PAGINATED:
            return { ...state, residents: payload.items, fetching: false, options: payload.items, ...payload }
        case GET_RESIDENTS_SUCCESS:
            return { ...state, residents: payload, fetching: false, options: payload, }
        case CREATE_RESIDENT_SUCCESS:
            return { ...state, residents: [...state.residents, payload], fetching: false }
        case UPDATE_RESIDENT_SUCCESS:
            return { ...state, residents: state.residents.map(resident => resident._id === payload._id ? payload : resident), fetching: false }
        case DELETE_RESIDENT_SUCCESS:
            return { ...state, residents: state.residents.filter(resident => resident._id !== payload._id), fetching: false }
        default:
            return { ...state }
    }
}

// set
export const setResidentAction = (id) => (dispatch, getState) => {
    const resident = getState().residents.residents.find(resi => resi.id === id)
    if (resident) dispatch({ type: SET_RESIDENT, payload: resident })
}

export const searchOptionsAction = (value) => (dispatch, getState) => {
    const residents = getState().residents.residents
    const { token } = getState().user
    const paginated = {}
    if (!isNaN(Number(value))) {
        paginated.q = `number=${value}`
    } else {
        paginated.q = `name=${value}`
    }
    // look over api
    return Axios.get('/residents', {
        headers: { Authorization: token },
        params: {
            p: paginated && paginated.p ? paginated.p : 1,
            l: paginated && paginated.l ? paginated.l : 50,
            q: paginated && paginated.q ? paginated.q : null
        }
    })
        .then(res => {
            if (res.data.items) return dispatch({ type: SET_OPTIONS, payload: res.data.items })
            dispatch({ type: SET_OPTIONS, payload: residents })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: GET_RESIDENTS_ERROR, payload: e })
            message.error(e.response?.data)
        })

    // const regEx = new RegExp(value, "i")
    // const options = residents.filter(res => (regEx.test(res.firstName) || regEx.test(res.residentNumber) || regEx.test(res.lastName)))
    // if (options.length) dispatch({ type: SET_OPTIONS, payload: options })
    // else

}

// getItems
export const getOneResidentAction = (id) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: GET_ONE_RESIDENT })
    return Axios.get('/residents/' + id, {
        headers: { Authorization: token }
    })
        .then(res => {
            // if (res.data.items) return dispatch({ type: GET_RESIDENTS_SUCCESS_PAGINATED, payload: res.data })
            dispatch({ type: GET_ONE_RESIDENT_SUCCESS, payload: res.data })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: GET_ONE_RESIDENT_ERROR, payload: e })
            message.error(e.response?.data)
        })
}

export const getresidentsAction = (paginated) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: GET_RESIDENTS })
    return Axios.get('/residents', {
        headers: { Authorization: token },
        params: {
            p: paginated && paginated.p ? paginated.p : 1,
            l: paginated && paginated.l ? paginated.l : 50,
            q: paginated && paginated.q ? paginated.q : null
        }
    })
        .then(res => {
            if (res.data.items) return dispatch({ type: GET_RESIDENTS_SUCCESS_PAGINATED, payload: res.data })
            dispatch({ type: GET_RESIDENTS_SUCCESS, payload: res.data })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: GET_RESIDENTS_ERROR, payload: e })
            message.error(e.response?.data)
        })
}

// CreateItem
export const createresidentAction = (resident) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: CREATE_RESIDENT })
    return Axios.post('/residents', resident, { headers: { Authorization: token } })
        .then(res => {
            dispatch({ type: CREATE_RESIDENT_SUCCESS, payload: res.data })
            return res;
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: CREATE_RESIDENT_ERROR, payload: e })
            message.error(e.response.data)
        })
}

// updateItem
export const updateresidentAction = (resident) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: UPDATE_RESIDENT })
    return Axios.patch(`/residents/${resident._id}`, resident, { headers: { Authorization: token } })
        .then(res => {
            dispatch({ type: UPDATE_RESIDENT_SUCCESS, payload: res.data })
            return res
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: UPDATE_RESIDENT_ERROR, payload: e })
            message.error(e.response.data)
        })
}
// deleteItem
export const deleteresidentAction = (resident) => (dispatch, getState) => {
    const { token } = getState().user
    dispatch({ type: DELETE_RESIDENT })
    return Axios.patch(`/residents/${resident._id}`, { headers: { Authorization: token } })
        .then(res => {
            dispatch({ type: DELETE_RESIDENT_SUCCESS, payload: res.data })
        })
        .catch(e => {
            console.log(e)
            dispatch({ type: DELETE_RESIDENT_ERROR, payload: e })
            message.error(e.response.data)
        })
}