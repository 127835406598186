import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Systems from './Systems'
import Promotion from './Promotion'
import { SideLayout } from '../Common'
import { getFomentInvoicesAction } from '../../redux/invoicesDuck'
import LomasServiceInvoices from './Lomas/LomasInvoices'

function Invoices() {
  const dispatch = useDispatch()
  const { TabPane } = Tabs
  const location = useLocation()
  const type = location.pathname.split('/')[1]

  useEffect(() => {
    dispatch(getFomentInvoicesAction())
  }, [])

  return (
    <SideLayout type={type} backButton={false}>
      <h2 className="title">Facturas</h2>
      <h3 className="title-description">
        Historial de facturas emitidas a los residentes de Lomas de Cocoyoc.
      </h3>
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Sistemas" key="1">
            <Systems />
          </TabPane>
          <TabPane tab="Fomento" key="2">
            <Promotion />
          </TabPane>
          <TabPane tab="Lomas" key="3">
            <LomasServiceInvoices />
          </TabPane>
        </Tabs>
      </div>
    </SideLayout>
  )
}

export default Invoices
