import React from 'react'
import { useSelector } from 'react-redux'
import { translateMoney } from '../../tools/moneyToWords'
import moment from 'moment'
import fondo from '../../assets/deposit-receipt.png'
import { formatMoney } from '../../helpers/formatMoney'
import sectionNames from '../Common/CashierForm/sectionNames'

const TagReceipt = ({ printData, currentRef }) => {
  const { displayName } = useSelector(({ user }) => user)
  const initialValues = {
    folio: 7777,
    paymentForm: [
      {
        tipo: 'EFEC',
      },
    ],
    totalPaid: 0,
    seccion: 25,
    concept: 'Tag vehicular',
    lotDetails: {
      residentName: 'Héctor Bliss Campos ',
      seccion: '117',
      manzana: '16-A',
      lote: '2',
      area: 235,
    },
    lot: { residents: [] },
  }
  //   let { print: PDF=initialValues} = useSelector(({ fomento }) => fomento);
  const PDF = React.useMemo(() => {
    if (!printData || !Object.keys(printData).length) {
      return initialValues
    }

    // console.log('data: ', printData)
    return {
      folio: printData.folio || printData._doc?.folio || '00',
      paymentForm: printData.paymentForm,
      totalPaid: printData.totalPaid,
      seccion: printData.lot?.seccion,
      concept:
        printData.tipo === 'tag'
          ? 'Tag vehicular'
          : printData.tipo === 'multa'
            ? 'Multa'
            : printData.tipo === 'agreement' ? 'Mensualidad' : 'Extra',
      lotDetails: {
        residentName:
          printData.lot && printData.lot.residents
            ? `${printData.lot?.residents[0]?.firstName} ${printData.lot?.residents[0]?.lastName}`
            : 'CLIENTE',
        seccion: printData.lot?.seccion,
        manzana: printData.lot?.manzana,
        lote: printData.lot?.lote,
        area: printData.lot?.area,
      },
    }
  }, [printData])

  const renderItem = (item = {}) => {
    return (
      <div
        style={{
          display: 'flex',
          gap: 50,
        }}
      >
        <span style={{ width: 150 }}>{item.cuota}</span>
        <span style={{ width: 400 }}>{item.descripcion}</span>
        <span style={{ width: 300 }}>{formatMoney(item.importe)}</span>
        <span style={{ width: 300 }}>{formatMoney(item.recargos)}</span>
        <span style={{ width: 300 }}>{formatMoney(item.total)}</span>
      </div>
    )
  }

  const dateReceipt = PDF.createdAt

  return (
    <div
      style={{
        // backgroundImage: `url('${fondo}')`,
        // backgroundSize:'cover',
        // backgroundSize: 'contain',
        width: 816,
      }}
      // className={styles.mainwrapper}
      ref={currentRef}
    >
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: 60,
        }}
      >
        <Divider
          style={{
            flexGrow: 9,
          }}
          title=""
        />
        <span
          style={{
            paddingTop: 45,
            paddingBottom: 0,
            flexGrow: 1,
            paddingLeft: 15,
          }}
        >
          {PDF.folio}
        </span>
      </section>
      <section
        style={{
          display: 'flex',
        }}
      >
        <span
          style={{
            padding: '26px 50px 10px 50px',
            width: 200,
            flexGrow: 8,
          }}
        >
          {PDF.lotDetails?.residentName}
        </span>
        <span
          style={{
            padding: '36px 0px 0px 145px',
            flexGrow: 1,
          }}
        >
          {moment(dateReceipt).format('DD')} <span style={{ marginLeft: 15 }} />
          {moment(dateReceipt).format('MM')}
          <span style={{ marginLeft: 15 }} />
          {moment(dateReceipt).format('YYYY')}
        </span>
      </section>
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Divider
          style={{
            paddingTop: 56,
          }}
        />
        <span
          style={{
            paddingLeft: 30,
            width: 120,
          }}
        >
          {sectionNames[PDF.seccion]}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.seccion}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.manzana}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.lote}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.area}
        </span>
        <span
          style={{
            width: 170,
            textAlign: 'center',
          }}
        >
          {PDF.paymentForm?.length < 2 ? PDF.paymentForm[0].tipo : 'Mixto'}
        </span>
        <span
          style={{
            width: 170,
            textAlign: 'center',
          }}
        >
          {formatMoney(PDF.totalPaid, true)}
        </span>
      </section>
      <section>
        <span
          style={{
            paddingLeft: 50,
          }}
        >
          {translateMoney(Math.floor(PDF.totalPaid))}
        </span>
        <span
          style={{
            paddingRight: 150,
            float: 'right',
          }}
        >
          {PDF.concept}
        </span>
      </section>
      <section>
        <span
          style={{
            paddingTop: 40,
            paddingRight: 100,
            float: 'right',
          }}
        >
          {displayName}
        </span>
      </section>
      <section>
        <Divider
          style={{
            paddingTop: 50,
          }}
        />
      </section>
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Divider
          style={{
            paddingTop: 80,
          }}
        />
      </section>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-around',
          paddingLeft: 35,
          height: 130,
        }}
      >
        {PDF.invoiceDocs?.map(renderItem)}
        {/* {[1].map(renderItem)} */}
      </section>
      <section>
        <Divider
          style={{
            paddingTop: 515,
          }}
        />
      </section>
    </div>
  )
}
// const color = 'rgba(230,230,150,.7)'
const color = 'transparent'

const Divider = ({ style, title }) => (
  <div style={{ backgroundColor: color, padding: '22px 10px', ...style }}>
    {title}
  </div>
)

export default TagReceipt
