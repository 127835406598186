import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { SideLayout, Card } from '../Common/index';
import { Rules } from '../../helpers';
import styles from './AddResident.module.css'
import cardStyles from '../Common/Card/Card.module.css';

import { RESIDENTS } from '../../constants/routes';

import { createresidentAction, getresidentsAction, updateresidentAction } from '../../redux/residentsDuck';
import { useForm } from 'antd/lib/form/Form';

function AddResident() {
    const [form] = useForm()
    const history = useHistory();
    const dispatch = useDispatch();
    const { residentId: isUpdate } = useParams();
    const { fetching, role, resident } = useSelector(({ residents, user }) => ({ 
        resident: residents.residents[0], 
        fetching: residents.fetching, 
        role: user.role 
    }));

    useEffect(()=>{
        if(isUpdate) dispatch(getresidentsAction({q:`_id=${isUpdate}`}))
    },[])

    useEffect(()=>{
        if(isUpdate) form.setFieldsValue({...resident})
    },[resident])

    const handleAdd = (values) => {
        dispatch(createresidentAction(values))
            .then((response) => response && history.goBack());
    }

    const handleUpdate = (values) => {
        dispatch(updateresidentAction({ ...resident, ...values }))
            // .then((response) => response && history.push(`/${role}${RESIDENTS}`));
            .then((response) => response && history.goBack());
    };

    return (
        <SideLayout >
            <h4 className="title">
                {!!isUpdate ? 'Editar residente' : 'Agregar residente'}
            </h4>
            <h3 className="title-description">
                {!!isUpdate
                    ? 'Actualiza la información del residente.'
                    : 'Completa la siguiente información para agregar un residente'
                }
            </h3>
            <Col sm={24} xs={24} md={12} lg={12}>
                <Card>
                    <Row justify="space-between"></Row>
                    <h5 className={cardStyles.card__title}>Información del residente</h5>
                    <Form
                        form={form}
                        justify="center" align="middle"
                        layout="vertical"
                        requiredMark={false}
                        onFinish={isUpdate ? handleUpdate : handleAdd}
                    >
                        <Row gutter={[15, 0]}>
                            <Col span={8}>
                                <Form.Item label="Id:" name="number" rules={[Rules.basic]}>
                                    <Input type="number" placeholder="2545" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[15, 0]}>
                            <Col span={8}>
                                <Form.Item label="Nombre:" name="firstName" rules={[Rules.basic]}>
                                    <Input placeholder="Juan" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Apellido Paterno:"
                                    name="lastName"
                                // rules={[Rules.basic]}
                                >
                                    <Input placeholder="López" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Apellido Materno:"
                                    name="surName"
                                // rules={[Rules.basic]}
                                >
                                    <Input placeholder="López" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label="Email:"
                            name="email"
                        // rules={[Rules.basic]}
                        >
                            <Input
                                type="email"
                                placeholder="ejemplo@lomasdecococyoc.com"
                            // rules={[Rules.basic]} 
                            />
                        </Form.Item>
                        <Form.Item label="Teléfono:" name="tel"
                        // rules={[Rules.basic]}
                        >
                            <Input placeholder="55 555 55 55" />
                        </Form.Item>
                        <Button
                            className={styles.btn__footer} type="primary"
                            htmlType="submit"
                            loading={fetching}
                        >
                            {!!isUpdate ? 'Guardar' : 'Agregar'}
                        </Button>
                    </Form>
                </Card>
            </Col>
        </SideLayout>
    );
}

export default AddResident;