import React, { useEffect } from 'react'
import { Card, Tag, Table, Empty } from 'antd'
import { formatMoney } from '../../../helpers/formatMoney';
import moment from 'moment'

const RenderLotTable = ({
    loading,
    onInsuficient = () => false,
    allSelected,
    services = {},
    onChange = () => false,
    onSelectAll = () => false,

    dataSource

}) => {

    const [keys, setKeys] = React.useState([])
    const [selected, setSelected] = React.useState([])
    // const dataSource = React.useMemo(() => services[lot._id] ? services[lot._id].map(item => ({ ...item, key: item._id })) : [], [services])

    // const filteredServices = React.useMemo(() => {
    //     return services[lot._id] ? services[lot._id].filter(item => keys.includes(item._id)) : []
    // }, [lot, keys])

    const onSelectChange = (values, records) => {
        setKeys(values)
        setSelected(records)
        onChange(lot._id, records)
    }



    const { lot } = React.useMemo(() => dataSource[0], [dataSource])

    const globalEnough = React.useMemo(() => {
        return lot.depositAmount > dataSource.reduce((acc, item) => acc + item.amount + item.surcharge, 0)
    }, [dataSource])

    // const isEnough = React.useMemo(() => {
    //     const value = lot.depositAmount > dataSource.reduce((acc, item) => acc + item.amount + item.surcharge, 0)
    //     onInsuficient(value)
    //     if (!value) {
    //         setKeys([])
    //         setSelected([])
    //         onChange({ lot: lot._id, keys: [] }, [])
    //     }
    //     return value
    // }, [keys])

    const total = React.useMemo(() => dataSource.reduce((acc, item) => acc + item.amount + item.surcharge, 0), [dataSource])

    useEffect(() => {
        if (allSelected && dataSource.length && globalEnough) {
            const ks = dataSource.map(item => item._id)
            setKeys(ks)
            onSelectAll(dataSource)
        } else {
            setKeys([])
            // onChange({ lot: lot._id, keys: [] }, 'remove')
            // onSelectAll(lot._id, 'remove')
        }
    }, [allSelected, dataSource])

    const title = React.useMemo(() => `Seccion ${lot.seccion}, Manzana: ${lot.manzana}, Lote: ${lot.lote}` + ` | ${lot.residents[0].firstName} ${lot.residents[0].lastName} ${lot.residents[0].surName}`, [dataSource])

    const Extra = () => <><Tag>{`Deposito: ${formatMoney(lot.depositAmount)}`}</Tag> <Tag color={globalEnough ? 'green' : 'red'} >{`Adeudo total: ${formatMoney(total)}`}</Tag> {!globalEnough ? <Tag color={'red'}>Saldo insuficiente</Tag> : null}</>

    return <Card
        extra={<Extra />}
        style={{ minWidth: 600 }}
        title={title}>
        {dataSource.length ?

            <Table
                rowKey="_id"
                loading={loading}
                dataSource={dataSource}
                rowSelection={{
                    selectedRowKeys: keys,
                    onChange: onSelectChange,
                    getCheckboxProps: record => ({
                        disabled: !globalEnough || allSelected
                    })
                }}

                columns={[{
                    title: 'Folio',
                    key: '_id',
                    dataIndex: '_id',
                    render: id => id.slice(id.length - 5)
                },
                {
                    title: 'Medidor',
                    key: 'measurerNumber',
                    dataIndex: 'measurerNumber',
                },
                {
                    title: 'Año',
                    key: 'year',
                    dataIndex: 'year',
                },
                {
                    title: 'Fecha',
                    key: 'createdAt',
                    dataIndex: 'createdAt',
                    render: val => moment(val).format('ll')
                },
                {
                    title: 'Total',
                    key: 'amount',
                    dataIndex: 'amount',
                    render: (val, record) => formatMoney(val + record.surcharge)
                }
                ]}

            /> : <Empty />}



    </Card>
}

export default RenderLotTable