import React from "react";
import { Row, Col, Table } from "antd";

import {
  CashierForm,
  CashierDebts,
  CashierPrint,
  CashierTicket,
	SideLayout,
	Card,
} from "../Common";
import { CASHIER_TYPE } from '../../constants/routes';

import cardStyles from '../Common/Card/Card.module.css';

export const columnsSystems = [
	{
		title: "Factura",
		dataIndex: "col0",
		key: "col0",
		fixed: "left",
	},
	{
		title: "Año",
		dataIndex: "col1",
		key: "col1",
	},
	{
		title: "Bim",
		dataIndex: "col2",
		key: "col2",
	},
	{
		title: "M3",
		dataIndex: "col3",
		key: "col3",
	},
	{
		title: "Importe",
		dataIndex: "col4",
		key: "col4",
	},
	{
		title: "Recargos",
		dataIndex: "col5",
		key: "col5",
	},
	{
		title: "Abono",
		dataIndex: "col6",
		key: "col6",
	},
	{
		title: "Total",
		dataIndex: "col7",
		key: "col7",
	},
];

const data = [
	{
		col0: '1234567',
		col1: '2020',
		col2: 3,
		col3: 123,
		col4: '$120,000.00',
		col5: '$12,000.00',
		col6: '$00.00',
		col7: '$12,000.00'
	}
]

function CashierSystems() {
  return (
    <SideLayout type={CASHIER_TYPE}>
      <h2 className="title">Sistemas</h2>
      <h3 className="title-description">Cobra el sevicio de agua.</h3>
      <Row gutter={[24, 0]}>
        <Col span={14}>
          <CashierForm type="systems" />
          <Card>
				<h4 className="subtitle-strong-18">Adeudos</h4>
				<Table
					className={cardStyles.card__table__responsive}
					dataSource={data}
					columns={columnsSystems}
					scroll={{ x: 700, y: 300 }}
					size="small"
					pagination={false}
				/>
			</Card>
        </Col>
        <Col span={10}>
          <CashierTicket type="systems" columns={columnsSystems} />
          <CashierPrint />
        </Col>
      </Row>
    </SideLayout>
  );
}

export default CashierSystems;
