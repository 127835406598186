import React from 'react'
import { useSelector } from 'react-redux'
import { translateMoney } from '../../../tools/moneyToWords'
import moment from 'moment'
import fondo from '../../../assets/agua.png'
import { formatMoney } from '../../../helpers/formatMoney'
import sectionNames from '../../Common/CashierForm/sectionNames'

const AguaReceipt = ({ printData, currentRef }) => {
  const { displayName } = useSelector(({ user }) => user)
  const initialValues = {
    folio: 7777,
    paymentForm: [
      {
        tipo: 'EFEC',
      },
    ],
    totalPaid: 0,
    seccion: 25,
    concept: 'Depósito',
    lotDetails: {
      residentName: 'Héctor Bliss Campos ',
      seccion: '117',
      manzana: '16-A',
      lote: '2',
      area: 235,
    },
    invoiceDocs: [
      {
        year: 2020,
        twomonths: 5,
        previousMeasurement: 123,
        currentMeasurement: 234,
        createdAt: '12/12/2021',
        amount: 234,
        surcharge: 50,
      },
    ],
    lot: { residents: [] },
  }
  //   let { print: PDF=initialValues} = useSelector(({ fomento }) => fomento);
  const PDF = React.useMemo(() => {
    console.log('el print?', printData)

    if (!printData || !Object.keys(printData).length) {
      return initialValues
    }
    //         authorizedBy: "605186561dbcf60017d96abb"
    // cancelled: false
    // createdAt: "2021-03-28T22:18:46.959Z"
    // deposit: "606100a8e9af5e0017b3f1b3"
    // folio: "1-DEP"
    // id: "606100c6e9af5e0017b3f1b4"
    // invoices: []
    // lot: "601323293a14f57399dfa9e2"
    // notes: ""
    // paymentForm: [{…}]
    // quantityType: "total"
    // reference: ""
    // subsidiary: "Lomas"
    // tipo: "deposit"
    // totalPaid: 22222
    // updatedAt: "2021-03-28T22:18:46.993Z"
    // __v: 0
    // _id: "606100c6e9af5e0017b3f1b4"
    return {
      folio: printData.folio,
      paymentForm: printData.paymentForm,
      totalPaid: printData.totalPaid,
      seccion: printData.lot?.seccion,
      concept: 'Depósito',
      lotDetails: {
        residentName:
          printData.lot && printData.lot.residents
            ? `${printData.lot?.residents[0]?.firstName} ${printData.lot?.residents[0]?.lastName}`
            : 'CLIENTE',
        seccion: printData.lot?.seccion,
        manzana: printData.lot?.manzana,
        lote: printData.lot?.lote,
        area: printData.lot?.area,
      },
      invoiceDocs: printData.waterInvoices,
    }
  }, [printData])

  const renderItem = (item = {}) => {
    return (
      <div
        style={{
          display: 'flex',
          gap: 60,
          paddingLeft: '-50px',
        }}
      >
        <span style={{ width: 100, paddingLeft: 0 }}>{item.year}</span>
        <span style={{ width: 300, paddingLeft: 60 }}>{item.twomonths}</span>
        <span style={{ width: 300 }}>{item.previousMeasurement}</span>
        <span style={{ width: 200 }}>{item.currentMeasurement}</span>
        <span style={{ width: 200 }}>{''}</span>
        <span style={{ width: 450, paddingRight: 35 }}>
          {moment(item.createdAt).format('L')}
        </span>
        <span style={{ width: 400, paddingRight: 0 }}>
          {item.currentMeasurement - item.previousMeasurement}
        </span>
        <span style={{ width: 300, paddingRight: 0 }}>
          {formatMoney(item.amount + item.surcharge)}
        </span>
      </div>
    )
  }

  const dateReceipt = PDF.createdAt

  return (
    <div
      style={{
        // backgroundImage: `url('${fondo}')`,
        // backgroundSize:'cover',
        // backgroundSize: 'contain',
        width: 816,
      }}
      // className={styles.mainwrapper}
      ref={currentRef}
    >
      <section
        style={{
          margin: 0,
          paddingLeft: '-10px',
          display: 'flex',
          alignItems: 'center',
          // paddingTop: '-60px',
        }}
      >
        <Divider
          style={{
            flexGrow: 9,
          }}
          title=""
        />
        <span
          style={{
            paddingTop: 50,
            paddingBottom: 0,
            flexGrow: 1,
            paddingLeft: 0,
          }}
        >
          {PDF.folio}
        </span>
      </section>
      <section
        style={{
          display: 'flex',
        }}
      >
        <span
          style={{
            padding: '40px 50px 10px 50px',
            width: 200,
            flexGrow: 8,
          }}
        >
          {PDF.lotDetails?.residentName}
        </span>
        <span
          style={{
            padding: '42px 0px 15px 350px',
            flexGrow: 1,
          }}
        >
          {moment(dateReceipt).format('DD')} <span style={{ marginLeft: 15 }} />
          {moment(dateReceipt).format('MM')}
          <span style={{ marginLeft: 15 }} />
          {moment(dateReceipt).format('YYYY')}
        </span>
      </section>
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Divider
          style={{
            paddingTop: 46,
          }}
        />
        <span
          style={{
            paddingLeft: 0,
            width: 120,
          }}
        >
          {sectionNames[PDF.seccion]}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.seccion}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.manzana}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.lote}
        </span>
        <span
          style={{
            width: 80,
            textAlign: 'center',
          }}
        >
          {PDF.lotDetails?.area}
        </span>
        <span
          style={{
            width: 170,
            textAlign: 'center',
          }}
        >
          {PDF.paymentForm?.length < 2 ? PDF.paymentForm[0].tipo : 'Mixto'}
        </span>
        <span
          style={{
            width: 170,
            textAlign: 'center',
          }}
        >
          {formatMoney(PDF.totalPaid, true)}
        </span>
      </section>
      <section style={{ marginTop: 20 }}>
        {/* <span
          style={{
            width: 170,
            textAlign: 'center',
            border:'1px solid',
            marginLeft:45
          }}
        >
          {'Año'}
        </span> */}
      </section>
      {/* <section>
        <span
          style={{
            paddingTop: 40,
            paddingRight: 100,
            float: 'right',
          }}
        >
          {displayName}
        </span>
      </section> */}
      <section>
        <Divider
          style={{
            paddingTop: 50,
          }}
        />
      </section>
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      ></section>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-around',
          paddingLeft: 20,
          height: 130,
        }}
      >
        {PDF.invoiceDocs?.map(renderItem)}
        {/* {[1].map(renderItem)} */}
        <Divider
          style={{
            paddingTop: 80,
          }}
        />
        <span
          style={{
            paddingLeft: 40,
            paddingTop: 510,
          }}
        >
          {translateMoney(Math.floor(PDF.totalPaid))}
        </span>
      </section>
      <section>
        <Divider
          style={{
            paddingTop: 515,
          }}
        />
      </section>
    </div>
  )
}
// const color = 'rgba(230,230,150,.7)'
const color = 'transparent'

const Divider = ({ style, title }) => (
  <div style={{ backgroundColor: color, padding: '22px 10px', ...style }}>
    {title}
  </div>
)

export default AguaReceipt
