import React from 'react';

import { Button } from 'antd';

function TagSwitch({ children, color, tagIsActive, ...rest }, ref) {
  return (
    <Button
      type={tagIsActive ? 'primary' : 'default'}
      style={{
        marginBottom: 16,marginRight: 16, fontSize: 12,
        height: 24, lineHeight: 1, color: tagIsActive ? null : '#828282'
      }}
      {...rest}
    >
        { children }
    </Button>
  )
}

export default TagSwitch;
