import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Select, Table, Checkbox, Button } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es-mx'

import { Ticket, Divider } from '../index';
import { setPdfData } from '../../../redux/reports.duck';
import { createReceiptAction, removeDebtFromReceiptAction } from '../../../redux/fomento.duck';

import cardStyles from '../Card/Card.module.css';
import ticketStyles from '../Ticket/Ticket.module.css';
import styles from './CashierTicket.module.css';
import { CashierPrint } from '../../Common'

import { Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

function CashierTicket({ receipt, type, columns, tagStatus }) {
  // redux
  const dispatch = useDispatch();
  const { handleClickPDF } = useSelector(({ reports }) => reports);
  const { servicesPayable } = useSelector(({ fomento }) => fomento);
  const { id: userId, displayName } = useSelector(({ user }) => user);
  const { lot } = useSelector(({ fomento }) => fomento);
  // redux
  const paymentMethod = [
    { code: 'EFEC', tag: 'Efectivo' },
    { code: 'TRANS', tag: 'Transferencia bancaria' },
    { code: 'CHEQ', tag: 'Cheque' },
    { code: 'TDDC', tag: 'Tarjeta de crédito' },
  ];
  const [form] = Form.useForm();
  const { Option } = Select;
  // const [partialPayment, setPartialPayment] = useState(false);
  const [selectOptions] = useState(paymentMethod);
  const [totals, setTotals] = useState({ saldo: 0, descuento: 0, total: 0 });
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [date] = useState(moment().format('LL'));
  const [surface] = useState(100);
  const [cashierTicketState] = useState({
    receipt,
    date,
    amount: 0,
    paymentMethod: paymentMethod[0].tag,
    surface,
  });
  const [print, setPrint] = useState(false)
  // const [multiplePayments, setMultiplePayment] = useState(false)

  const collectData = () => {
    dispatch(
      setPdfData({
        receipt: cashierTicketState.receipt,
        date: cashierTicketState.date,
        amount: totals.total,
        paymentMethod: cashierTicketState.paymentMethod,
        surface: cashierTicketState.surface,
        serviceData: servicesPayable,
      }),
    );
  };

  useEffect(() => {
    collectData();
  }, [handleClickPDF]);

  useEffect(() => {
    console.warn(cashierTicketState);
  }, [cashierTicketState]);

  useEffect(() => {
    const total = servicesPayable.reduce(
      (total, item) => total + item.total,
      0,
    );
    const descuento = servicesPayable.reduce((acc, item) => acc + item.discount, 0)
    console.log("la lista: ", servicesPayable)
    console.log("descuento? ", descuento)
    const discount = applyDiscount ? descuento : 0;
    setTotals({ saldo: total, descuento: discount, total: total - discount });
    calculateAmounts(total - discount)
  }, [servicesPayable, applyDiscount]);

  const calculateAmounts = (total, amount) => {

    const current = form.getFieldValue('paymentForm')
    // validations
    if (amount > total || amount < 1 || amount?.toFixed(0) == total?.toFixed(0)) {
      current[0].amount = Math.floor(total)
      return form.setFieldsValue({ paymentForm: [current[0]] })
    }

    if (!amount) {
      if (current.length > 1) {
        const rest = total - current[1].amount
        current[0].amount = Math.floor(rest)
      } else current[0].amount = Math.floor(total)
    } else {
      current[0].amount = Math.floor(total - amount)
      current[1].amount = Math.floor(amount)
    }
    form.setFieldsValue({ paymentForm: [...current,] })

  }

  const fomentTableColumns = [
    {
      title: 'Cuota',
      dataIndex: 'cuota',
      key: 'cuota',
      // render: (col1) => `$${col1}MXN`,
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      // render: (col1) => `$${col1}MXN`,
    },
    {
      title: 'Recargos',
      dataIndex: 'recargos',
      key: 'recargos',
      render: value => `$${Math.floor(Number(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}MXN`
    },
    {
      title: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      render: value => `$${Math.floor(Number(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}MXN`
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: value => `$${Math.floor(Number(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}MXN`
    },
  ];

  // const handlePayment = () => {
  //   setPartialPayment(!partialPayment);
  // };

  // const handleSelectCode = (value) => {
  //   let { tag } = selectOptions.find((option) => option.code === value);
  //   setCashierTicketState({ ...cashierTicketState, paymentMethod: tag });
  //   form.setFieldsValue({ tag });
  // };

  const renderSelectOptions = (option) => {
    return (
      <Option key={option.code} value={option.code}>
        {option.code}
      </Option>
    );
  };

  const TicketFooterItem = ({ title, qty }) => (
    <span style={{ display: "flex", justifyContent: "space-between" }}>
      <span>{`${title}: `}</span>
      <span className={ticketStyles.ticket__money}>{`$${qty === 0 ? '00.00' : qty
        }`}</span>
    </span >
  );

  const createReceipt = (values) => {
    // collect fields from values
    const receipt = {
      ...values,
      discount: applyDiscount,
      quantity: "total",
      quantityType: "total"
    }
    // clean up paymentForm
    receipt.paymentForm = values.paymentForm.map(pf => ({ amount: pf.amount, tipo: pf.tipo }))
    // add fields from user
    receipt.authorizedBy = userId
    // add fields from invoices
    receipt.invoices = servicesPayable.map(inv => inv.id)
    receipt.invoiceDocs = servicesPayable
    receipt.lot = lot.id
    receipt.lotDetails = lot
    // calculate totals
    receipt.discountQuantity = totals.descuento
    receipt.balance = totals.saldo
    receipt.totalPaid = totals.total
    // save
    console.log("final: ", receipt)
    dispatch(createReceiptAction(receipt))
      .then(result => {
        if (result) {
          form.resetFields()
          setApplyDiscount(false)
          setPrint(true)
        }
      })
    // show modal on duck
  }

  return (
    <>
      <Ticket>
        <Row justify="space-between">
          <Col>
            <h4 className={cardStyles.card__title}>
              {type === 'systems' || type === 'detail' || type === 'promotion'
                ? 'Resumen del recibo'
                : 'Resumen del la factura'}
            </h4>
          </Col>
          {type === 'detail' && (
            <Col>
              <span className={styles.cancel__text}>CANCELADO</span>
            </Col>
          )}
        </Row>
        {/* <Row gutter={[15, 15]} className="text-coal">
        <Col>Número de recibo: </Col>
        <Col>
          <span className={styles.receipt__number}>#{uuidv4().slice(0, 6).toUpperCase()}</span>
        </Col>
      </Row> */}
        <p className="text-coal">
          Fecha: <span className="text-dark">{date}</span>
        </p>
        <Form
          // validateTrigger={['onChange', 'onBlur', 'onSubmit', 'onFinish']}
          form={form}
          onFinish={createReceipt}
          layout="vertical"
          initialValues={{
            paymentForm: [{ tipo: "EFEC" }],
            tag: selectOptions[0]?.tag,
          }}
          requiredMark={false}
        >
          {/* <Row gutter={[15, 0]}>
          <Col span={8}>

            <Form.Item
              label="Monto:"
              name="quantityType"
            // rules={[Rules.basic]}
            >
              <Select
                defaultValue="total"
                onChange={handlePayment}
                disabled={type === 'detail'}
              >
                <Option value="total">Total</Option>
                <Option value="partial">Parcial</Option>
              </Select>
            </Form.Item>

          </Col>
          <Col span={tagStatus ? 16 : 8}>

            <Form.Item
              label="Cantidad:"
              name="quantity"
              required
              rules={partialPayment ? [Rules.basic] : null}

            >
              <Input
                disabled={!partialPayment}
                placeholder="$00.00"
                type="number"
                min={1}
              // onChange={(e) =>
              //   setCashierTicketState({
              //     ...cashierTicketState,
              //     amount: Number(e.target.value),
              //   })
              // }
              />
            </Form.Item>

          </Col>
        </Row> */}

          <Row gutter={[15, 0]}>
            {/* <Col md={9} lg={8}>



              <Form.Item
                label="Forma de pago:"
                name="paymentForm"
                rules={[Rules.basic]}
              >
                <Select onChange={handleSelectCode} disabled={type === 'detail'}>
                  {selectOptions.map(renderSelectOptions)}
                </Select>
              </Form.Item>
            </Col>
            <Col md={tagStatus ? 16 : 9} lg={tagStatus ? 16 : 8}>
              <Form.Item label={' '} name="tag" rules={[Rules.basic]}>
                <Input disabled />
              </Form.Item>
            </Col> */}
            <Col md={24} lg={8}>
              <Form.Item name="discount" label="Descuento:">
                <Checkbox checked={applyDiscount} onChange={(e) => setApplyDiscount(e.target.checked)}>
                  INAPAM
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.List name="paymentForm">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      {...field}
                      label="Forma de pago"
                      name={[field.name, 'tipo']}
                      fieldKey={[field.fieldKey, 'tipo']}
                      rules={[{ required: true, message: 'Selecciona una forma de pago' }]}
                    >
                      <Select
                        onChange={val => {
                          if (fields.length < 2) return
                          let previus = form.getFieldValue('paymentForm').filter(f => f.tipo === val)
                          // previus = {tipo:previus.tipo, amount:previus.amount}
                          if (previus.length === 2) {
                            form.setFieldsValue({ paymentForm: [previus[0]] })
                          }

                        }}
                        label="Forma de pago" style={{ width: 130 }}>
                        {(selectOptions || []).map(renderSelectOptions)}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...field}
                      label="Cantidad"
                      name={[field.name, 'amount']}
                      fieldKey={[field.fieldKey, 'amount']}
                      rules={[{ required: true, message: 'Ingresa la cantidad' }]}
                      disabled={index === 0}
                    >
                      <Input
                        onChange={({ target: { value } }) => {
                          if (!servicesPayable.length) return
                          calculateAmounts(totals.total, Number(value))
                        }}
                        placeholder={fields.length < 2 ? "total" : "cantidad"}
                        disabled={index === 0} />
                    </Form.Item>

                    {fields.length > 1 && <MinusCircleOutlined onClick={() => {
                      remove(field.name)
                      const current = form.getFieldValue('paymentForm')
                      current[0].amount = servicesPayable.reduce((acc, s) => acc + s.total, 0).toFixed(0)
                      form.setFieldsValue({ paymentForm: [...current,] })
                    }} />}
                  </Space>
                ))}

                <Form.Item>
                  {(fields.length < 2 && !!servicesPayable.length) && <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
                    Agregar forma de pago
                  </Button>}
                </Form.Item>
              </>
            )}
          </Form.List>

          <Row gutter={[15, 0]}>
            <Col span={12}>
              <Form.Item label="Referencia:" name="reference">
                <Input
                  placeholder="Ingresa una referencia"
                  disabled={type === 'detail'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Autorizado por:" name="authorization">
                <Input placeholder={displayName} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Notas:" name="notes">
                <Input
                  placeholder="Ingresa un comentario"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <h4 className={cardStyles.card__title}>Servicio</h4>
          <p className="text-coal">
            Superficie: <span className="text-dark">{lot.area} m2</span>
            {"  "}
            Cluster: <span className="text-dark">{lot.cluster} m2</span>
          </p>
          <Table
            columns={type === 'promotion' ? fomentTableColumns : columns}
            scroll={{ y: 300 }}
            dataSource={type === 'promotion' ? servicesPayable : []}
            pagination={false}
            size="small"
            onRow={(record) => ({
              onClick: () => dispatch(removeDebtFromReceiptAction(record.id)),
            })}
            className={cardStyles.card__table__responsive}
          />
          <div className={ticketStyles.ticket__footer}>
            {!tagStatus && (
              <>
                <TicketFooterItem title="Saldo" qty={`${Math.floor(Number(totals.saldo)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MXN`} />
                <TicketFooterItem title=" Desc" qty={`${Math.floor(Number(totals.descuento)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MXN`} />
              </>
            )}
            <TicketFooterItem title="Total" qty={`${Math.floor(Number(totals.total)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MXN`} />
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Button
              type="primary"
              disabled={totals.saldo < 1} // add basic form data is present

              htmlType="submit"
            >
              Pagar
            </Button>
          </div>
        </Form>
      </Ticket>
      {print && <CashierPrint onFinish={() => setPrint(false)} />}
      {/* <CashierPrint onFinish={() => setPrint(false)} /> */}
    </>
  );
}

export default CashierTicket;

CashierTicket.defaultProps = {
  columns: [],
  receipt: 'AGU-123',
};

CashierTicket.propTypes = {
  type: PropTypes.oneOf(['promotion', 'services', 'systems', 'detail']),
  columns: PropTypes.array,
  receipt: PropTypes.string,
};
