import React, { useEffect } from 'react';
import {
    Row, Col,
    Card, Tag, Button
} from 'antd';
import {
    SideLayout,
} from '../Common/index';
import { formatMoney } from '../../helpers/formatMoney';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { cancelReceiptAction, getReceiptAction } from '../../redux/invoicesDuck';
import { Link } from 'react-router-dom'
import AguaPrintingModal from '../Common/CashierPrint/PrintingModal';
import PrintingModal from '../Common/CashierPrint/PrintingModal';
// import PrintingModal from '../CashierPromotion/PrintingModal';
// import PrintingModal from '../Systems/PrintingModal';

function ReceiptDetail({ match: { params: { receipt: receiptId } } }) {
    const dispatch = useDispatch()
    const { receipt, fetching } = useSelector(({ invoices }) => invoices)
    const { lot } = useSelector(({ lots }) => lots);
    const [printing, setPrinting] = React.useState(false);
    const { role } = useSelector(({ user }) => user)

    useEffect(() => {
        dispatch(getReceiptAction(receiptId))
    }, [])

    const renderInvoice = (inv, i) =>
        receipt.subsidiary === 'Fomento' ? (
            <><Link
                style={{ color: 'var(--blueAnt)' }}
                key={i} to={`/admin/facturas/detalle/${inv.id}`}>{inv.folio}</Link><br /></>
        ) : (
            <><Link
                style={{ color: 'var(--blueAnt)' }}
                key={i} to={`/admin/facturas/detalle/${inv.id}/agua`}>
                {inv._id?.slice(inv._id.length - 5)}
            </Link><br /></>
        )

    const handlePrint = () => {
        dispatch({ type: "SET_FOR_PRINTING", payload: { ...receipt } })
        setPrinting(true)
    }

    const cancelReceipt = () => {
        if (!window.confirm('¿Estas seguro de cancelar este recibo?, esta acción no es reversible.')) { return }
        dispatch(cancelReceiptAction(receipt._id))
    }

    return (
        <SideLayout >
            <h2 className="title">
                {`Recibo ${receipt.folio || ""}`}
            </h2>
            <Row gutter={[24, 0]} style={{ position: "relative" }}>
                <Col span={12}>
                    <Card
                        loading={fetching}
                        extra={receipt.cancelled ? <Tag color="red">Cancelado</Tag> : null}
                        title={"Resumen del recibo"}
                        loading={fetching}
                        style={{ minWidth: 400 }}
                    >
                        <section
                            style={{ display: "flex", flexDirection: "column", gap: 20 }}
                        >
                            <span>
                                Fecha: {"   "} <BoldText>{moment(receipt.createdAt).format('DD/MMM/YYYY')}</BoldText>
                            </span>
                            <span>
                                Subsidiaria: {"   "} <BoldText>{receipt.subsidiary}</BoldText>
                            </span>
                            {!receipt.deposit && <span>
                                Descuento: {"   "} <BoldText>{formatMoney(receipt.discountQuantity)}</BoldText>
                            </span>}
                            <span>
                                Monto pagado: {"   "} <BoldText>{formatMoney(receipt.totalPaid, true)}</BoldText>
                            </span>
                            <span>
                                Forma de pago: {"   "}<BoldText>{receipt.paymentForm?.reduce((acc, pf) => acc + `${pf.tipo}, `, '')}</BoldText>
                            </span>
                            <span>
                                {/* Cambiar esto por el displayName del user */}
                                Authorizado por: {"   "} <BoldText>{receipt.authorizedBy?.email}</BoldText>
                            </span>
                            {!receipt.deposit && <article
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10
                                }}
                            >
                                <span>
                                    Facturas pagadas en este recibo:
                                </span>
                                <div>
                                    {receipt.invoices?.map(renderInvoice)}
                                    {receipt.waterInvoices?.map(renderInvoice)}
                                </div>
                            </article>}
                            {receipt.deposit && <span>
                                Referencia: {"   "} <BoldText>{receipt.reference}</BoldText>
                            </span>}
                            {receipt.deposit && <span>
                                Notas: {"   "} <BoldText>{receipt.notes}</BoldText>
                            </span>}
                        </section>
                        {!receipt.cancelled && <section
                            style={{ display: "flex", gap: 20, marginTop: 40, justifyContent: 'flex-end' }}
                        >
                            <Button
                                onClick={handlePrint}
                                type="primary"
                            >
                                Imprimir
                            </Button>
                            <Button
                                onClick={cancelReceipt}
                                type="danger"
                                disabled={role === "CAJERO"}
                            >
                                Cancelar Recibo
                            </Button>
                        </section>}

                    </Card>
                </Col>
                {receipt.subsidiary === 'Sistemas' ? <AguaPrintingModal
                    agua
                    onFinish={() => true}
                    show={printing}
                    onCancel={() => setPrinting(false)} /> : receipt.subsidiary === 'Fomento' ? <PrintingModal
                        mantenimiento={receipt}
                        onFinish={() => true}
                        show={printing}
                        onCancel={() => setPrinting(false)}
                    /> : null}

            </Row>
        </SideLayout>
    );
}

const BoldText = ({ children }) => (<span style={{ fontWeight: "500" }}>{children}</span>)

export default ReceiptDetail;
