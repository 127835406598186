import React, { useState, useEffect } from 'react';
import { Table, Button, Pagination } from 'antd';
import moment from 'moment';

import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Seeker, Card, SideLayout } from '../Common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { RESIDENTS_DETAIL, RESIDENTS_EDIT, RESIDENTS_ADD } from '../../constants/routes';
import { getresidentsAction } from '../../redux/residentsDuck';

import styles from './Residents.module.css';

const data = [
  {
    key: '1',
    id: '109090',
    residente: 'Enrique Días Cruz',
    telefono: '55 775 769 9812',
    registro: '12/12/2020',
    lotes: '5',
    deposito: '12/dic/1990',
    acciones: '109090',
  },
];

function Residents() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const type = pathname.split('/')[1];
  const history = useHistory();
  const { role, residents } = useSelector(({ user, residents }) => ({ role: user.role.toLowerCase(), residents: residents.residents }))
  const { total, page, limit } = useSelector(({ residents }) => residents)
  const [handleLink, setHandleLink] = useState(false);

  useEffect(() => {
    dispatch(getresidentsAction());
  }, []);

  const handleRowClick = (id) => {
    history.push(`/${role}${RESIDENTS_DETAIL}`.replace(':residentId', id));
  };

  const columns = [
    { title: 'Id', dataIndex: 'number', key: 'number' },
    {
      title: 'Residente',
      dataIndex: 'residente',
      key: 'residente',
      render: (t, r) => `${r.firstName || ''} ${r.lastName || ''} ${r.surName || ''}`
    },
    { title: 'Teléfono', dataIndex: 'tel', key: 'telefono' },
    { title: 'Fecha registro', dataIndex: 'createdAt', key: 'registro', render: (t) => moment(t).format('ll') },
    { title: 'Lotes asociados', dataIndex: 'lotes', key: 'lotes' },
    { title: 'Depósito', dataIndex: 'deposito', key: 'deposito' },
    {
      title: 'Acciones',
      dataIndex: 'acciones',
      key: 'acciones',
      render: (text, record) => (
        <div
          className={styles.table__actions}
          onMouseEnter={() => setHandleLink(true)}
          onMouseLeave={() => setHandleLink(false)}
        >
          <Button
            type="text"
            htmlType="button"
            onClick={() => history.push(`/${role}${RESIDENTS_EDIT}`.replace(':residentId', record.id))}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
        </div>
      ),
    },
  ];

  const changePage = (nextPage) => {
    const paginated = {
      p: nextPage,
      l: limit
    }
    dispatch(getresidentsAction(paginated))
  }

  const onSearch = ({ target: { value } }) => {
    if (value === "") return dispatch(getresidentsAction())
    if (isNaN(value) && value.length < 3) return
    if (!isNaN(value)) {
      const paginated = {
        q: `number=${value}`
      }
      dispatch(getresidentsAction(paginated))
    } else {
      const paginated = {
        q: `name=${value}`
      }
      dispatch(getresidentsAction(paginated))
    }
  }

  return (
    <SideLayout type={type} backButton={false}>
      <h2 className="title">Residentes</h2>
      <h3 className="title-description">
        Consulta la información de los residentes de Lomas de Cocoyoc{' '}
      </h3>
      <Seeker
        onSearch={onSearch}
        btnAdd={{
          action: role === 'cajero' ? null : () => history.push(`/${role}${RESIDENTS_ADD}`)
        }}
      />
      <Card>
        <Table
          pagination={false}
          columns={columns}
          onRow={(record) => ({
            onClick: !handleLink && (() => handleRowClick(record.id)),
          })}
          dataSource={residents}
        />
        <Pagination
          showSizeChanger={false}
          pageSize={limit || 50}
          current={page}
          onChange={changePage}
          total={total}
          style={{ marginTop: 32 }} />
      </Card>
    </SideLayout>
  );
}

export default Residents;
