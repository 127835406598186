import React from 'react'
import { Table } from 'antd'

import { Divider, SideLayout, Card } from '../Common'
import Total from '../Common/Total'
import { ADMIN_TYPE } from '../../constants/routes'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatMoney } from '../../helpers/formatMoney'

const columns = [
  {
    title: 'Factura',
    dataIndex: 'waterInvoices',
    key: 'waterInvoices',
    render: (invoices) =>
      invoices.map((inv) => (
        <Link
          to={`/admin/facturas/agua/detalle/${inv.id}`}
          style={{ color: '#2D9CDB' }}
        >
          {inv.id?.slice(0, 4)}
        </Link>
      )),
  },
  {
    title: 'Residente',
    dataIndex: 'lot',
    key: 'lot',
    render: (lot) =>
      lot?.residents?.length
        ? lot?.residents[0]?.firstName + ' ' + lot?.residents[0]?.lastName
        : '',
  },
  {
    title: 'Lote',
    dataIndex: 'lot',
    key: 'lot',
    render: (lot) =>
      lot ? `Sec ${lot.seccion}, Mz ${lot.manzana}, Lt ${lot.lote}` : '',
  },
  {
    title: 'Forma de pago',
    dataIndex: 'paymentForm',
    key: 'paymentForm',
    render: (paymentForm) =>
      paymentForm.reduce((acc, pf) => acc + `${pf.tipo}, `, ''),
  },
  {
    title: '#Recibo',
    dataIndex: 'folio',
    key: 'folio',
    render: (val, rec) => (
      <Link to={`/admin/recibos/${rec.id}`} style={{ color: '#2D9CDB' }}>
        {val}
      </Link>
    ),
  },
  {
    title: 'Importe',
    dataIndex: 'totalPaid',
    key: 'totalPaid',
    render: (val) => <b>{formatMoney(val)}</b>,
  },
]

function AdminBox() {
  const { todayReceipts = [] } = useSelector(({ user }) => user.stats)
  // console.log(todayReceipts[0]?.waterInvoices)
  return (
    <SideLayout type={ADMIN_TYPE}>
      <h2 className="title">Resumen</h2>
      <h3 className="title-description">
        Manten el control de tus cobros del día.
      </h3>
      <Total isGlobal />
      <Divider />
      <Card>
        <Table columns={columns} dataSource={todayReceipts} />
        {/* // update to actual invoices */}
      </Card>
    </SideLayout>
  )
}

export default AdminBox
