import React from 'react';
import { useHistory } from 'react-router-dom';

import { Seeker, Card, SideLayout, UsersTable } from '../Common';
import { USERS_REGISTER, ADMIN_TYPE } from '../../constants/routes';

function Users() {
  const history = useHistory();

  const handleNew = () => {
    history.push(USERS_REGISTER);
  };

  return (
    <SideLayout type={ADMIN_TYPE} backButton={false}>
      <h2 className="title">Usuarios</h2>
      <h3 className="title-description">
        Agrega o edita el rol de los usuarios de Cocoyoc.
      </h3>
      <Seeker noUpload noFilter btnAdd={{ action: handleNew }} />
      <Card>
        <UsersTable />
      </Card>
    </SideLayout>
  );
}

export default Users;
