import React, { useState, useRef } from 'react'

import { Button, Modal, Row, Table, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import { SideLayout, Card } from '../Common'

import styles from './InvoicesAdd.module.css'
import uploadingFilesImage from '../../assets/svgs/UploadingFiles.svg'

import { INVOICES } from '../../constants/routes'
import AddXLSX from '../Invoices/Systems/AddXLSX'

const columns = [
  {
    title: 'Factura',
    dataIndex: 'factura',
    key: 'factura',
  },
  {
    title: 'Residente',
    dataIndex: 'residente',
    key: 'residente',
  },
  {
    title: 'Lote',
    dataIndex: 'lote',
    key: 'lote',
  },
  {
    title: 'Año',
    dataIndex: 'anio',
    key: 'anio',
  },
  {
    title: 'Bim',
    dataIndex: 'bim',
    key: 'bim',
  },
  {
    title: 'Lec. anterior',
    dataIndex: 'la',
    key: 'codigo',
  },
  {
    title: 'Lec. actual',
    dataIndex: 'la',
    key: 'fecha',
  },
  {
    title: 'Consumo',
    dataIndex: 'la',
    key: 'fecha',
  },
  {
    title: 'Costo x m3',
    dataIndex: 'cos',
    key: 'fecha',
  },
  {
    title: 'Importe',
    dataIndex: 'importe',
    key: 'importe',
    align: 'right',
  },
]

const data = [
  {
    key: '1',
    factura: '1234567',
    residente: 'Jose Luis Enriquez',
    lote: 'Sec. 1 Lt. 4 Mz. 6',
    codigo: 'MANT21',
    anio: '2020',
    bim: 'B1',
    la: '280m2',
    fecha: '12/dic/1990',
    recibo: 'AGU-123',
    importe: '$120,000.00',
    estatus: 'Pagado',
    cos: '$23',
  },
  {
    key: '2',
    factura: '1234567',
    residente: 'Jose Luis Enriquez',
    lote: 'Sec. 1 Lt. 4 Mz. 6',
    codigo: 'MANT21',
    anio: '2020',
    bim: 'B1',
    la: '280m2',
    fecha: '12/dic/1990',
    recibo: 'AGU-123',
    importe: '$0.00',
    estatus: 'Pagado',
    cos: '$23',
  },
]

function InvoicesAdd() {
  /**
   * hooks
   */
  const history = useHistory()
  /**
   * Refs
   */
  const inputFile = useRef(null)
  /**
   * states
   */
  const [loading, setLoading] = useState(false)
  const [tablePreview, setTablePreview] = useState(false)

  return (
    <SideLayout backButton={false} classNameContainer={styles.container}>
      <h2 className="title">Agregar facturas</h2>
      <h3 className="title-description">
        Agrega los registros de agua de los residentes.
      </h3>
      <Card className={styles.addinvoice}>
        <Tooltip title="El tipo de archivo soportado es XLSX únicamente">
          <h3>
            Facturas de Agua <InfoCircleOutlined />
          </h3>
        </Tooltip>
        <AddXLSX />
      </Card>
    </SideLayout>
  )
}

export default InvoicesAdd
