import React from 'react';
import { Row, Modal, Button, Form } from 'antd';
import * as XLSX from 'xlsx';

import { useState } from 'react';

import uploadingFilesImage from '../../../assets/svgs/UploadingFiles.svg';
import styles from './InvoicesModalUpload.module.css';

function InvoicesModalUpload({ setTableData }) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalVisivility = (visivility) => {
    setModalVisible(visivility);
  };

  const readFileToRender = async (file) => {
    setModalVisible(true);
    let readPromise = new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          let data = e.target.result;
          let workbook = XLSX.read(data, { type: 'buffer' });
          let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
          let dataTable = XLSX.utils.sheet_to_json(first_worksheet);
          resolve(dataTable);
        };
      } catch (error) {
        reject(error);
      }
    });
    readPromise
      .then((data) => {
        console.log(data);
        setTableData(data);
      })
      .finally(() => {
        setModalVisible(false);
      });
  };

  return (
    <>
      <Form>
        <label htmlFor="xlsxFiles" className={styles.upload__btn}>
          Cargar archivo
        </label>
        <input
          id="xlsxFiles"
          type="file"
          className={styles.hide}
          onChange={(e) => {
            let file = e.target.files[0];
            readFileToRender(file);
          }}
        />
      </Form>
      <Modal
        title="Cargando facturas"
        centered
        visible={modalVisible}
        onOk={() => handleModalVisivility(false)}
        onCancel={() => handleModalVisivility(false)}
        footer={[
          <Button key="back" onClick={() => handleModalVisivility(false)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={true}
            onClick={() => handleModalVisivility(false)}
          />,
        ]}
      >
        <p className="textColor">
          Espera un momento, estamos cargando la información.
        </p>
        <Row justify="center">
          <img src={uploadingFilesImage} alt="upload files" />
        </Row>
      </Modal>
    </>
  );
}

export default InvoicesModalUpload;
