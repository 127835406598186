import React, { useState } from 'react';

import {
  Form, Input, InputNumber,
  Button, Modal, Row, Col,
  Select, Divider
} from 'antd';

import { SideLayout, Card } from '../Common';

import uploadingFilesImage from '../../assets/svgs/UploadingFiles.svg';

function GeneratePenalty() {
  const [loading, setLoading] = useState(false);
  return (
    <SideLayout backButton={false}>
      <h2 className="title">Generar multa</h2>
      <h3 className="title-description">
        Genera la multa al residente en caso de percances dentro de Lomas de Cocoyoc.
      </h3>

      <Card style={{ maxWidth: 580 }}>
        <h3 className="subtitle-strong-24">
          Información de mantenimiento
        </h3>
        <div style={{ marginBottom: 32 }}>
          Completa la información del residente.
        </div>
        <Form layout="vertical">
          <Row>
            <Col>
              <Form.Item label="Sección">
                <InputNumber
                  maxLength={4}
                  placeholder="000"
                  style={{ width: 60 }}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={5}>
              <Form.Item label=" ">
                <Select defaultValue="1">
                  <Select.Option value="1">
                    Lomas
                  </Select.Option>
                  <Select.Option value="2">
                    Sistemas
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={5}>
              <Form.Item label="Manzana:">
                <InputNumber maxLength={4} placeholder="00" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Lote:">
                <InputNumber
                  maxLength={4}
                  placeholder="00"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="Id:">
                <InputNumber
                  placeholder="000"
                  style={{ width: 60 }}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={15}>
              <Form.Item label="Nombre del residente:">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={9}>
              <Form.Item label="Referencia:">
                <Input placeholder="Ingresa una referencia" />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={9}>
              <Form.Item label="Cantidad:">
                <InputNumber
                  placeholder="$00.00"
                  formatter={(value) => value ? `$${value}` : ''}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Descripción:">
            <Row>
              <Col span={20}>
                <Input placeholder="Ingresa un comentario" />
              </Col>
            </Row>
          </Form.Item>

          <div style={{ textAlign: 'center' }}>
            <Button type="primary" onClick={() => setLoading(true)}>
              Generar Multa
            </Button>
          </div>
        </Form>
      </Card>

      <Modal
        visible={loading}
        title="Cargando facturas"
        onCancel={() => setLoading(false)}
        onOk={() => {
          setLoading(false);
        }}
      >
        <p className="textColor">
          Espera un momento, estamos cargando la información.
        </p>
        <Row justify="center">
          <img src={uploadingFilesImage} alt="upload files" />
        </Row>
      </Modal>
    </SideLayout>
  );
}

export default GeneratePenalty;
